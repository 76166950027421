import React from 'react'

function Wallet({ fill }) {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.1667 10.5H8.16669"
                stroke={fill}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M25.6667 12.7974V15.2008C25.6667 15.8424 25.1534 16.3674 24.5 16.3907H22.2134C20.9534 16.3907 19.7984 15.4691 19.6934 14.2091C19.6234 13.4741 19.9034 12.7857 20.3934 12.3074C20.825 11.8641 21.42 11.6074 22.0734 11.6074H24.5C25.1534 11.6308 25.6667 12.1557 25.6667 12.7974Z"
                stroke={fill}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.3933 12.309C19.9033 12.7873 19.6233 13.4756 19.6933 14.2106C19.7983 15.4706 20.9533 16.3923 22.2133 16.3923H24.5V18.084C24.5 21.584 22.1666 23.9173 18.6666 23.9173H8.16665C4.66665 23.9173 2.33331 21.584 2.33331 18.084V9.91732C2.33331 6.74398 4.24665 4.52731 7.22165 4.15398C7.52498 4.10731 7.83998 4.08398 8.16665 4.08398H18.6666C18.97 4.08398 19.2616 4.09564 19.5416 4.1423C22.5516 4.4923 24.5 6.72065 24.5 9.91732V11.609H22.0733C21.42 11.609 20.825 11.8656 20.3933 12.309Z"
                stroke={fill}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default Wallet
