import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import lWhite from '../../../assets/flippay_logo_white.svg'
import vector from '../../../assets/Vector.svg'
import { forgotPassword } from '../../../api'

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState(null)
    const navigate = useNavigate()

    const handleChange = (e) => {
        setEmail(e.target.value)
        setError(null)
    }

    const handleResetPassword = async (e) => {
        e.preventDefault()
        try {
            // Call the forgotPassword API using Axios
            const response = await forgotPassword({ email: email })
            // Handle success, e.g., show a success message

            navigate('/recovery', { state: { email: email } });
        } catch (error) {
            // Handle error, e.g., show an error message
            console.error('Password reset request failed:', error)
            setError('Password reset failed. Please check your email and try again.')
        }
    }

    return (
        <div className="pt-12 md:overflow-y-hidden h-screen bg-emailV bg-50% bg-center-r bg-no-repeat relative">
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-br from-blue-900 via-blue-800 to-purple-900 opacity-70"></div>

            <div className="absolute right-5 top-1 xl:hidden">
                <img src={lWhite} alt="" />
            </div>

            <div className="hidden xl:block absolute xl:bottom-5 xl:right-10">
                <img src={lWhite} alt="" />
            </div>

            <div className="w-full xl:w-1/2 h-full rounded-3xl -top-3 before:rounded-xl before:absolute before:bg-white after:rounded-sm after:absolute after:left-0 after:bg-white after:transform after:-skew-y-0 text-black relative before:w-48 md:before:w-96 before:h-48 md:before:h-96 before:-top-1 md:before:-top-5 before:left-24 md:before:left-48 before:transform before:rotate-45 after:w-48 md:after:w-96 after:h-48 md:after:h-96 after:-top-10 md:after:-top-24">
                <div className="bg-white h-screen rounded-3xl pt-6 pb-28 relative z-10 flex flex-col items-center justify-center">
                    <p className="text-3xl font-bold">Enter email address</p>

                    <div className="px-10 md:px:0">
                        <form action="" className="mt-4">
                            <div className="mb-6">
                                <p className="text-center">
                                    Remember password?{' '}
                                    <Link className="text-blue-500 font-bold" to="/signin">
                                        Back to Login
                                    </Link>
                                </p>
                            </div>

                            <div className="flex items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-6">
                                <div className="w-full">
                                    <label htmlFor="email" className="text-sm font-semibold">
                                        Email address
                                    </label>
                                    <div>
                                        <input
                                            className="focus:border-none focus:outline-none w-full"
                                            type="email"
                                            id="email"
                                            name="email"
                                            required
                                            placeholder="example@gmail.com"
                                            value={email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <p className="mb-6">Please enter your registered email</p>
                            <p className="text-sm italic text-red-500">{error}</p>

                            <div>
                                <button
                                    className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-full"
                                    onClick={handleResetPassword}
                                >
                                    Reset
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="relative -top-48 md:hidden z-20 -right-10">
                    <img src={vector} className="transform rotate-45 scale-50" alt="" />
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
