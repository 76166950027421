import React from 'react'

const MiniTitle = ({ sideImage, title }) => {
    return (
        <div className="flex mb-1.5 items-center justify-start md:hidden">
            <img src={sideImage} alt="" className="mr-1" />
            <p className="text-xs font-bold font-poppins text-warn">{title}</p>
        </div>
    )
}

export default MiniTitle
