import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ProfileCircle from './ProfileCircle'

function ProfileImage() {
    return (
        <li className="flex flex-col items-center lg:mr-6 mr-2 gap-1">
            <ProfileCircle />
            <p className="text-center text-warn text-xs ">Name</p>
        </li>
    )
}

export default ProfileImage
