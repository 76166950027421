import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

const FaqItem = ({ question, answer, answers }) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className="mb-5">
            <div className="flex  justify-start items-center cursor-pointer" onClick={toggleOpen}>
                <div className="mr-5">
                    {isOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                </div>
                <h2 className="text-sm text-cfont18 md:text-xl font-semibold">{question}</h2>
            </div>
            {isOpen && <div className="text-sm md:text-base pl-10">{answer}</div>}
            {isOpen && (
                <ol className="list-decimal mt-3 text-txtcolor font-normal text-sm md:text-base pl-12 md:pl-20">
                    {answers.map((answer, index) => (
                        <li className="mb-1" key={index}>
                            {answer}
                        </li>
                    ))}
                </ol>
            )}
        </div>
    )
}

export default FaqItem
