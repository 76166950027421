import { Modal } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
// import Modal from '@mui/material/Modal'

function CustomModal({ children, open, handleClose }) {
    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`${darkmode ? 'bg-darkSide' : 'bg-white'} bg-opacity-50`}
        >
            <div className={` absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 `}>{children}</div>
        </Modal>
    )
}

export default CustomModal
