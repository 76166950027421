import React, { useState } from 'react'
import CustomModal from './CustomModal'
import { useDispatch, useSelector } from 'react-redux'
import dsafe from '../../assets/d_security-safe.svg'
import safe from '../../assets/safe.svg'
import CustomInput from './CustomInput'
import { verifybvn } from '../../api'

function BVNModal({ open, closeModal }) {
    const darkmode = useSelector((state) => state.userReducer.dark)
    const [bvnnumber, setBvnnumber] = useState('')
    const dispatch = useDispatch()

    const handleBvnchange = (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }
        setBvnnumber(value)
    }
    const handlesubmit = async () => {
        try {
            dispatch({ type: 'SETLOADING' })
            await verifybvn({
                number: bvnnumber
            })
            closeModal()
        } catch (err) {
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }
    const handledisbled = () => {
        return bvnnumber.length != 11
    }

    if (!open) {
        return null
    }
    return (
        <CustomModal open={open} handleClose={closeModal}>
            <div
                className={`${
                    darkmode ? 'bg-darkSide text-white' : 'bg-white text-black'
                } p-8 shadow-3xl rounded-3xl flex items-center flex-col w-96`}
            >
                <div className="flex justify-center">
                    <img src={darkmode ? dsafe : safe} alt="" />
                </div>
                <p className="text-cfont32 font-poppins font-semibold text-center mb-10">Bank Verification Number.</p>

                <div className="max-w-xs w-full ">
                    <CustomInput
                        value={bvnnumber}
                        handlechange={handleBvnchange}
                        title={'BVN Number'}
                        name={'number'}
                        placeholder={'Enter Number'}
                    />
                    <div className="flex w-full justify-center mt-6">
                        <button
                            className={` bg-cblue font-bold  text-white p-5 rounded-cround text-cfont14  w-full disabled:bg-gray-400  `}
                            disabled={handledisbled()}
                            onClick={handlesubmit}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default BVNModal
