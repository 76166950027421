import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import doorLock from '../../../../assets/door-lock.svg'
import doorLockBlue from '../../../../assets/door-lock-blue.svg'
import lock from '../../../../assets/lock.svg'
import lockBlue from '../../../../assets/lockBlue.svg'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import CPin from './CPin'
import CPassword from './CPassword'
import ResetPin from './ResetPin'
import { useSelector } from 'react-redux'
import wChangePin from '../../../../assets/w_changePin.svg'
import wLock from '../../../../assets/w_lock.svg'

const Security = ({ isResetTrue }) => {
    const [isCPinOpen, setIsCPinOpen] = useState(false)
    const [isCPasswordOpen, setIsCPasswordOpen] = useState(false)
    const [isResetPin, setIsResetPin] = useState(false)
    const [isOn, setIsOn] = useState(false)
    const [isRequestOn, setIsRequestOn] = useState(false)

    const handleClick = () => {
        setIsOn(!isOn)
    }

    const handleRequestClick = () => {
        setIsRequestOn(!isRequestOn)
    }

    const toggleCPin = () => {
        setIsCPinOpen(!isCPinOpen)
    }

    const toggleCPassword = () => {
        setIsCPasswordOpen(!isCPasswordOpen)
    }

    const toggleReset = () => {
        setIsResetPin(!isResetPin)
    }

    useEffect(() => {
        // Check if isTrue is true and set isOpen to true
        if (isResetTrue) {
            setIsResetPin(true)
        }
    }, [isResetTrue])

    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <div
            className={`flex flex-col items-center mt-6 px-5 lg:px-20 ${
                darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'
            }`}
        >
            <div className="flex justify-between w-3/4 mb-5 cursor-pointer" onClick={toggleCPin}>
                <div className="flex items-start">
                    <img
                        src={darkmode && !isCPinOpen ? wChangePin : isCPinOpen ? doorLockBlue : doorLock}
                        alt=""
                        className="mr-3"
                    />

                    <div>
                        <p
                            className={`${
                                darkmode && !isCPinOpen ? 'text-white' : isCPinOpen ? 'text-cblue' : 'text-boldgray'
                            } font-bold`}
                        >
                            Change PIN
                        </p>
                        <p
                            className={`text-xs lg:text-sm ${
                                darkmode && !isCPinOpen ? 'text-properGrey' : isCPinOpen ? 'text-cblue' : 'text-greyy'
                            }`}
                        >
                            Change your transaction PIN
                        </p>
                    </div>
                </div>

                <FontAwesomeIcon icon={isCPinOpen ? faAngleDown : faAngleRight} />
            </div>

            <div className="flex justify-between w-3/4 mb-5 cursor-pointer" onClick={toggleCPassword}>
                <div className="flex items-start">
                    <img
                        src={darkmode && !isCPasswordOpen ? wLock : isCPasswordOpen ? lockBlue : lock}
                        alt=""
                        className="mr-3"
                    />

                    <div>
                        <p
                            className={`${
                                darkmode && !isCPasswordOpen
                                    ? 'text-white'
                                    : isCPasswordOpen
                                    ? 'text-cblue'
                                    : 'text-boldgray'
                            } font-bold`}
                        >
                            Change Password
                        </p>
                        <p
                            className={`text-xs lg:text-sm ${
                                darkmode && !isCPasswordOpen
                                    ? 'text-properGrey'
                                    : isCPasswordOpen
                                    ? 'text-cblue'
                                    : 'text-greyy'
                            }`}
                        >
                            Change your login password
                        </p>
                    </div>
                </div>

                <FontAwesomeIcon icon={isCPasswordOpen ? faAngleDown : faAngleRight} />
            </div>

            <div className="flex justify-between w-3/4 mb-5 cursor-pointer" onClick={toggleReset}>
                <div className="flex items-start">
                    <img
                        src={darkmode && !isResetPin ? wChangePin : isResetPin ? doorLockBlue : doorLock}
                        alt=""
                        className="mr-3"
                    />

                    <div>
                        <p
                            className={`${
                                darkmode && !isResetPin ? 'text-white' : isResetPin ? 'text-cblue' : 'text-boldgray'
                            } font-bold`}
                        >
                            Reset PIN
                        </p>
                        <p
                            className={`text-xs lg:text-sm ${
                                darkmode && !isResetPin ? 'text-properGrey' : isResetPin ? 'text-cblue' : 'text-greyy'
                            }`}
                        >
                            Reset forgotten PIN
                        </p>
                    </div>
                </div>

                <FontAwesomeIcon icon={isResetPin ? faAngleDown : faAngleRight} />
            </div>

            <CPin open={isCPinOpen} toggleCPin={toggleCPin} />
            <CPassword open={isCPasswordOpen} toggleCPassword={toggleCPassword} />
            <ResetPin open={isResetPin} toggleReset={toggleReset} />
        </div>
    )
}

export default Security
