import { faUser } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function TransactOne({ beneficiary }) {
    const dateString = beneficiary.createdAt
    const dateObject = new Date(dateString)
    const timeString = dateObject.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })
    return (
        <li className="flex justify-between items-center w-full">
            <div className="flex items-center">
                <FontAwesomeIcon
                    icon={faUser}
                    className="p-2 border-2 rounded-full text-lg mr-2"
                    style={{ color: '#8b8e92', borderColor: '#007BFF' }}
                />
                <div className="ml-2">
                    {beneficiary.tranType === 'receiving' && (
                        <p style={{ fontSize: '10px', fontWeight: '600' }} className=" text-left font-semibold">
                            {beneficiary?.to_from?.split('/')[0]} sent you money
                        </p>
                    )}

                    {beneficiary.tranType === 'sending' && (
                        <p style={{ fontSize: '10px', fontWeight: '600' }} className=" text-left font-semibold">
                            Transfer to {beneficiary?.to_from?.split('/')[0]}
                        </p>
                    )}

                    <p
                        style={{ fontSize: '10px', fontWeight: '600' }}
                        className={`text-left ${
                            beneficiary.tranType === 'receiving'
                                ? ' text-green-600'
                                : beneficiary.tranType === 'sending'
                                ? ' text-red-600'
                                : 'text-black'
                        } font-semibold`}
                    >
                        N{beneficiary.amount}
                    </p>
                </div>
            </div>

            <p style={{ fontSize: '10px', fontWeight: '400' }} className="text-xs">
                {timeString}
            </p>
        </li>
    )
}

export default TransactOne
