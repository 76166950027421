import * as api from '../../api'

export const getAllRawCardInformation = () => async (dispatch) => {
    try {
        const { data } = await api.getAllRawCardsNoAuth()

        dispatch({ type: 'GET_RAW_GIFTCARDS', data: data?.data })
    } catch (error) {
        // console.log(error);
        console.log(error)
    }
}
