import card from '../../../assets/cardsInit.svg'
import coin from '../../../assets/coin.svg'
import convert from '../../../assets/convert.svg'
import receipt from '../../../assets/receipt-text.svg'
import steam from '../../../assets/steam_card.svg'
import ebay from '../../../assets/ebay-logo.svg'
import itunesLogo from '../../../assets/itunes-logo.svg'
import targetLogo from '../../../assets/target-logo.svg'
import att from '../../../assets/at-t.svg'
import cards from '../../../assets/cards.svg'
import card2 from '../../../assets/cards2.svg'
import frame9 from '../../../assets/frame-9.svg'
import check from '../../../assets/check.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import FooterComponent from '../../LandingPage/LandingPageComponents/FooterComponent'
import Dot from '../../UniversalComponents/Dot'
import CustomDropdown from '../../UniversalComponents/CustomDropdown'
import cardimg from '../../../assets/cardbackground.png'
import CardAmount from '../../UniversalComponents/CardAmount'
import CardDetail from '../../UniversalComponents/CardDetail'
import fingerprint from '../../../assets/fingerprint.png'
import secure from '../../../assets/security-safe-init.svg'
import Beneficiary from '../../UniversalComponents/Beneficiary'
import dashBoardAsh from '../../../assets/view-dashboard-ash.svg'
import MiniTitle from '../../UniversalComponents/MiniTitle'

const PilletButton = ({ link, imgsrc, name }) => {
    return (
        <button
            onClick={link}
            className="bg-cblue hover:bg-cdarkblue flex items-center justify-between px-5 py-3 rounded-3xl gap-1 mb-5 lg:mb-0"
            style={{
                maxWidth: '151px',
                width: '100%'
            }}
        >
            <p className="text-white font-medium text-xs">{name}</p>
            <div>
                <img src={imgsrc} alt="" />
            </div>
        </button>
    )
}

const MainMid = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [selectedOption, setSelectedOption] = useState('')
    const [option, setOption] = useState(false)

    const toggleOption = () => {
        setOption(!option)
    }

    const handleOptionChange = (opt) => {
        setSelectedOption(opt)
        toggleOption()
    }

    const handleClickTransfer = () => {
        navigate('/wallet', { state: { isTrueTransfer: true } })
    }

    const handleClickBill = () => {
        navigate('/topup', { state: { isTrueBill: true } })
    }

    const handleClickAirtime = () => {
        navigate('/topup', { state: { isTrueAirtime: true } })
    }

    const handleClickGift = () => {
        navigate('/giftcard')
    }

    useEffect(() => {
        // dispatch()
    }, [])
    const user = useSelector((state) => state.userReducer.user)
    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <div className={`${darkmode ? 'bg-darkAll' : 'bg-white'} text-${darkmode ? 'white' : 'black'} w-full`}>
            <div className="max-w-2xl py-3 px-5 lg:px-0">
                <MiniTitle sideImage={dashBoardAsh} title="Dashboard" />

                <p className="text-4xl">
                    Welcome back, <br />
                    <p className=" font-semibold">
                        <span>{user?.fname?.split(' ')[0]}</span>
                        <Dot />
                    </p>
                </p>

                <p className="text-sm mt-3 mb-8">Glad to have you back 😊.</p>
                <div className="block md:flex gap-4">
                    <CardAmount
                        bgcolor={'#213B98'}
                        sidecontent={
                            user.verified ? (
                                <span>
                                    <img src={check} alt="" />
                                </span>
                            ) : (
                                <p>
                                    <span>Card transactions </span>
                                    <span>0/1</span>
                                </p>
                            )
                        }
                        bgimg={cardimg}
                    />
                    <CardDetail
                        title="We take care of your privacy and security."
                        subtitle="You matter to us!"
                        footertitle="Flippay puts your safety first"
                        cardimg={fingerprint}
                        footerimg={secure}
                    />
                </div>

                <div className="flex flex-wrap lg:flex-nowrap justify-between mt-4">
                    <PilletButton name="Gift cards" imgsrc={card} link={handleClickGift} />
                    <PilletButton link={handleClickTransfer} imgsrc={convert} name={'Transfers'} />
                    <PilletButton link={handleClickBill} name={'Pay bills'} imgsrc={receipt} />
                    <PilletButton link={handleClickAirtime} name={'Airtime/Data'} imgsrc={coin} />
                </div>

                <Beneficiary />
            </div>
            <section>
                <div className="relative z-40 text-black">
                    <div className="block md:flex justify-between px-5 lg:px-0">
                        <p
                            className={`md:font-semibold text-2xl md:text-base mb-12 font-semibold text-${
                                darkmode ? 'white' : 'black'
                            }`}
                        >
                            See free summaries about <br /> any card on Flippay!
                        </p>

                        <CustomDropdown placeholder={'Search gift card'} />
                    </div>
                </div>

                <div className="md:hidden">
                    <div className="z-10 px-10 mt-4 mb-10 lg:mb-0">
                        <img src={steam} alt="steam" className="w-full" />
                    </div>

                    <div className="flex flex-nowrap my-1 scrollbar-hide overflow-x-scroll">
                        <div className="z-10 min-w-max">
                            <img src={ebay} alt="ebay" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max mx-1">
                            <img src={targetLogo} alt="target" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max">
                            <img src={card2} alt="gift-card" className="w-44" />
                        </div>
                    </div>

                    <div className="flex flex-nowrap scrollbar-hide overflow-x-scroll">
                        <div className="z-10  min-w-max">
                            <img src={cards} alt="google-play" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max mx-1">
                            <img src={att} alt="at&t" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max">
                            <img src={itunesLogo} alt="itunes" className="w-44" />
                        </div>
                    </div>
                </div>

                <div className="hidden md:grid grid-cols-12 grid-rows-3 gap-1">
                    <div className="col-span-5 row-span-3 z-10">
                        <img src={steam} alt="steam" />
                    </div>
                    <div className="col-span-4 z-10">
                        <img src={ebay} alt="ebay" />
                    </div>
                    <div className="col-span-3 z-10">
                        <img src={itunesLogo} alt="itunes" />
                    </div>
                    <div className="col-span-3 z-10">
                        <img src={targetLogo} alt="target" />
                    </div>
                    <div className="col-span-3 z-10">
                        <img src={att} alt="at&t" />
                    </div>
                    <div className="col-span-1 row-span-2 z-10">
                        <img src={frame9} alt="frame-9" />
                    </div>
                    <div className="relative -top-5 col-span-3 z-10">
                        <img src={cards} alt="gift-card" />
                    </div>
                    <div className="relative -top-5 col-span-3 z-10">
                        <img src={card2} alt="google-play" />
                    </div>
                </div>
            </section>
            <section className="pl-5 text-white bg-black relative rounded-b-3xl md:hidden">
                <FooterComponent />
            </section>

            <section
                className={`text-center pt-5 relative before:w-full before:absolute before:-top-5 before:left-0 before:-bottom-5 before:bg-white before:z-[-1] flex flex-col md:flex-row justify-between items-center md:hidden ${
                    darkmode ? 'bg-darkAll' : 'bg-white'
                } text-${darkmode ? 'white' : 'black'}`}
            >
                <p className="w-52 md:w-auto text-sm mb-5 md:mb-0">
                    All rights reserved. Copyrights © 2023 Flippay. co.{' '}
                </p>
                <p className="text-sm">
                    Owned by PayNyce Nigeria Ltd, a parent company registered under the Corporate Affairs Commission
                    (CAC) with RC 1731068
                </p>
            </section>
        </div>
    )
}

export default MainMid
