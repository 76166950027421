import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Stepper, Step, StepLabel } from '@mui/material'
import { faAngleDown, faAngleRight, faAngleUp, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import FooterComponent from '../../LandingPage/LandingPageComponents/FooterComponent'

import uk from '../../../assets/UK.svg'
import usa from '../../../assets/USA.svg'
import eu from '../../../assets/EU.svg'
import nigeria from '../../../assets/Nigeria.svg'
import steamcard from '../../../assets/streamcard.svg'
import cardvalue from '../../../assets/cardvalue.svg'
import gtype from '../../../assets/g-type.svg'
import gallery from '../../../assets/gallery.svg'
import PINInput from '../../Account/AccountComponent/Security/PINInput'
import { Link, useNavigate } from 'react-router-dom'
import GPopup from './GPopup'
import CardAmount from '../../UniversalComponents/CardAmount'
import check from '../../../assets/check.svg'
import GiftCardPreview from '../../UniversalComponents/GiftCardPreview'
import { getAllRawCardInformation } from '../../../redux/actions/giftcardAction'
import { comparePin, uploadGiftCard } from '../../../api'
import galleryGreen from '../../../assets/gallery_green.svg'

const countries = [
    {
        title: 'USD',
        image: usa
    },
    {
        title: 'GBP',
        image: uk
    },
    {
        title: 'EUR',
        image: eu
    },
    {
        title: 'NGN',
        image: nigeria
    }
]

const formatRate = {
    eCode: 'E-card',
    largeCard: 'Large card',
    others: 'Other card',
    physicalCard: 'Physical Card',
    standardCard: 'Standard Card',
    smallCard: 'Small Card'
}

const steps = ['Details', 'Upload Images', 'PIN & Sale']

const allGiftCards = ['Vanilla', 'Ebay', 'Steam', 'Target', 'Walmart']
const cardCategories = ['eCode', 'largeCard', 'others', 'standardCard', 'smallCard']
const cardCategoriestransform = {
    eCode: 'E-Code',
    largeCard: 'Large-card',
    others: 'Others',
    standardCard: 'Standard-Card',
    smallCard: 'Small-Card'
}
const GMid = () => {
    const navigate = useNavigate()
    const [option, setOption] = useState(false)

    const [istoggleGPopup, setIsToggleGPopup] = useState(true)

    const toggleGPopup = () => {
        setIsToggleGPopup(!istoggleGPopup)
    }
    const [isCountry, setIsCountry] = useState(false)
    const [isComplete, setIsComplete] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [receiptAvailable, setReceiptAvailable] = useState('Yes')
    const [showPassword, setShowPassword] = useState(true)
    const [pin, setPin] = useState('')
    const [isCard, setIsCard] = useState(false)
    const [isCardCategory, setIsCardCategory] = useState(false)
    const [selectedImages, setSelectedImages] = useState([])
    const [selectedImagesTwo, setSelectedImagesTwo] = useState([])
    const [selectedCard, setSelectedCard] = useState('')
    const [selectedCardCategory, setSelectedCardCategory] = useState('')
    const [currrentCardInfo, setCurrentCardInfo] = useState({})
    const [cardValue, setCardValue] = useState('')
    const [selectedCountry, setSelectedCountry] = useState('')
    const cards = useSelector((state) => state.giftcardReducer.cards)
    const dispatch = useDispatch()
    const [error, setError] = useState('')
    const [success, setSucces] = useState('')

    // const [giftCardInfo, setGiftCardInfo] = useState({})

    // console.log('grr', giftCardInfo[selectedCard.toUpperCase()][0].countryIds)

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    useEffect(() => {
        dispatch(getAllRawCardInformation())
    }, [])

    useEffect(() => {
        if (activeStep === 0) {
            if (selectedCard && selectedCountry && cardValue) {
                setIsComplete(true)
            } else {
                setIsComplete(false)
            }
        } else if (activeStep === 1) {
            setIsComplete(false)
            if (receiptAvailable === 'Yes' && selectedImages.length > 0 && selectedImagesTwo.length > 0) {
                setIsComplete(true)
            } else if (receiptAvailable === 'No' && selectedImages.length > 0) {
                setIsComplete(true)
            } else {
                setIsComplete(false)
            }
        } else if (activeStep === 2) {
            setIsComplete(false)
            if (pin.length === 4) {
                setIsComplete(true)
            }
        }
    }, [activeStep, receiptAvailable, selectedCard, selectedCountry, cardValue, selectedImages, selectedImagesTwo, pin])

    const removeImage = (index) => {
        setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index))
    }

    const removeImageTwo = (index) => {
        setSelectedImagesTwo((prevImages) => prevImages.filter((_, i) => i !== index))
    }

    const handleLabelClick = () => {
        document.getElementById('uploadGiftCard').click()
    }

    const handleLabelClickTwo = () => {
        document.getElementById('uploadReceipt').click()
    }

    const handleImageChange = (event) => {
        const files = event.target.files

        // Convert FileList to an array
        const selectedImagesArray = Array.from(files)

        // Update the state with the selected images
        setSelectedImages((prevImages) => [...prevImages, ...selectedImagesArray])
    }

    const handleImageChangeTwo = (event) => {
        const files = event.target.files

        // Convert FileList to an array
        const selectedImagesArrayTwo = Array.from(files)

        // Update the state with the selected images
        setSelectedImagesTwo((prevImages) => [...prevImages, ...selectedImagesArrayTwo])
    }

    const handleInputChange = (e) => {
        setCardValue(e.target.value)
    }

    const handleReceiptAvailable = (event) => {
        setReceiptAvailable(event.target.value)
    }

    const toggleOption = () => {
        setOption(!option)
    }

    const handleOptionChange = (opt) => {
        setCurrentCardInfo(opt)
        toggleOption()
    }

    const toggleCard = () => {
        setIsCard(!isCard)
    }

    const handleCardChange = (cat) => {
        setSelectedCard(cat)
        setSelectedCountry({})
        setSelectedCardCategory('')
        toggleCard()
    }

    const toggleCountry = () => {
        setIsCountry(!isCountry)
    }

    const handleCountryChange = (cou) => {
        setSelectedCountry(cou)
        setSelectedCardCategory('')
        toggleCountry()
    }

    const toggleCardCategory = () => {
        setIsCardCategory(!isCardCategory)
    }

    const handleCardCategoryChange = (ccat) => {
        setSelectedCardCategory(ccat)
        toggleCardCategory()
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleSubmit = async () => {
        try {
            const newobj = new FormData()
            newobj.append('cardType', selectedCard?.cardType)
            newobj.append('currency', selectedCountry?.currencyCode)
            newobj.append('rate', selectedCountry[selectedCardCategory])
            newobj.append('country', selectedCountry.countryName)
            newobj.append('value', cardValue)
            newobj.append('cardCategory', cardCategoriestransform[selectedCardCategory])

            for (let i = 0; i < selectedImages.length; i++) {
                let image = selectedImages[i]
                newobj.append('images', image)
            }

            dispatch({ type: 'SETLOADING' })

            // return
            await comparePin({ pin })
            await uploadGiftCard(newobj)

            // success
            setSucces('Giftcard successfully uploaded')

            //  programmatical redirect to dashboard
            setTimeout(() => {
                navigate('/dashboard')
            }, 1000)
        } catch (err) {
            console.log(err)
            setError(err?.response?.data?.message)
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const user = useSelector((state) => state.userReducer.user)

    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <div className={`${darkmode ? 'bg-darkAll' : 'bg-white'} text-${darkmode ? 'white' : 'black'} w-full`}>
            <div className="max-w-2xl py-3">
                <div className="py-3 px-5 lg:px-0">
                    <p className="text-4xl">Sell gift cards</p>

                    <p className="text-sm mt-3 mb-8">Start trading any gift card..</p>

                    <div className="block md:flex gap-4">
                        <CardAmount
                            bgcolor={'#213B98'}
                            sidecontent={<img src={check} className="-translate-y-2" alt="side" />}
                            // bgimg={cardimg}
                        />

                        <GiftCardPreview
                            cardValue={cardValue}
                            selectedCountry={selectedCountry}
                            selectedCardCategory={selectedCardCategory}
                        />
                    </div>

                    <div className={`relative md:z-40 ${darkmode ? 'text-white' : 'text-black'} mt-7`}>
                        <div className="block md:flex justify-between">
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label, index) => (
                                    <Step key={index}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </div>
                </div>

                {activeStep === 0 && (
                    <div>
                        {/* <div className="px-5 lg:px-10">
                            <ul className="flex gap-5 justify-center">
                                {countries.map((country, index) => (
                                    <li className="" key={index}>
                                        <div
                                            className="rounded-full border grid place-items-center border-grayed cursor-pointer text-warn"
                                            style={{
                                                width: '41px',
                                                height: '41px'
                                            }}
                                        >
                                            <div className="bg-white flex flex-col items-center gap-1">
                                                <img src={country.image} alt="" className=" text-3xl" />
                                                <p style={{ fontSize: '8px' }} className="text-warn">
                                                    {country.title}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                ))}

                                <li className="">
                                    <div
                                        className="rounded-full border grid place-items-center border-grayed cursor-pointer text-warn"
                                        style={{
                                            width: '41px',
                                            height: '41px'
                                        }}
                                    >
                                        <div className="bg-white flex flex-col items-center gap-1">
                                            <FontAwesomeIcon icon={faAngleDown} className=" text-xs" />
                                            <p style={{ fontSize: '8px' }} className="text-warn">
                                                more
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div> */}

                        <div className="flex justify-center px-5 lg:px-10 mt-8">
                            <div className="relative w-4/5 md:w-5/6 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl mb-4 mr-2.5">
                                <label htmlFor="category" className="text-sm font-semibold" onClick={toggleCard}>
                                    Select Card
                                </label>
                                <div className="flex items-center justify-between w-full rounded-2xl py-1">
                                    <input
                                        type="text"
                                        id="category"
                                        readOnly
                                        required
                                        value={selectedCard.cardType}
                                        className="outline-none w-full cursor-pointer bg-transparent"
                                        onClick={toggleCard}
                                    />

                                    <div className="cursor-pointer" onClick={toggleCard}>
                                        <FontAwesomeIcon icon={isCard ? faAngleUp : faAngleDown} />
                                    </div>
                                </div>

                                {isCard && (
                                    <div
                                        className={`absolute right-0 mt-1 w-2/3 rounded-3xl rounded-tr-none shadow-lg z-20 max-h-64 scrollbar-hide overflow-y-scroll ${
                                            darkmode
                                                ? 'bg-ccdarkblue text-white border-cdarkblue'
                                                : 'bg-white text-black border-gray-300'
                                        }`}
                                    >
                                        <p className="px-4 text-xs font-medium text-warn py-1">Card types</p>
                                        {/* {Object.keys(selectedCountry).map((rate, i) => {
                                            if (
                                                rate === 'countryName' ||
                                                rate === '_id' ||
                                                rate === '__v' ||
                                                selectedCountry[rate] === '' ||
                                                rate === 'currencyCode' ||
                                                rate === 'createdAt' ||
                                                rate === 'updatedAt' ||
                                                rate === 'countryImage'
                                            ) {
                                                return null
                                            } else {
                                                return (
                                                    <div
                                                        key={rate}
                                                        value={rate}
                                                        className={`px-4 py-2 cursor-pointer text-xs flex ${
                                                            darkmode
                                                                ? 'hover:text-black hover:bg-gray-400'
                                                                : 'hover:bg-gray-100'
                                                        }`}
                                                        onClick={() => handleCardChange(rate)}
                                                    >
                                                        {formatRate[rate] || rate}
                                                    </div>
                                                )
                                            }
                                        }
                                        
                                        )} */}

                                        {cards.map((oneGiftCard) => (
                                            <div
                                                key={oneGiftCard}
                                                value={oneGiftCard}
                                                className={`px-4 py-2 cursor-pointer text-xs flex ${
                                                    darkmode
                                                        ? 'hover:text-black hover:bg-gray-400'
                                                        : 'hover:bg-gray-100'
                                                }`}
                                                onClick={() => handleCardChange(oneGiftCard)}
                                            >
                                                {oneGiftCard.cardType}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div className="relative w-4/5 md:w-5/6 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl mb-4">
                                <label htmlFor="country" className="text-sm font-semibold" onClick={toggleCountry}>
                                    Select Country
                                </label>
                                <div className="flex items-center justify-between w-full rounded-2xl py-1">
                                    <input
                                        type="text"
                                        id="country"
                                        readOnly
                                        required
                                        value={selectedCountry.countryName || ''}
                                        className="outline-none w-full cursor-pointer bg-transparent"
                                        onClick={toggleCountry}
                                    />

                                    <div className="cursor-pointer" onClick={toggleCountry}>
                                        <FontAwesomeIcon icon={isCountry ? faAngleUp : faAngleDown} />
                                    </div>
                                </div>

                                {isCountry && (
                                    <div
                                        className={`absolute right-0 mt-1 w-2/3 rounded-3xl rounded-tr-none shadow-lg z-20 max-h-64 scrollbar-hide overflow-y-scroll ${
                                            darkmode
                                                ? 'bg-ccdarkblue text-white border-cdarkblue'
                                                : 'bg-white text-black border-gray-300'
                                        }`}
                                    >
                                        <p className="px-4 text-xs font-medium text-warn py-1">Countries</p>
                                        {selectedCard?.countryIds?.map((country) => (
                                            <div
                                                key={country?._id ?? 'defaultId'}
                                                value={country?.countryName ?? 'Default Country'}
                                                className={`px-4 py-2 cursor-pointer text-xs flex ${
                                                    darkmode
                                                        ? 'hover:text-black hover:bg-gray-400'
                                                        : 'hover:bg-gray-100'
                                                }`}
                                                onClick={() => handleCountryChange(country)}
                                            >
                                                {country?.countryName ?? 'Default Country'}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="mt-5 flex justify-center px-5 lg:px-10 items-center">
                            <div className="relative w-full md:w-full border-gray-300 border hover:border-gray-300 rounded-2xl mb-4 px-5 lg:px-10">
                                <label htmlFor="country" className="text-sm font-semibold" onClick={toggleCardCategory}>
                                    Select Card Type
                                </label>
                                <div className="flex items-center justify-between w-full rounded-2xl py-1">
                                    <input
                                        type="text"
                                        id="cardCategory"
                                        readOnly
                                        required
                                        value={selectedCardCategory}
                                        className="outline-none w-full cursor-pointer bg-transparent"
                                        onClick={toggleCardCategory}
                                    />

                                    <div className="cursor-pointer" onClick={toggleCardCategory}>
                                        <FontAwesomeIcon icon={isCardCategory ? faAngleUp : faAngleDown} />
                                    </div>
                                </div>

                                {isCardCategory && (
                                    <div
                                        className={`absolute right-0 mt-1 w-2/3 rounded-3xl rounded-tr-none shadow-lg z-20 max-h-64 scrollbar-hide overflow-y-scroll ${
                                            darkmode
                                                ? 'bg-ccdarkblue text-white border-cdarkblue'
                                                : 'bg-white text-black border-gray-300'
                                        }`}
                                    >
                                        <p className="px-4 text-xs font-medium text-warn py-1">Card Categories</p>
                                        {cardCategories.map((oneCard) => {
                                            if (selectedCountry[oneCard] == '' || selectedCountry[oneCard] == 0) {
                                                return null
                                            }
                                            return (
                                                <div
                                                    key={oneCard}
                                                    value={oneCard}
                                                    className={`px-4 py-2 cursor-pointer text-xs flex ${
                                                        darkmode
                                                            ? 'hover:text-black hover:bg-gray-400'
                                                            : 'hover:bg-gray-100'
                                                    }`}
                                                    onClick={() => handleCardCategoryChange(oneCard)}
                                                >
                                                    {oneCard}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="mt-5 flex justify-center px-5 lg:px-10 items-center">
                            <div className="flex items-center justify-between w-4/5 md:w-4/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-gray-300 mb-4 mr-2.5">
                                <div className="w-full flex flex-col items-start">
                                    <label htmlFor="cardValue" className="text-sm font-semibold">
                                        Card value
                                    </label>
                                    <div className="w-full">
                                        <input
                                            className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                            type="number"
                                            id="cardValue"
                                            name="cardValue"
                                            required
                                            placeholder="0"
                                            value={cardValue}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-2/6">
                                <p className="text-green-600 font-semibold">₦{selectedCountry[selectedCardCategory]}</p>
                                <p className="font-medium text-sm">Current rate in Naira</p>
                            </div>
                        </div>

                        <p className="mt-8 mb-6 px-5 lg:px-10 text-sm font-medium">Transaction summary</p>

                        <div className="px-5 lg:px-10">
                            <div className="flex justify-between items-center">
                                <div className="flex items-center">
                                    <img src={steamcard} alt="" className="mr-2" />
                                    <p>
                                        {selectedCard?.cardType} rate ({selectedCountry.currencyCode})
                                    </p>
                                </div>
                                <p className="font-semibold">₦{selectedCountry[selectedCardCategory]}</p>
                            </div>

                            <div className="flex justify-between items-center mt-2.5">
                                <div className="flex items-center">
                                    <img src={cardvalue} alt="" className="mr-2" />
                                    <p>Card value</p>
                                </div>
                                <p className="font-semibold">{cardValue}</p>
                            </div>

                            <div className="flex justify-between items-center mt-2.5">
                                <div className="flex items-center">
                                    <img src={gtype} alt="" className="mr-2" />
                                    <p>Type</p>
                                </div>
                                <p className="font-semibold"> {selectedCard?.cardType}</p>
                            </div>

                            <div className="flex justify-between items-center mt-2.5">
                                <div className="flex items-center">
                                    <p>Expected payment</p>
                                </div>
                                <p className="font-semibold">N{cardValue * selectedCountry[selectedCardCategory]}</p>
                            </div>
                        </div>
                    </div>
                )}

                {activeStep === 1 && (
                    <div className="px-5 lg:px-10">
                        <p className="text-sm font-medium mt-8">Upload Images</p>
                        <p className="font-semibold text-xs mt-6 ml-5 lg:ml-10">Card receipt?</p>
                        <div className="flex items-center justify-start w-2/3 ml-5 lg:ml-10 mt-2.5">
                            <label className="flex items-center mr-8">
                                <input
                                    type="radio"
                                    name="receiptAvailable"
                                    value="Yes"
                                    checked={receiptAvailable === 'Yes'}
                                    onChange={handleReceiptAvailable}
                                />
                                <span className="ml-2">Yes</span>
                            </label>
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="receiptAvailable"
                                    value="No"
                                    checked={receiptAvailable === 'No'}
                                    onChange={handleReceiptAvailable}
                                />
                                <span className="ml-2">No</span>
                            </label>
                        </div>

                        <div className="mt-6 md:flex">
                            <div>
                                <label
                                    onClick={handleLabelClick}
                                    className={`flex items-center justify-between w-4/5 md:w-4/6 rounded-2xl border-dashed border-2 cursor-pointer ${
                                        selectedImages.length > 0 ? 'border-green-500' : 'border-cblue'
                                    } px-4 py-6 mb-6 mr-5`}
                                >
                                    <div className="w-full flex flex-col items-center">
                                        <img src={selectedImages.length > 0 ? galleryGreen : gallery} alt="" />
                                        <p className="text-sm text-cblue">Upload card image</p>
                                    </div>
                                </label>

                                {selectedImages.length > 0 && (
                                    <div className="w-full max-h-64 scrollbar-hide overflow-y-scroll mb-2">
                                        {selectedImages.map((image, index) => (
                                            <div key={index} className="relative">
                                                <img
                                                    src={URL.createObjectURL(image)}
                                                    alt={`Selected ${index + 1}`}
                                                    className="w-full h-auto rounded-2xl mb-6"
                                                />
                                                <button
                                                    onClick={() => removeImage(index)}
                                                    className="absolute top-0 right-0 bg-red-500 text-white p-2 rounded-full"
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {receiptAvailable === 'Yes' && (
                                <div>
                                    <label
                                        onClick={handleLabelClickTwo}
                                        className={`flex items-center cursor-pointer justify-between w-4/5 md:w-4/6 rounded-2xl border-dashed border-2 ${
                                            selectedImagesTwo.length > 0 ? 'border-green-500' : 'border-cblue'
                                        } px-6 py-6 mb-4`}
                                    >
                                        <div className="w-full flex flex-col items-center">
                                            <img src={selectedImagesTwo.length > 0 ? galleryGreen : gallery} alt="" />
                                            <p className="text-sm text-cblue">Upload receipt</p>
                                        </div>
                                    </label>

                                    {selectedImagesTwo.length > 0 && (
                                        <div className="w-full max-h-64 scrollbar-hide overflow-y-scroll mb-2">
                                            {selectedImagesTwo.map((image, index) => (
                                                <div key={index} className="relative">
                                                    <img
                                                        src={URL.createObjectURL(image)}
                                                        alt={`Selected ${index + 1}`}
                                                        className="w-full h-auto rounded-2xl mb-6"
                                                    />
                                                    <button
                                                        onClick={() => removeImageTwo(index)}
                                                        className="absolute top-0 right-0 bg-red-500 text-white p-2 rounded-full"
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className="flex items-center justify-between">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className="mb-4 hidden"
                                    id="uploadGiftCard"
                                    multiple
                                />
                            </div>

                            <div className="flex items-center justify-between">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChangeTwo}
                                    className="mb-4 hidden"
                                    id="uploadReceipt"
                                    multiple
                                />
                            </div>
                        </div>

                        <div>
                            <p className="text-sm text-center">*Upload image(s) to continue</p>
                        </div>
                    </div>
                )}

                {activeStep === 2 && (
                    <div>
                        <div className="flex flex-col items-center">
                            <PINInput
                                handleOnChange={setPin}
                                showPassword={showPassword}
                                toggleShowPassword={toggleShowPassword}
                                title={'Enter PIN'}
                            />

                            <p className="text-sm text-red-500 italic text-center">{error}</p>
                            <p className="text-sm font-bold mt-6 mb-4">
                                Forgot PIN? <Link className="text-blue-500 font-bold">Reset</Link>
                            </p>
                        </div>

                        <p className="mt-8 mb-6 px-5 lg:px-10 text-sm font-medium">Transaction summary</p>

                        <div className="px-5 lg:px-10">
                            <div className="flex justify-between items-center">
                                <div className="flex items-center">
                                    <img src={steamcard} alt="" className="mr-2" />
                                    <p>
                                        {selectedCard.cardType} rate ({selectedCountry.currencyCode})
                                    </p>
                                </div>
                                <p className="font-semibold">N{selectedCountry[selectedCardCategory]}</p>
                            </div>

                            <div className="flex justify-between items-center mt-2.5">
                                <div className="flex items-center">
                                    <img src={cardvalue} alt="" className="mr-2" />
                                    <p>Card value</p>
                                </div>
                                <p className="font-semibold">{cardValue}</p>
                            </div>

                            <div className="flex justify-between items-center mt-2.5">
                                <div className="flex items-center">
                                    <img src={gtype} alt="" className="mr-2" />
                                    <p>Type</p>
                                </div>
                                <p className="font-semibold"> {selectedCard?.cardType}</p>
                            </div>

                            <div className="flex justify-between items-center mt-2.5">
                                <div className="flex items-center">
                                    <p>Expected payment</p>
                                </div>
                                <p className="font-semibold">N{cardValue * selectedCountry[selectedCardCategory]}</p>
                            </div>
                        </div>
                        <p className="text-lg text-green-500 italic text-center">{success}</p>
                    </div>
                )}

                <div className="mt-10 px-5 lg:px-10">
                    {activeStep === steps.length ? (
                        <div>
                            <p>All steps completed</p>
                            {istoggleGPopup && <GPopup toggleGPopup={toggleGPopup} />}
                        </div>
                    ) : (
                        <div className="flex w-full justify-center mb-24">
                            <button
                                className={`${
                                    !isComplete
                                        ? 'bg-gray-400 text-black cursor-not-allowed'
                                        : 'bg-cblue hover:bg-blue-700 text-white cursor-pointer'
                                } font-bold py-5 px-4 rounded-2xl w-1/2 mr-5`}
                                onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
                                disabled={!isComplete}
                            >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </button>
                            <button
                                className={`border border-solid border-red-500 text-${
                                    darkmode ? 'white' : 'black'
                                } font-bold py-5 px-4 rounded-2xl w-1/2`}
                                onClick={handleBack}
                                disabled={activeStep === 0}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </div>

                <section className="pl-5 text-white bg-black relative rounded-b-3xl md:hidden">
                    <FooterComponent extra={true} />
                </section>

                <section
                    className={`text-center pt-5 relative before:w-full before:absolute before:-top-5 before:left-0 before:-bottom-5 before:bg-white before:z-[-1] flex flex-col md:flex-row justify-between items-center md:hidden ${
                        darkmode ? 'bg-darkAll' : 'bg-white'
                    } text-${darkmode ? 'white' : 'black'}`}
                >
                    <p className="w-52 md:w-auto text-sm mb-5 md:mb-0">
                        All rights reserved. Copyrights © 2023 Flippay. co.{' '}
                    </p>
                    <p className="text-sm">
                        Owned by PayNyce Nigeria Ltd, a parent company registered under the Corporate Affairs Commission
                        (CAC) with RC 1731068
                    </p>
                </section>
            </div>
        </div>
    )
}

export default GMid
