import React, { useEffect, useState } from 'react'
import CustomModal from './CustomModal'
import { useDispatch, useSelector } from 'react-redux'
import dbank from '../../assets/d_bank.svg'
import bankjpg from '../../assets/bank.svg'
import check from '../../assets/check-circle-small.svg'
import CustomInput, { CustomDropdown } from './CustomInput'
import { updateBankInfo, verifybankinfo, verifybvn } from '../../api'
import SmallLoading from './SmallLoading'
import Dot from './Dot'

function BankModal({ open, closeModal }) {
    const darkmode = useSelector((state) => state.userReducer.dark)
    const banks = useSelector((state) => state.userReducer.banks)
    const [accountnumber, setAccountnumber] = useState('')
    const [bank, setBank] = useState()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [acctDetails, setAccDetails] = useState({
        account_name: 'Forrest Green',
        account_number: '0690000031'
    })
    const [acctMessage, setAcctMessage] = useState('')
    useEffect(() => {
        if (accountnumber.length == 10 && bank?.code) {
            handleverifyBankInfo()
        } else {
            setAccDetails({})
        }
    }, [accountnumber, bank])

    const handleBankchange = (val) => {
        setBank(val)
    }

    const handleverifyBankInfo = async () => {
        try {
            setLoading(true)
            const { data } = await verifybankinfo({ accNumber: accountnumber, bankCode: bank.code })

            setAccDetails(data?.res?.data)
            setAcctMessage(data?.res?.message)
        } catch (err) {
            setAccDetails({})
        } finally {
            setLoading(false)
        }
    }

    const handleAccountchange = (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }

        setAccountnumber(value)
    }

    const handlesubmit = async () => {
        try {
            const formdata = {
                accNumber: acctDetails?.account_number,
                accName: acctDetails?.account_name,
                bankCode: bank?.code,
                bank: bank?.name
            }
            dispatch({ type: 'SETLOADING' })
            await updateBankInfo(formdata)
            dispatch({ type: 'UPDATEUSERDATA', data: formdata })
            closeModal()
        } catch (err) {
            console.log(err.response)
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }

    const handledisbled = () => {
        if (!acctDetails?.account_name) {
            return true
        }
        return false
    }

    if (!open) {
        return null
    }
    return (
        <CustomModal open={open} handleClose={closeModal}>
            <div
                className={`${
                    darkmode ? 'bg-darkSide text-white' : 'bg-white text-black'
                } p-8 shadow-3xl rounded-3xl flex items-center flex-col w-96`}
            >
                <div className="flex justify-center mb-9">
                    <img src={darkmode ? dbank : bankjpg} alt="" />
                </div>
                <p className="text-cfont32 font-poppins font-semibold text-center mb-10">
                    Add Bank Account
                    <Dot />
                </p>

                <div className="max-w-xs w-full ">
                    <CustomDropdown
                        value={bank?.name}
                        handlechange={handleBankchange}
                        title={'Choose Bank'}
                        name={'number'}
                        placeholder={'Enter Bank'}
                        options={banks}
                    />
                    <CustomInput
                        value={accountnumber}
                        handlechange={handleAccountchange}
                        title={'Account Number'}
                        name={'number'}
                        placeholder={'Enter Number'}
                    />
                    <div className="flex justify-center">
                        {loading && <SmallLoading loading={loading} />}
                        {!loading && acctDetails?.account_name && (
                            <div className="w-full flex bg-clime items-center justify-between rounded-2xl  px-4 py-2 ">
                                <div>
                                    <p className="text-base italic text-green-500">{acctMessage}</p>
                                    <p className="text-boldgray text-xs font-semibold">Account name</p>
                                    <p>{acctDetails?.account_name}</p>
                                </div>
                                <img src={check} alt="" />
                            </div>
                        )}
                        {!loading && !acctDetails?.account_name && (
                            <div>
                                <p className="text-base italic text-red-500">{acctMessage}</p>
                            </div>
                        )}
                    </div>
                    <div className="flex w-full justify-center mt-6">
                        <button
                            className={` bg-cblue font-bold  text-white p-5 rounded-cround text-cfont14  w-full disabled:bg-gray-400  `}
                            // disabled={handledisbled()}
                            onClick={handlesubmit}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default BankModal
