import { combineReducers } from 'redux'

// import postReducers from './postReducers';
import authReducers from './authReducers'
import userReducer from './userReducer'
import transactionReducer from './transactionReducer'
import giftcardReducer from './giftcardReducers'

export default combineReducers({
    // postReducers, //normally it looks like this post:postReducers, since key and value are the same, they are represented this way
    authReducers,
    userReducer,
    transactionReducer,
    giftcardReducer
})
