const userReducer = (state = { user: {}, beneficiaries: [], banks: [], loading: false, dark: false }, action) => {
    switch (action.type) {
        case 'GETUSER':
            return { ...state, user: action?.data }
        case 'UPDATEUSERDATA':
            return { ...state, user: { ...state.user, ...action?.data } }
        case 'SETLOADING':
            return { ...state, loading: true }
        case 'CLEARLOADING':
            return { ...state, loading: false }
        case 'SETDARKMODE':
            return { ...state, dark: true }
        case 'CLEARDARKMODE':
            return { ...state, dark: false }
        case 'GETBANKS':
            return { ...state, banks: action?.data }
        case 'GETBENEFICIARIES':
            return { ...state, beneficiaries: action?.data }
        default:
            return state
    }
}

export default userReducer
