import React, { useEffect, useRef, useState } from 'react'
import steam from '../../../assets/steam_card.svg'
import ebay from '../../../assets/ebay-logo.svg'
import itunesLogo from '../../../assets/itunes-logo.svg'
import targetLogo from '../../../assets/target-logo.svg'
import att from '../../../assets/at-t.svg'
import frame9 from '../../../assets/frame-9.svg'
// import cards from '../../../assets/cards.svg'
import cards1 from '../../../assets/cards.svg'
import card2 from '../../../assets/cards2.svg'
import CustomDropdown from '../../UniversalComponents/CustomDropdown'
import cardcurve from '../../../assets/cardcurve.png'
import cardcurvedark from '../../../assets/cardcurvedark.png'
import CardsGrid from '../../UniversalComponents/CardsGrid'
import { useSelector } from 'react-redux'

function AboutCards({ selectedOption, setSelectedOption }) {
    const [option, setOption] = useState(false)
    const cards = useSelector((state) => state.giftcardReducer.cards)
    const containerRef = useRef(null)
    const containerRef1 = useRef(null)

    useEffect(() => {
        const handleMouseMoveContainer1 = (e) => {
            const container = containerRef.current
            handleMouseMove(container, e)
        }

        const handleMouseMoveContainer2 = (e) => {
            const container = containerRef1.current
            handleMouseMove(container, e)
        }

        const container1 = containerRef.current
        const container2 = containerRef1.current

        container1.addEventListener('mousemove', handleMouseMoveContainer1)
        container2.addEventListener('mousemove', handleMouseMoveContainer2)

        return () => {
            container1.removeEventListener('mousemove', handleMouseMoveContainer1)
            container2.removeEventListener('mousemove', handleMouseMoveContainer2)
        }
    }, [])

    const handleMouseMove = (container, e) => {
        const mouseX = e.clientX
        const windowWidth = window.innerWidth
        const containerWidth = container.offsetWidth
        const threshold = 50

        if (mouseX >= windowWidth - threshold) {
            container.scrollTo({
                left: container.scrollWidth - containerWidth,
                behavior: 'smooth' // Smooth scroll to left
            })
        } else if (mouseX <= threshold) {
            container.scrollTo({
                left: 0,
                behavior: 'smooth' // Smooth scroll to left
            })
        }
    }

    const toggleOption = () => {
        setOption(!option)
    }

    const handleOptionChange = (opt) => {
        setSelectedOption(opt)
        // window.history.pushState({}, '', '/#calc')
        const sectiontop = window?.document?.getElementById('calc')?.offsetTop
        if (sectiontop) {
            window.scroll({ top: sectiontop, behavior: 'smooth' })
        }
        toggleOption()
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <section
            className={`-translate-y-20 lg:-translate-y-24 xl:-translate-y-72 rounded-2xl relative z-30 ${
                darkmode ? 'bg-darkSide' : 'bg-white'
            } text-${darkmode ? 'white' : 'black'}`}
        >
            <img
                src={darkmode ? cardcurvedark : cardcurve}
                className="w-full -translate-y-5 md:-translate-y-20 xl:-translate-y-12"
                alt=""
            />

            <div className="pt-10 md:pt-0 md:px-20 -translate-y-20">
                <ul
                    className={`pl-8 -translate-y-3 xl:-translate-y-16 md:pl-0 ml-5 mb-6 md:mb-0 text-${
                        darkmode ? 'white' : 'black'
                    } list-disc `}
                >
                    <li className="">
                        <p className="text-sm lg:text-2xl font-poppins font-medium">About Cards</p>
                    </li>
                </ul>

                <div className={`pl-8 pr-8 md:pr-0 md:pl-0 text-${darkmode ? 'white' : 'black'} mb-10`}>
                    <div className="block md:flex justify-between">
                        <p className="font-medium font-poppins md:font-normal text-2xl md:text-4xl mb-5 lg:mb-0">
                            See brief summaries <br /> about any card on <br /> Flippay!{' '}
                        </p>
                        <div>
                            <CustomDropdown
                                handleOptionChange={handleOptionChange}
                                option={option}
                                options={cards}
                                selectedOption={selectedOption}
                                toggleOption={toggleOption}
                                placeholder={'Search gift card'}
                            />
                            <p className={`text-right text-xs ${darkmode ? 'text-white' : 'text-txtcolor'} mt-2`}>
                                Search the card you want to know about.
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="md:hidden">
                    <div className="z-10 px-10">
                        <img src={steam} alt="steam" className="w-full" />
                    </div>

                    <div className="flex flex-nowrap my-1 scrollbar-hide overflow-x-scroll">
                        <div className="z-10 min-w-max">
                            <img src={ebay} alt="ebay" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max mx-1">
                            <img src={targetLogo} alt="target" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max">
                            <img src={card2} alt="gift-card" className="w-44" />
                        </div>
                    </div>

                    <div className="flex flex-nowrap scrollbar-hide overflow-x-scroll">
                        <div className="z-10  min-w-max">
                            <img src={cards} alt="google-play" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max mx-1">
                            <img src={att} alt="at&t" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max">
                            <img src={itunesLogo} alt="itunes" className="w-44" />
                        </div>
                    </div>
                </div> */}

                {/* <CardsGrid /> */}

                <div className="md:hidden">
                    <div className="z-10 px-10 mt-4 mb-10 lg:mb-0">
                        <img src={steam} alt="steam" className="w-full" />
                    </div>

                    <div className="flex flex-nowrap my-1 scrollbar-hide overflow-x-scroll" ref={containerRef}>
                        <div className="z-10 min-w-max">
                            <img src={ebay} alt="ebay" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max mx-1">
                            <img src={targetLogo} alt="target" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max">
                            <img src={card2} alt="gift-card" className="w-44" />
                        </div>
                    </div>

                    <div className="flex flex-nowrap scrollbar-hide overflow-x-scroll" ref={containerRef1}>
                        <div className="z-10  min-w-max">
                            <img src={cards1} alt="google-play" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max mx-1">
                            <img src={att} alt="at&t" className="w-44" />
                        </div>

                        <div className="z-10 min-w-max">
                            <img src={itunesLogo} alt="itunes" className="w-44" />
                        </div>
                    </div>
                </div>

                <div className="hidden md:grid grid-cols-12 grid-rows-3 gap-1">
                    <div className="col-span-5 row-span-3 z-10">
                        <img src={steam} alt="steam" />
                    </div>
                    <div className="col-span-4 z-10">
                        <img src={ebay} alt="ebay" />
                    </div>
                    <div className="col-span-3 z-10">
                        <img src={itunesLogo} alt="itunes" />
                    </div>
                    <div className="col-span-3 z-10">
                        <img src={targetLogo} alt="target" />
                    </div>
                    <div className="col-span-3 z-10">
                        <img src={att} alt="at&t" />
                    </div>
                    <div className="col-span-1 row-span-2 z-10">
                        <img src={frame9} alt="frame-9" />
                    </div>
                    <div className="relative -top-5 col-span-3 z-10">
                        <img src={cards1} alt="google-play" />
                    </div>
                    <div className="relative -top-5 col-span-3 z-10">
                        <img src={card2} alt="gift-carg" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutCards
