import React from 'react'

function Viewdashboardsvg({ fill = '#fff' }) {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.45001 5.59922C2.45001 5.27418 2.45001 5.11166 2.46349 4.97477C2.59443 3.64539 3.64619 2.59363 4.97556 2.4627C5.11246 2.44922 5.27498 2.44922 5.60001 2.44922H8.40001C8.72505 2.44922 8.88757 2.44922 9.02446 2.4627C10.3538 2.59363 11.4056 3.64539 11.5365 4.97477C11.55 5.11166 11.55 5.27418 11.55 5.59922C11.55 5.92426 11.55 6.08677 11.5365 6.22367C11.4056 7.55304 10.3538 8.6048 9.02446 8.73573C8.88757 8.74922 8.72505 8.74922 8.40001 8.74922H5.60001C5.27498 8.74922 5.11246 8.74922 4.97556 8.73573C3.64619 8.6048 2.59443 7.55304 2.46349 6.22367C2.45001 6.08677 2.45001 5.92426 2.45001 5.59922Z"
                fill={fill}
            />
            <path
                d="M16.45 6.92922C16.45 5.36107 16.45 4.577 16.7552 3.97805C17.0236 3.45119 17.452 3.02285 17.9788 2.7544C18.5778 2.44922 19.3619 2.44922 20.93 2.44922H21.07C22.6382 2.44922 23.4222 2.44922 24.0212 2.7544C24.548 3.02285 24.9764 3.45119 25.2448 3.97805C25.55 4.577 25.55 5.36107 25.55 6.92922V9.86922C25.55 11.4374 25.55 12.2214 25.2448 12.8204C24.9764 13.3472 24.548 13.7756 24.0212 14.044C23.4222 14.3492 22.6382 14.3492 21.07 14.3492H20.93C19.3619 14.3492 18.5778 14.3492 17.9788 14.044C17.452 13.7756 17.0236 13.3472 16.7552 12.8204C16.45 12.2214 16.45 11.4374 16.45 9.86922V6.92922Z"
                fill={fill}
            />
            <path
                d="M2.45001 18.1292C2.45001 16.5611 2.45001 15.777 2.75519 15.178C3.02364 14.6512 3.45198 14.2228 3.97884 13.9544C4.57779 13.6492 5.36187 13.6492 6.93001 13.6492H7.07001C8.63816 13.6492 9.42223 13.6492 10.0212 13.9544C10.548 14.2228 10.9764 14.6512 11.2448 15.178C11.55 15.777 11.55 16.5611 11.55 18.1292V21.0692C11.55 22.6374 11.55 23.4214 11.2448 24.0204C10.9764 24.5472 10.548 24.9756 10.0212 25.244C9.42223 25.5492 8.63816 25.5492 7.07001 25.5492H6.93001C5.36187 25.5492 4.57779 25.5492 3.97884 25.244C3.45198 24.9756 3.02364 24.5472 2.75519 24.0204C2.45001 23.4214 2.45001 22.6374 2.45001 21.0692V18.1292Z"
                fill={fill}
            />
            <path
                d="M16.45 22.3992C16.45 22.0742 16.45 21.9117 16.4635 21.7748C16.5944 20.4454 17.6462 19.3936 18.9756 19.2627C19.1125 19.2492 19.275 19.2492 19.6 19.2492H22.4C22.725 19.2492 22.8876 19.2492 23.0245 19.2627C24.3538 19.3936 25.4056 20.4454 25.5365 21.7748C25.55 21.9117 25.55 22.0742 25.55 22.3992C25.55 22.7243 25.55 22.8868 25.5365 23.0237C25.4056 24.353 24.3538 25.4048 23.0245 25.5357C22.8876 25.5492 22.725 25.5492 22.4 25.5492H19.6C19.275 25.5492 19.1125 25.5492 18.9756 25.5357C17.6462 25.4048 16.5944 24.353 16.4635 23.0237C16.45 22.8868 16.45 22.7243 16.45 22.3992Z"
                fill={fill}
            />
            <path
                d="M2.45001 5.59922C2.45001 5.27418 2.45001 5.11166 2.46349 4.97477C2.59443 3.64539 3.64619 2.59363 4.97556 2.4627C5.11246 2.44922 5.27498 2.44922 5.60001 2.44922H8.40001C8.72505 2.44922 8.88757 2.44922 9.02446 2.4627C10.3538 2.59363 11.4056 3.64539 11.5365 4.97477C11.55 5.11166 11.55 5.27418 11.55 5.59922C11.55 5.92426 11.55 6.08677 11.5365 6.22367C11.4056 7.55304 10.3538 8.6048 9.02446 8.73573C8.88757 8.74922 8.72505 8.74922 8.40001 8.74922H5.60001C5.27498 8.74922 5.11246 8.74922 4.97556 8.73573C3.64619 8.6048 2.59443 7.55304 2.46349 6.22367C2.45001 6.08677 2.45001 5.92426 2.45001 5.59922Z"
                stroke={fill}
                stroke-width="1.5"
                stroke-linejoin="round"
            />
            <path
                d="M16.45 6.92922C16.45 5.36107 16.45 4.577 16.7552 3.97805C17.0236 3.45119 17.452 3.02285 17.9788 2.7544C18.5778 2.44922 19.3619 2.44922 20.93 2.44922H21.07C22.6382 2.44922 23.4222 2.44922 24.0212 2.7544C24.548 3.02285 24.9764 3.45119 25.2448 3.97805C25.55 4.577 25.55 5.36107 25.55 6.92922V9.86922C25.55 11.4374 25.55 12.2214 25.2448 12.8204C24.9764 13.3472 24.548 13.7756 24.0212 14.044C23.4222 14.3492 22.6382 14.3492 21.07 14.3492H20.93C19.3619 14.3492 18.5778 14.3492 17.9788 14.044C17.452 13.7756 17.0236 13.3472 16.7552 12.8204C16.45 12.2214 16.45 11.4374 16.45 9.86922V6.92922Z"
                stroke={fill}
                stroke-width="1.5"
                stroke-linejoin="round"
            />
            <path
                d="M2.45001 18.1292C2.45001 16.5611 2.45001 15.777 2.75519 15.178C3.02364 14.6512 3.45198 14.2228 3.97884 13.9544C4.57779 13.6492 5.36187 13.6492 6.93001 13.6492H7.07001C8.63816 13.6492 9.42223 13.6492 10.0212 13.9544C10.548 14.2228 10.9764 14.6512 11.2448 15.178C11.55 15.777 11.55 16.5611 11.55 18.1292V21.0692C11.55 22.6374 11.55 23.4214 11.2448 24.0204C10.9764 24.5472 10.548 24.9756 10.0212 25.244C9.42223 25.5492 8.63816 25.5492 7.07001 25.5492H6.93001C5.36187 25.5492 4.57779 25.5492 3.97884 25.244C3.45198 24.9756 3.02364 24.5472 2.75519 24.0204C2.45001 23.4214 2.45001 22.6374 2.45001 21.0692V18.1292Z"
                stroke={fill}
                stroke-width="1.5"
                stroke-linejoin="round"
            />
            <path
                d="M16.45 22.3992C16.45 22.0742 16.45 21.9117 16.4635 21.7748C16.5944 20.4454 17.6462 19.3936 18.9756 19.2627C19.1125 19.2492 19.275 19.2492 19.6 19.2492H22.4C22.725 19.2492 22.8876 19.2492 23.0245 19.2627C24.3538 19.3936 25.4056 20.4454 25.5365 21.7748C25.55 21.9117 25.55 22.0742 25.55 22.3992C25.55 22.7243 25.55 22.8868 25.5365 23.0237C25.4056 24.353 24.3538 25.4048 23.0245 25.5357C22.8876 25.5492 22.725 25.5492 22.4 25.5492H19.6C19.275 25.5492 19.1125 25.5492 18.9756 25.5357C17.6462 25.4048 16.5944 24.353 16.4635 23.0237C16.45 22.8868 16.45 22.7243 16.45 22.3992Z"
                stroke={fill}
                stroke-width="1.5"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default Viewdashboardsvg
