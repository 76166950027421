import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PINInput from '../../../Account/AccountComponent/Security/PINInput'
import { useDispatch, useSelector } from 'react-redux'
import SidePopup from '../../../UniversalComponents/SidePopup'
import { buyCable, getBillInfo } from '../../../../api'

const categories = ['Startimes', 'DSTV', 'GOTV']

const Cable = ({ toggleCable, togglePayCable }) => {
    const [selectedOption, setSelectedOption] = useState('')
    const [selectedBouquet, setSelectedBouquet] = useState('')
    const [category, setCategory] = useState(false)
    const [request, setRequest] = useState(false)
    const [bouquet, setBouquet] = useState(false)
    const [decodenum, setDecodeNum] = useState('')
    const [showPassword, setShowPassword] = useState(true)
    const [pin, setPin] = useState('')
    const [amount, setAmount] = useState('')
    const [cables, setCables] = useState({})
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        //  FOR CABLE
        getBillInfo('cable=1')
            .then((res) => {
                const { data } = res
                const cablesdata = data.data

                const ngCables = cablesdata.filter((cab) => cab.country == 'NG')
                // console.log('ngC', ngCables)
                const processedData = {
                    DSTV: [],
                    GOTV: [],
                    STARTIMES: []
                }

                // console.log(cablesdata, 'here go')
                for (let cab of ngCables) {
                    // console.log(cab.country)
                    // if (cab.country == 'NG' && cab.name in processedData) {
                    //     console.log('county', cab.country)
                    //     processedData[cab.name].push(cab)
                    // }

                    const lowerCaseName = cab.name.toLowerCase()

                    if (lowerCaseName.includes('dstv')) {
                        processedData['DSTV'].push(cab)
                    } else if (lowerCaseName.includes('gotv')) {
                        processedData['GOTV'].push(cab)
                    } else if (lowerCaseName.includes('startimes')) {
                        processedData['STARTIMES'].push(cab)
                    }
                }
                // console.log(processedData, 'this is processed')
                setCables(processedData)
            })
            .catch((err) => {})
    }, [])

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleCategoryChange = (cat) => {
        setSelectedOption(cat)
        toggleCategory()
    }

    const handleBouquetChange = (bou) => {
        setSelectedBouquet(bou)
        setAmount(bou.amount)
        toggleBouquet()
        // console.log('wala', bou)
    }

    const toggleCategory = () => {
        setCategory(!category)
        setRequest(false)
        setBouquet(false)
    }

    const toggleBouquet = () => {
        setBouquet(!bouquet)
        setCategory(false)
        setRequest(false)
    }

    const handleClickReset = () => {
        // console.log('working')
        navigate('/account', { state: { isResetTrue: true } })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            dispatch({ type: 'SETLOADING' })
            // await validateUser(selectedBouquet.item_code, selectedBouquet.biller_code, decodenum)

            await buyCable('cable', {
                item_code: selectedBouquet.item_code,
                decoderNum: decodenum,
                code: selectedBouquet.biller_code
            })
            togglePayCable()
        } catch (err) {
            setError(err.response.data.message)
            console.log(err)
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }

    // console.log('wenever', {
    //     item_code: selectedBouquet.item_code,
    //     decoderNum: decodenum,
    //     code: selectedBouquet.biller_code
    // })

    const handleAmountChange = (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }
        setAmount(value)
    }

    const handleDisabled = () => {
        if (!decodenum || !selectedBouquet || !amount || pin.length != 4) {
            return true
        }

        return false
    }

    const handleDecoderChange = (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }
        setDecodeNum(value)
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <SidePopup toggleGeneralPopup={toggleCable}>
            <div className="w-full flex flex-col items-center">
                <h2 className="text-4xl font-semibold mt-24">CABLE TV.</h2>

                <p className="text-sm text-center mt-2.5 mb-16 w-4/6">Easily pay your cable TV bill on Flippay.</p>

                <div className="relative w-4/5 md:w-5/6 border-gray-300 px-4 border hover:border-gray-300 rounded-cround mb-4">
                    <label htmlFor="category" className="text-sm font-semibold" onClick={toggleCategory}>
                        Select a category
                    </label>
                    <div className="flex items-center justify-between w-full rounded-2xl py-1">
                        <input
                            type="text"
                            id="category"
                            readOnly
                            required
                            value={selectedOption}
                            className="outline-none w-full cursor-pointer bg-transparent"
                            onClick={toggleCategory}
                        />

                        <div className="cursor-pointer" onClick={toggleCategory}>
                            <FontAwesomeIcon icon={category ? faAngleDown : faAngleRight} />
                        </div>
                    </div>

                    {category && (
                        <div
                            className={`absolute right-0 mt-1 w-2/3 border rounded-3xl rounded-tr-none shadow-lg z-20 ${
                                darkmode
                                    ? 'bg-ccdarkblue text-white border-cdarkblue'
                                    : 'bg-white text-black border-gray-300'
                            }`}
                        >
                            <p className="px-4 text-xs font-medium text-warn py-1">Categories</p>
                            {categories.map((category) => (
                                <div
                                    key={category}
                                    value={category}
                                    className={`px-4 py-2 cursor-pointer ${
                                        darkmode ? 'hover:bg-gray-400' : 'hover:bg-gray-100'
                                    }`}
                                    onClick={() => handleCategoryChange(category)}
                                >
                                    {category}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-gray-300 mb-4">
                    <div className="w-full flex flex-col items-start">
                        <label htmlFor="smartCardNumber" className="text-sm font-semibold">
                            Smart card number
                        </label>
                        <div className="w-full">
                            <input
                                className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                type="number"
                                id="smartCardNumber"
                                name="smartCardNumber"
                                required
                                placeholder="0000-0000-0000-0000"
                                value={decodenum}
                                onChange={handleDecoderChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="relative w-4/5 md:w-5/6 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl mb-4">
                    <label htmlFor="bouquet" className="text-sm font-semibold" onClick={toggleBouquet}>
                        Select bouquet
                    </label>
                    <div className="flex items-center justify-between w-full rounded-2xl py-1">
                        <input
                            type="text"
                            id="bouquet"
                            readOnly
                            required
                            value={selectedBouquet.biller_name}
                            className="outline-none w-full cursor-pointer bg-transparent"
                            onClick={toggleBouquet}
                        />

                        <div className="cursor-pointer" onClick={toggleBouquet}>
                            <FontAwesomeIcon icon={bouquet ? faAngleDown : faAngleRight} />
                        </div>
                    </div>

                    {bouquet && (
                        <div
                            className={`absolute right-0 mt-1 w-2/3 border rounded-3xl rounded-tr-none shadow-lg z-20 ${
                                darkmode
                                    ? 'bg-ccdarkblue text-white border-cdarkblue'
                                    : 'bg-white text-black border-gray-300'
                            }`}
                        >
                            <p className="px-4 text-xs font-medium text-warn py-1">Bouquets</p>
                            {cables[selectedOption.toUpperCase()]?.map((bouq) => (
                                <div
                                    key={bouq.id}
                                    value={bouq}
                                    className={`px-4 py-2 cursor-pointer ${
                                        darkmode ? 'hover:bg-gray-400' : 'hover:bg-gray-100'
                                    }`}
                                    onClick={() => handleBouquetChange(bouq)}
                                >
                                    {bouq.biller_name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-gray-300 mb-5">
                    <div className="w-full flex flex-col items-start">
                        <label htmlFor="phoneNum" className="text-sm font-semibold">
                            Amount
                        </label>
                        <div className="w-full">
                            <input
                                className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                type="text"
                                inputMode="numeric"
                                id="amount"
                                name="amount"
                                required
                                placeholder="N000,000"
                                onChange={handleAmountChange}
                                value={amount}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <PINInput
                    handleOnChange={setPin}
                    showPassword={showPassword}
                    toggleShowPassword={toggleShowPassword}
                    title={'Enter PIN'}
                />

                <p className="text-sm italic text-center text-red-500">{error}</p>
                <p className="text-sm font-bold mt-6 mb-4">
                    Forgot PIN?{' '}
                    <button onClick={handleClickReset} className="text-blue-500 font-bold">
                        Reset
                    </button>
                </p>
                <div className="flex w-full justify-center mb-24">
                    <button
                        className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-48 mr-3 disabled:bg-gray-600"
                        onClick={handleSubmit}
                        disabled={handleDisabled()}
                    >
                        Pay bill
                    </button>
                    <button
                        className={`border-2 border-solid border-red-500 text-${
                            darkmode ? 'white' : 'black'
                        } font-bold py-2 px-4 rounded-2xl w-48`}
                        onClick={toggleCable}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </SidePopup>
    )
}

export default Cable
