import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import lWhite from '../../../assets/flippay_logo_white.svg'
import vector from '../../../assets/Vector.svg'
import { useDispatch } from 'react-redux'
import { emailVerificationWithCodeAction } from '../../../redux/actions/authActions'

const EnterOtp = () => {
    const { email } = useParams()
    const [verificationCode, setVerificationCode] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSubmit = () => {
        dispatch(emailVerificationWithCodeAction({ verificationCode, email }, navigate))
    }

    return (
        <div className="pt-12 md:overflow-y-hidden h-screen bg-emailV bg-50% bg-center-r bg-no-repeat relative">
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-br from-blue-900 via-blue-800 to-purple-900 opacity-70"></div>

            <div className="absolute right-5 top-1 xl:hidden">
                <img src={lWhite} alt="" />
            </div>

            <div className="hidden xl:block absolute xl:bottom-5 xl:right-10">
                <img src={lWhite} alt="" />
            </div>

            <div className="w-full xl:w-1/2 h-full rounded-3xl -top-3 before:rounded-xl before:absolute before:bg-white after:rounded-sm after:absolute after:left-0 after:bg-white after:transform after:-skew-y-0 text-black relative before:w-48 md:before:w-96 before:h-48 md:before:h-96 before:-top-1 md:before:-top-5 before:left-24 md:before:left-48 before:transform before:rotate-45 after:w-48 md:after:w-96 after:h-48 md:after:h-96 after:-top-10 md:after:-top-24">
                <div className="flex justify-end mr-5 relative z-20 top-10">
                    <Link>
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                    </Link>
                </div>

                <div className="bg-white h-screen rounded-3xl pt-6 pb-28 relative z-10 flex flex-col items-center justify-center">
                    <div className="px-10 md:px:0">
                        <p className="font-semibold text-justify text-4xl mt-6 mb-3">Enter OTP.</p>
                        <p className="text-justify">A One Time Password (OTP) was sent to your email. </p>

                        <div className="w-full mt-12 mb-4">
                            <input
                                className="border-gray-400 border-2 border-solid p-5 rounded-2xl w-full"
                                type="password"
                                id="password"
                                name="verificationCode"
                                required
                                placeholder="* * * * * *"
                                onChange={(e) => setVerificationCode(e.target.value)}
                                value={verificationCode}
                            />
                        </div>
                        <p className="mb-10">Enter the six digits in your email</p>

                        <div>
                            <button
                                onClick={handleSubmit}
                                className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-full"
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>

                <div className="relative -top-48 md:hidden z-20 -right-10">
                    <img src={vector} className="transform rotate-45 scale-50" alt="" />
                </div>
            </div>
        </div>
    )
}

export default EnterOtp
