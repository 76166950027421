import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import phoneTablet from '../../assets/phoneTabletSvg.svg'
import playCircle from '../../assets/play-cricle.svg'
import googlePlay from '../../assets/google-play.svg'
import appleStore from '../../assets/apple-store.svg'
import darkPhoneTablet from '../../assets/darkPhoneTablet.svg'
import { useSelector } from 'react-redux'

function SellGiftCard() {
    const darkmode = useSelector((state) => state.userReducer.dark)
    const navigate = useNavigate()

    const handleCreateAcc = () => {
        navigate('/signup')
    }

    return (
        <section className="pl-8 md:pl-20 md:flex justify-between items-start py-10 relative">
            <div className="flex justify-start md:justify-center items-center">
                <div className="hidden md:flex flex-col items-center">
                    <div className="border-l-2 border-cdarkblue border-solid h-40"></div>
                    <Link to={'https://instagram.com/flippay.co'}>
                        <FontAwesomeIcon icon={faInstagram} className="text-cdarkblue text-2xl my-4 mx-2" />
                    </Link>
                    <Link to={'https://facebook.com/flippay.co'}>
                        <FontAwesomeIcon icon={faFacebook} className="text-cdarkblue text-2xl my-4 mx-2" />
                    </Link>
                    <Link to={'https://x.com/flippayconnect'}>
                        <FontAwesomeIcon icon={faTwitter} className="text-cdarkblue text-2xl my-4 mx-2" />
                    </Link>
                    <div className="border-l-2 border-cdarkblue border-solid h-40"></div>
                </div>

                <div className="md:ml-20">
                    <h1 className="text-4xl md:text-2xl xl:text-6xl font-bold md:leading-12">
                        Sell your gift <br />
                        <div className="flex">
                            <div className="mr-3">card</div>
                            <div className="flex flex-col h-12 md:h-16 overflow-hidden">
                                <span className="special bg-gradient-to-r from-cblue to-cdarkblue text-transparent bg-clip-text">
                                    faster
                                </span>{' '}
                                <span className="special bg-gradient-to-r from-cblue to-cdarkblue text-transparent bg-clip-text">
                                    with ease
                                </span>{' '}
                                <span className="special bg-gradient-to-r from-cblue to-cdarkblue text-transparent bg-clip-text">
                                    securely
                                </span>
                            </div>
                        </div>
                    </h1>

                    <p className="text-xs md:text-sm font-normal leading-5 mt-5">
                        Welcome to Flippay, the ultimate platform for selling your <br /> gift cards. Don't let those
                        unused gift cards go to waste - <br />{' '}
                        <span className="italic">Let us help you sell faster, with ease and securely!</span>
                    </p>

                    <div className="flex gap-6 items-center mt-14">
                        <div>
                            <button
                                className="bg-cblue flex items-center gap-2 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-cround"
                                onClick={handleCreateAcc}
                            >
                                Create account <FontAwesomeIcon icon={faArrowRightLong} />
                            </button>
                        </div>

                        <div className="hidden md:block">
                            <Link
                                to="https://youtu.be/kTVUpqIkZOc?si=hss7_W35tknRIP9l"
                                className="flex mr-16 hover:text-cblue"
                            >
                                <span className="mr-2 text-sm font-semibold">How it works</span>{' '}
                                <img src={playCircle} alt="Play" />
                            </Link>
                        </div>
                    </div>

                    <div className="mt-20">
                        <p className="hidden md:flex font-medium text-xs font-poppins mb-2">Download App</p>
                        <div className="hidden md:flex justify-start">
                            <Link to={'https://play.google.com/store/apps/details?id=com.paynyce.flippay'}>
                                <img src={googlePlay} alt="Google Play" />
                            </Link>
                            <Link
                                className="ml-3"
                                to={'https://apps.apple.com/app/flippay-sell-gift-cards/id6475052879'}
                            >
                                <img src={appleStore} alt="Apple Store" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-10 mt-10">
                <img src={darkmode ? darkPhoneTablet : phoneTablet} alt="Phone and Tablet Version of Flippay" />
            </div>
        </section>
    )
}

export default SellGiftCard
