import React from 'react'
import flippay from '../../assets/flippay_init.svg'
import { useDispatch, useSelector } from 'react-redux'
import cardimg from '../../assets/cardbackground.png'
import numeral from 'numeral'
import ProfileCircle from './ProfileCircle'
import edit from '../../assets/edit.svg'
import { uploadImage } from '../../api'

function CardProfile({ bgimg = cardimg, bgcolor = '#213B98', sidecontent }) {
    const user = useSelector((state) => state.userReducer.user)

    const dispatch = useDispatch()
    const handleimage = async (event) => {
        try {
            const files = event.target.files

            // return
            const data = new FormData()
            data.append('image', files[0])
            dispatch({ type: 'SETLOADING' })
            await uploadImage(data)
        } catch (err) {
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }
    return (
        <div
            style={{
                // width: '400px',
                height: '203px',
                backgroundColor: bgcolor,
                borderRadius: '30px',
                backgroundImage: `url(${bgimg})`,
                // backgroundSize: 'cover',
                backgroundSize: bgcolor === '#fff' ? '100% 100%' : '102% 102%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: bgcolor === '#fff' ? 'unset' : '-2px -2px',
                color: '#fff',
                padding: '20px 22px 20px 38px'
            }}
            className="w-full md:w-100 mb-2.5 lg:mb-0"
        >
            <div className="flex justify-between mb-14">
                <img src={flippay} alt="flippay" />
                {sidecontent}
            </div>
            <div className="flex gap-6 items-end">
                <label className="relative cursor-pointer">
                    <ProfileCircle size={60} bglinear="linear-gradient(#007BFF,#D2E8FF)" />
                    <img src={edit} className="absolute bottom-0 -right-4" alt="" />
                    <input className="hidden" type="file" onChange={handleimage} />
                </label>
                <div>
                    <h2 className="font-poppins text-xl">{user?.fname}</h2>
                    <p className="text-sm">{user?.originalEmail}</p>
                </div>
            </div>
        </div>
    )
}

export default CardProfile
