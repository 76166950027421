import React from 'react'
import spin from '../../assets/spin-loading.png'
import logo from '../../assets/logo-load.svg'

function Loading({ loading }) {
    if (loading) {
        return (
            <div
                className="fixed w-full grid place-content-center h-full bg-black bg-opacity-50 "
                style={{ zIndex: 1400 }}
            >
                <div className="text-white relative w-28 h-28">
                    <img
                        src={spin}
                        className="absolute w-full top-1/2 left-1/2 custom-animate-spin -translate-x-1/2 -translate-y-1/2 "
                        alt="pass"
                    />
                    <img
                        src={logo}
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                        alt="pass"
                    />
                </div>
            </div>
        )
    }
    return null
}

export default Loading
