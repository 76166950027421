import React from 'react'

const Logoutsvg = ({ fill }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.6166 8.8193C17.255 4.6193 15.0966 2.9043 10.3716 2.9043H10.22C5.00497 2.9043 2.91664 4.99263 2.91664 10.2076V17.8143C2.91664 23.0293 5.00497 25.1176 10.22 25.1176H10.3716C15.0616 25.1176 17.22 23.426 17.605 19.296"
                stroke={fill}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.5 14H23.7767"
                stroke={fill}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M21.175 10.0918L25.0833 14.0001L21.175 17.9085"
                stroke={fill}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default Logoutsvg
