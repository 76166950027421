const checkTokenExpiration = () => {
    const token = localStorage.getItem('token')
    const expiresIn = localStorage.getItem('expiresIn')
    if (token && expiresIn) {
        // const currentTime = Date.now()
        // const expirationTime = parseInt(expiresIn, 10) + currentTime
        // if (expirationTime > currentTime) {
        //     return true // Token is still valid
        // } else {
        //     return false // Token has expired
        // }
    }

    if (token) {
        return true
    }

    return false // Token or expiration time not found
}

export default checkTokenExpiration
