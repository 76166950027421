import medal from '../../../assets/medal-star.svg'
import UserDp from '../../UniversalComponents/UserDp'
import bellimg from '../../../assets/bellimg.svg'
import check from '../../../assets/check.svg'
import { Link, useNavigate } from 'react-router-dom'
import income from '../../../assets/income.svg'
import notif_white from '../../../assets/notification_icon_white.svg'
import outward from '../../../assets/outward.svg'
import import_white from '../../../assets/import_white.svg'
import export_white from '../../../assets/export_white.svg'
import wallet from '../../../assets/wallet.svg'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import RecentTransactions from '../../UniversalComponents/RecentTransactions'

const MainSide = () => {
    const [isFixed, setIsFixed] = useState(false)
    const navigate = useNavigate()

    const handleClickGift = () => {
        navigate('/giftcard')
    }

    const handleClickSend = () => {
        navigate('/wallet', { state: { isTrue: true } })
    }

    const handleClickTransfer = () => {
        navigate('/wallet', { state: { isTrueTransfer: true } })
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsFixed(true)
            } else {
                setIsFixed(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const darkmode = useSelector((state) => state.userReducer.dark)
    const user = useSelector((state) => state.userReducer.user)

    return (
        <div className="pr-10">
            <div className="hidden lg:flex justify-center mt-5 items-center">
                <div>
                    <img src={darkmode ? notif_white : bellimg} alt="bell" />
                </div>

                <div className="flex items-center ml-5">
                    <UserDp />

                    <div className="ml-2">
                        <p className={`bg-dark ${darkmode ? 'text-white' : 'text-bluePurple'} text-sm font-semibold`}>
                            {user?.fname}
                        </p>
                        <p className="flex items-center">
                            <span className="text-warn text-sm">Account:&nbsp;</span>
                            {!user.verified && (
                                <span className="text-xs italic text-red-500">
                                    <Link>not verified</Link>
                                </span>
                            )}
                            {user.verified && (
                                <span>
                                    <img src={check} alt="" />
                                </span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div className={`hidden lg:block text-center mt-14 ${darkmode ? 'text-white' : ''}`}>
                <p className="text-sm">Total number of cards sold</p>
                <p className="text-5xl font-semibold font-poppins mt-2 mb-2">{user?.approvedGiftCards}</p>
                {/* <p className={`text-sm italic ${darkmode ? 'text-anotherBlue' : 'text-cdarkblue'}`}>
                    6 gift cards sold in the past 3 days
                </p> */}
                {user?.approvedGiftCards > 69 && (
                    <div className="flex justify-center mt-3 mb-8">
                        <div className="flex gap-1 bg-warninit w-max p-3 rounded-3xl">
                            <img src={medal} alt="" />
                            <p className="text-xs text-cdarkblue">Card lord</p>
                        </div>
                    </div>
                )}

                {/* RECENT TRANSACTIONS */}
                <RecentTransactions />
                {/* Transact Buttons */}
                <div>
                    <button
                        onClick={handleClickSend}
                        className={`w-full border rounded-full flex justify-center p-3 text-sm font-poppins mb-2 items-center gap-3 ${
                            darkmode ? 'border-white text-white' : 'border-niceBlue text-niceBlue'
                        }`}
                    >
                        <span>Send to account</span>
                        <img src={darkmode ? import_white : income} alt="income" />
                    </button>
                    <button
                        onClick={handleClickTransfer}
                        className={`w-full border rounded-full  flex justify-center p-3 text-sm font-poppins mb-2 items-center gap-3 ${
                            darkmode ? 'border-white text-white' : 'border-niceBlue text-niceBlue'
                        }`}
                    >
                        <span> Transfer to others</span>
                        <img src={darkmode ? export_white : outward} alt="outward" />
                    </button>
                    <button
                        onClick={handleClickGift}
                        className={`w-full ${
                            darkmode ? 'bg-ccdarkblue hover:bg-cdarkblue' : 'bg-cblue hover:bg-cdarkblue'
                        } rounded-full  flex justify-center p-3 text-sm font-poppins text-white mb-2 items-center gap-3`}
                    >
                        <span>Sell gift cards</span>
                        <img src={wallet} alt="wallet" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MainSide
