import * as api from '../../api'
export const getUserAction = () => async (dispatch) => {
    try {
        const { data } = await api.getUserDetails()
        console.log('USER', data?.data)

        dispatch({ type: 'GETUSER', data: data?.data })
    } catch (error) {
        // console.log(error);
        console.log(error)
    }
}

export const getAllBankAction = () => async (dispatch) => {
    try {
        const { data } = await api.getallbanks()
        const sortedbank = data?.banks
        sortedbank.sort((a, b) => {
            const nameA = a.name.toUpperCase() // Convert names to uppercase for case-insensitive comparison
            const nameB = b.name.toUpperCase()

            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0 // Names are equal
        })

        dispatch({ type: 'GETBANKS', data: sortedbank })
    } catch (error) {
        // console.log(error);
        console.log(error)
    }
}

/**
 * BENEFICIARIES
 */

export const getUserBeneficiary = (setLoading) => async (dispatch) => {
    try {
        setLoading(true)
        const { data } = await api.getBeneficiary()
        dispatch({ type: 'GETBENEFICIARIES', data: data?.data })
    } catch (error) {
        // console.log(error);
    } finally {
        setLoading(false)
    }
}
