import React, { useState } from 'react'
import natlID2 from '../../../../assets/nationalID2.svg'
import { useDispatch, useSelector } from 'react-redux'
import CustomInput from '../../../UniversalComponents/CustomInput'
import { verifynin } from '../../../../api'
import CustomModal from '../../../UniversalComponents/CustomModal'

const NationalID = ({ open, toggleNationalID, toggleCancel }) => {
    const [selectedImage, setSelectedImage] = useState(null)
    const [idnumber, setIdnumber] = useState('')
    const dispatch = useDispatch()
    const handleNumberChange = (e) => {
        const { value } = e.target
        setIdnumber(value)
    }

    const handleNINVerification = async () => {
        try {
            dispatch({ type: 'SETLOADING' })
            const response = await verifynin({ number: idnumber })
        } catch (err) {
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
        toggleNationalID()
    }
    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <CustomModal open={open} handleClose={toggleNationalID}>
            <div
                className={`${
                    darkmode ? 'bg-darkSide text-white' : 'bg-white text-black'
                } p-8 shadow-3xl rounded-3xl w-456`}
            >
                <div className="max-w-xs m-auto">
                    <div className="flex justify-center">
                        <img src={natlID2} alt="" />
                    </div>
                    <p className="text-cfont32 font-poppins font-semibold text-center">National ID</p>
                    <p className="text-sm mt-3 mb-6 text-center">Enter your National ID Number.</p>

                    <div className="max-w-xs w-full ">
                        <CustomInput
                            value={idnumber}
                            handlechange={handleNumberChange}
                            title={'Number'}
                            name={'number'}
                            placeholder={'Enter Number'}
                        />
                        <div className="flex w-full justify-center mt-24">
                            <button
                                className={` bg-cblue font-bold  text-white p-5 rounded-cround text-cfont14  w-full`}
                                // disabled={}
                                onClick={handleNINVerification}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default NationalID
