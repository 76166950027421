import React from 'react'

import Navigation from '../UniversalComponents/Navigation'

function Terms() {
    return (
        <div>
            <Navigation />
            <div className="terms">
                <h1>TERMS AND CONDITIONS</h1>
                <h2>FOR FLIPPAY.CO IN RESPECT OF THE TRADE AGREEMENT REGULATING CLIENT AND COMPANY’S RELATIONSHIP </h2>
                <h3>TRADE AGREEMENT</h3>
                <p>
                    <b>THIS TRADE AGREEMENT</b> (hereinafter referred to as <b>“the agreement”</b>) is made and
                    effective this day{' '}
                </p>
                <p>BETWEEN: THE CLIENT</p>
                <p>
                    AND: PAYNYCE NIGERIA LTD (trading under the name and style of Flippay.co, hereinafter referred to as
                    “the company”)
                </p>
                <h4>WHEREAS:</h4>
                <ul>
                    <li>
                        {' '}
                        The company is a corporation organized, registered and existing under the Companies and Allied
                        Matters Act. And its head office located at 6, Afisman drive, Anifowoshe, Ikeja, Lagos State.{' '}
                    </li>
                    <li>The company provides services in trading gift-cards, and all other incidental services.</li>
                    <li>
                        Both parties, pursuant to the agreement, concede to be bound by the terms and conditions
                        stipulated in the trade agreement.
                    </li>
                </ul>
                <h4>TERMS AND CONDITION</h4>
                <p>
                    <b>NOW THEREFORE</b>, the parties hereby agree as follows
                </p>
                <ul>
                    <li>
                        <h4>LICENSE, USE OF WEBSITE AND APPLICATIONS</h4>
                    </li>
                    <li>
                        Subject to the Client’s obligations under the Agreement being fulfilled, the Company hereby
                        grants the Client a limited license, which is personal, non- transferable, non-exclusive and
                        fully recoverable, to use the Platform(s) (including the use of the Website and any associated
                        downloadable software available on our Website from time to time), solely for personal use and
                        benefit in accordance with the terms of this Agreement. Should the Agreement be terminated for
                        any reason, the license will automatically be revoked, and the Platform software must no longer
                        be used by the Client.
                    </li>
                    <li>
                        The Company reserves any and all rights to the Platform not expressly granted to the Client by
                        this Agreement. Rights to the Platform are licensed to the Client by the Company and not sold.
                        All rights to the Platform shall remain the property of the Company.
                    </li>
                    <li>
                        The Company has the right to shut down the Platform(s) at any time for maintenance purposes
                        without prior notice to the Client. In these cases, the Platform(s) will be inaccessible
                    </li>
                    <li>
                        From time to time, acting reasonably, the Company shall have the right to add to, modify, or
                        remove any of the Platform or parts of it without liability under this Agreement. In such a
                        case, it shall use reasonable endeavors to replace any part of the Platform with an equivalent
                        where practicable.
                    </li>
                </ul>
                <h4>2. INTELLECTUAL PROPERTY</h4>
                <p>
                    2.1. The Platform(s), all copyrights, trademarks, patents, service marks, trade names, software
                    code, icons, logos, characters, layouts, trade secrets, buttons, color scheme, graphics and data
                    names are the sole and exclusive Intellectual Property (IP) of the Company or of third parties and
                    are protected by local and international intellectual property laws and treaties. This Agreement
                    does not convey an interest in or to the Platform(s) but only a right to use the Platform(s)
                    according to the terms of this Agreement. Nothing in this Agreement constitutes a waiver of the
                    Company’s IP rights.
                </p>
                <p>
                    2.2. Under no circumstances shall the Client obscure or remove any copyright, trademark or any other
                    notices from any of the Company’s IP or Website or Platform(s).
                </p>
                <p>
                    2.3. It is understood that the Company may offer its Services under different tradenames or
                    trademarks and websites. The Company owns all the images displayed on its websites, Platform(s) and
                    downloadable software and material. The Client may not use these images in any way other than the
                    manner which the Company provides them for.
                </p>
                <p>
                    2.4. The Client is permitted to store and print the information made available to him through the
                    Company’s Website or Platform(s) including documents, policies, text, graphics, video, audio,
                    software code, user interface design or logos. The Client is not permitted to alter, modify,
                    publish, transmit, distribute, otherwise reproduce commercially exploit that information, in whole
                    or in part, in any format to any third party without the Company’s express written consent.
                </p>
                <h4> 3. USE OF THE PLATFORMS, WEBSITE AND APPLICATIONS</h4>
                <p>
                    3.1. The Client agrees that he:
                    <p>
                        a) may only use the Platform for so long as he is authorized to do so under the terms of the
                        license granted hereunder
                    </p>
                    <p>b) will use the Platform only for lawful purposes;</p>
                    <p>
                        c) may not use the Platform for any purpose other than for the purpose for which it has been
                        provided under this Client Agreement
                    </p>
                    <p>
                        d) is responsible for all transactions effected on his Client Account via the Platform and the
                        use of the Platform (including the Access Data)
                    </p>
                    <p>
                        e) will logout from the Platform should his access terminal be left unattended, to prevent
                        unauthorized access to his Client Account.
                    </p>
                </p>
                <p>
                    3.2. It is absolutely prohibited for the Client to take any of the following actions in relation to
                    the Platform(s);
                    <p>
                        a) Use any software, which applies artificial intelligence analysis to the Company’s systems
                        and/or Platform(s)
                    </p>
                    <p>b) Intercept, monitor, damage or modify any communication which is not intended for him. </p>
                    <p>
                        c) Use any type of spider, virus, worm, Trojan-horse, time bomb or any other codes or
                        instructions that are designed to distort, delete, damage or disassemble the Platform(s) or the
                        communication system or any system of the Company.{' '}
                    </p>
                    <p>
                        d) Send any unsolicited commercial communication not permitted under applicable law or
                        Applicable Regulations
                    </p>
                    <p>
                        e) Do anything that will or may violate the integrity of the Company computer system or
                        Platform(s) or cause such system(s) to malfunction or stop their operation.{' '}
                    </p>
                    <p>
                        f) Unlawfully access or attempt to gain access, reverse engineer or otherwise circumvent any
                        security measures that the Company has applied to the Platform(s).{' '}
                    </p>
                    <p>
                        g) Perform any action that could potentially allow the irregular or unauthorized access or use
                        of the Platform(s).{' '}
                    </p>
                    <p>
                        h) Carry out any commercial business on the Platform, unless specifically allowed by us in
                        writing
                    </p>
                    <p>
                        i) The Company makes no express or implied representations:
                        <p>
                            1. that the Platform will be available for access all the time, or at any time on a
                            continuous uninterrupted basis. Access to the Platform may be affected, for example, by
                            routine maintenance, repairs, reconfigurations or upgrades
                        </p>
                        <p>2. as to the operation, quality or functionality of the Platform</p>
                        <p>3. that the Platform will be free of errors or defects</p>
                        <p>
                            4. that the Platform is free from viruses or anything else that has contaminating or
                            destructive properties including where such results in loss of or corruption to Client data
                            or other property
                        </p>
                    </p>
                    3.3. The Company will not be liable to the Client should his computer system or mobile phone or
                    tablet fail, damage, destroy and/or format his records and data. Furthermore, if the Client incurs
                    delays and any other form of data integrity problems that are a result of his hardware configuration
                    or mismanagement, the Company shall not be liable.
                    <p>
                        3.4. The Company will not be liable for any such disruptions or delays or problem in any
                        communication experienced by the Client when using the Platform(s).
                    </p>
                    <p>
                        3.5. The Company agrees to hold harmless the Client from losses on his Client Account in the
                        event that the Platform is ‘hacked’, or any unauthorized use of a Client Account’s Access Data
                        occurs which is due to the negligence of the Company. Likewise, the Client shall hold harmless
                        the Company from losses in the event that his Client Account is hacked or associated
                        unauthorized use of his Access Data occurs due to his negligence.
                    </p>
                </p>
                <h4>4. SAFETY AND SECURITY</h4>
                <p>
                    4.1. When the Client access the Platform, he will be asked to enter his Access Data, which are
                    confidential and he agrees to keep secret and not to disclose to any third person.
                </p>
                <p>
                    4.2. The Client agrees to notify the Company immediately if he knows or suspects that his Access
                    Data or Client Account number have or may have been disclosed to any unauthorized person. The
                    Company will then take steps to prevent any further use of such Access Data and will issue
                    replacement Access Data. The Client will be unable to place any Orders until he receives the
                    replacement Access Data.
                </p>
                <p>
                    4.3. The Client agrees that he will co-operate with any investigation the Company may conduct into
                    any misuse or suspected misuse of his Access Data.
                </p>
                <p>
                    4.4. The Client acknowledges that the Company has no responsibility if unauthorized third persons
                    gain access to information, including electronic addresses, electronic communication, personal data
                    and Access Data when the above are transmitted between the Parties using the internet or other
                    network communication facilities, post, telephone, or any other electronic means.
                </p>
                <p>
                    4.5. If the Company is informed from a reliable source that the Access Data of the Client may have
                    been received by unauthorized third parties, the Company may, at its discretion without having an
                    obligation to the Client, deactivate the Client Account.
                </p>
                <h4>5. SERVICES</h4>
                <p>
                    5.1. Trading with the Company involves the provision of the following services from the Company to
                    the Client, subject to the Client’s obligations under the Agreement being fulfilled:{' '}
                </p>
                <p>a) Brokerage/Consultancy in Trading in gift-cards</p>
                <p>b) Trading in gift cards</p>
                <p>c) Airtime and Data purchase</p>
                <p>d) Bill payment</p>
                <p>e) Money transfer services between registered users</p>
                <p>f) Money withdrawal service (to banks in Nigeria)</p>
                <h4>6. REGISTRATION AND ACCOUNT </h4>
                <p>
                    6.1. We are obligated by law to confirm and verify the identity of each person who registers on our
                    system and opens an Account with us. Therefore, at any given time, starting from the date of your
                    registration with us, we may ask you to provide personally identifiable information. We reserve the
                    right to limit, block access to our Services and/or terminate and/or close your Account with us, if
                    such information is not provided and/or if any such information provided to us appears to be untrue,
                    inaccurate, incomplete and/or incorrect. If you choose to provide us with such information and
                    register with us as our client, you are confirming to us that any information provided to us is
                    true, accurate, updated and complete information about yourself. Additionally, you agree that you
                    will not impersonate any person or entity, misrepresent any affiliation with another person, entity
                    or association, use false headers or otherwise conceal your identity from us for any purpose or
                    reason.
                </p>
                <p>
                    6.2. If you are registering as or for a business entity, you hereby declare that you have the
                    authority to bind that entity to these Terms and Conditions. We apply strict security procedures and
                    undertake to treat the information that you provide us with care in accordance with the company’s
                    private policy.
                </p>
                <p>
                    6.3. Please note that when you register with us, you will choose a username and password that will
                    personally identify you each time you log on to our system (“Access Codes”). Your Access Codes
                    (username and password) should be kept strictly private and confidential at all times. It is your
                    sole and exclusive responsibility to safeguard this information and you are responsible for all
                    actions made using your Account User Information.{' '}
                </p>
                <p>
                    6.4. You agree: (a) to notify us immediately of any unauthorized use of your Access Codes or of any
                    other violation of security and (b) at the end of each use, to log out from your Account in an
                    orderly way. If the security of your Access Codes (username and password) is breached or if you
                    suspect that they are being wrongfully used – please contact our Customer Support team immediately.
                </p>
                <h4>7. ADVICE AND COMMENTARY</h4>
                <p>
                    7.1. The Company will not advise the Client about the merits of a particular Order or transaction or
                    give him any form of investment advice and the Client acknowledges that the Services do not include
                    the provision of investment. The Client alone will decide how to handle his Client Account and place
                    Orders 0r transactions and take relevant decisions based on his own judgment.
                </p>
                <p>
                    7.2. The Company will not be under any duty to provide the Client with any legal, tax or other
                    advice relating to any Transaction. The Client may wish to seek independent advice before entering
                    into a Transaction.
                </p>
                <p>
                    7.3. The Company will not provide to the Client any trading and or other advice, but it may, from
                    time to time and at its discretion, provide the Client (or in newsletters which it may post on its
                    Website or provide to subscribers via its Website or otherwise) with information, news, market
                    commentary or other information but not as part of its Services to the Client.{' '}
                </p>
                <p>
                    7.4. Where it does so:
                    <p>(a) The Company will not be responsible for such information. </p>
                    <p>
                        (b) The Company gives no representation, warranty or guarantee as to the accuracy, correctness
                        or completeness of such information or as to the tax or legal consequences of any related
                        Transaction.{' '}
                    </p>
                    <p>
                        c) This information is provided solely to enable the Client to make his own decisions and does
                        not amount to investment advice or unsolicited financial promotions to the Client.{' '}
                    </p>
                </p>
                (
                <p>
                    7.5. It is understood that commentaries, news, or other information provided or made available by
                    the Company are subject to change and may be withdrawn at any time without notice.
                </p>
                <h4>8. PLACEMENT, ORDERS AND EXECUTION</h4>
                <p>
                    8.1. The Company may from time to time accept Client Orders or transaction in different ways such as
                    on the Platform, via telephone call and any other methods at the Company’s discretion.{' '}
                </p>
                <p>
                    8.2. The Client may place Orders with the Company on the Platform and via telephone call, by using
                    his Access Data and provided all the Essential Details are given in both cases.{' '}
                </p>
                <p>
                    8.3. The Company will use reasonable efforts to execute an Order, but it is agreed and understood
                    that despite the Company’s reasonable efforts transmission or execution may not always be achieved
                    at all for reasons beyond the control of the Company.{' '}
                </p>
                <p>
                    8.4. Orders may be placed within the normal trading hours of the Company, available on the Platform
                    and/or the Website, as amended from time to time.{' '}
                </p>
                <p>
                    8.5. The Company shall not be held responsible for any loss or damage caused, directly, indirectly,
                    by any events, actions or omissions beyond our control including, without limitation, loss or damage
                    resulting, directly or indirectly, from any delays or inaccuracies in the transmission of orders
                    and/or information due to a breakdown in, delay or failure of any transmission, communication or
                    computing facilities.{' '}
                </p>
                <p>
                    8.6. We shall have no obligation to contact you to advise upon appropriate action in light of
                    changes in market conditions or otherwise. Following execution of any transaction, you are solely
                    responsible for making and maintaining contact with us for the purpose of monitoring the position of
                    ensuring that any further instructions are given on a timely basis. We shall not be responsible for
                    any loss caused directly, indirectly, actually or alleged as a result of any inability or failure by
                    you to do so.{' '}
                </p>
                <h4>9. DECLINE OF CLIENTS ORDERS</h4>
                <p>
                    9.1. Without prejudice to any other provisions herein, the Company is entitled, at any time and at
                    its discretion, without giving any notice or explanation to the Client to restrict the Client’s
                    trading activity, to cancel Orders, to decline or refuse to transmit or execute any Order of the
                    Client, and the Client has no right to claim any damages, specific performance or compensation
                    whatsoever from the Company, in any of the following cases:{' '}
                </p>
                <p>(a) Internet connection or communications are disrupted.</p>
                <p>
                    (b) In consequence of request of regulatory or supervisory authorities, a court order or antifraud
                    or anti-money laundering authorities.
                </p>
                <p>(c) Where the legality or genuineness of the Order is under doubt. </p>
                <p>(d) A Force Majeure Event has occurred, according to paragraph.</p>
                <p>(e) In an Event of Default of the Client. </p>
                <p>(f) The Company has sent a notice of Termination of the Agreement to the Client.</p>
                <p>(g) The system of the Company rejects the Order due to trading limits imposed. </p>
                <p>(h) Under abnormal market conditions. </p>
                <p>
                    (i) The Client does not hold adequate funds in his Balance for the specific Order or the Balance
                    goes below zero.{' '}
                </p>
                <h4>10. EVENT OF DEFAULT</h4>
                <p>10.1. Each of the following constitutes an "Event of Default”: </p>
                <p>a) The failure of the Client to perform any obligation due to the Company.</p>
                <p>
                    b) Any other circumstance where the Company reasonably believes that it is necessary or desirable to
                    take any action set in the agreement.{' '}
                </p>
                <p>
                    c) An action set out in the agreement is required by a competent regulatory authority or body or
                    court.
                </p>
                <p>
                    d) The Company reasonably considers that the Client involves the Company in any type of fraud or
                    illegality or breach of Applicable Regulations or the Company is placed at risk of being involved in
                    any type of fraud or illegality or breach of Applicable Regulations if it continues offering
                    Services to the Client, even when this is not due to the Client’s wrongdoing.
                </p>
                <p>
                    e) The Company reasonably considers that there is a material violation by the Client of the
                    requirements established by legislation of the Federal Republic of Nigeria or other countries having
                    jurisdiction over the Client or his trading activities, such being materiality determined in good
                    faith by the Company.
                </p>
                <p>
                    f) If the Company suspects that the Client is engaged into money laundering activities or terrorist
                    financing or card fraud or other criminal activities.{' '}
                </p>
                <p>g) The Company reasonably suspects that the Client opened the Client Account fraudulently. </p>
                <p>
                    h) The Company reasonably suspects that the Client performed forgery or used a stolen card to fund
                    his Client Account.{' '}
                </p>
                <p>
                    10.2 If an Event of Default occurs the Company may, at its absolute discretion, at any time and
                    without prior Written Notice, take one or more of the following actions as deemed appropriate under
                    the circumstances:{' '}
                </p>
                <p>a) Terminate this Agreement immediately without prior notice to the Client. </p>
                <p>
                    b) Temporarily or permanently bar access to the Platform(s) or suspend or prohibit any functions of
                    the Platform(s) until the Company can reasonably determine that an Event of Default occurred.{' '}
                </p>
                <p>
                    c) Reject or Decline or refuse to transmit or execute any Order of the Client until the Company can
                    reasonably determine that an Event of Default occurred.
                </p>
                <p>
                    d) Restrict the Client’s trading activity until the Company can reasonably determine that an Event
                    of Default occurred.{' '}
                </p>
                <p>
                    e) In the case of fraud, forgery or use of stolen cards reverse the funds back to real owner or
                    according to the instructions of the law enforcement authorities of the relevant country, or of the
                    credit card company or of another financial institution.
                </p>
                <p>
                    f) Cancel or reverse any profits gained through abusive trading and or the application of artificial
                    intelligence on the Client Account or in case of the use of stolen cards, forgery, fraud or when the
                    Client engaged into a criminal activity or money laundering.{' '}
                </p>
                <p>Take legal action for any losses suffered by the Company. </p>
                <h4>11. TRADE CONFIRMATIONS </h4>
                <p>
                    11.1. The Company shall provide the Client with adequate reporting on his Orders. For this reason,
                    the Company will provide the Client with an online access to his Client Account via the Platform(s),
                    which will provide him with sufficient information.{' '}
                </p>
                <p>
                    If the Client has a reason to believe that the Confirmation is wrong or if the Client does not
                    receive any Confirmation when he should, the Client shall contact the Company within ten Business
                    Days from the date the Order was sent or ought to have been sent (in the event that a Conformation
                    was not sent).{' '}
                </p>
                <h4>10. CLIENT MONEY HANDLINE RULES</h4>
                <p>
                    12.1. The Company will promptly place any Client money it receives into one or more segregated
                    account(s) with reliable financial institutions (for example a bank) and the Client funds will be
                    segregated from the Company’s own money and cannot be used in the course of its business.{' '}
                </p>
                <p>
                    12.2. The Company shall not pay to the Client any interest earned on Client money (other than profit
                    gained through trading Transactions from his Client Account(s) under this Agreement) and the Client
                    waives all right to interest.
                </p>
                <p>
                    12.3. The Company may deposit Client money in overnight deposits and will be allowed to keep any
                    interest.{' '}
                </p>
                <p>12.4. Client money may be held on the Client’s behalf by bank located within or outside Nigeria.</p>
                <p>
                    12.5. It is agreed that the Company shall have the right to transfer the Client Money to successors
                    or assignees or transferees or buyers, with 15 Business Days prior Written Notice to the Client.
                </p>
                <p>
                    12.6. The Company shall have a general lien on all funds held by the Company or its Associates or
                    its nominees on the Client’s behalf until the satisfaction of his obligations.
                </p>
                <h4>13. CLIENTS ACCOUNTS, DEPOSIT AND WITHDRAWALS</h4>
                <p>
                    13.1 The Company shall open one or more a Client Account(s) for the Client to allow him to place
                    Orders.
                </p>
                <p>
                    13.2 It is understood that the types of the different Client Accounts offered by the Company and the
                    characteristics of such Client Accounts are found on the Website and are subject to change at the
                    Company’s discretion.
                </p>
                <p>
                    13.3 The Client Account shall be activated upon the Client depositing the minimum initial deposit,
                    as determined and mended by the Company in its discretion from time to time. The minimum initial
                    deposit may vary according to the type of Client Account offered to the Client and is found on the
                    Website.
                </p>
                <p>
                    13.4 The Client may deposit funds into the Client Account at any time during the course of this
                    Agreement. Deposits will be made via the methods and in the currencies accepted by the Company from
                    time to time. The detailed information about deposit options is shown on the Website.
                </p>
                <p>
                    13.5 The Company shall have the right to request the Client at any time any documentation to confirm
                    the source of funds deposited into the Client Account. The Company shall have the right to reject a
                    deposit of the Client and/or block the Client Account in any of the following cases:{' '}
                </p>
                <p>(a) if the Company is not duly satisfied as to the legality of the source of funds;</p>
                <p>
                    (b) if the Client fails to provide the Company with any relevant documents it requests from the
                    Client for client identification purposes or for any other reason; (
                </p>
                <p>
                    c) if the Company reasonably suspects or has concerns that the submitted documents may be false or
                    fake;{' '}
                </p>
                <p>
                    (d) if the Company reasonably suspects that the Client is involved in illegal or fraudulent
                    activity;{' '}
                </p>
                <p>
                    (e) if the Company is informed that the credit or debit card (or any other payment method used by
                    the Client) has been lost or stolen;{' '}
                </p>
                <p>
                    (f) where the Company reasonably considers that there is a chargeback risk for any other reason; or{' '}
                </p>
                <p>
                    (g) when the Client deposits $100,000 or more (in one or more separate deposits) and the Company is
                    unable to verify the source,{' '}
                </p>
                <p>
                    (h) when the acquiring bank, issuer bank or any third-party processor or payment service provider
                    rejected the transaction.{' '}
                </p>
                <p>
                    13.6. If the funds sent by the Client are not deposited in the Client Account when they were
                    supposed to, the Client shall notify the Company and request from the Company to make a banking
                    investigation of the transfer. The Client agrees that any charges of the investigation shall be paid
                    by the Client and deducted from his Client Account or paid directly to the bank performing the
                    investigation. The Client understands and aggress that in order to perform the investigation the
                    Client shall have to provide the Company with the requested documents and certificates.{' '}
                </p>
                <p>
                    13.7. The Company shall make withdrawals of Client funds upon the Company receiving a relevant
                    request from the Client by email or in any other method accepted by the Company from time to time.{' '}
                </p>
                <p>
                    13.7 Upon the Company receiving an instruction from the Client to withdraw funds from the Client
                    Account, after the Client completed the withdrawal process, the Company shall pay the said amount
                    within one to five Business Days, if the following requirements are met:{' '}
                </p>
                <p>
                    (a) the withdrawal instruction includes all required information and identification details of the
                    Client as may be required by the Company;{' '}
                </p>
                <p>
                    (b) the instruction is to make a transfer to the originating account (whether that is a bank
                    account, a payment system account etc) from which the money was originally deposited in the Client
                    Account or at the Client’s request to a bank account belonging to the Client;
                </p>
                <p> (c) the account where the transfer is to be made belongs to the Client; </p>
                <p>
                    (d) at the moment of payment, the Client’s Balance exceeds the amount specified in the withdrawal
                    instruction including all payment charges;{' '}
                </p>
                <p>(e) there is no Force Majeure event which prohibiting the Company from effecting the withdrawal;</p>
                <p>
                    (f) the Client does not have any Open Positions or in the case if any Open Positions the remaining
                    Balance in the Client Account shall be at least double the necessary Maintenance Margin required to
                    keep the positions open;
                </p>
                <p>
                    {' '}
                    g) the Client sends the withdrawal instruction in an email or in any other approved method by the
                    Company from time to time. It is agreed and understood that the Company will not accept third party
                    or anonymous payments in the Client Account and will not to make withdrawals to any other third
                    party or anonymous account.
                </p>
                <p>
                    13.8. The Company reserves the right to reasonably decline a withdrawal request of the Client asking
                    for a specific transfer method and the Company has the right to suggest an alternative.{' '}
                </p>
                <p>
                    13.9. All payment and transfer charges of third parties will be borne by the Client and the Company
                    shall debit the relevant Client Account for these charges.
                </p>
                <p>
                    13.9.1 The Client may send the request for internal transfer of funds to another Client Account held
                    by him with the Company. Internal transfers shall be subject to the Company’s policy from time to
                    time.{' '}
                </p>
                <p>
                    Mistakes made by the Company during transfer of funds shall be refunded to the Client. It is
                    understood that should the Client provide wrong instructions for a transfer; the Company may be
                    unable to correct the mistake and the Client may have to suffer the loss. It is further understood
                    that the Company shall not be liable for any mistakes of third-party payment service providers.{' '}
                </p>
                <h4>14. CURRENCY CONVERSION </h4>
                <p>
                    14.1. In the event that the Client deposits money in a different currency of that of the Currency of
                    the Client Account then the Company shall convert the sum deposited into the Currency of the Client
                    Account. The Company shall do this at reasonable market rate and/or rate of exchange and/or bank
                    that it considers appropriate. The Company shall be entitled to charge the Client for currency
                    conversion or retain a mark-up from the exchange rates for arranging such conversion as the Company
                    may from time to time specify to the Client and publish on the Platform and/or the Website. The
                    Company shall be entitled to charge to the Client and obtain from the Client Account or from the
                    deposited amount the expenses incurred with regard to currency conversions for the Client, including
                    commissions to banks, money transfer fees, commissions to intermediaries.{' '}
                </p>
                <p>
                    14.2. Depending on the currency used to deposit money in the Client Account, the Company may charge
                    an amount in the form of a percentage (as a fixed spread) of the amount deposited.
                </p>
                <p>
                    14.3. In the event of currency fluctuations, the Company will have no liability for any losses or
                    damages incurred on the Client.{' '}
                </p>
                <h4>15. LANQUAGE</h4>
                <p>
                    The Company’s official language is the English language and the Client should always read and refer
                    to the main Website for all information and disclosures about the Company and its activities.
                    Translation or information provided in languages other than English is for informational purposes
                    only and do not bind the Company or have any legal effect whatsoever, the Company having no
                    responsibility or liability regarding the correctness of the information therein.
                </p>
                <h4>16. COMMUNICATION AND WRITTEN NOTICE</h4>
                <p>16.1. The Client shall be able to call the Company within its normal working hours. </p>
                <p>
                    16.2. In order to communicate with the Company, the Client may use the contact details of the
                    Company available on its Website or notified to the Client in any other way.{' '}
                </p>
                <p>
                    16.3 In order to communicate with the Client, the Company will use the contact details provided by
                    the Client whilst opening the Client Account or as updated latter on. Hence, the Client has an
                    obligation to notify the Company immediately of any change in the Client’s contact details. Should
                    the Client fail to do so, the Company shall have no liability should any important notices or
                    cheques issued in his name are lost when sent by the Company at his last know details.{' '}
                </p>
                <p>
                    16.4. The following methods of communication are considered as Written Notice from the Company to
                    the Client: email, Platform’s internal mail, post, commercial courier service, air mail or the
                    Company’s Website. The following methods of communication are considered as Written Notice from the
                    Client to the Company: email, post, commercial courier service or air mail or commercial courier.{' '}
                </p>
                <p>
                    16.5. Any Written Notices sent to the Company shall have to be received within the working hours of
                    the Company. Any Notices received outside the normal working hours shall be treated as being
                    received the following Business Day.
                </p>
                <h4>17. PRIVACY, CONFIDENTIALITY AND DATA PROTECTION</h4>
                <p>
                    17.1. You acknowledge that by entering into this Agreement and opening a trading account with us and
                    using our Online Trading Facility, you will be providing us with personal data. You provide your
                    consent to us to process all such information for the purposes of complying with our legal
                    obligations, performing our contractual obligations and administering the relationship between you
                    and us.{' '}
                </p>
                <p>
                    17.2. We are the Data Controller for the purposes of all applicable Personal Data Protection
                    Legislation. For all information regarding privacy and data protection as well as for the legal
                    bases and purposes of the processing of your personal data and other relevant information, please
                    read the complete terms of our Privacy Policy carefully, before submitting an application for the
                    opening of a trading account with us.{' '}
                </p>
                <p>
                    17.3. Your telephone conversations, e-mails, internet conversations (chat), meetings and other
                    communications with us, our Associates and/or Third Party Providers will be recorded/maintained by
                    us for security purposes, in compliance with the Applicable Laws, Rules and/or Regulations, training
                    purposes as well as to maintain and improve the quality of our Services. Any recordings shall be and
                    shall remain our sole property and will be accepted by you as conclusive evidence of their content
                    as recorded by us. You agree that we may deliver copies of transcripts of such recordings to any
                    court, regulatory or government authority, including without limitation, in disputes which may arise
                    between you and us. However, technical reasons may prevent us from recording a conversation, and
                    recordings or transcripts made by us will be securely destroyed in accordance with our normal
                    practice. Consequently, you should not rely on such recordings to be available.
                </p>
                <h4>16. AMENDMENT, ALTERATIONS AND UPGRADE</h4>
                <p>
                    18.1. The Company may upgrade the Client Account, convert Client Account type, upgrade or replace
                    the Platform or enhance the services offered to the Client if it reasonably considers this is to the
                    Clients advantage and there is no increased cost to the Client.{' '}
                </p>
                <p>18.2. The Company may also change any terms of the Agreement for any of the following reasons: </p>
                <p>
                    a) Where the Company reasonably considers that: the change would make the terms of the Agreement
                    easier to understand or are more complete; or · the change would not be to the disadvantage of the
                    Client.{' '}
                </p>
                <p>b) To cover: the involvement of any service or facility the Company offers to the Client; </p>
                <p>c) the introduction of a new service or facility; </p>
                <p>d) or the replacement of an existing service or facility with a new one;</p>
                <p>
                    e) or the withdrawal of a service or facility which has become obsolete, or has ceased to be widely
                    used, or has not been used by the Client at any time in the previous year, or it has become very
                    expensive for the Company to offer.
                </p>
                <p>
                    f) To enable the Company to make reasonable changes to the services offered to the Client as a
                    result of changes in: the banking, investment or financial system; or · technology; or · the systems
                    or Platform used by the Company to run its business or offer the Services hereunder.{' '}
                </p>
                <p>
                    g) As a result of a request by any other authority or as a result of change or expected change in
                    Applicable Regulations.{' '}
                </p>
                <p>
                    h) Where the Company finds that any term in the Agreement is inconsistent with Applicable
                    Regulations. In such a case, it will not rely on that term but treat it as if it did reflect the
                    relevant Applicable Regulations and shall update the Agreement to reflect the Applicable
                    Regulations.{' '}
                </p>
                <p>
                    18.3. The Company may change any of the terms of the Agreement for any serious reason not listed
                    under paragraph{' '}
                </p>
                <p>
                    18.4. The Company shall provide the Client with advance notice of at least 10 Business Days.
                    However, the Client acknowledges that a change which is made to reflect a change of Applicable
                    Regulations may, if necessary, take effect immediately.{' '}
                </p>
                <h4>19. TERMINATIONS AND RESULTS OF TERMINATION</h4>
                <p>
                    19.1. The Client may terminate this Agreement with Written Notice to the Company at any time. 19..2.
                    Without prejudice to the Company’s rights under this Agreement to terminate it immediately without
                    prior notice to the Client, the Company may terminate the Agreement by giving at least 15 Business
                    Days Written Notice to the other Party.{' '}
                </p>
                <p>
                    19.3. Termination by any Party will not affect any obligation which has already been incurred by
                    either Party or any legal rights or obligations which may already have arisen under the Agreement or
                    any Transactions made hereunder.{' '}
                </p>
                <p>
                    19.5. Upon termination of this Agreement, all amounts payable by the Client to the Company will
                    become immediately due and payable including (but without limitation) all outstanding costs and any
                    other amounts payable to the Company, any charges and additional expenses incurred or to be incurred
                    by the Company as a result of the termination of the Agreement.{' '}
                </p>
                <p>19.5. Once notice of termination of this Agreement is sent and before the termination date: </p>
                <p>
                    a) the Company will be entitled to cease to grant the Client access to the Platform(s) or may limit
                    the functionalities the Client is allowed to use on the Platform(s);{' '}
                </p>
                <p>b) the Company will be entitled to refuse to accept new Orders from the Client; </p>
                <p>
                    c) the Company will be entitled to refuse to the Client to withdraw money from the Client Account
                    and the Company reserves the right to keep Client’s funds as necessary to close positions which have
                    already been opened and/or pay any pending obligations of the Client under the Agreement.{' '}
                </p>
                <p>19.6. Upon Termination any or all the following may apply: </p>
                <p>
                    a) The Company has the right to combine any Client Accounts of the Client, to consolidate the
                    Balances in such Client Accounts and to set off those Balances;{' '}
                </p>
                <p>b) The Company has the right to close the Client Account(s); </p>
                <p>c) The Company has the right to convert any currency; </p>
                <p>
                    d) In absence of illegal activity or suspected illegal activity or fraud of the Client or
                    instructions from the relevant authorities, if there is Balance in the Client’s favor, the Company
                    will (after withholding such amounts that in the Company’s absolute discretion considers appropriate
                    in respect of future liabilities) pay such Balance to the Client as soon as reasonably practicable
                    and supply him with a statement showing how that Balance was arrived at and, where appropriate,
                    instruct any Nominee or/and any Custodian to also pay any applicable amounts. Such funds shall be
                    delivered in accordance to the Client’s Instructions to the Client. It is understood that the
                    Company will effect payments only to an account in the name of the Client. The Company has the right
                    to refuse, at its discretion, to effect thirty party payments.
                </p>
                <h4>20. FORCE MAJEAURE</h4>
                <p>20.1. A Force Majeure Event includes without limitation each of the following: </p>
                <p>
                    a) Government actions, the outbreak of war or hostilities, the threat of war, acts of terrorism,
                    national emergency, riot, civil disturbance, sabotage, requisition, or any other international
                    calamity, economic or political crisis that, in the Company’s opinion, prevents it from maintaining
                    an orderly market in one or more of the Financial Instruments in respect of which it deals on the
                    Platform;{' '}
                </p>
                <p>
                    b) Act of God, earthquake, tsunami, hurricane, typhoon, accident, storm, flood, fire, epidemic or
                    other natural disaster making it impossible for the Company to offer its Services;{' '}
                </p>
                <p>c) Labor disputes and lock-out which affect the operations of the Company;</p>
                <p>
                    d) Suspension of trading on a market or the imposition of limits or special or unusual terms on the
                    trading in any such market or a regulatory ban on the activities of any party (unless the Company
                    has caused that ban), decisions of state authorities, governing bodies of self-regulating
                    organizations, decisions of governing bodies of organized trading platforms;{' '}
                </p>
                <p>
                    e) A financial services moratorium having been declared by appropriate regulatory authorities or any
                    other acts or regulations of any regulatory, governmental, supervisory, regulatory or supranational
                    body or authority;{' '}
                </p>
                <p>
                    f) Breakdown, failure or malfunction of any electronic, network and communication lines (not due to
                    the bad faith or willful default of the Company);{' '}
                </p>
                <p>
                    g) Any event, act or circumstances not reasonably within the Company’s control and the effect of
                    that event(s) is such that the Company is not in a position to take any reasonable action to cure
                    the default;{' '}
                </p>
                <p>
                    20.2. If the Company determines in its reasonable opinion that a Force Majeure Event exists (without
                    prejudice to any other rights under the Agreement) the Company may without prior notice and at any
                    time take any or all of the following steps:{' '}
                </p>
                <p>
                    a) Suspend or modify the application of any or all terms of the Agreement to the extent that the
                    Force Majeure Event makes it impossible or impractical for the Company to comply with them.{' '}
                </p>
                <p>
                    b) Take or omit to take all such other actions as the Company deems to be reasonably appropriate in
                    the circumstances with regard to the position of the Company, the Client and other clients;{' '}
                </p>
                <p>c) Shut down the Platform(s) in case of malfunction for maintenance or to avoid damage; </p>
                <p>
                    d) Cancel any Client Orders and Refuse to accept Orders from Clients to the extent that the Force
                    Majeure Event makes it impossible or impractical for the Company to comply with them or to avoid
                    losses to the Client;{' '}
                </p>
                <p>e) Inactivate the Client Account to avoid damage; </p>
                <p>
                    f) Close out any or all Open Positions at such prices as the Company considers in good faith to be
                    appropriate to avoid losses for the Client;{' '}
                </p>
                <p>
                    g) Increase Spreads, increase Margin requirements, decrease Leverage without notice to avoid
                    damages.{' '}
                </p>
                <p>
                    20.3. Except as expressly provided in this Agreement, the Company will not be liable or have any
                    responsibility for any type of loss or damage arising out of any failure, interruption, or delay in
                    performing its obligations under this Agreement where such failure, interruption or delay is due to
                    a Force Majeure event
                </p>
                <h4>21. REPRESENTATIONS AND WARRANTIES</h4>
                <p>21.1. The Client represents and warrants to the Company the following:</p>
                <p>a) The Client is of sound mind and capable of taking decisions for his own actions;</p>
                <p>
                    b) All actions performed under the Agreement will not violate any law or rule applicable to the
                    Client or to the jurisdiction in which the Client is resident, or any agreement by which the Client
                    is bound or by which any of the Client’s assets or funds are affected;
                </p>
                <p>
                    c) The Client will not use the IP or the Platform or Website in contravention to this Agreement, or
                    for unauthorized or unlawful purposes and that he will use the IP, Platform and Website only for the
                    benefit of his Client Account and not on behalf of any other person;{' '}
                </p>
                <p>
                    d) The Client is duly authorized to enter into the Agreement, to give Orders and to perform its
                    obligations hereunder;{' '}
                </p>
                <p>
                    e) The Client is the individual who has completed the Account Opening Application Form or, if the
                    Client is a company, the person who has completed Account Opening Application Form on the Client’s
                    behalf is duly authorized to do so;
                </p>
                <p>
                    f) The Client is acting as a principal and not as agent or representative or trustee or custodian on
                    behalf of someone else. The Client may act on behalf of someone else only if the Company
                    specifically consents to this in writing and provided all the documents required by the Company for
                    this purpose are received;{' '}
                </p>
                <p>
                    g) The information provided by the Client to the Company in the Account Opening Application Form and
                    at any time thereafter is true, accurate and complete and the documents handed over by the Client
                    are valid and authentic.{' '}
                </p>
                <p>h) The Client has read and fully understood the terms of the Agreement;</p>
                <p>
                    i) The Client funds used for trading are not in any direct or indirect way the proceeds of any
                    illegal activity or used or intended to be used for terrorist financing;{' '}
                </p>
                <p>
                    j) The Client consents to the provision of the information of the Agreement by means of a Website or
                    email;{' '}
                </p>
                <p>
                    k) The Client confirms that he has regular access to the internet and consents to the Company
                    providing him with information, including, without limitation, information about amendments to the
                    terms and conditions, costs, fees, this Agreements, policies and information by posting such
                    information on the Website or email. Should the Client wish, he may request for these to be sent by
                    a mail.
                </p>
                <h4>22. COMPLAINTS AND DISPUTES</h4>
                <p>
                    22.1. If the Client wishes to report a complaint, he must send an email to the platform, or fulfill
                    any other procedure stipulated on the company’s website or platform for that purpose. The Company
                    will try to resolve the complaint without undue delay and according to the Company’s Complaints &
                    Dispute Resolution Policy.
                </p>
                <h4>23. WAIVER</h4>
                <p>
                    23.1. Either Party’s failure to seek redress for violations, or to insist upon strict performance,
                    of any condition or provision of this Agreement, or its failure to exercise any or part of any of
                    right or remedy to which that Party is entitled under this Agreement, shall not constitute an
                    implied waiver thereof.
                </p>
                <h4>24. MULTIPLE ACCOUNT HOLDERS</h4>
                <p>
                    24.1. Where the Client comprises two or more persons, the liabilities and obligations under the
                    Agreement shall be joint and several. Any warning or other notice given to one of the persons which
                    form the Client shall be deemed to have been given to all the persons who form the Client. Any Order
                    given by one of the persons who form the Client shall be deemed to have been given by all the
                    persons who form the Client.
                </p>
                <p>
                    25.1. In the event of the death or mental incapacity of one of the persons who form the Client, all
                    funds held by the Company or its Nominee, will be for the benefit and at the order of the
                    survivor(s) and all obligations and liabilities owed to the Company will be owed by such
                    survivor(s).
                </p>
                <h4>25. FEES, TAXES AND INDUCEMENT</h4>
                <p>
                    25.1. The engagement of the Services by the Company is subject to payment of fees found on the
                    Company’s Platform or the Website (as the case may be).
                </p>
                <p>
                    25.2. It is agreed and understood that the Client shall be solely responsible for all filings, tax
                    returns and reports which should be made to any relevant authority, whether governmental or
                    otherwise, and for payment of all taxes (including but not limited to any transfer or value added
                    taxes), arising out of or in connection with his trading activity with the Company hereunder
                </p>
                <p>
                    25.3. The Client undertakes to pay all applicable stamp expenses relating to this Agreement and any
                    documentation which may be required for the currying out of the transactions under this Agreement.{' '}
                </p>
                <p>25.4. Should the Company pay or receive any fees for the Client, it shall notify the Client.</p>
                <h4>INTERPRETATION OF TERMS </h4>
                <p>In this Agreement: </p>
                <p>
                    <b>Access Data</b>: shall mean the Login and Password of the Client, which are required so as to
                    have access on and use the Platform(s) and the telephone password and Client Account number, which
                    are required so as to place Orders via phone and any other secret codes issued by the Company to the
                    Client. <br />
                    <b>Account Opening Application Form</b>: shall mean the online application form completed by the
                    Client in order to apply for the Company’s Services under this Agreement and a Client Account, via
                    which form the Company will obtain amongst other things information for the Client’s identification
                    and due diligence in accordance with the Applicable Regulations. <br />
                    <b>Affiliate</b>: shall mean in relation to the Company, any entity which directly or indirectly
                    controls or is controlled by the Company, or any entity directly or indirectly under common control
                    with the Company; <br />
                    <b>Control</b>: means the power to direct or the presence of ground to manage the affairs of the
                    Company or entity. <br />
                    <b>Applicable Regulations</b>: includes rules of a relevant regulatory authority having powers over
                    the Company, the Rules of the relevant Market or trade; and all other applicable laws, rules and
                    regulations of Nigeria. <br />
                    <b>Authorized Representative</b>: shall mean the person who act as the agent of the Client. <br />
                    <b>Balance</b>: shall mean the total financial result in the Client Account after the last Completed
                    Transaction and depositing/withdrawal operation at any period of time. <br />
                    <b>Business Day</b>: shall mean any day, other than a Saturday or a Sunday, or the 25th of December,
                    or the 1st of January or any other Nigerian or international holidays to be announced on the
                    Company’s Website. <br />
                    <b>Client</b>: refers to the proprietor of an account that deals directly with the company.
                    <br />
                    <b>Client Account</b>: shall mean the unique personalized account of the Client consisting of all
                    Completed Transactions, Open Positions and Orders in the Platform, the Balance of the Client money
                    and deposit/withdrawal transactions of the Client money. Our Website and communication may use the
                    term trading account or account, which mean Client Account. <br />
                    <b>Event of Default</b>: shall have the meaning given in the trade agreement. <br />
                    <b>Expert Advisor</b>: shall mean a mechanical online trading system designed to automate trading
                    activities on an electronic trading platform such as the Company’s Platform. It can be programmed to
                    alert the Client of a trading opportunity and can also trade his Client Account automatically
                    managing all aspects of trading operations.
                    <br />
                    <b>Force Majeure Event</b>: shall have the meaning as set out the agreement of the Client Agreement.{' '}
                    <br />
                    <b>Order</b>: shall mean an instruction from the Client to trade. <br />
                    <b>Parties</b>: shall mean the parties to this Client Agreement - i.e. the Company and the Client.{' '}
                    <br />
                    <b>Platform</b>: shall mean the electronic mechanism operated and maintained by the Company
                    consisting of a trading platform, computer devices, software, databases, telecommunication hardware,
                    programs and technical facilities, which facilitates trading activity of the Client in Financial
                    Instruments via the Client Account. It is understood that the Company may use different Platforms
                    depending on the Financial Instrument and transaction. <br />
                    <b>This day</b>: refers to the day the client assents to the terms and conditions.{' '}
                </p>
                <h4>NOTICE OF AGREEMENT</h4>
                <p>
                    By registering as a user, you are consenting to the terms and conditions of all the above-mentioned
                    documents and it means that in the event that you are accepted by us as our Client you shall be
                    bound by these terms and conditions.
                </p>
                <p>
                    By accepting these terms, you enter into a legally binding agreement with us. You acknowledge that
                    you have read and understood the terms of the Agreement.
                </p>
            </div>
        </div>
    )
}

export default Terms
