import React, { useState } from 'react'
import natlID from '../../../../assets/nationalID.svg'
import intlPassport from '../../../../assets/intl-passport.svg'
import NationalID from './NationalID'
import InternationalPassport from './InternationalPassport'
import DriversLicense from './DriversLicense'
import { useSelector } from 'react-redux'
import wNatl from '../../../../assets/w_natl.svg'
import wIntl from '../../../../assets/w_intl.svg'
import wDrive from '../../../../assets/w_driversLicense.svg'
import driverL from '../../../../assets/folder-add.svg'


const Verify = () => {
    const [isNationalID, setIsNationalID] = useState(false)
    const [isIntlPassport, setIsIntlPassport] = useState(false)
    const [isDriversLicense, setIsDriversLicense] = useState(false)

    const toggleNationalID = () => {
        setIsNationalID(!isNationalID)
    }

    const toggleIntlPassport = () => {
        setIsIntlPassport(!isIntlPassport)
    }

    const toggleDriversLicense = () => {
        setIsDriversLicense(!isDriversLicense)
    }

    const toggleCancel = () => {
        setIsNationalID(false)
        setIsIntlPassport(false)
        setIsDriversLicense(false)
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

  return (
    <div className='px-5 lg:px-20 flex flex-col items-center mt-6'>
        <div className='flex flex-col justify-between w-3/4 mb-6'>
            <p className='text-sm font-medium'>Identity verification</p>
            <p className='w-5/6 lg:w-full text-xs text-warn'>Verifying your account allows you to perform unlimited transactions on Flippay</p>
        </div>

        <div className='flex justify-between w-3/4 mb-3'>
            <div className='flex items-start'>
                <img src={darkmode ? wNatl :natlID} alt="" className='mr-3'/>

                <div>
                    <p className='font-bold'>National ID</p>
                    <p className={`text-xs lg:text-sm ${darkmode ? 'text-properGrey' : ''}`}>use your government issued ID card</p>
                </div>
            </div>

            <div className="flex items-center">
                <button
                    onClick={toggleNationalID}
                    className={`relative w-12 h-6 rounded-full transition-colors duration-300 ${
                    isNationalID ? 'bg-cblue' : 'bg-gray-400'
                    }`}
                >
                    <div
                    className={`absolute left-1 top-1 w-4 h-4 rounded-full transition-transform duration-300 transform ${
                        isNationalID ? 'translate-x-full' : 'translate-x-0'
                    } bg-white`}
                    />
                </button>
            </div>
        </div>

        <NationalID open={isNationalID} toggleNationalID={toggleNationalID} toggleCancel={toggleCancel} />

        <div className='flex justify-between w-3/4 mb-3'>
            <div className='flex items-start'>
                <img src={darkmode ? wIntl : intlPassport} alt="" className='mr-3'/>

                <div>
                    <p className='font-bold'>Int. passport</p>
                    <p className={`text-xs lg:text-sm ${darkmode ? 'text-properGrey' : ''}`}>International passport</p>
                </div>
            </div>

            <div className="flex items-center">
                <button
                    onClick={toggleIntlPassport}
                    className={`relative w-12 h-6 rounded-full transition-colors duration-300 ${
                    isIntlPassport ? 'bg-cblue' : 'bg-gray-400'
                    }`}
                >
                    <div
                    className={`absolute left-1 top-1 w-4 h-4 rounded-full transition-transform duration-300 transform ${
                        isIntlPassport ? 'translate-x-full' : 'translate-x-0'
                    } bg-white`}
                    />
                </button>
            </div>
        </div>

        <InternationalPassport open={isIntlPassport} toggleIntlPassport={toggleIntlPassport} toggleCancel={toggleCancel}/>

        <div className='flex justify-between w-3/4 mb-3'>
            <div className='flex items-start'>
                <img src={darkmode ? wDrive  : driverL} alt="" className='mr-3'/>

                <div>
                    <p className='font-bold'>Driver's license</p>
                    <p className={`text-xs lg:text-sm ${darkmode ? 'text-properGrey' : ''}`}>Not expired license</p>
                </div>
            </div>

            <div className="flex items-center">
                <button
                    onClick={toggleDriversLicense}
                    className={`relative w-12 h-6 rounded-full transition-colors duration-300 ${
                    isDriversLicense ? 'bg-cblue' : 'bg-gray-400'
                    }`}
                >
                    <div
                    className={`absolute left-1 top-1 w-4 h-4 rounded-full transition-transform duration-300 transform ${
                        isDriversLicense ? 'translate-x-full' : 'translate-x-0'
                    } bg-white`}
                    />
                </button>
            </div>
        </div>

        <DriversLicense open={isDriversLicense} toggleDriversLicense={toggleDriversLicense} toggleCancel={toggleCancel}/>

    </div>
  )
}

export default Verify