import { faAngleDown, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'

function CustomDropdown({ toggleOption, options, option, selectedOption, handleOptionChange, placeholder }) {
    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <div className="flex flex-col mb-auto relative w-full md:w-96 bg-gray-200 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl z-50">
            <div className="flex items-center justify-between w-full rounded-2xl py-1 text-xs">
                <div className="cursor-pointer mr-2" onClick={toggleOption}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-500" />
                </div>

                <input
                    type="text"
                    id="option"
                    readOnly
                    required
                    value={selectedOption?.cardType}
                    className="outline-none w-full cursor-pointer bg-inherit py-1.5 placeholder-gray-500 text-gray-500"
                    onClick={toggleOption}
                    placeholder={placeholder || 'Type a gift card name'}
                />

                <div className="cursor-pointer" onClick={toggleOption}>
                    <FontAwesomeIcon icon={option ? faAngleDown : faAngleDown} className="text-gray-500" />
                </div>
            </div>

            {option && (
                <div
                    className={`absolute right-0 mt-1 w-2/3 ${
                        darkmode ? 'bg-ccdarkblue text-white border-cdarkblue' : 'bg-white text-black border-gray-300'
                    } border  rounded-3xl rounded-tr-none shadow-lg z-50`}
                >
                    <p className="px-4 text-xs font-medium text-warn py-1">Categories</p>
                    {options.map((option) => (
                        <div
                            key={option._id}
                            value={option?.cardType}
                            className={`px-4 py-1 cursor-pointer  text-xs ${
                                darkmode ? 'hover:bg-gray-400' : 'hover:bg-gray-100'
                            }`}
                            onClick={() => handleOptionChange(option)}
                        >
                            {option?.cardType}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default CustomDropdown
