import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import playCircle from '../../../assets/play-cricle.svg'
import googlePlay from '../../../assets/google-play.svg'
import appleStore from '../../../assets/apple-store.svg'
import multiplePhones from '../../../assets/multiple-phones.svg'
import twoPhonesMobile from '../../../assets/twoPhonesMobile.svg'
import twoPhonesWeb from '../../../assets/twoPhonesWeb.svg'
import multiplesInit from '../../../assets/respon.svg'
import FooterComponent from './FooterComponent'

const AboutBody = () => {
    const dispatch = useDispatch()
    const darkmode = useSelector((state) => state.userReducer.dark)
    const [isOn, setIsOn] = useState(false)
    const location = useLocation()
    const isTrueMobile = location.state?.isTrueMobile

    const scrollToSection1 = (sectionId) => {
        const section = document.querySelector(sectionId)

        if (section) {
            // Smooth scroll to the section
            section.scrollIntoView({ behavior: 'smooth' })
        }
    }

    if (isTrueMobile) {
        scrollToSection1('#mobile')
    }

    useEffect(() => {
        // console.log('here', isTrueMobile)

        if (isTrueMobile) {
            scrollToSection1('#mobile')
        }
    }, [isTrueMobile])

    useEffect(() => {
        if (darkmode) {
            setIsOn(true)
        } else if (!darkmode) {
            setIsOn(false)
        }
    }, [darkmode])

    return (
        <div className={`${darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'}`}>
            <section className="md:flex justify-between items-center relative bg-mesh py-20 lg:py-0">
                {/* Background Overlay */}
                <div className="absolute w-full h-full bg-black bg-opacity-50 z-10 py-20 top-0 lg:py-0"></div>

                {/* Text Content */}
                <div className="pl-10 md:pl-20 md:flex justify-center items-center text-white relative z-20">
                    <div className="hidden lg:flex flex-col items-center">
                        <div className="border-l-2 border-darkGray border-solid h-40"></div>
                        <Link>
                            <FontAwesomeIcon icon={faInstagram} className="text-white text-2xl my-4 mx-2" />
                        </Link>
                        <Link>
                            <FontAwesomeIcon icon={faFacebook} className="text-white text-2xl my-4 mx-2" />
                        </Link>
                        <Link>
                            <FontAwesomeIcon icon={faTwitter} className="text-white text-2xl my-4 mx-2" />
                        </Link>
                        <div className="border-l-2 border-darkGray border-solid h-40"></div>
                    </div>

                    <div className="lg:ml-20">
                        <p className="hidden md:block text-xs">About Flippay</p>
                        <h1 className="text-4xl md:text-2xl xl:text-6xl font-bold md:leading-12">
                            Finally, you can <br />
                            <div className="flex">
                                <div className="mr-3">trade</div>
                                <div className="flex flex-col h-12 md:h-16 overflow-hidden">
                                    <span className="special bg-gradient-to-r from-cblue to-cdarkblue text-transparent bg-clip-text">
                                        faster
                                    </span>{' '}
                                    <span className="special bg-gradient-to-r from-cblue to-cdarkblue text-transparent bg-clip-text">
                                        with ease
                                    </span>{' '}
                                    <span className="special bg-gradient-to-r from-cblue to-cdarkblue text-transparent bg-clip-text">
                                        securely
                                    </span>
                                </div>
                            </div>
                        </h1>

                        <p className="text-sm font-normal leading-5 mt-5 w-4/5 md:w-auto">
                            Flippay is a Fin-Tech platform that allows users sell their gift card(s), make bill
                            payments, buy airtime/data and carryout money transfers.
                        </p>

                        <div className="flex justify-between items-center mt-14">
                            <div className="border-2 border-solid border-blue-500 p-2 rounded-3xl">
                                <Link
                                    to="https://youtu.be/kTVUpqIkZOc?si=hss7_W35tknRIP9l"
                                    className="flex hover:text-cblue"
                                >
                                    <span className="mr-2 text-sm font-semibold">How it works</span>{' '}
                                    <img src={playCircle} alt="Play" />
                                </Link>
                            </div>
                        </div>

                        <div className="flex justify-start mt-20">
                            <Link to={'https://play.google.com/store/apps/details?id=com.paynyce.flippay'}>
                                <img src={googlePlay} alt="Google Play" />
                            </Link>
                            <Link
                                className="ml-3"
                                to={'https://apps.apple.com/app/flippay-sell-gift-cards/id6475052879'}
                            >
                                <img src={appleStore} alt="Apple Store" />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Image */}
                <div className="absolute top-0 md:relative bottom-0 w-full md:w-1/2 mt-10 md:mt-0 flex justify-center">
                    <img
                        src={multiplePhones}
                        alt="Phone and Tablet Version of Flippay"
                        className="hidden md:inline w-full md:h-auto"
                    />
                    <img src={multiplesInit} alt="" className="md:hidden w-full" />
                </div>
            </section>

            <section
                className={`pl-10 pr-10 md:pl-20 md:pr-20 flex flex-col-reverse md:flex-row justify-between items-center rounded-3xl pt-10 -bottom-10 z-40 relative ${
                    darkmode ? 'text-white bg-darkAll' : 'text-black bg-white'
                }`}
                id="mobile"
            >
                <div className="">
                    <ul className="hidden md:block ml-5 mb-10 list-disc">
                        <li>
                            <p className="font-medium text-2xl">Mobile app </p>
                        </li>
                    </ul>

                    <p className="text-center md:text-left text-2xl md:text-4xl font-medium mb-10">
                        Easier to trade <br />
                        on our mobile app.
                    </p>

                    <p className="mb-5 text-sm md:text-base text-center md:text-left">Download our app here.</p>
                    <div className="flex justify-start pb-10 md:pb-0">
                        <Link to={'https://play.google.com/store/apps/details?id=com.paynyce.flippay'}>
                            <img src={googlePlay} alt="Google Play" />
                        </Link>
                        <Link className="ml-3" to={'https://apps.apple.com/app/flippay-sell-gift-cards/id6475052879'}>
                            <img src={appleStore} alt="Apple Store" />
                        </Link>
                    </div>
                </div>

                <div className="flex pb-2">
                    <div
                        className="hidden md:block z-10 relative top-2 right-20 overflow-hidden"
                        style={{ height: '448px' }}
                    >
                        <img src={twoPhonesWeb} alt="" />
                    </div>
                    {/* <div className="hidden md:block relative top-20">
                        <img src={youngerPhone} alt="" />
                    </div> */}

                    <div className="md:hidden">
                        <img src={twoPhonesMobile} alt="" />
                    </div>
                </div>
            </section>

            <section className="pl-10 md:pl-20 md:pr-20 text-white bg-black relative before:w-full before:absolute before:-top-5 before:left-0 before:-bottom-5 before:bg-black before:z-[-1] pt-10">
                <FooterComponent />
            </section>

            <section
                className={`pl-10 pr-10 text-center md:pl-20 md:pr-20 pt-5 relative before:w-full before:absolute before:-top-5 before:left-0 before:bg-white before:z-[-1] flex flex-col md:flex-row justify-between items-center ${
                    darkmode ? 'bg-darkAll' : 'bg-white'
                } text-${darkmode ? 'white' : 'black'} pb-4`}
            >
                <p className="w-52 md:w-auto text-sm mb-5 md:mb-0">
                    All rights reserved. Copyrights © 2023 Flippay. co.{' '}
                </p>
                <p className="text-sm">
                    Owned by PayNyce Nigeria Ltd, a parent company registered under the Corporate Affairs Commission
                    (CAC) with RC 1731068
                </p>
            </section>
        </div>
    )
}

export default AboutBody
