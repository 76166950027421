import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import mtn from '../../../../assets/mtn.svg'
import airtel from '../../../../assets/airtel.svg'
import mobile from '../../../../assets/9mobile.svg'
import glo from '../../../../assets/glo.svg'
import PINInput from '../../../Account/AccountComponent/Security/PINInput'
import { useDispatch, useSelector } from 'react-redux'
import SidePopup from '../../../UniversalComponents/SidePopup'
import { buyAirtime, buyData, getBillInfo } from '../../../../api'

const Airtime = ({ toggleAirtimePopup, toggleBuyAirtimePopup }) => {
    const [selectedOption, setSelectedOption] = useState('self')
    const [showPassword, setShowPassword] = useState(true)
    const [pin, setPin] = useState('')
    const [transactionType, setTransactionType] = useState('buyAirtime')
    const [selectedPlan, setSelectedPlan] = useState('')
    const [airtimeDatas, setAirtimeDatas] = useState([])
    const [dataDatas, setDataDatas] = useState([])
    const [network, setNetwork] = useState('')
    const [amount, setAmount] = useState('')
    const [phone, setPhone] = useState('')
    const [success, setSuccess] = useState('')
    const [successTwo, setSuccessTwo] = useState('')
    const [error, setError] = useState('')
    const [errorTwo, setErrorTwo] = useState('')
    const [selectedPlanCategory, setSelectedPlanCategory] = useState('')
    const dispatch = useDispatch()

    const user = useSelector((state) => state.userReducer.user)
    const { billType } = useParams()
    const navigate = useNavigate()

    const plans = ['10', '20', '50', '100', '200', '500']

    const [isPlan, setIsPlan] = useState(false)

    useEffect(() => {
        //  FOR DATA

        getBillInfo('data_bundle=1')
            .then((res) => {
                const { data } = res
                const nigeriaCurrency = data?.data.filter((d) => d.country == 'NG')
                const processedData = {
                    'MTN NIGERIA': [],
                    'GLO NIGERIA': [],
                    'AIRTEL NIGERIA': [],
                    '9MOBILE NIGERIA': []
                }
                for (let curr of nigeriaCurrency) {
                    if (curr.name.includes('MTN')) {
                        processedData['MTN NIGERIA'].push(curr)
                    } else if (curr.name.includes('9MOBILE')) {
                        processedData['9MOBILE NIGERIA'].push(curr)
                    } else if (curr.name.includes('AIRTEL')) {
                        processedData['AIRTEL NIGERIA'].push(curr)
                    } else if (curr.name.includes('GLO')) {
                        processedData['GLO NIGERIA'].push(curr)
                    }
                    // switch (curr.name) {
                    //     case 'MTN DATA BUNDLE':
                    //         processedData['MTN NIGERIA'].push(curr)
                    //         break
                    //     case 'GLO DATA BUNDLE':
                    //         processedData['GLO NIGERIA'].push(curr)
                    //         break
                    //     case 'AIRTEL DATA BUNDLE':
                    //         processedData['AIRTEL NIGERIA'].push(curr)
                    //         break
                    //     case '9MOBILE DATA BUNDLE':
                    //         processedData['9MOBILE NIGERIA'].push(curr)
                    //         break
                    //     default:
                    //         break
                    // }
                }

                // console.log(processedData, 'YEYEYEYE')
                setDataDatas(processedData)
            })
            .catch((err) => {})
    }, [])

    useEffect(() => {
        if (selectedOption === 'self') {
            setPhone(user.phone)
        }
        //  FOR AIRTIME
        getBillInfo('airtime=1')
            .then((res) => {
                const { data } = res
                const nigeriaCurrency = data?.data.filter((d) => d.country == 'NG')
                const currency = new Set(['MTN VTU', 'AIRTEL VTU', '9MOBILE VTU', 'GLO VTU'])
                const processedCurrency = {
                    'MTN VTU': {},
                    'AIRTEL VTU': {},
                    '9MOBILE VTU': {},
                    'GLO VTU': {}
                }
                for (let curr of nigeriaCurrency) {
                    if (currency.has(curr.name)) {
                        processedCurrency[curr.name] = curr

                        currency.delete(curr.name)
                    }
                    if (currency.size == 0) {
                        break
                    }
                }
                // console.log('DUDUDUDUEUUDE', processedCurrency)
                setAirtimeDatas(processedCurrency)
            })
            .catch((err) => {})
    }, [selectedOption])

    const toggleDataPlan = () => {
        setIsPlan(!isPlan)
    }

    const handleDataPlanChange = (data) => {
        setSelectedPlan(data)
        toggleDataPlan()
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value)
    }

    const handleTransactionTypeChange = (event) => {
        setTransactionType(event.target.value)
    }

    const handleBuyAirtime = async () => {
        try {
            dispatch({ type: 'SETLOADING' })
            const response = await buyAirtime('airtime', {
                itemCode: airtimeDatas[network]?.item_code,
                code: airtimeDatas[network]?.biller_code,
                phone: phone,
                amount: Number(amount)
            })

            setSuccess(response?.data?.message)
        } catch (err) {
            setError(err?.response?.data?.message)
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }

    const handleClickReset = () => {
        navigate('/account', { state: { isResetTrue: true } })
    }

    // console.log('sopt',selectedOption)
    // console.log('alive', airtimeDatas)
    // console.log('networl', network)

    const handleBuyData = async () => {
        try {
            dispatch({ type: 'SETLOADING' })
            const responseTwo = await buyData('data_bundle', {
                itemCode: selectedPlan.item_code,
                code: selectedPlan.biller_code,
                phone: phone
            })

            setSuccessTwo(responseTwo?.data?.message)
        } catch (err) {
            setErrorTwo(err?.response?.data?.message)
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }

    const handleBuyAirtimeDisable = () => {
        if (!network || !amount || pin.length != 4) {
            return true
        }
        if (selectedOption !== 'self' && !phone) {
            return true
        }
        return false
    }

    const handleBuyDataDisabled = () => {
        if (!network || !selectedPlan || pin.length != 4) {
            return true
        }
        if (selectedOption !== 'self' && !phone) {
            return true
        }
        return false
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

    const handleAmountChange = (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }
        setAmount(value)
    }

    const hanglePhoneChange = (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }
        setPhone(value)
    }

    return (
        <SidePopup toggleGeneralPopup={toggleAirtimePopup}>
            <div className="w-full flex flex-col items-center">
                <h2 className="w-4/5 lg:w-auto  text-left lg:text-center font-poppins text-cfont32 font-semibold mt-24">
                    {transactionType === 'buyAirtime' ? 'Buy Airtime' : 'Buy Data'}
                </h2>

                <p className="text-sm text-left lg:text-center mt-2.5 w-4/5 ">
                    Easily top-up on airtime or data subscriptions on Flippay.
                </p>

                <div className="flex items-center justify-between w-4/5 lg:w-2/3 mt-12">
                    <div
                        className={`cursor-pointer w-12 h-12 grid place-content-center   rounded-full ${
                            network == 'MTN VTU' ? 'border-blue-400 border-2' : 'border-gray-400 border'
                        } `}
                        onClick={() => {
                            setSelectedPlanCategory('MTN NIGERIA')
                            setNetwork('MTN VTU')
                        }}
                    >
                        <img src={mtn} alt="" />
                    </div>
                    <div
                        className={`cursor-pointer w-12 h-12 grid place-content-center  rounded-full ${
                            network == 'AIRTEL VTU' ? 'border-blue-400 border-2' : 'border-gray-400 border'
                        } `}
                        onClick={() => {
                            setNetwork('AIRTEL VTU')
                            setSelectedPlanCategory('AIRTEL NIGERIA')
                        }}
                    >
                        <img src={airtel} alt="" />
                    </div>
                    <div
                        className={`cursor-pointer w-12 h-12 grid place-content-center rounded-full ${
                            network == '9MOBILE VTU' ? 'border-blue-400 border-2' : 'border-gray-400 border'
                        } `}
                        onClick={() => {
                            setNetwork('9MOBILE VTU')
                            setSelectedPlanCategory('9MOBILE NIGERIA')
                        }}
                    >
                        <img src={mobile} alt="" />
                    </div>
                    <div
                        className={`cursor-pointer w-12 h-12 grid place-content-center   rounded-full ${
                            network == 'GLO VTU' ? 'border-blue-400 border-2' : 'border-gray-400 border'
                        } `}
                        onClick={() => {
                            setNetwork('GLO VTU')
                            setSelectedPlanCategory('GLO NIGERIA')
                        }}
                    >
                        <img src={glo} alt="" />
                    </div>
                </div>

                <div className="flex items-center justify-between w-4/5 lg:w-2/3 my-7">
                    <label className="flex items-center">
                        <input
                            type="radio"
                            name="transactionType"
                            value="buyAirtime"
                            checked={transactionType === 'buyAirtime'}
                            onChange={handleTransactionTypeChange}
                        />
                        <span className="ml-2 text-sm lg:text-base">Buy Airtime</span>
                    </label>
                    <label className="flex items-center">
                        <input
                            type="radio"
                            name="transactionType"
                            value="dataSubscription"
                            checked={transactionType === 'dataSubscription'}
                            onChange={handleTransactionTypeChange}
                        />
                        <span className="ml-2 text-sm lg:text-base">Data Subscription</span>
                    </label>
                </div>

                {transactionType === 'buyAirtime' && (
                    <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-black mb-4">
                        <div className="w-full flex flex-col items-start">
                            <label htmlFor="amount" className="text-sm font-semibold text-left">
                                Amount
                            </label>
                            <div className="w-full">
                                <input
                                    className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                    type="text"
                                    inputMode="numeric"
                                    id="amount"
                                    name="amount"
                                    required
                                    placeholder="N0.00"
                                    value={amount}
                                    onChange={handleAmountChange}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {transactionType === 'dataSubscription' && (
                    <div className="relative w-4/5 md:w-5/6 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl mb-3">
                        <label htmlFor="request" className="text-sm font-semibold" onClick={toggleDataPlan}>
                            Data plan
                        </label>
                        <div className="flex items-center justify-between w-full rounded-2xl py-1">
                            <input
                                type="text"
                                id="request"
                                readOnly
                                required
                                value={selectedPlan && selectedPlan?.short_name}
                                className="outline-none w-full cursor-pointer bg-transparent"
                                onClick={toggleDataPlan}
                                placeholder="50gb"
                            />
                        </div>

                        {isPlan && (
                            <div
                                className={`absolute right-0 mt-1 w-7/8 border rounded-3xl rounded-tr-none shadow-lg z-20 ${
                                    darkmode
                                        ? 'bg-ccdarkblue text-white border-cdarkblue'
                                        : 'bg-white text-black border-gray-300'
                                } max-h-64 scrollbar-hide overflow-y-scroll`}
                            >
                                <p className="px-4 text-xs font-medium text-warn py-1">Data plans</p>
                                {dataDatas[selectedPlanCategory]?.map((plan) => (
                                    <div
                                        key={plan.id}
                                        value={plan}
                                        className={`px-4 py-2 cursor-pointer ${
                                            darkmode ? 'hover:bg-gray-400' : 'hover:bg-gray-100'
                                        }`}
                                        onClick={() => handleDataPlanChange(plan)}
                                    >
                                        {plan?.short_name} - N{plan?.amount}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                <div className="w-4/6">
                    <p className="text-left">For who?</p>
                </div>

                <div className="flex items-center justify-center w-2/3 mt-4 mb-5">
                    <label className="flex items-center mr-12">
                        <input
                            type="radio"
                            name="who"
                            value="self"
                            checked={selectedOption === 'self'}
                            onChange={handleOptionChange}
                        />
                        <span className="ml-2">Self</span>
                    </label>
                    <label className="flex items-center">
                        <input
                            type="radio"
                            name="who"
                            value="others"
                            checked={selectedOption === 'others'}
                            onChange={handleOptionChange}
                        />
                        <span className="ml-2">Others</span>
                    </label>
                </div>

                <div
                    className={`flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-black mb-5 ${
                        selectedOption === 'self' ? 'opacity-30' : ''
                    }`}
                >
                    <div className="w-full flex flex-col items-start">
                        <label htmlFor="phoneNum" className="text-sm font-semibold">
                            Other's number
                        </label>
                        <div className="w-full">
                            <input
                                className="focus:border-none focus:outline-none w-full appearance-none bg-transparent cursor-pointer"
                                type="tel"
                                id="phoneNum"
                                name="phoneNum"
                                required
                                placeholder="Enter Phone Number"
                                disabled={selectedOption === 'self'}
                                value={phone}
                                onChange={hanglePhoneChange}
                            />
                        </div>
                    </div>
                </div>
                <PINInput showPassword={showPassword} toggleShowPassword={toggleShowPassword} handleOnChange={setPin} />

                <p className="text-sm font-bold mt-6 mb-4">
                    Forgot PIN?{' '}
                    <button onClick={handleClickReset} className="text-blue-500 font-bold">
                        Reset
                    </button>
                </p>

                {error && <p className="text-sm my-5 w-2/3 text-red-500 text-center">{error}</p>}

                {errorTwo && <p className="text-sm my-5 w-2/3 text-red-500 text-center">{errorTwo}</p>}

                {success && <p className="text-sm my-5 w-2/3 text-green-500 text-center">{success}</p>}

                {successTwo && <p className="text-sm my-5 w-2/3 text-green-500 text-center">{successTwo}</p>}

                <div className="flex w-full justify-center mb-24">
                    <button
                        className="bg-cblue hover:bg-blue-700 text-white font-bold py-5 px-4 rounded-cround w-48 mr-3 disabled:bg-gray-600"
                        onClick={transactionType === 'buyAirtime' ? handleBuyAirtime : handleBuyData}
                        disabled={
                            transactionType === 'buyAirtime' ? handleBuyAirtimeDisable() : handleBuyDataDisabled()
                        }
                    >
                        {transactionType === 'buyAirtime' ? 'Buy Airtime' : 'Buy Data'}
                    </button>
                    <button
                        className={`border border-solid border-red-500 text-${
                            darkmode ? 'white' : 'black'
                        } font-bold py-5 px-4 rounded-cround w-48`}
                        onClick={toggleAirtimePopup}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </SidePopup>
    )
}

export default Airtime
