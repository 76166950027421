import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import flippayLogo from '../../assets/flippay-logo.svg'
import flipWhite from '../../assets/flip_white.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleUp, faArrowRightLong, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

const Navigation = ({ selectedOption, setSelectedOption }) => {
    const location = useLocation()
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen2, setIsOpen2] = useState(false)
    const [isFixed, setIsFixed] = useState(false)
    const [option, setOption] = useState(false)
    const navigate = useNavigate()
    const cards = useSelector((state) => state.giftcardReducer.cards)
    const [isTrueCalc, setIsTrueCalc] = useState(false)

    const handleClickCalc = () => {
        setIsTrueCalc(location.state && location?.state?.isTrueCalc)
        navigate('/', { state: { isTrueCalc: true } })
        setTimeout(() => {
            setIsTrueCalc(false)
        }, 2000)
    }

    const toggleOption = () => {
        setOption(!option)
    }

    const handleOptionChange = (opt) => {
        setSelectedOption(opt)
        // window.history.pushState({}, '', '/#calc')
        const sectiontop = window?.document?.getElementById('calc')?.offsetTop
        if (sectiontop) {
            window.scroll({ top: sectiontop, behavior: 'smooth' })
        }
        toggleOption()
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsFixed(true)
            } else {
                setIsFixed(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0) // Scroll to the top on component mount
    }, [])

    const toggleNavbar = () => {
        setIsOpen2(false)
        setIsOpen(!isOpen)
    }

    const toggleNavbar2 = () => {
        setIsOpen(false)
        setIsOpen2(!isOpen2)
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <div className={`${darkmode ? 'bg-darkAll' : ''}`}>
            <div
                className={`hidden lg:flex justify-between md:px-5 xl:px-20 py-5 ${
                    isFixed ? 'fixed top-0 left-0 w-full bg-white/30 backdrop-blur-lg shadow z-50 bg-opacity-90' : ''
                } ${darkmode ? 'bg-darkSide text-white' : 'bg-white/30 text-black'}`}
            >
                <div className="flex justify-center items-center">
                    <div className="border-r-2 border-solid border-grayed pr-5">
                        <Link to="/">
                            <img src={darkmode ? flipWhite : flippayLogo} alt="Flippay" />{' '}
                        </Link>
                    </div>
                    <div className="px-5">
                        <button onClick={handleClickCalc}>
                            <Link
                                className={`hover:text-cblue hover:border-solid hover:border-cblue hover:border-b-2 pb-2 ${
                                    isTrueCalc
                                        ? 'text-cblue border-cblue border-b-2'
                                        : darkmode
                                        ? 'text-white'
                                        : 'txtcolor'
                                }`}
                            >
                                Calculate Rates
                            </Link>
                        </button>
                    </div>
                    <div className="px-5">
                        <Link
                            to="/about"
                            className={`
                             hover:text-cblue hover:border-solid hover:border-cblue hover:border-b-2 pb-2 ${
                                 location.pathname === '/about'
                                     ? 'text-cblue border-cblue border-b-2'
                                     : darkmode
                                     ? 'text-white'
                                     : 'text-txtcolor'
                             }`}
                        >
                            About Us
                        </Link>
                    </div>
                    <div className="px-5">
                        <Link
                            to="/support"
                            className={` hover:text-cblue hover:border-solid hover:border-cblue hover:border-b-2 pb-2 ${
                                location.pathname === '/support'
                                    ? 'text-cblue border-cblue border-b-2'
                                    : darkmode
                                    ? 'text-white'
                                    : 'text-txtcolor'
                            }`}
                        >
                            Support
                        </Link>
                    </div>

                    <div
                        className={`hidden z-50 lg:flex items-center ${
                            darkmode ? 'bg-darkAll' : 'bg-white/30'
                        } justify-end backdrop-blur sticky w-4/5 md:w-96 rounded-2xl`}
                    >
                        <div className="relative w-4/5 md:w-96 bg-gray-200 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl">
                            <div className="flex items-center justify-between w-full rounded-2xl py-1 text-xs">
                                <div className="cursor-pointer mr-2" onClick={toggleOption}>
                                    <FontAwesomeIcon icon={option ? '' : faMagnifyingGlass} className="text-gray-500" />
                                </div>

                                <input
                                    type="text"
                                    id="option"
                                    readOnly
                                    required
                                    value={selectedOption?.cardType}
                                    className="outline-none w-full cursor-pointer bg-inherit py-1.5 placeholder-gray-500 text-gray-500"
                                    onClick={toggleOption}
                                    placeholder="Type a gift card name"
                                />

                                <div className="cursor-pointer" onClick={toggleOption}>
                                    <FontAwesomeIcon
                                        icon={option ? faAngleUp : faAngleRight}
                                        className="text-gray-500"
                                    />
                                </div>
                            </div>

                            {option && (
                                <div
                                    className={`absolute right-0 mt-1 w-2/3 ${
                                        darkmode
                                            ? 'bg-ccdarkblue text-white border-cdarkblue'
                                            : 'bg-white text-black border-gray-300'
                                    } border  rounded-3xl rounded-tr-none shadow-lg z-50`}
                                >
                                    <p className="px-4 text-xs font-medium text-warn py-1">Categories</p>
                                    {cards.map((oneGiftCard) => (
                                        <div
                                            key={oneGiftCard._id}
                                            value={oneGiftCard?.cardType}
                                            className={`px-4 py-1 cursor-pointer  text-xs ${
                                                darkmode ? 'hover:bg-gray-400' : 'hover:bg-gray-300'
                                            }`}
                                            onClick={() => handleOptionChange(oneGiftCard)}
                                        >
                                            {oneGiftCard?.cardType}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex justify-center items-center">
                    <div className="px-5">
                        <Link
                            to="/signin"
                            className="hover:text-cblue hover:border-solid hover:border-cblue hover:border-b-2 pb-2"
                        >
                            Log In
                        </Link>
                    </div>
                    <div className="pl-5">
                        <Link
                            to="/signup"
                            className="bg-cblue flex items-center gap-2 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-2xl"
                        >
                            <span>Create account</span> <FontAwesomeIcon icon={faArrowRightLong} />
                        </Link>
                    </div>
                </div>
            </div>

            <div
                className={`py-10 px-6 flex justify-between items-center lg:hidden ${
                    darkmode ? 'bg-darkSide text-white' : 'bg-white text-black'
                }`}
            >
                <div>
                    <Link to="/">
                        <img src={darkmode ? flipWhite : flippayLogo} alt="Flippay" />{' '}
                    </Link>
                </div>

                <div className="flex items-center">
                    <button onClick={toggleNavbar2} className="text-black mr-4">
                        {isOpen2 ? (
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="text-blue-500 text-2xl" />
                        ) : (
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                className={`${darkmode ? 'text-white' : 'text-black'} text-2xl`}
                            />
                        )}
                    </button>

                    <button onClick={toggleNavbar} className="text-black">
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke={darkmode && !isOpen ? 'white' : darkmode && isOpen ? 'blue' : 'currentColor'}
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            {isOpen ? (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            ) : (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16m-7 6h7"
                                />
                            )}
                        </svg>
                    </button>
                </div>
            </div>

            {isOpen && (
                <div
                    className={`${
                        darkmode ? 'bg-cdarkblue text-white' : 'bg-white text-black'
                    } -top-10 rounded-3xl relative shadow-left before:rounded-xl before:w-48 before:h-48 before:absolute before:-top-11 before:left-24 before:${
                        darkmode ? 'bg-cdarkblue text-white' : 'bg-white text-black'
                    } before:shadow-up before:transform before:rotate-45 after:w-48 after:rounded-sm after:h-48 after:absolute after:-top-20 after:left-0 after:${
                        darkmode ? 'bg-cdarkblue text-white' : 'bg-white text-black'
                    } after:transform after:-skew-y-0 lg:hidden`}
                >
                    <div className="z-10 relative pt-5">
                        <div className="px-5 py-5 flex justify-center">
                            <button onClick={handleClickCalc}>
                                <Link className="hover:text-cblue hover:border-solid hover:border-cblue hover:border-b-2 pb-2">
                                    Calculate Rates
                                </Link>
                            </button>
                        </div>
                        <div className="px-5 py-5 flex justify-center">
                            <Link
                                to="/about"
                                className="hover:text-cblue hover:border-solid hover:border-cblue hover:border-b-2 pb-2"
                            >
                                About Us
                            </Link>
                        </div>
                        <div className="px-5 py-5 flex justify-center">
                            <Link
                                to="/support"
                                className="hover:text-cblue hover:border-solid hover:border-cblue hover:border-b-2 pb-2"
                            >
                                Support
                            </Link>
                        </div>

                        <div className="px-5 py-5 flex justify-center">
                            <Link
                                to="/signin"
                                className="hover:text-cblue hover:border-solid hover:border-cblue hover:border-b-2 pb-2"
                            >
                                Log In
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            {isOpen2 && (
                <div
                    className={`${
                        darkmode ? 'bg-cdarkblue text-white' : 'bg-white text-black'
                    } -top-10 rounded-3xl relative shadow-left before:rounded-xl before:w-48 before:h-48 before:absolute before:-top-11 before:left-24 before:${
                        darkmode ? 'bg-cdarkblue text-white' : 'bg-white text-black'
                    } before:shadow-up before:transform before:rotate-45 after:w-48 after:rounded-sm after:h-48 after:absolute after:-top-20 after:left-0 after:${
                        darkmode ? 'bg-cdarkblue text-white' : 'bg-white text-black'
                    } after:transform after:-skew-y-0 lg:hidden`}
                >
                    <div className="pb-32 pt-10  flex flex-col items-center mb-auto relative w-full md:w-96rounded-2xl z-50">
                        <div className="flex items-center justify-between rounded-2xl py-1 text-xs w-4/5 relative z-10 bg-gray-300 border-gray-300 border px-5 hover:border-gray-300">
                            <div className="cursor-pointer mr-2" onClick={toggleOption}>
                                <FontAwesomeIcon icon={option ? '' : faMagnifyingGlass} />
                            </div>

                            <input
                                type="text"
                                id="option"
                                readOnly
                                required
                                value={selectedOption?.cardType}
                                className="outline-none w-full cursor-pointer py-1.5 bg-inherit"
                                onClick={toggleOption}
                                placeholder="Type a gift card name"
                            />

                            <div className="cursor-pointer" onClick={toggleOption}>
                                <FontAwesomeIcon icon={option ? faAngleUp : faAngleRight} />
                            </div>
                        </div>

                        {option && (
                            <div
                                className={`absolute top-16 right-10 mt-1 w-2/3 ${
                                    darkmode
                                        ? 'bg-ccdarkblue text-white border-cdarkblue'
                                        : 'bg-white text-black border-gray-300'
                                } border  rounded-3xl rounded-tr-none shadow-lg z-50`}
                            >
                                <p className="px-4 text-xs font-medium text-warn py-1">Categories</p>
                                {cards.map((oneGiftCard) => (
                                    <div
                                        key={oneGiftCard?._id}
                                        value={oneGiftCard?.cardType}
                                        className="px-4 py-1 cursor-pointer hover:bg-gray-100 text-xs"
                                        onClick={() => handleOptionChange(oneGiftCard)}
                                    >
                                        {oneGiftCard?.cardType}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Navigation
