import MainMid from '../Dashboard/DashboardComponent/MainMid'
import UserContainer from '../UniversalComponents/UserContainer'

const Dashboard = () => {
    return (
        <UserContainer active={'dashboard'}>
            <MainMid />
        </UserContainer>
    )
}

export default Dashboard
