import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import receipt from '../../../assets/receipt-text.svg'
import coin from '../../../assets/coin.svg'
import cable from '../../../assets/tv.svg'
import cableBlue from '../../../assets/tv-blue.svg'
import globe from '../../../assets/globe.svg'
import globeBlue from '../../../assets/globe-blue.svg'
import bolt from '../../../assets/bolt.svg'
import boltBlue from '../../../assets/bolt-blue.svg'
import frame from '../../../assets/Frame.svg'
import Airtime from './Airtime/Airtime'
import BuyAirtime from './Airtime/BuyAirtime'
import Cable from './Cable/Cable'
import PayCable from './Cable/PayCable'
import Internet from './InternetBill/Internet'
import PayInternet from './InternetBill/PayInternet'
import Electricity from './ElectricityBill/Electricity'
import PayElectricity from './ElectricityBill/PayElectricity'
import { useSelector } from 'react-redux'
import tvWhite from '../../../assets/tv-white.svg'
import globeWhite from '../../../assets/globe-white.svg'
import boltWhite from '../../../assets/bolt-white.svg'
import CardAmount from '../../UniversalComponents/CardAmount'
import CardDetail from '../../UniversalComponents/CardDetail'
import cardimg from '../../../assets/cardwallet.png'
import guard from '../../../assets/guard.png'
import check from '../../../assets/check.svg'
import identitypass from '../../../assets/identitypass.png'
import ActionTablet from '../../UniversalComponents/ActionTablet'
import FooterComponent from '../../LandingPage/LandingPageComponents/FooterComponent'
import { useLocation } from 'react-router-dom'

const TopupMid = () => {
    const [isAirtimeOpen, setIsAirtimeOpen] = useState(false)
    const [isBuyAirtimePopupOpen, setIsBuyAirtimePopupOpen] = useState(false)

    const [isBillOpen, setIsBillOpen] = useState(false)

    // CABLE
    const [isCableOpen, setIsCableOpen] = useState(false)
    const [isPayCableOpen, setIsPayCableOpen] = useState(false)

    //INTERNET
    const [isInternetOpen, setIsInternetOpen] = useState(false)
    const [isPayInternetOpen, setIsPayInternetOpen] = useState(false)

    //ELECTRICITY
    const [isElectricityOpen, setIsElectricityOpen] = useState(false)
    const [isPayElectricityOpen, setIsPayElectricityOpen] = useState(false)

    const location = useLocation();
    const isTrueBill = location.state?.isTrueBill;
    const isTrueAirtime = location.state?.isTrueAirtime;

    const clickOut = () => {
        if (isAirtimeOpen) {
            setIsAirtimeOpen(false)
        } else if (isCableOpen) {
            setIsCableOpen(false)
        } else if (isInternetOpen) {
            setIsInternetOpen(false)
        } else if (isElectricityOpen) {
            setIsElectricityOpen(false)
        }
    }

    useEffect(() => {
        // Check if isTrue is true and set isOpen to true
        if (isTrueAirtime) {
            setIsAirtimeOpen(true);
        } else if (isTrueBill) {
            setIsBillOpen(true)
        }
    }, [isTrueAirtime, isTrueBill]);

    //AIRTIME
    const toggleAirtimePopup = () => {
        setIsAirtimeOpen(!isAirtimeOpen)
        setIsBillOpen(false)
    }

    const toggleBuyAirtimePopup = () => {
        setIsBuyAirtimePopupOpen(!isBuyAirtimePopupOpen)
        setIsAirtimeOpen(false)
    }

    const toggleNewTransactionPopup = () => {
        setIsAirtimeOpen(!isAirtimeOpen)
        setIsBuyAirtimePopupOpen(!isBuyAirtimePopupOpen)
    }

    const toggleBillDisplay = () => {
        setIsBillOpen(!isBillOpen)
    }

    // CABLE
    const toggleCable = () => {
        setIsCableOpen(!isCableOpen)
    }

    const togglePayCable = () => {
        setIsPayCableOpen(!isPayCableOpen)
        setIsCableOpen(false)
    }

    const toggleNewTransactionCable = () => {
        setIsCableOpen(!isCableOpen)
        setIsPayCableOpen(!isPayCableOpen)
    }

    //INTERNET
    const toggleInternet = () => {
        setIsInternetOpen(!isInternetOpen)
    }

    const togglePayInternet = () => {
        setIsPayInternetOpen(!isPayInternetOpen)
        setIsInternetOpen(false)
    }

    const toggleNewTransactionInternet = () => {
        setIsInternetOpen(!isInternetOpen)
        setIsPayInternetOpen(!isPayInternetOpen)
    }

    //ELECTRICITY
    const toggleElectricity = () => {
        setIsElectricityOpen(!isElectricityOpen)
    }

    const togglePayElectricity = () => {
        setIsPayElectricityOpen(!isPayElectricityOpen)
        setIsElectricityOpen(false)
    }

    const toggleNewTransactionElectricity = () => {
        setIsElectricityOpen(!isElectricityOpen)
        setIsPayElectricityOpen(!isPayElectricityOpen)
    }

    const user = useSelector((state) => state.userReducer.user)
    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <div onClick={clickOut} className="max-w-2xl py-3 px-5 lg:px-0">
            <div className={`${darkmode ? 'bg-darkAll' : 'bg-white'} text-${darkmode ? 'white' : 'black'} w-full`}>
                <p className="text-4xl font-semibold">Topup</p>

                <p className="text-sm mt-3 mb-8">
                    Send money to your local account, or transfer to <br /> others on Flippay.
                </p>

                <div className="block md:flex gap-4">
                    <CardAmount
                        bgcolor={darkmode ? '#2D2D2D' : '#fff'}
                        sidecontent={<img src={check} className="-translate-y-2" alt="side" />}
                        bgimg={cardimg}
                    />
                    <CardDetail
                        title="All transactions are
                        secured."
                        subtitle="You need not worry about fraudsters!"
                        footertitle="100% Secured"
                        cardimg={guard}
                        // footerimg={secure}
                        titlecolor="#213B98"
                        subtitlecolor="#213B98"
                        bgcolor="rgb(204, 229, 255)"
                        footerbgcolor="#7FBDFF"
                        footerimg={identitypass}
                    />
                </div>
                <div>
                    <div className="block lg:flex items-center justify-between gap-4 mt-12">
                        <ActionTablet
                            sideimg={coin}
                            action={toggleAirtimePopup}
                            title="Airtime/data"
                            subtitle="Top-up on airtime or data"
                        />
                        <ActionTablet
                            sideimg={receipt}
                            action={toggleBillDisplay}
                            title="Pay bills"
                            subtitle="Quickly settle bills"
                        />
                    </div>

                    {isBillOpen && (
                        <p className={`text-xs font-semibold mt-7 mb-5 ml-10 ${darkmode ? 'text-properGrey' : ''}`}>
                            Fast bill payment portal
                        </p>
                    )}

                    {isBillOpen && (
                        <div>
                            <div
                                className={`flex justify-between px-5 py-3 mb-5 rounded-3xl w-full mr-4 cursor-pointer ${
                                    darkmode ? 'bg-black' : ''
                                }`}
                                onClick={toggleCable}
                            >
                                <div className="flex items-center">
                                    <div className="mr-3">
                                        <img
                                            src={darkmode && !isCableOpen ? tvWhite : isCableOpen ? cableBlue : cable}
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <p
                                            className={`${
                                                darkmode && !isCableOpen
                                                    ? 'text-white'
                                                    : isCableOpen
                                                    ? 'text-cblue'
                                                    : 'text-black'
                                            } font-bold`}
                                        >
                                            Cable TV
                                        </p>
                                        <p
                                            className={`text-sm ${
                                                darkmode && !isCableOpen
                                                    ? 'text-properGrey'
                                                    : isCableOpen
                                                    ? 'text-cblue'
                                                    : 'text-black'
                                            } `}
                                        >
                                            Easily pay your cable bill
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className={`p-3 w-8 h-8 grid place-content-center rounded-full ${
                                        darkmode ? 'bg-cdarkblue' : 'bg-black'
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={isCableOpen ? faAngleDown : faAngleRight}
                                        className="text-white"
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {isBillOpen && (
                        <div>
                            <div
                                className={`flex justify-between px-5 py-3 mb-5 rounded-3xl w-full mr-4 cursor-pointer ${
                                    darkmode ? 'bg-black' : ''
                                }`}
                                onClick={toggleInternet}
                            >
                                <div className="flex items-center">
                                    <div className="mr-3">
                                        <img
                                            src={
                                                darkmode && !isInternetOpen
                                                    ? globeWhite
                                                    : isInternetOpen
                                                    ? globeBlue
                                                    : globe
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <p
                                            className={`${
                                                darkmode && !isInternetOpen
                                                    ? 'text-white'
                                                    : isInternetOpen
                                                    ? 'text-cblue'
                                                    : 'text-black'
                                            } font-bold`}
                                        >
                                            Internet Bill
                                        </p>
                                        <p
                                            className={`text-sm ${
                                                darkmode && !isInternetOpen
                                                    ? 'text-properGrey'
                                                    : isInternetOpen
                                                    ? 'text-cblue'
                                                    : 'text-black'
                                            } `}
                                        >
                                            Easily pay your internet bill
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className={`p-3 w-8 h-8 grid place-content-center rounded-full ${
                                        darkmode ? 'bg-cdarkblue' : 'bg-black'
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={isInternetOpen ? faAngleDown : faAngleRight}
                                        className="text-white"
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {isBillOpen && (
                        <div>
                            <div
                                className={`flex justify-between px-5 py-3 mb-5 rounded-3xl w-full mr-4 cursor-pointer ${
                                    darkmode ? 'bg-black' : ''
                                }`}
                                onClick={toggleElectricity}
                            >
                                <div className="flex items-center">
                                    <div className="mr-3">
                                        <img
                                            src={
                                                darkmode && !isElectricityOpen
                                                    ? boltWhite
                                                    : isElectricityOpen
                                                    ? boltBlue
                                                    : bolt
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <p
                                            className={`${
                                                darkmode && !isElectricityOpen
                                                    ? 'text-white'
                                                    : isElectricityOpen
                                                    ? 'text-cblue'
                                                    : 'text-black'
                                            } font-bold`}
                                        >
                                            Electricity Bill
                                        </p>

                                        <p
                                            className={`text-sm ${
                                                darkmode && !isElectricityOpen
                                                    ? 'text-properGrey'
                                                    : isElectricityOpen
                                                    ? 'text-cblue'
                                                    : 'text-black'
                                            } `}
                                        >
                                            Easily pay your electricity bill
                                        </p>
                                    </div>
                                </div>

                                <div
                                    className={`p-3 w-8 h-8 grid place-content-center rounded-full ${
                                        darkmode ? 'bg-cdarkblue' : 'bg-black'
                                    }`}
                                >
                                    <FontAwesomeIcon
                                        icon={isElectricityOpen ? faAngleDown : faAngleRight}
                                        className="text-white"
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {!isBillOpen && (
                        <div className="flex flex-col items-center">
                            <div>
                                <img src={frame} alt="" />
                            </div>
                            <p className="font-medium text-2xl text-greyy">Holla! you must be new here.</p>
                            <p className="text-greyy">Perform a transaction</p>
                        </div>
                    )}
                </div>

                <section className="pl-5 text-white bg-black relative rounded-b-3xl md:hidden">
                    <FooterComponent extra={true} />
                </section>

                <section className={`text-center pt-5 relative before:w-full before:absolute before:-top-5 before:left-0 before:-bottom-5 before:bg-white before:z-[-1] flex flex-col md:flex-row justify-between items-center md:hidden ${darkmode ? 'bg-darkAll' : 'bg-white'} text-${darkmode ? 'white' : 'black'}`}>
                    <p className="w-52 md:w-auto text-sm mb-5 md:mb-0">
                        All rights reserved. Copyrights © 2023 Flippay. co.{' '}
                    </p>
                    <p className="text-sm">
                        Owned by PayNyce Nigeria Ltd, a parent company registered under the Corporate Affairs Commission
                        (CAC) with RC 1731068
                    </p>
                </section>

                {isAirtimeOpen && (
                    <Airtime toggleBuyAirtimePopup={toggleBuyAirtimePopup} toggleAirtimePopup={toggleAirtimePopup} />
                )}

                {isBuyAirtimePopupOpen && (
                    <BuyAirtime
                        toggleNewTransactionPopup={toggleNewTransactionPopup}
                        toggleBuyAirtimePopup={toggleBuyAirtimePopup}
                    />
                )}

                {isCableOpen && <Cable togglePayCable={togglePayCable} toggleCable={toggleCable} />}

                {isPayCableOpen && (
                    <PayCable toggleNewTransactionCable={toggleNewTransactionCable} togglePayCable={togglePayCable} />
                )}

                {isInternetOpen && <Internet togglePayInternet={togglePayInternet} toggleInternet={toggleInternet} />}

                {isPayInternetOpen && (
                    <PayInternet
                        toggleNewTransactionInternet={toggleNewTransactionInternet}
                        togglePayInternet={togglePayInternet}
                    />
                )}

                {isElectricityOpen && (
                    <Electricity togglePayElectricity={togglePayElectricity} toggleElectricity={toggleElectricity} />
                )}

                {isPayElectricityOpen && (
                    <PayElectricity
                        toggleNewTransactionElectricity={toggleNewTransactionElectricity}
                        togglePayElectricity={togglePayElectricity}
                    />
                )}
            </div>
        </div>
    )
}

export default TopupMid
