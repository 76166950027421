import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBankAction } from '../../../../redux/actions/userActions'
import dsafe from '../../../../assets/d_security-safe.svg'
import safe from '../../../../assets/safe.svg'
import dbank from '../../../../assets/d_bank.svg'
import bank from '../../../../assets/bank.svg'
import BVNModal from '../../../UniversalComponents/BVNModal'
import BankModal from '../../../UniversalComponents/BankModal'
const Bank = () => {
    const dispatch = useDispatch()

    const darkmode = useSelector((state) => state.userReducer.dark)
    const user = useSelector((state) => state.userReducer.user)

    const [openbvn, setOpenbvn] = useState(false)
    const [openbank, setOpenbank] = useState(false)

    useEffect(() => {
        dispatch(getAllBankAction())
    }, [])

    return (
        <div className="pl-20 lg:px-20 mt-6">
            <div
                className={`flex gap-3 mb-6  ${user.bvnVerified ? 'opacity-40' : 'cursor-pointer'}`}
                onClick={user.bvnVerified ? () => {} : () => setOpenbvn(true)}
            >
                <img src={darkmode ? dsafe : safe} alt="" />
                <div>
                    <p className="text-sm font-medium">BVN details</p>
                    <p className="w-5/6 lg:w-full text-xs text-warn">Click here to add BVN</p>
                </div>
            </div>
            <div
                className={`flex gap-3 mb-6 ${user.bvnVerified ? 'cursor-pointer' : 'opacity-40'}`}
                onClick={user.bvnVerified ? () => setOpenbank(true) : () => {}}
            >
                <img src={darkmode ? dbank : bank} alt="" />
                <div>
                    <p className="text-sm font-medium">Add Bank account</p>
                    <p className="w-5/6 lg:w-full text-xs text-warn">Set-up preferred Bank for transactions</p>
                </div>
            </div>
            <BVNModal open={openbvn} closeModal={() => setOpenbvn(false)} />
            <BankModal open={openbank} closeModal={() => setOpenbank(false)} />
        </div>
    )
}

export default Bank
