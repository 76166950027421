import React, { useEffect, useState } from 'react'
import vector1 from '../../../assets/vector1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllRawCardInformation } from '../../../redux/actions/giftcardAction'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
const cardCategories = ['eCode', 'largeCard', 'others', 'standardCard', 'smallCard']

function CalcGiftCards({ selectedOption }) {
    const [selectedCard, setSelectedCard] = useState({})
    const [selectedCountry, setSelectedCountry] = useState('')
    const [selectedCardCategory, setSelectedCardCategory] = useState('')
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const [cardValue, setCardValue] = useState('')

    const dispatch = useDispatch()
    const darkmode = useSelector((state) => state.userReducer.dark)
    const cards = useSelector((state) => state.giftcardReducer.cards)

    useEffect(() => {
        handleCardChange(selectedOption)
    }, [selectedOption])
    const handleCardChange = (cat) => {
        setSelectedCard(cat)
        setSelectedCountry({})
        setSelectedCardCategory('')
        setIsCard(false)
    }
    const handleCountryChange = (cou) => {
        setSelectedCountry(cou)
        setSelectedCardCategory('')
        toggleCountry()
    }

    const toggleCard = () => {
        setIsCard(!isCard)
        setIsCardCategory(false)
        setIsCountry(false)
        setIsCardAmount(false)
    }

    const toggleCountry = () => {
        setIsCountry(!isCountry)
        setIsCard(false)
        setIsCardCategory(false)
        setIsCardAmount(false)
    }
    const handleCardCategoryChange = (ccat) => {
        setSelectedCardCategory(ccat)
        toggleCardCategory()
    }

    const handleInputChange = (e) => {
        setCardValue(e.target.value)
    }

    const toggleCardAmount = () => {
        setIsCardAmount(!isCardAmount)
        setIsCard(false)
        setIsCountry(false)
        setIsCardCategory(false)
    }

    const toggleCardCategory = () => {
        setIsCardCategory(!isCardCategory)
        setIsCard(false)
        setIsCountry(false)
        setIsCardAmount(false)
    }

    const [isCard, setIsCard] = useState(false)
    const [isCountry, setIsCountry] = useState(false)
    const [isCardCategory, setIsCardCategory] = useState(false)
    const [isCardAmount, setIsCardAmount] = useState(false)

    useEffect(() => {
        dispatch(getAllRawCardInformation())
    }, [])

    return (
        <section
            id="calc"
            className="pl-8 relative md:pl-20 bg-cdarkblue -translate-y-16 lg:-translate-y-24 xl:-translate-y-36 flex flex-col lg:flex-row justify-end pb-14 lg:pb-24 xl:pb-14"
        >
            <div className="z-10 text-white mt-24 w-full md:pr-10">
                <p className="w-48 font-poppins md:w-auto text-2xl lg:text-3xl font-semibold mb-6">
                    Calculate gift card rates.
                </p>
                <p className="w-4/5 text-sm md:hidden">
                    Input the required details in each field below to calculate how much you will be paid for certain
                    gift cards on Flippay.
                </p>

                <p className="hidden md:block text-base font-normal">
                    Input the required details in each field below to calculate how <br /> much you will be paid for
                    certain gift cards on Flippay.
                </p>

                <p className="text-red-300 text-bold mt-6">{error}</p>

                <div className="mt-12 pr-10">
                    <div className="mb-6 w-full">
                        <div className="relative w-4/5 md:w-full mr-2.5">
                            <label htmlFor="category" className="text-sm font-semibold ml-4" onClick={toggleCard}>
                                Gift card name
                            </label>
                            <div className="flex items-center justify-between w-full rounded-ccround py-5 border-gray-400  border hover:border-gray-500 px-4 mt-4">
                                <input
                                    type="text"
                                    id="category"
                                    readOnly
                                    required
                                    value={selectedCard?.cardType}
                                    className="outline-none w-full cursor-pointer bg-transparent"
                                    onClick={toggleCard}
                                    placeholder="Enter card name"
                                />

                                <div className="cursor-pointer" onClick={toggleCard}>
                                    <FontAwesomeIcon icon={isCard ? faAngleUp : faAngleDown} />
                                </div>
                            </div>

                            {isCard && (
                                <div
                                    className={`absolute right-0 mt-1 w-2/3 rounded-3xl rounded-tr-none shadow-lg z-20 max-h-64 scrollbar-hide overflow-y-scroll ${
                                        darkmode
                                            ? 'bg-ccdarkblue text-white border-cdarkblue'
                                            : 'bg-white text-black border-gray-300'
                                    }`}
                                >
                                    <p className="px-4 text-xs font-medium text-warn py-1">Card names</p>
                                    {/* {Object.keys(selectedCountry).map((rate, i) => {
                                if (
                                    rate === 'countryName' ||
                                    rate === '_id' ||
                                    rate === '__v' ||
                                    selectedCountry[rate] === '' ||
                                    rate === 'currencyCode' ||
                                    rate === 'createdAt' ||
                                    rate === 'updatedAt' ||
                                    rate === 'countryImage'
                                ) {
                                    return null
                                } else {
                                    return (
                                        <div
                                            key={rate}
                                            value={rate}
                                            className={`px-4 py-2 cursor-pointer text-xs flex ${
                                                darkmode
                                                    ? 'hover:text-black hover:bg-gray-400'
                                                    : 'hover:bg-gray-100'
                                            }`}
                                            onClick={() => handleCardChange(rate)}
                                        >
                                            {formatRate[rate] || rate}
                                        </div>
                                    )
                                }
                            }
                            
                            )} */}

                                    {cards.map((oneGiftCard) => (
                                        <div
                                            key={oneGiftCard}
                                            value={oneGiftCard}
                                            className={`px-4 py-2 cursor-pointer text-xs flex ${
                                                darkmode ? 'hover:text-black hover:bg-gray-400' : 'hover:bg-gray-300'
                                            }`}
                                            onClick={() => handleCardChange(oneGiftCard)}
                                        >
                                            {oneGiftCard?.cardType}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mb-6 mr-3 w-full">
                        <div className="relative w-4/5 md:w-full">
                            <label htmlFor="country" className="text-sm font-semibold ml-4" onClick={toggleCountry}>
                                Card Country
                            </label>
                            <div className="flex items-center justify-between w-full rounded-ccround py-5 border-gray-400 border hover:border-gray-500 px-4 mt-4">
                                <input
                                    type="text"
                                    id="country"
                                    readOnly
                                    required
                                    value={selectedCountry.countryName}
                                    className="outline-none w-full cursor-pointer bg-transparent"
                                    onClick={toggleCountry}
                                    placeholder="Enter gift card country"
                                />

                                <div className="cursor-pointer" onClick={toggleCountry}>
                                    <FontAwesomeIcon icon={isCountry ? faAngleUp : faAngleDown} />
                                </div>
                            </div>

                            {isCountry && (
                                <div
                                    className={`absolute right-0 mt-1 w-2/3 rounded-3xl rounded-tr-none shadow-lg z-20 max-h-64 scrollbar-hide overflow-y-scroll ${
                                        darkmode
                                            ? 'bg-ccdarkblue text-white border-cdarkblue'
                                            : 'bg-white text-black border-gray-300'
                                    }`}
                                >
                                    <p className="px-4 text-xs font-medium text-warn py-1">Countries</p>
                                    {selectedCard?.countryIds?.map((country) => (
                                        <div
                                            key={country?._id ?? 'defaultId'}
                                            value={country?.countryName ?? 'Default Country'}
                                            className={`px-4 py-2 cursor-pointer text-xs flex ${
                                                darkmode ? 'hover:text-black hover:bg-gray-400' : 'hover:bg-gray-300'
                                            }`}
                                            onClick={() => handleCountryChange(country)}
                                        >
                                            {country?.countryName ?? 'Default Country'}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mb-6 w-full">
                        <div className="relative w-4/5 md:w-full">
                            <label
                                htmlFor="country"
                                className="text-sm font-semibold ml-4"
                                onClick={toggleCardCategory}
                            >
                                Card Type
                            </label>
                            <div className="flex items-center justify-between w-full py-5 border-gray-400 border hover:border-gray-500 px-4 mt-4 rounded-ccround">
                                <input
                                    type="text"
                                    id="cardCategory"
                                    readOnly
                                    required
                                    value={selectedCardCategory}
                                    className="outline-none w-full cursor-pointer bg-transparent"
                                    onClick={toggleCardCategory}
                                    placeholder="Select type"
                                />

                                <div className="cursor-pointer" onClick={toggleCardCategory}>
                                    <FontAwesomeIcon icon={isCardCategory ? faAngleUp : faAngleDown} />
                                </div>
                            </div>

                            {isCardCategory && (
                                <div
                                    className={`absolute right-0 mt-1 w-2/3 rounded-3xl rounded-tr-none shadow-lg z-20 max-h-64 scrollbar-hide overflow-y-scroll ${
                                        darkmode
                                            ? 'bg-ccdarkblue text-white border-cdarkblue'
                                            : 'bg-white text-black border-gray-300'
                                    }`}
                                >
                                    <p className="px-4 text-xs font-medium text-warn py-1">Card Types</p>
                                    {cardCategories.map((oneCard) => {
                                        if (selectedCountry[oneCard] == '' || selectedCountry[oneCard] == 0) {
                                            return null
                                        }
                                        return (
                                            <div
                                                key={oneCard}
                                                value={oneCard}
                                                className={`px-4 py-2 cursor-pointer text-xs flex ${
                                                    darkmode
                                                        ? 'hover:text-black hover:bg-gray-400'
                                                        : 'hover:bg-gray-300'
                                                }`}
                                                onClick={() => handleCardCategoryChange(oneCard)}
                                            >
                                                {oneCard}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="">
                        <div className="flex items-center justify-between w-4/5 md:w-full">
                            <div className="w-full flex flex-col items-start">
                                <label htmlFor="cardValue" className="text-sm font-semibold ml-4">
                                    Card amount
                                </label>
                                <div className="w-full py-5 border-gray-400 border hover:border-gray-500 px-4 mt-4 rounded-ccround">
                                    <input
                                        className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                        type="number"
                                        id="cardValue"
                                        name="cardValue"
                                        required
                                        placeholder="Input amount (value)"
                                        value={cardValue}
                                        onChange={handleInputChange}
                                        onClick={toggleCardAmount}
                                    />
                                </div>
                            </div>
                        </div>

                        {selectedCountry[selectedCardCategory] && cardValue && (
                            <>
                                <div className="w-full flex items-center gap-2 mt-2.5">
                                    <p className="font-medium text-sm">Current rate in Naira</p>
                                    <p className="text-green-600 font-semibold">
                                        ₦{selectedCountry[selectedCardCategory]}
                                    </p>
                                </div>

                                <div className="flex items-center gap-2 mt-2.5">
                                    <div className="flex items-center">
                                        <p>Expected payment</p>
                                    </div>
                                    <p className="font-semibold">
                                        ₦{cardValue * selectedCountry[selectedCardCategory]}
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <img
                src={vector1}
                className="-translate-y-24 lg:w-1/3 lg:h-1/3 lg:translate-y-5 xl:w-auto xl:h-auto xl:-translate-y-48"
                alt=""
            />
        </section>
    )
}

export default CalcGiftCards
