import React from 'react'
import cardimg from '../../assets/cardbackground.png'
import fingerPrint from '../../assets/fingerprint_init.svg'
import fingerprint from '../../assets/fingerprint.png'
import secure from '../../assets/security-safe-init.svg'
import flippay from '../../assets/flippay_init.svg'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import cloud from '../../assets/cloudIcon.svg'

function WalletCard2({ bgimg = cardimg, bgcolor = '#213B98', sidecontent, handleDownloadStatementPopup }) {
    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <div
            className="bg-cblue text-white rounded-ccround mt-5 lg:mt-0  cursor-pointer flex flex-col"
            onClick={handleDownloadStatementPopup}
        >
            <div className="flex px-4 justify-between py-4 items-center">
                <div>
                    <p className="text-sm mb-1 font-poppins font-medium">
                        You can now download <br /> <span>account statement.</span>
                    </p>

                    <p className="text-xs italic ">Track your transactions.</p>
                </div>

                <div className="flex justify-center">
                    <img src={cloud} alt="" />
                </div>
            </div>
            <div className="bg-cdarkblue flex justify-between items-center rounded-b-3xl py-3 px-5 mt-auto">
                <p className="text-white font-bold font-poppins text-xs">Download account statement</p>

                <FontAwesomeIcon icon={faArrowRightLong} className="text-white" />
            </div>
        </div>
    )
}

export default WalletCard2
