import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import checkCircle from '../../../assets/check-circle.svg'
import lWhite from '../../../assets/flippay_logo_white.svg'
import vector from '../../../assets/Vector.svg'
import PinInput from 'react-pin-input'
import { useDispatch } from 'react-redux'
import { createPin } from '../../../redux/actions/authActions'

const CreatePin = () => {
    const [showPopup, setShowPopup] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [pin, setPin] = useState('')

    const handlesubmit = () => {
        dispatch(createPin({ pin }))
        setShowPopup(!showPopup)
    }
    return (
        <div className="pt-12 h-screen md:overflow-y-hidden bg-emailV bg-50% bg-center-r bg-no-repeat relative">
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-br from-blue-900 via-blue-800 to-purple-900 opacity-70"></div>

            <div className="absolute right-5 top-1 xl:hidden">
                <img src={lWhite} alt="" />
            </div>

            <div className="hidden xl:block absolute xl:bottom-5 xl:right-10">
                <img src={lWhite} alt="" />
            </div>

            <div className="w-full xl:w-1/2 h-full rounded-3xl -top-3 before:rounded-xl before:absolute before:bg-white after:rounded-sm after:absolute after:left-0 after:bg-white after:transform after:-skew-y-0 text-black relative before:w-48 md:before:w-96 before:h-48 md:before:h-96 before:-top-1 md:before:-top-5 before:left-24 md:before:left-48 before:transform before:rotate-45 after:w-48 md:after:w-96 after:h-48 md:after:h-96 after:-top-10 md:after:-top-24">
                <div className="flex justify-end mr-5 relative z-20 top-10">
                    <Link>
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                    </Link>
                </div>

                <div className="bg-white h-screen rounded-3xl pt-6 pb-28 relative z-10 flex flex-col items-center justify-center">
                    <div className="px-10 md:px:0">
                        <p className="font-semibold text-justify text-4xl mt-6 mb-3">Create PIN.</p>
                        <p className="text-justify">Your pin will be used for all transactions on Flippay. </p>

                        <form className="flex mt-10">
                            <PinInput
                                length={4} // Define the length of the PIN
                                focus
                                secret={true}
                                type="numeric"
                                onChange={(value) => setPin(value)}
                                inputStyle={{
                                    borderColor: '#BFBEBE',
                                    borderRadius: '15px',
                                    margin: '5px',

                                    width: '42px',
                                    height: '42px'
                                }} // Style the inputs
                                inputFocusStyle={{ borderColor: 'blue' }} // Style the focused input
                            />
                        </form>

                        <div className="mt-3 mb-6">
                            <p>Create a 4-digit PIN</p>
                        </div>
                        <div>
                            <button
                                className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-full"
                                onClick={handlesubmit}
                            >
                                Create PIN
                            </button>
                        </div>
                    </div>

                    {showPopup && (
                        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                            <div className="bg-white p-8 shadow-lg rounded-3xl">
                                <div className="flex justify-center">
                                    <img src={checkCircle} alt="" />
                                </div>
                                <p className="text-4xl font-semibold text-center">PIN Created.</p>
                                <p className="text-lg mb-10 text-center">
                                    Your four digit pin has been added successfully.
                                </p>
                                <div className="flex justify-center">
                                    <button
                                        className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                                        onClick={() => navigate('/dashboard')}
                                    >
                                        <Link to="/signin">Continue to Dashboard</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="relative -top-48 md:hidden z-20 -right-10">
                    <img src={vector} className="transform rotate-45 scale-50" alt="" />
                </div>
            </div>
        </div>
    )
}

export default CreatePin
