import React, { useState } from 'react'
import logoSend from '../../../assets/logo_send.svg'
import { verifybankinfo } from '../../../api'
import SmallLoading from '../../UniversalComponents/SmallLoading'
import { useSelector } from 'react-redux'

const NewTransaction = ({ toggleNewTransactionPopup }) => {
    const [accountNumber, setAccountNumber] = useState()
    const [accountName, setAccountName] = useState()
    const [bankCode, setBankCode] = useState()
    const [accountInformation, setAccountInformation] = useState({})
    const [loading, setLoading] = useState(false)
    const darkmode = useSelector((state) => state.userReducer.dark)

    const handleAccountNumber = (e) => {
        const { value } = e.target

        if (isNaN(value)) return
        setAccountNumber(value)

        if (value.length == 10) {
            verifyAccount(value)
        }
    }
    const verifyAccount = async (value) => {
        try {
            setLoading(true)
            const { data } = await verifybankinfo({ accNumber: value, bankCode: bankCode })
            setAccountInformation(data.res.data)
        } catch (err) {
        } finally {
            setLoading(false)
        }
    }
    const handleChangeAccount = () => {
        toggleNewTransactionPopup()
    }
    return (
        <div
            className={`fixed inset-0 flex items-center justify-center z-50 ${
                darkmode ? 'bg-darkSide' : 'bg-white'
            } bg-opacity-50`}
            onClick={() => {}}
        >
            <div
                className={`px-20 py-12 shadow-3xl rounded-3xl flex items-center flex-col w-full lg:w-1/3 ${
                    darkmode ? 'bg-darkSide' : 'bg-white'
                }`}
            >
                <div className="flex justify-center">
                    <img src={logoSend} alt="" />
                </div>
                <p className="text-cfont32 font-poppins font-semibold text-center">Change account.</p>
                <p className="text-sm mt-2 mb-14 text-center w-4/5">Send to this account instead.</p>

                <div className=" w-full rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4">
                    <div className="w-full">
                        <label htmlFor="bank" className="text-sm font-semibold">
                            Choose Bank
                        </label>

                        <input
                            className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                            type="text"
                            id="bank"
                            name="bank"
                            required
                            placeholder="First Bank Ng Plc"
                            // value={formData.phone}
                            // onChange={handleChange}
                        />

                        {/* <div className='text-red-500 text-sm mb-2'>{errors.phone}</div> */}
                    </div>
                </div>

                <div className=" w-full rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4">
                    <div className="w-full">
                        <label htmlFor="acc" className="text-sm font-semibold">
                            Account number
                        </label>

                        <input
                            className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                            type="text"
                            inputMode="numeric"
                            id="acc"
                            name="acc"
                            required
                            placeholder="0000000000"
                            value={accountNumber}
                            onChange={handleAccountNumber}
                        />

                        {/* <div className='text-red-500 text-sm mb-2'>{errors.phone}</div> */}
                    </div>
                </div>

                <div className=" w-full flex items-center rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4">
                    <div className="w-full flex-1">
                        <label htmlFor="accname" className="text-sm font-semibold">
                            Account name
                        </label>

                        <input
                            className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                            type="text"
                            id="accname"
                            name="accname"
                            required
                            placeholder="John Doe"
                            disabled
                            value={accountInformation?.account_name}
                        />
                    </div>

                    <SmallLoading loading={loading} />
                </div>

                <div className="flex justify-end items-center mt-9">
                    <button
                        className="bg-cblue w-full p-5 mr-4 hover:bg-blue-700 text-white font-bold rounded-cround "
                        onClick={handleChangeAccount}
                    >
                        Send money
                    </button>

                    <button
                        className={`border border-solid border-red-500 text-${
                            darkmode ? 'white' : 'black'
                        } font-bold py-5 px-3 rounded-cround  w-48`}
                        onClick={toggleNewTransactionPopup}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NewTransaction
