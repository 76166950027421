import React, { useState } from 'react'
import doorLockDBlue from '../../../../assets/door-lock-dblue.svg'
import PINInput from './PINInput'
import { useDispatch } from 'react-redux'
import { resetPinAction } from '../../../../redux/actions/authActions'
import { useSelector } from 'react-redux'
import keyWhite from '../../../../assets/keyWhite.svg'
import CustomModal from '../../../UniversalComponents/CustomModal'



const ResetPin = ({ open, toggleReset }) => {
    const [showPassword1, setShowPassword1] = useState(true)
    const [showPassword2, setShowPassword2] = useState(false)
    const [verificationCode, setVerificationCode] = useState('')
    const [newPin, setnewPin] = useState('')
    const [confirmNewPin, setconfirmNewPin] = useState('')
    const dispatch = useDispatch()

    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1)
    }

    const toggleShowPassword2 = () => {
        setShowPassword2(!showPassword2)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(resetPinAction({ verificationCode, newPin, confirmNewPin }))
    }
    const darkmode = useSelector((state) => state.userReducer.dark)


    //if reset is successful, create popup of Successful
    if (!open) {
        return null
    }

    return (
        <CustomModal open={open} handleClose={toggleReset}>
            <div className={`${darkmode ? 'bg-darkSide text-white' : 'bg-white text-black'} p-8 shadow-3xl rounded-3xl w-456`}>
                <div className='max-w-xs m-auto'>
                    <div className="flex justify-center">
                        <img src={darkmode ? keyWhite : doorLockDBlue} alt="" />
                    </div>
                    <p className="text-4xl font-semibold text-center">Reset PIN.</p>
                    <p className="text-sm mt-3 mb-6 text-center">
                        Enter a secure four-digit PIN for accessing the app. This is different from your password
                    </p>

                    <form action="" className="flex flex-col items-center">
                        <div className="w-full mt-12 mb-4">
                            <input
                                className="border-gray-400 border-2 border-solid p-5 rounded-2xl w-full bg-transparent"
                                type="password"
                                id="password"
                                name="verificationCode"
                                required
                                placeholder="* * * * * *"
                                onChange={(e) => setVerificationCode(e.target.value)}
                                value={verificationCode}
                            />
                        </div>
                        <PINInput
                            showPassword={showPassword1}
                            toggleShowPassword={toggleShowPassword1}
                            title={'Set new PIN'}
                            handleOnChange={setnewPin}
                        />
                        <PINInput
                            showPassword={showPassword2}
                            toggleShowPassword={toggleShowPassword2}
                            title={'Re-enter new PIN'}
                            handleOnChange={setconfirmNewPin}
                        />

                        <div className="flex w-full justify-center mt-10">
                            <button
                                className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-48 mr-3"
                                onClick={handleSubmit}
                            >
                                Save PIN
                            </button>
                            <button
                                className={`border-2 border-solid border-red-500 font-bold ${darkmode ? 'text-white' : 'text-black'} py-2 px-4 rounded-2xl w-48`}
                                onClick={toggleReset}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </CustomModal>
    )
}

export default ResetPin
