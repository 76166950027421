import React from 'react'
import flippay from '../../assets/flippay_init.svg'
import { useSelector } from 'react-redux'
import cardimg from '../../assets/cardbackground.png'
import numeral from 'numeral'

function CardAmount({ bgimg = cardimg, bgcolor = '#213B98', sidecontent }) {
    const user = useSelector((state) => state.userReducer.user)
    return (
        <div
            style={{
                // width: '400px',
                height: '203px',
                backgroundColor: bgcolor,
                borderRadius: '30px',
                backgroundImage: `url(${bgimg})`,
                // backgroundSize: 'cover',
                backgroundSize: bgcolor === '#fff' ? '100% 100%' : '102% 102%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: bgcolor === '#fff' ? 'unset' : '-2px -2px',
                color: '#fff',
                padding: '20px 22px 20px 38px'
            }}
            className="w-full md:w-100 mb-2.5 lg:mb-0"
        >
            <div className="flex justify-between mb-12">
                <img src={flippay} alt="flippay" />
                {sidecontent}
            </div>
            <p className="text-5xl mb-2">₦{numeral(user?.balance).format(0, 0)}</p>
            <p>Wallet balance</p>
        </div>
    )
}

export default CardAmount
