import React, { useEffect, useState } from 'react'
import frame from '../../../assets/Frame.svg'
import SendToAccount from './SendToAccount'
import TransferToOthers from './TransferToOthers'
import SendMoney from './SendMoney'
import NewTransaction from './NewTransaction'
import SendMoney2 from './SendMoney2'
import { useSelector } from 'react-redux'
import cardimg from '../../../assets/cardwallet.png'
import guard from '../../../assets/guard.png'
import check from '../../../assets/check.svg'
import CardAmount from '../../UniversalComponents/CardAmount'
import CardDetail from '../../UniversalComponents/CardDetail'
import Beneficiary from '../../UniversalComponents/Beneficiary'
import identitypass from '../../../assets/identitypass.png'
import ActionTablet from '../../UniversalComponents/ActionTablet'
import MiniTitle from '../../UniversalComponents/MiniTitle'
import walletAsh from '../../../assets/wallet-ash.svg'
import FooterComponent from '../../LandingPage/LandingPageComponents/FooterComponent'
import { useLocation } from 'react-router-dom'

const WalletMid = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isSendMoneyPopupOpen, setIsSendMoneyPopupOpen] = useState(false)
    const [isSendMoneyPopupOpen2, setIsSendMoneyPopupOpen2] = useState(false)
    const [isNewTransactionPopupOpen, setIsNewTransactionPopupOpen] = useState(false)
    const [isOthersOpen, setIsOthersOpen] = useState(false)

    const location = useLocation();
    const isTrue = location.state?.isTrue;
    const isTrueTransfer = location.state?.isTrueTransfer;


    useEffect(() => {
        // Check if isTrue is true and set isOpen to true
        if (isTrue) {
            setIsOpen(true);
        } else if (isTrueTransfer) {
            setIsOthersOpen(true)
        }
    }, [isTrue, isTrueTransfer]);


    const toggleNewTransactionPopup = () => {
        setIsNewTransactionPopupOpen(!isNewTransactionPopupOpen)
        setIsSendMoneyPopupOpen(false)
    }

    const toggleNewTransactionPopup2 = () => {
        setIsOthersOpen(true)
        setIsSendMoneyPopupOpen2(!isSendMoneyPopupOpen2)
    }

    const toggleSendMoneyPopup = () => {
        setIsSendMoneyPopupOpen(!isSendMoneyPopupOpen)
        setIsOpen(false)
    }

    const toggleSendMoneyPopup2 = () => {
        setIsSendMoneyPopupOpen2(!isSendMoneyPopupOpen2)
        setIsOthersOpen(false)
    }

    const toggleOthersPopup = () => {
        setIsOthersOpen(!isOthersOpen)
    }

    const togglePopup = () => {
        setIsOpen(!isOpen)
        // document.body.style.overflow = isOpen ? 'auto' : 'hidden';
    }

    const user = useSelector((state) => state.userReducer.user)
    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <div className={`${darkmode ? 'bg-darkAll' : 'bg-white'} text-${darkmode ? 'white' : 'black'} w-full`}>
            <div
                className={`max-w-2xl py-3 px-5 lg:px-0 mb-20 lg:mb-0 ${
                    isOpen || isOthersOpen ? 'hidden md:block' : ''
                }`}
            >
                <MiniTitle sideImage={walletAsh} title="Wallet" />
                <p className="text-4xl font-semibold">My wallet.</p>
                <p className="text-sm mt-3 mb-8">
                    Send money to your local account, or transfer to <br /> others on Flippay.
                </p>
                <div className="gap-4 block md:flex">
                    <CardAmount
                        bgcolor={darkmode ? '#2D2D2D' : '#fff'}
                        sidecontent={user.verified && <img src={check} className="-translate-y-2" alt="side" />}
                        bgimg={cardimg}
                    />
                    <CardDetail
                        title="All transactions are
                        secured."
                        subtitle="You need not worry about fraudsters!"
                        footertitle="100% Secured"
                        cardimg={guard}
                        // footerimg={secure}
                        titlecolor="#213B98"
                        subtitlecolor="#213B98"
                        bgcolor="rgb(204, 229, 255)"
                        footerbgcolor="#7FBDFF"
                        footerimg={identitypass}
                    />
                </div>

                {/* <div className="mt-6 ">
                    <p className="font-semibold mb-10">Beneficiaries</p>

                    <ul className="flex ml-10 overflow-x-scroll scrollbar-hide">
                        {beneficiaries.map((oneBeneficiary, index) => (
                            <ProfileImage />
                        ))}
                    </ul>
                </div> */}
                <Beneficiary />
                <div className="mt-6">
                    <p className="font-semibold  mb-6">Money transfers</p>

                    <div className="block lg:flex items-center gap-4 justify-between mt-4">
                        <ActionTablet
                            action={togglePopup}
                            title="Send to my account"
                            subtitle="Withdraw money to local account."
                        />
                        <ActionTablet
                            action={toggleOthersPopup}
                            title="Transfer to others"
                            subtitle="Send money through Flippay."
                        />
                    </div>

                    <div className="flex flex-col items-center mt-12">
                        <div className="mb-4">
                            <img src={frame} alt="" />
                        </div>
                        <p className="font-medium text-2xl text-greyy">Holla! you must be new here.</p>
                        <p className="text-greyy">Perform a transaction</p>
                    </div>
                </div>
            </div>
            <section
                className={`pl-5 text-white bg-black relative rounded-b-3xl md:hidden ${
                    isOpen || isOthersOpen ? 'hidden md:block' : ''
                }`}
            >
                <FooterComponent extra={true} />
            </section>

            <section
                className={`text-center pt-5 relative before:w-full before:absolute before:-top-5 before:left-0 before:-bottom-5 before:bg-white before:z-[-1] flex flex-col md:flex-row justify-between items-center md:hidden ${
                    isOpen || isOthersOpen ? 'hidden md:block' : ''
                } ${darkmode ? 'bg-darkAll' : 'bg-white'} text-${darkmode ? 'white' : 'black'}`}
            >
                <p className="w-52 md:w-auto text-sm mb-5 md:mb-0">
                    All rights reserved. Copyrights © 2023 Flippay. co.{' '}
                </p>
                <p className="text-sm">
                    Owned by PayNyce Nigeria Ltd, a parent company registered under the Corporate Affairs Commission
                    (CAC) with RC 1731068
                </p>
            </section>

            {isOpen && <SendToAccount toggleAlternateSendMoney={toggleNewTransactionPopup} togglePopup={togglePopup} />}

            {isOthersOpen && (
                <TransferToOthers toggleSendMoneyPopup2={toggleSendMoneyPopup2} toggleOthersPopup={toggleOthersPopup} />
            )}

            {isSendMoneyPopupOpen && (
                <SendMoney
                    toggleNewTransactionPopup={toggleNewTransactionPopup}
                    toggleSendMoneyPopup={toggleSendMoneyPopup}
                />
            )}

            {isSendMoneyPopupOpen2 && (
                <SendMoney2
                    toggleNewTransactionPopup2={toggleNewTransactionPopup2}
                    toggleSendMoneyPopup2={toggleSendMoneyPopup2}
                />
            )}

            {isNewTransactionPopupOpen && <NewTransaction toggleNewTransactionPopup={toggleNewTransactionPopup} />}
        </div>
    )
}

export default WalletMid
