import React, { useState } from 'react'
import logoSend from '../../../assets/logo_send.svg'
import check from '../../../assets/check-circle.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import MonthlyCalendar from './MonthlyCalendar'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import CustomModal from '../../UniversalComponents/CustomModal'
import { transactionStatement } from '../../../api'

const DownloadStatement = ({ open, handleDownloadStatementPopup }) => {
    const [showStartDateCalendar, setShowStartDateCalendar] = useState(false)
    const [showEndDateCalendar, setShowEndDateCalendar] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [tStatementResponse, setTStatementResponse] = useState(null)

    const handleStartDateClick = () => {
        setShowStartDateCalendar(true)
    }

    const handleEndDateClick = () => {
        setShowEndDateCalendar(true)
    }

    const handleSelectStartDate = (selectedDate) => {
        setStartDate(selectedDate)
        setShowStartDateCalendar(false)
    }

    const handleSelectEndDate = (selectedDate) => {
        setEndDate(selectedDate)
        setShowEndDateCalendar(false)
    }

    const handleStartDateCalendarClose = () => {
        setShowStartDateCalendar(false)
    }

    const handleEndDateCalendarClose = () => {
        setShowEndDateCalendar(false)
    }

    const handleSubmit = async () => {
        handleDownloadStatementPopup()
        if (startDate && endDate) {
            const formattedStartDate = format(startDate, 'yyyy-MM-dd')
            const formattedEndDate = format(endDate, 'yyyy-MM-dd')
            const query = `startDate=${formattedStartDate}&endDate=${formattedEndDate}`

            try {
                const res = await transactionStatement(query)
                console.log('res', res)
                setTStatementResponse(res?.data?.message)
            } catch (error) {
                console.log(error)
            }
        }
    }

    const darkmode = useSelector((state) => state.userReducer.dark)
    return (
        <>
            <CustomModal open={open} handleClose={handleDownloadStatementPopup}>
                <div
                    className={`${
                        darkmode ? 'bg-darkSide text-white' : 'bg-white text-black'
                    } p-8 shadow-3xl rounded-3xl w-456`}
                >
                    <div className="max-w-xs m-auto flex flex-col items-center">
                        <div className="flex justify-center mb-7">
                            <img src={logoSend} alt="" />
                        </div>
                        <p className="text-4xl font-semibold text-left mx-10">Download account statement.</p>
                        <p className="text-sm mt-3 mb-6 text-left mx-10">
                            Your account statement will be sent to your registered email on Flippay.
                        </p>

                        <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4">
                            <div className="w-full">
                                <label htmlFor="saccount" className="text-sm font-semibold">
                                    Select account
                                </label>
                                <div>
                                    <input
                                        className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                        type="text"
                                        id="saccount"
                                        name="saccount"
                                        required
                                        placeholder="First Bank Ng Plc"
                                        value="Flippay wallet"
                                        readOnly
                                        // value={formData.phone}
                                        // onChange={handleChange}
                                    />
                                </div>
                                {/* <div className='text-red-500 text-sm mb-2'>{errors.phone}</div> */}
                            </div>

                            <div className="p-3 w-8 h-8 grid place-content-center rounded-full bg-grayed">
                                <FontAwesomeIcon icon={faAngleRight} className="text-white" />
                            </div>
                        </div>

                        <div
                            className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 mb-4 cursor-pointer"
                            onClick={handleStartDateClick}
                        >
                            <div className="w-full cursor-pointer">
                                <label htmlFor="startdate" className="text-sm font-semibold">
                                    Start date
                                </label>
                                <div>
                                    <input
                                        className="focus:border-none focus:outline-none w-full appearance-none cursor-pointer bg-transparent"
                                        type="text"
                                        id="startdate"
                                        name="startdate"
                                        required
                                        placeholder="Select date"
                                        value={startDate ? format(startDate, 'MM/dd/yyyy') : ''}
                                        readOnly
                                    />
                                </div>
                                {/* <div className='text-red-500 text-sm mb-2'>{errors.phone}</div> */}
                            </div>

                            <div className="p-3 w-8 h-8 grid place-content-center rounded-full bg-cdarkblue">
                                <FontAwesomeIcon icon={faAngleRight} className="text-white" />
                            </div>
                        </div>

                        <div
                            className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 mb-4 cursor-pointer"
                            onClick={handleEndDateClick}
                        >
                            <div className="w-full cursor-pointer">
                                <label htmlFor="enddate" className="text-sm font-semibold">
                                    End Date
                                </label>
                                <div>
                                    <input
                                        className="focus:border-none focus:outline-none w-full appearance-none cursor-pointer bg-transparent"
                                        type="text"
                                        id="enddate"
                                        name="enddate"
                                        required
                                        placeholder="Select date"
                                        value={endDate ? format(endDate, 'MM/dd/yyyy') : ''}
                                        readOnly
                                    />
                                </div>

                                {/* <div className='text-red-500 text-sm mb-2'>{errors.phone}</div> */}
                            </div>

                            <div className="p-3 w-8 h-8 grid place-content-center rounded-full bg-cdarkblue">
                                <FontAwesomeIcon icon={faAngleRight} className="text-white" />
                            </div>
                        </div>

                        {showStartDateCalendar && (
                            <MonthlyCalendar
                                onSelectDate={handleSelectStartDate}
                                onClose={handleStartDateCalendarClose}
                            />
                        )}

                        {showEndDateCalendar && (
                            <MonthlyCalendar onSelectDate={handleSelectEndDate} onClose={handleEndDateCalendarClose} />
                        )}
                        <div className="flex w-full justify-center mt-6">
                            <button
                                className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-48"
                                onClick={handleSubmit}
                            >
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal>

            {tStatementResponse && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-8 shadow-md max-w-md flex flex-col items-center">
                        <img src={check} alt="" />
                        <p className="mb-4">{tStatementResponse}</p>
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-3xl"
                            onClick={() => setTStatementResponse(null)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default DownloadStatement
