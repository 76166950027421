import React from 'react'
import AccountMid from './AccountComponent/AccountMid'
import UserContainer from '../UniversalComponents/UserContainer'

const Account = () => {
    return (
        <UserContainer active={'account'}>
            <AccountMid />
        </UserContainer>
    )
}

export default Account
