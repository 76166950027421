import * as api from '../../api'

export const signUpAction = (signUpData, navigate, setErrors, errors) => async (dispatch) => {
    //do something
    // console.log('signUpData',signUpData);
    try {
        dispatch({ type: 'SETLOADING' })
        const { data } = await api.signUp(signUpData) //once action is dispatched, it gets a response "data"

        dispatch({ type: 'SIGNUP', data }) //dispatches the response to the reducer, depending on its action type
        navigate('/verify/' + signUpData.email)
    } catch (error) {
        if (error?.response?.data?.errors) {
            let newerror = {}
            const errorarr = error?.response?.data?.errors
            for (let err of errorarr) {
                newerror[err['path']] = err['msg']
            }
            setErrors({ ...errors, ...newerror })
            return
        }
    } finally {
        dispatch({ type: 'CLEARLOADING' })
    }
}

export const signInAction = (signInData, navigate, setErrors, errors) => async (dispatch) => {
    try {
        dispatch({ type: 'SETLOADING' })
        const { data } = await api.signIn(signInData)
        // const {data2} = await api.getUserDetails()

        dispatch({ type: 'SIGNIN', data })
        dispatch({
            type: 'GETUSER',
            data: {
                fname: data?.data?.name,
                balance: data?.data?.balance,
                phone: data?.data?.phoneNum,
                email: data?.data?.email
            }
        })

        if (!data?.data?.pinAvailable) {
            return navigate('/create')
        }
        navigate('/dashboard')
    } catch (error) {
        if (error?.response?.data?.error === 'Unauthorized') {
            navigate('/verify/' + signInData.email)
            return
        }
        if (error?.response?.data?.error === 'USER_NOT_FOUND') {
            setErrors({ ...errors, email: 'incorrect email address' })
            return
        }
        setErrors({ ...errors, email: 'An Error Occured' })
        // Do a error message of sort
    } finally {
        dispatch({ type: 'CLEARLOADING' })
    }
}

export const emailVerificationAction = (email) => async (dispatch) => {
    try {
        dispatch({ type: 'SETLOADING' })
        const { data } = await api.emailVerify(email)
    } catch (error) {
    } finally {
        dispatch({ type: 'CLEARLOADING' })
    }
}

export const emailVerificationWithCodeAction = (emaildata, navigate) => async (dispatch) => {
    try {
        dispatch({ type: 'SETLOADING' })
        const { data } = await api.emailVerifyWithCode(emaildata)
        navigate('/signin')
        // dispatch({ type: 'EMAIL_VERIFY', data })
    } catch (error) {
    } finally {
        dispatch({ type: 'CLEARLOADING' })
    }
}

export const logOutAction = (navigate) => async (dispatch) => {
    try {
        const data = null
        dispatch({ type: 'LOGOUT', data })
        navigate('/signin')
    } catch (error) {
        // console.log(error);
    }
}

export const changePin = (pindata) => async (dispatch) => {
    try {
        dispatch({ type: 'SETLOADING' })
        await api.changepin(pindata)
    } catch (error) {
        // console.log(error);
    } finally {
        dispatch({ type: 'CLEARLOADING' })
    }
}

export const createPin = (pindata) => async (dispatch) => {
    try {
        dispatch({ type: 'SETLOADING' })
        await api.createPin(pindata)
    } catch (error) {
        // console.log(error);
    } finally {
        dispatch({ type: 'CLEARLOADING' })
    }
}

export const resetPinAction = (pindata) => async (dispatch) => {
    try {
        dispatch({ type: 'SETLOADING' })
        await api.pinReset(pindata)
    } catch (error) {
        // console.log(error);
    } finally {
        dispatch({ type: 'CLEARLOADING' })
    }
}
