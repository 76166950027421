import React from 'react'
import GMid from './GiftCardsComponents/GMid'
import UserContainer from '../UniversalComponents/UserContainer'

const GiftCards = () => {
    return (
        <UserContainer>
            <GMid />
        </UserContainer>
    )
}

export default GiftCards
