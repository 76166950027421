const transactionReducer = (state = { transactions: [] }, action) => {
    switch (action.type) {
        case 'GET_ALL_TRANSACTIONS':
            return { ...state, transactions: action?.data }
        default:
            return state
    }
}

export default transactionReducer
