import React from 'react'
import Navigation from '../UniversalComponents/Navigation'
import SupportBody from './SupportPageComponents/SupportBody'

const SupportPageContainer = () => {
  return (
    <div>
      <Navigation />
      <SupportBody />
    </div>
  )
}

export default SupportPageContainer