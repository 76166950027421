import React from 'react'
import warning from '../../assets/warning.svg'
import nigeria from '../../assets/Nigeria.svg'

function PhoneInput({ title, name, value, handlechange, error, type = 'text', placeholder, ...rest }) {
    return (
        <div className="flex items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 ">
            <div className="w-full">
                <label htmlFor="email" className="text-boldgray text-sm font-semibold">
                    {title}
                </label>
                <div className="flex ">
                    <div className="flex gap-1 items-center border-r border-gray-400 pr-8 mr-6 mt-1 mb-1">
                        <img src={nigeria} alt=''/>
                        <span className="text-cfont10">+234</span>
                    </div>
                    <input
                        className="focus:border-none focus:outline-none w-full bg-transparent"
                        type={type}
                        id={name}
                        name={name}
                        required
                        placeholder={placeholder}
                        value={value}
                        onChange={handlechange}
                        {...rest}
                    />
                </div>
            </div>

            {error && (
                <div className="relative">
                    <img src={warning} alt=''/>
                    <div
                        className="absolute bottom-7 -right-4 w-28 px-5 py-2 text-center rounded-cround"
                        style={{ background: '#FFE0B2', fontSize: '8px', boxShadow: '0px 0px 3px 2px rgba(0,0,0,.1)' }}
                    >
                        {error}
                    </div>
                </div>
            )}
        </div>
        // {errors.email}
    )
}

export default PhoneInput
