import React from 'react'
import flippayFooter from '../../../assets/flippay-footer.svg'
import tm from '../../../assets/tm.svg'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import income_white from '../../../assets/w_import.svg'
import outward_white from '../../../assets/w_export.svg'
import wallet from '../../../assets/wallet.svg'
const FooterComponent = ({ extra = false }) => {
    const navigate = useNavigate()

    const handleClickGift = () => {
        navigate('/giftcard')
    }

    const handleClickTransfer = () => {
        navigate('/wallet', { state: { isTrueTransfer: true } })
    }

    const handleClickSend = () => {
        navigate('/wallet', { state: { isTrue: true } })
    }

    return (
        <div className="flex flex-col md:flex-row justify-between md:items-center pt-5 pb-2">
            <div>
                <div className="flex mb-10 md:mb-5">
                    <img src={flippayFooter} alt="" />
                    <img src={tm} alt="" className="relative -top-2" />
                </div>

                <p className="text-sm font-normal mb-10 md:mb-0">
                    A Fin-Tech platform that allows <br /> users sell their gift card(s), make bill payments, <br /> buy
                    airtime/data and transfer money to users via <br /> their e-mail address.
                </p>
            </div>

            <div className="flex  gap-16 lg:gap-32 md:justify-between items-center">
                <div className="">
                    <h1 className="text-white">Company</h1>
                    <Link>
                        <p className="text-cblack text-sm mt-5 mb:mt-2">About</p>
                    </Link>
                    <Link>
                        <p className="text-cblack text-sm mt-5 mb:mt-2">Contact us</p>
                    </Link>
                    <Link>
                        <p className="text-cblack text-sm mt-5 mb:mt-2">FAQ</p>
                    </Link>
                </div>

                <div className="">
                    <h1 className="text-white">Products</h1>
                    <Link>
                        <p className="text-cblack mt-5 text-sm mb:mt-2">Business</p>
                    </Link>
                    <Link>
                        <p className="text-cblack mt-5 text-sm mb:mt-2">Personal</p>
                    </Link>
                    <Link>
                        <p className="text-cblack mt-5 text-sm mb:mt-2">Rate Calculator</p>
                    </Link>
                </div>

                <div className="hidden md:flex flex-col items-center">
                    <div className="border-l border-white border-solid h-20"></div>
                    <Link to={'https://instagram.com/flippay.co'}>
                        <FontAwesomeIcon icon={faInstagram} className="text-2xl my-4 mx-2" />
                    </Link>
                    <Link to={'https://facebook.com/flippay.co'}>
                        <FontAwesomeIcon icon={faFacebookF} className="text-2xl my-4 mx-2" />
                    </Link>
                    <Link to={'https://x.com/flippayconnect'}>
                        <FontAwesomeIcon icon={faTwitter} className="text-2xl my-4 mx-2" />
                    </Link>
                    <div className="border-l border-white border-solid h-20"></div>
                </div>
            </div>

            {extra && (
                <div className="mr-5 mt-8">
                    <button
                        onClick={handleClickSend}
                        className={`w-full border rounded-full flex justify-center p-3 text-sm font-poppins mb-2 items-center gap-3 ${'border-white text-white'}`}
                    >
                        <span>Send to account</span>
                        <img src={income_white} alt="income" />
                    </button>
                    <button
                        onClick={handleClickTransfer}
                        className={`w-full border rounded-full  flex justify-center p-3 text-sm font-poppins mb-2 items-center gap-3 ${'border-white text-white'}`}
                    >
                        <span> Transfer to others</span>
                        <img src={outward_white} alt="outward" />
                    </button>
                    <button
                        onClick={handleClickGift}
                        className={`w-full rounded-full  flex justify-center p-3 text-sm font-poppins text-white mb-2 items-center gap-3 bg-cblue`}
                    >
                        <span>Sell gift cards</span>
                        <img src={wallet} alt="wallet" />
                    </button>
                </div>
            )}

            <div className="flex flex-row items-center justify-start mt-10 md:hidden">
                <hr className="w-10 border-white border-solid border-t-2 text-white" />
                <Link to={'https://instagram.com/flippay.co'}>
                    <FontAwesomeIcon icon={faInstagram} className="text-2xl my-4 mx-5" />
                </Link>
                <Link to={'https://facebook.com/flippay.co'}>
                    <FontAwesomeIcon icon={faFacebookF} className="text-2xl my-4 mx-5" />
                </Link>
                <Link to={'https://x.com/flippayconnect'}>
                    <FontAwesomeIcon icon={faTwitter} className="text-2xl my-4 mx-5" />
                </Link>
                <hr className="w-10 border-white border-solid border-t-2 text-white" />
            </div>
        </div>
    )
}

export default FooterComponent
