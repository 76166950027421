import React from 'react'
import Navigation from '../UniversalComponents/Navigation'
import AboutBody from './AboutPageComponents/AboutBody'


const AboutPageContainer = () => {
  return (
    <div>
      <Navigation />
      <AboutBody />
    </div>
  )
}

export default AboutPageContainer