import React from 'react'
import checkCircle from '../../../assets/check-circle.svg'
import { useSelector } from 'react-redux'



const SendMoney2 = ({ toggleNewTransactionPopup2, toggleSendMoneyPopup2 }) => {
  const darkmode = useSelector((state) => state.userReducer.dark)

  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 ${darkmode ? 'bg-darkSide' : 'bg-white'} bg-opacity-50`}>
        <div className={`${darkmode ? 'bg-darkSide' : 'bg-white'} p-8 shadow-3xl rounded-3xl flex items-center flex-col w-11/12 lg:w-auto`}>
            <div className='flex justify-center'><img src={checkCircle} alt="" /></div>
            <p className='text-2xl lg:text-4xl font-semibold text-center'>Money sent.</p>
            <p className="text-sm mt-3 mb-6 text-center w-4/5">Withdrawal to your account was successful.</p>
            <div className='flex w-full justify-center mt-6'>
                <button className="border-2 border-solid border-blue-500 text-blue-500 font-bold py-2 px-3 lg:px-4 rounded-2xl w-36 lg:w-48 mr-3 text-xs lg:text-base" onClick={toggleNewTransactionPopup2}>New Transaction</button>
                <button className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-3 lg:px-4 rounded-2xl w-36 lg:w-48 text-xs lg:text-base" onClick={toggleSendMoneyPopup2}>See Receipt</button>
            </div>
        </div>
    </div>
  )
}

export default SendMoney2