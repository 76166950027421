import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ViewDashboard from '../../assets/Viewdashboardsvg.jsx'
import WalletSvg from '../../assets/Wallet.jsx'
import PieChart from '../../assets/PieChart.jsx'
import Profile from '../../assets/Profile.jsx'
import moon from '../../assets/moon.svg'
import NavBar from './NavBar/NavBar'
import MainSide from '../Dashboard/DashboardComponent/MainSide'
import curvebg from '../../assets/dashboardcurve.png'
import darkcurvebg from '../../assets/dashboardcurvedark.png'
import flippayLogoWhite from '../../assets/flippay_logo_white.svg'
import { logOutAction } from '../../redux/actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import checkTokenExpiration from '../../authServices/authServices'
import Logoutsvg from '../../assets/Logoutsvg.jsx'
import Supportsvg from '../../assets/Supportsvg.jsx'

const activestyle =
    'relative after:w-cw after:scale-cs after:h-10 after:w-1 after:bg-blue-400 after:absolute after:right-0 after:rounded-l-lg'

function UserContainer({ children, active }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isOn, setIsOn] = useState(false)
    const [isHoveredD, setIsHoveredD] = useState(false)
    const [isHoveredW, setIsHoveredW] = useState(false)
    const [isHoveredA, setIsHoveredA] = useState(false)
    const [isHoveredS, setIsHoveredS] = useState(false)
    const [isHoveredT, setIsHoveredT] = useState(false)
    const [isHoveredL, setIsHoveredL] = useState(false)
    const [changed, setChanged] = useState(false)

    const isTokenValid = checkTokenExpiration()

    useEffect(() => {
        if (!isTokenValid) {
            dispatch(logOutAction(navigate))
        }
    }, [dispatch, isTokenValid, navigate])

    if (!isTokenValid) {
        dispatch(logOutAction(navigate))
    }

    const handleLogout = () => {
        dispatch(logOutAction(navigate))
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

    // console.log('dark', darkmode)

    useEffect(() => {
        if (darkmode) {
            setIsOn(true)
        } else if (!darkmode) {
            setIsOn(false)
        }
    }, [darkmode])

    const handleClick = () => {
        setIsOn(!isOn)
        setChanged(true)
        if (!darkmode) {
            dispatch({ type: 'SETDARKMODE' })
        } else if (darkmode) {
            dispatch({ type: 'CLEARDARKMODE' })
        }
    }
    return (
        <main className="flex h-screen overflow-y-scroll scrollbar-hide">
            <section
                className={`h-screen relative hidden lg:flex flex-col w-72 overflow-y-scroll scrollbar-hide ${
                    darkmode ? 'bg-darkAll' : 'bg-white'
                }`}
            >
                <Link to="/">
                    <img src={flippayLogoWhite} className="absolute top-8 left-10" alt="" />
                </Link>

                <img src={darkmode ? darkcurvebg : curvebg} alt="curve" />
                <div
                    className={`${darkmode ? 'bg-darkSide' : 'bg-ccdarkblue'}  pt-5 flex-1 `}
                    style={{ transform: 'translate(0px, -1px' }}
                >
                    <div className="pl-10 relative ">
                        <p className="text-cblack mb-5 text-xs">Main</p>

                        <Link to="/dashboard">
                            <div
                                className={`mt-3 mb-3  flex items-center pt-2 pb-2 ${
                                    active === 'dashboard' ? activestyle : ''
                                }`}
                                onMouseEnter={() => setIsHoveredD(true)}
                                onMouseLeave={() => setIsHoveredD(false)}
                            >
                                <ViewDashboard
                                    fill={isHoveredD ? '#55A7FF' : active === 'dashboard' ? '#55A7FF' : '#fff'}
                                />
                                <p
                                    className={`ml-3 ${
                                        isHoveredD
                                            ? 'text-specialblue font-bold'
                                            : active === 'dashboard'
                                            ? 'text-specialblue'
                                            : ' text-white'
                                    }`}
                                >
                                    Dashboard
                                </p>
                            </div>
                        </Link>

                        <Link to="/wallet">
                            <div
                                className={`mt-3 mb-3 flex items-center pt-2 pb-2 ${
                                    active === 'wallet' ? activestyle : ''
                                }`}
                                onMouseEnter={() => setIsHoveredW(true)}
                                onMouseLeave={() => setIsHoveredW(false)}
                            >
                                <WalletSvg fill={isHoveredW ? '#55A7FF' : active === 'wallet' ? '#55A7FF' : '#fff'} />

                                <p
                                    className={`ml-3 ${
                                        isHoveredW
                                            ? 'text-specialblue font-bold'
                                            : active === 'wallet'
                                            ? 'text-specialblue'
                                            : ' text-white'
                                    }`}
                                >
                                    Wallet
                                </p>
                            </div>
                        </Link>

                        <Link to="/transaction">
                            <div
                                className={`mt-3 mb-3 flex items-center pt-2 pb-2 ${
                                    active === 'transaction' ? activestyle : ''
                                }`}
                                onMouseEnter={() => setIsHoveredT(true)}
                                onMouseLeave={() => setIsHoveredT(false)}
                            >
                                <PieChart
                                    fill={isHoveredT ? '#55A7FF' : active === 'transaction' ? '#55A7FF' : '#fff'}
                                />
                                <p
                                    className={`ml-3 ${
                                        isHoveredT
                                            ? 'text-specialblue font-bold'
                                            : active === 'transaction'
                                            ? 'text-specialblue'
                                            : ' text-white'
                                    }`}
                                >
                                    Transactions
                                </p>
                            </div>
                        </Link>

                        <Link to="/account">
                            <div
                                className={`mt-3 mb-3 flex items-center pt-2 pb-2 ${
                                    active === 'account' ? activestyle : ''
                                }`}
                                onMouseEnter={() => setIsHoveredA(true)}
                                onMouseLeave={() => setIsHoveredA(false)}
                            >
                                <Profile
                                    fill={isHoveredA ? '#55A7FF' : active === 'account' ? '#55A7FF' : '#fff'}
                                    fill2={isHoveredA ? '#55A7FF' : active === 'account' ? '#55A7FF' : 'none'}
                                />

                                <p
                                    className={`ml-3 ${
                                        isHoveredA
                                            ? 'text-specialblue font-bold'
                                            : active === 'account'
                                            ? 'text-specialblue'
                                            : ' text-white'
                                    }`}
                                >
                                    Account
                                </p>
                            </div>
                        </Link>

                        <Link to="/support">
                            <div
                                className="mt-3 mb-3 flex items-center pt-2 pb-2"
                                onMouseEnter={() => setIsHoveredS(true)}
                                onMouseLeave={() => setIsHoveredS(false)}
                            >
                                <Supportsvg
                                    fill={isHoveredS ? '#55A7FF' : active === 'support' ? '#55A7FF' : '#fff'}
                                    fill2={isHoveredS ? '#55A7FF' : active === 'support' ? '#55A7FF' : 'none'}
                                />
                                <p
                                    className={`ml-3 ${
                                        isHoveredS
                                            ? 'text-specialblue font-bold'
                                            : active === 'support'
                                            ? 'text-specialblue'
                                            : ' text-white'
                                    }`}
                                >
                                    Support center
                                </p>
                            </div>
                        </Link>
                    </div>

                    <div className="pl-10 mt-28">
                        <p className="text-cblack text-xs mb-8">Management</p>

                        <Link>
                            <div className="mb-6 flex items-center justify-between pt-2 pb-2">
                                <div className="flex justify-center items-center">
                                    <img src={moon} alt="" />
                                    <p className="ml-1 text-white">Dark mode</p>
                                </div>

                                <button
                                    onClick={handleClick}
                                    className={`mr-2 relative w-9 h-5 rounded-full transition-colors duration-300 ${
                                        isOn ? 'bg-cblue' : 'bg-gray-400'
                                    }`}
                                >
                                    <div
                                        className={`absolute left-0 top-1 w-4 h-4 rounded-full transition-transform duration-300 transform ${
                                            isOn ? 'translate-x-full' : 'translate-x-0'
                                        } bg-white`}
                                    />
                                </button>
                            </div>
                        </Link>

                        <Link onClick={handleLogout}>
                            <div
                                className="mb-14 flex items-center pt-2 pb-2"
                                onMouseEnter={() => setIsHoveredL(true)}
                                onMouseLeave={() => setIsHoveredL(false)}
                            >
                                <Logoutsvg fill={isHoveredL ? 'red' : '#fff'} />

                                <p className={`ml-3 ${isHoveredL ? 'text-red-600 font-bold' : ' text-white'}`}>
                                    Log Out
                                </p>
                            </div>
                        </Link>

                        <div className="flex">
                            <Link className="text-specialblue">TC &nbsp;</Link>
                            <p className="text-white">& &nbsp; </p>
                            <Link className="text-specialblue">Privacy Policy</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className={`flex-1 h-screen overflow-y-scroll scrollbar-hide lg:px-12 ${
                    darkmode ? 'bg-darkAll' : 'bg-white'
                }`}
            >
                <NavBar active={active} />
                {children}
            </section>
            <section
                className={`hidden xl:flex overflow-y-scroll scrollbar-hide ${darkmode ? 'bg-darkAll' : 'bg-white'}`}
            >
                <MainSide />
            </section>
        </main>
    )
}

export default UserContainer
