import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// import lWhite from '../../../assets/flippay_logo_white.svg'
import { useDispatch } from 'react-redux'
import { signUpAction } from '../../../redux/actions/authActions'
import AuthContainer from '../../UniversalComponents/AuthContainer'
import AuthInput from '../../UniversalComponents/AuthInput'
import Dot from '../../UniversalComponents/Dot'
import PasswordStrengthBar from 'react-password-strength-bar'
import PhoneInput from '../../UniversalComponents/PhoneInput'

const Signup = () => {
    const [formData, setFormData] = useState({
        fname: '',
        username: '',
        email: '',
        password: '',
        phone: ''
    })

    const [errors, setErrors] = useState({})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const [agreedToTerms, setAgreedToTerms] = useState(false);

    useEffect(() => {
        localStorage.clear()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target

        setFormData({ ...formData, [name]: value })

        let newErrors = { ...errors }

        switch (name) {
            case 'fname':
                newErrors.fname = !value.match(/^[A-Z][a-z]*( [A-Z][a-z]*)*$/)
                    ? 'Full name must start with a capital letter and contain only letters (minimum 3 characters)'
                    : ''
                break
            case 'username':
                newErrors.username = !value.match(/^[a-zA-Z0-9_]{3,16}$/)
                    ? 'Username must be at least 4 characters long and contain only letters and numbers'
                    : ''
                break
            case 'email':
                newErrors.email = !value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)
                    ? 'Please enter a valid email address'
                    : ''
                break
            case 'password':
                newErrors.password = !value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/)
                    ? 'Password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one number and one special character'
                    : ''
                break
            case 'phone':
                newErrors.phone = !value.match(/^\d{11}$/) ? 'Please enter a valid 10-digit phone number' : ''
                break
            // case 'terms':
            //   newErrors.terms = !agreedToTerms ? 'You must agree to the terms and conditions': "";
            //   console.log('att', agreedToTerms)

            //   break;

            default:
                break
        }

        setErrors(newErrors)
    }

    const validateForm = () => {
        let valid = true
        let newErrors = {}

        // Validate fname
        if (!formData.fname.match(/^[A-Z][a-z]*( [A-Z][a-z]*)*$/)) {
            valid = false
            newErrors.fname =
                'Full name must start with a capital letter and contain only letters (minimum 3 characters)'
        }

        // Validate username
        if (!formData.username.match(/^[a-zA-Z0-9_]{3,16}$/)) {
            valid = false
            newErrors.username = 'Username must be at least 4 characters long and contain only letters and numbers'
        }

        // Validate email
        if (!formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
            valid = false
            newErrors.email = 'Please enter a valid email address'
        }

        // Validate password
        if (!formData.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/)) {
            valid = false
            newErrors.password =
                'Password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one number and one special character'
        }

        // Validate phone number
        if (!formData.phone.match(/^\d{11}$/)) {
            valid = false
            newErrors.phone = 'Please enter a valid 11-digit phone number'
        }

        setErrors(newErrors)
        return valid
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateForm()) {
            // Form submission logic here
            try {
                const newformdata = {
                    // ...formData,
                    // fullname: formData.fname + ' ' + formData.username,
                    phone: '+234' + formData.phone.substring(1),
                    username: formData.username,
                    password: formData.password,
                    email: formData.email,
                    firstname: formData.fname,
                    lastname: formData.username
                }
                dispatch(signUpAction(newformdata, navigate, setErrors, errors))
            } catch (error) {}
        } else {
        }
    }

    // const handleCheckboxChange = (e) => {
    //   setAgreedToTerms(e.target.checked);
    // }

    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    // console.log(errors)

    return (
        <AuthContainer>
            <div className="-translate-y-7 w-96 ">
                <p className="font-semibold font-poppins text-4xl mt-6 mb-3">
                    Create account
                    <Dot />{' '}
                </p>

                <p className="text-xs w-4/5 md:w-full">
                    You are few clicks from selling your cards, faster, with <br /> ease and securely.
                </p>

                <form action="" className="mt-10" onSubmit={handleSubmit}>
                    <AuthInput
                        title={'First name'}
                        name={'fname'}
                        value={formData.fname}
                        handlechange={handleChange}
                        error={errors.fname}
                        placeholder={'Usman'}
                    />
                    <AuthInput
                        title={'Last name'}
                        name={'username'}
                        value={formData.username}
                        handlechange={handleChange}
                        error={errors.username}
                        placeholder={'Ibrahim'}
                    />
                    <AuthInput
                        title={'Email Address'}
                        name={'email'}
                        value={formData.email}
                        handlechange={handleChange}
                        error={errors.email}
                        placeholder={'example@gmail.com'}
                        type="email"
                    />

                    <div className="flex items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4">
                        <div className="w-full">
                            <label htmlFor="password" className="text-sm font-semibold">
                                Password
                            </label>
                            <div className="flex items-center">
                                <input
                                    className="focus:border-none focus:outline-none w-full"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    required
                                    placeholder="********"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <div className="cursor-pointer" onClick={toggleShowPassword}>
                                    {showPassword ? (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clip-path="url(#clip0_678_53085)">
                                                <path
                                                    d="M8.24999 3.53301C8.8236 3.39874 9.41088 3.33162 9.99999 3.33301C15.8333 3.33301 19.1667 9.99967 19.1667 9.99967C18.6608 10.946 18.0575 11.837 17.3667 12.658M11.7667 11.7663C11.5378 12.012 11.2618 12.209 10.9551 12.3456C10.6485 12.4822 10.3174 12.5557 9.98174 12.5616C9.64606 12.5676 9.31263 12.5058 9.00134 12.3801C8.69004 12.2543 8.40726 12.0672 8.16987 11.8298C7.93247 11.5924 7.74532 11.3096 7.61959 10.9983C7.49385 10.687 7.4321 10.3536 7.43802 10.0179C7.44394 9.68226 7.51742 9.35121 7.65406 9.04455C7.7907 8.73788 7.98771 8.46188 8.23333 8.23301M0.833328 0.833008L19.1667 19.1663M14.95 14.9497C13.5255 16.0355 11.7909 16.6371 9.99999 16.6663C4.16666 16.6663 0.833328 9.99967 0.833328 9.99967C1.8699 8.06792 3.30761 6.38018 5.04999 5.04967L14.95 14.9497Z"
                                                    stroke="#AAAAAA"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_678_53085">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    ) : (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clip-path="url(#clip0_678_53085)">
                                                <path
                                                    d="M8.24999 3.53301C8.8236 3.39874 9.41088 3.33162 9.99999 3.33301C15.8333 3.33301 19.1667 9.99967 19.1667 9.99967C18.6608 10.946 18.0575 11.837 17.3667 12.658M11.7667 11.7663C11.5378 12.012 11.2618 12.209 10.9551 12.3456C10.6485 12.4822 10.3174 12.5557 9.98174 12.5616C9.64606 12.5676 9.31263 12.5058 9.00134 12.3801C8.69004 12.2543 8.40726 12.0672 8.16987 11.8298C7.93247 11.5924 7.74532 11.3096 7.61959 10.9983C7.49385 10.687 7.4321 10.3536 7.43802 10.0179C7.44394 9.68226 7.51742 9.35121 7.65406 9.04455C7.7907 8.73788 7.98771 8.46188 8.23333 8.23301M0.833328 0.833008L19.1667 19.1663M14.95 14.9497C13.5255 16.0355 11.7909 16.6371 9.99999 16.6663C4.16666 16.6663 0.833328 9.99967 0.833328 9.99967C1.8699 8.06792 3.30761 6.38018 5.04999 5.04967L14.95 14.9497Z"
                                                    stroke="#AAAAAA"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_678_53085">
                                                    <rect width="20" height="20" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <span className="" style={{ fontSize: '10px', color: '#aaa' }}>
                            Minimum of 8 characters.
                        </span>
                        <PasswordStrengthBar className="w-20" password={formData.password} />
                    </div>

                    <PhoneInput
                        title={'Phone number'}
                        name={'phone'}
                        value={formData.phone}
                        handlechange={handleChange}
                        type="tel"
                        placeholder="0814 624 1700"
                        error={errors.phone}
                    />

                    <div className="mb-4 mt-4">
                        <input type="checkbox" name="terms" id="terms" className="mr-2" required />
                        <span>
                            I agree with the{' '}
                            <Link className="text-blue-500 font-bold" to={'/terms'}>
                                TC
                            </Link>{' '}
                            &{' '}
                            <Link className="text-blue-500 font-bold" to={'/terms'}>
                                Privacy Policy
                            </Link>
                        </span>
                    </div>
                    <div className="text-red-500 text-sm mb-2">{errors.terms}</div>

                    <div>
                        <button className="bg-cblue hover:bg-blue-700 text-white font-bold py-5 px-4 rounded-2xl w-full">
                            Create account
                        </button>
                    </div>

                    <div className="w-full  mt-4">
                        <p className="text-left md:text-center">
                            Already have an account?{' '}
                            <Link to="/signin" className="text-blue-500 font-bold">
                                Login
                            </Link>{' '}
                        </p>
                    </div>
                </form>
            </div>
        </AuthContainer>
    )
}

export default Signup
