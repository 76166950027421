import React, { useState } from 'react'
import logoSend from '../../../../assets/logo_send.svg'
import addCircle from '../../../../assets/add-circle.svg'
import { useSelector } from 'react-redux'

const Wallet = () => {
    const [isWalletOn, setIsWalletOn] = useState(false)

    const handleWalletClick = () => {
        setIsWalletOn(!isWalletOn);
    };

    const darkmode = useSelector((state) => state.userReducer.dark)

  return (
    <div className='px-5 lg:px-20 flex flex-col items-center mt-6'>
        <div className='flex justify-between w-3/4 mb-3'>
            <div className='flex items-center'>
                <img src={logoSend} alt="" className='mr-3 w-6'/>

                <div>
                    <p className='font-bold'>Flippay wallet</p>
                    <p className={`text-xs lg:text-sm ${darkmode ? 'text-properGrey' : ''}`}>Toggle to activate or deactivate</p>
                </div>
            </div>

            <div className="flex items-center">
                <button
                    onClick={handleWalletClick}
                    className={`relative w-12 h-6 rounded-full transition-colors duration-300 ${
                    isWalletOn ? 'bg-cblue' : 'bg-gray-400'
                    }`}
                >
                    <div
                    className={`absolute left-1 top-1 w-4 h-4 rounded-full transition-transform duration-300 transform ${
                        isWalletOn ? 'translate-x-full' : 'translate-x-0'
                    } bg-white`}
                    />
                </button>
            </div>
        </div>

        <div className='flex justify-between w-3/4 mb-3'>
            <div className='flex items-center'>
                <img src={addCircle} alt="" className='mr-3'/>

                <div>
                    <p className='font-bold text-gray-400'>Add new wallet</p>
                </div>
            </div> 
        </div>
    </div>
    
  )
}

export default Wallet