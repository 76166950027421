import React, { useState } from 'react'
import doorLockDBlue from '../../../../assets/door-lock-dblue.svg'
import PasswordInput from './PasswordInput'
import { useDispatch, useSelector } from 'react-redux'
import keyWhite from '../../../../assets/keyWhite.svg'
import CustomModal from '../../../UniversalComponents/CustomModal'
import Dot from '../../../UniversalComponents/Dot'
import { changepassword } from '../../../../api'

const CPassword = ({ open, toggleCPassword }) => {
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const [showPassword3, setShowPassword3] = useState(false)
    const [password, setpassword] = useState('')
    const [newPassword, setnewPassword] = useState('')
    const [confirmNewPassword, setconfirmNewPassword] = useState('')
    const dispatch = useDispatch()
    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1)
    }

    const toggleShowPassword2 = () => {
        setShowPassword2(!showPassword2)
    }

    const toggleShowPassword3 = () => {
        setShowPassword3(!showPassword3)
    }

    const handledisabled = () => {
        if (!password || !newPassword || !confirmNewPassword) {
            return true
        }
        if (newPassword !== confirmNewPassword) {
            return true
        }
        return false
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch({ type: 'SETLOADING' })
            await changepassword({ password, newPassword, confirmNewPassword })
            toggleCPassword()
        } catch (err) {
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }
    const darkmode = useSelector((state) => state.userReducer.dark)

    if (!open) {
        return null
    }
    return (
        <CustomModal open={open} handleClose={toggleCPassword}>
            <div
                className={`${
                    darkmode ? 'bg-darkSide text-white' : 'bg-white text-black'
                } p-8 shadow-3xl rounded-3xl w-456`}
            >
                <div className="max-w-xs m-auto ">
                    <div className="flex justify-center">
                        <img src={darkmode ? keyWhite : doorLockDBlue} alt="" />
                    </div>
                    <p className="text-4xl font-semibold text-center text-cfont32 font-poppins mb-2">
                        Change Password
                        <Dot />
                    </p>
                    <p className="text-sm mt-3 mb-6 text-center">Enter a minimum of 8 characters for your password.</p>

                    <form action="" className="w-full flex flex-col items-center">
                        <div className="flex items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4 w-full">
                            <div className="w-full">
                                <PasswordInput
                                    title={'Current password'}
                                    showPassword={showPassword1}
                                    toggleShowPassword={toggleShowPassword1}
                                    value={password}
                                    handleChange={(e) => setpassword(e.target.value)}
                                />
                                {/* <div className='text-red-500 text-sm mb-2'>{errors.phone}</div> */}
                            </div>
                        </div>

                        <div className="flex items-center justify-between  rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4 w-full">
                            <div className="w-full">
                                <PasswordInput
                                    title={'New password'}
                                    showPassword={showPassword2}
                                    toggleShowPassword={toggleShowPassword2}
                                    value={newPassword}
                                    handleChange={(e) => setnewPassword(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4 w-full">
                            <div className="w-full">
                                <PasswordInput
                                    title={'Re-enter new password'}
                                    showPassword={showPassword3}
                                    toggleShowPassword={toggleShowPassword3}
                                    value={confirmNewPassword}
                                    handleChange={(e) => setconfirmNewPassword(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="flex w-full justify-center mt-6">
                            <button
                                className="bg-cblue hover:bg-blue-700 text-white font-bold py-5 px-4 text-sm rounded-cround w-48 mr-3"
                                onClick={handleSubmit}
                                disabled={handledisabled()}
                            >
                                Save Password
                            </button>
                            <button
                                className={`border border-solid border-red-500 font-bold ${
                                    darkmode ? 'text-white' : 'text-black'
                                } py-5 px-4 text-sm  rounded-cround w-48`}
                                onClick={toggleCPassword}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </CustomModal>
    )
}

export default CPassword
