import React, { useState } from 'react'
import cloud from '../../../assets/cloudIcon.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong, faMagnifyingGlass, faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import frame from '../../../assets/Frame.svg'
import { useSelector } from 'react-redux'

import filter from '../../../assets/filter.svg'
import filterBlue from '../../../assets/filterBlue.svg'
import DownloadStatement from './DownloadStatement'

import linkBlue from '../../../assets/t_link_blue.svg'
import linkBlack from '../../../assets/t_link_black.svg'
import debit from '../../../assets/t_debit.svg'
import credit from '../../../assets/t_credit.svg'
import bullet from '../../../assets/t_bullet.svg'
import giftcards from '../../../assets/t_giftcards.svg'
import date from '../../../assets/t_date.svg'
import dateBlue from '../../../assets/t_date_blue.svg'
import calendar from '../../../assets/t_calendar.svg'
import { format } from 'date-fns'
import TMonthlyCalendar from './TMonthlyCalendar'
import dateWhite from '../../../assets/wcalendar-2.svg'
import linkWhite from '../../../assets/wlink.svg'
import debitWhite from '../../../assets/wmoney-send.svg'
import creditWhite from '../../../assets/wmoney-recive.svg'
import cardsWhite from '../../../assets/cards-white.svg'
import dashBoardAsh from '../../../assets/view-dashboard-ash.svg'
import receiptTextWhite from '../../../assets/receipt-text-white.svg'
import convertWhite from '../../../assets/convert.svg'
import convertBlack from '../../../assets/convert-black.svg'
import receiptTextBlack from '../../../assets/receipt-text-blue.svg'
import MiniTitle from '../../UniversalComponents/MiniTitle'
import FooterComponent from '../../LandingPage/LandingPageComponents/FooterComponent'
import CustomTable from '../../UniversalComponents/CustomTable'
import Dot from '../../UniversalComponents/Dot'

const TransactionMid = () => {
    const darkmode = useSelector((state) => state.userReducer.dark)
    const [selectedOption, setSelectedOption] = useState('All')
    const [option, setOption] = useState(false)
    const [isDownload, setIsDownload] = useState(false)
    const [isType, setIsType] = useState(false)
    const [isDate, setIsDate] = useState(false)
    const [isCalendar, setIsCalendar] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [isDateSelected, setIsDateSelected] = useState(false)

    const options = ['All', 'Type', 'Date', 'Debit', 'Credit']

    const toggleOption = () => {
        setOption(!option)
        setIsType(false)
    }

    const handleTypeToggle = () => {
        setIsType(!isType)
    }

    const handleDateToggle = () => {
        setIsDate(!isDate)
        setIsDateSelected(false)
    }

    const handleOptionChange = (opt) => {
        setSelectedOption(opt)
        toggleOption()
        setIsType(false)
        setIsDateSelected(false)
    }

    const handleSelectStartDate = (selectedDate) => {
        if (endDate) {
            // If end date is already selected, reset both start and end dates
            setStartDate(selectedDate)
            setEndDate(null)
        } else {
            // If end date is not selected, set the start date
            setStartDate(selectedDate)
        }
    }

    const handleSelectEndDate = (selectedDate) => {
        // Set the end date
        setEndDate(selectedDate)
        setIsCalendar(false)
        setIsDate(false)
        setOption(false)
        setSelectedOption('')
        setIsDateSelected(true)
    }

    const handleStartDateCalendarClose = () => {
        setIsCalendar(false)
        setIsDate(false)
        setOption(false)
    }

    const handleCalendarClick = () => {
        setIsCalendar(!isCalendar)
    }

    const handleDownloadStatementPopup = () => {
        setIsDownload(!isDownload)
    }

    return (
        <div className={`${darkmode ? 'bg-darkAll' : 'bg-white'} text-${darkmode ? 'white' : 'black'} w-full`}>
            <div className="max-w-2xl py-3 px-5 lg:px-0">
                <MiniTitle sideImage={dashBoardAsh} title="Transaction" />
                <p className="text-cfont32 font-poppins font-semibold">
                    Transactions
                    <Dot />
                </p>

                <p className="text-sm mt-3 mb-8">
                    See all your transactions on Flippay, and <br /> <i>download account statement.</i>
                </p>

                <div className="block xl:flex">
                    <div className={` flex-1 lg:mr-6 ${darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'}`}>
                        <div className="relative border-gray-300 px-5 border hover:border-gray-300 rounded-full mb-3">
                            <div className="flex items-center  py-4 ">
                                <div className="cursor-pointer mr-2">
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </div>

                                <input
                                    type="text"
                                    id="option"
                                    required
                                    className="outline-none flex-1  bg-inherit"
                                    placeholder="Search transactions"
                                />

                                <div className="cursor-pointer" onClick={toggleOption}>
                                    <img src={option ? filterBlue : filter} alt="" />
                                </div>
                            </div>

                            {option && (
                                <div
                                    className={`absolute right-0 mt-1 w-1/2 ${
                                        darkmode
                                            ? 'bg-ccdarkblue text-white border-cdarkblue'
                                            : 'bg-white text-black border-gray-300'
                                    } border rounded-3xl rounded-tr-none shadow-lg z-20 py-4`}
                                >
                                    <p className="px-4 text-xs font-medium text-warn py-1">filter by</p>
                                    {options.map((option) => (
                                        <div>
                                            {option !== 'Type' && option !== 'Date' && (
                                                <div
                                                    key={option}
                                                    value={option}
                                                    className={`px-4 py-1 cursor-pointer text-xs mt-3.5 flex ${
                                                        darkmode
                                                            ? 'hover:text-black hover:bg-gray-400'
                                                            : 'hover:bg-gray-100'
                                                    }`}
                                                    onClick={() => handleOptionChange(option)}
                                                >
                                                    {option === 'All' && (
                                                        <img
                                                            src={darkmode ? linkWhite : linkBlack}
                                                            alt=""
                                                            className="mr-2"
                                                        />
                                                    )}
                                                    {option === 'Debit' && (
                                                        <img
                                                            src={darkmode ? debitWhite : debit}
                                                            alt=""
                                                            className="mr-2"
                                                        />
                                                    )}
                                                    {option === 'Credit' && (
                                                        <img
                                                            src={darkmode ? creditWhite : credit}
                                                            alt=""
                                                            className="mr-2"
                                                        />
                                                    )}

                                                    {option}
                                                </div>
                                            )}

                                            {option === 'Type' && (
                                                <div
                                                    key={option}
                                                    value={option}
                                                    className={`px-4 py-1 cursor-pointer text-xs mt-3.5 flex justify-between items-betwen ${
                                                        isType ? 'text-cblue' : 'text-black'
                                                    } ${
                                                        darkmode
                                                            ? 'text-white hover:text-black hover:bg-gray-400'
                                                            : 'hover:bg-gray-100'
                                                    } `}
                                                    onClick={handleTypeToggle}
                                                >
                                                    <div className="flex">
                                                        <img
                                                            src={darkmode ? linkWhite : isType ? linkBlue : linkBlack}
                                                            alt=""
                                                            className="mr-2"
                                                        />
                                                        {option}
                                                    </div>
                                                    <FontAwesomeIcon icon={isType ? faAngleDown : faAngleRight} />
                                                </div>
                                            )}

                                            <div>
                                                {option === 'Type' && isType && (
                                                    <div className="mt-2 mx-4">
                                                        <div
                                                            key="bills"
                                                            value="bills"
                                                            className={`pl-8 py-1 cursor-pointer text-xs flex ${
                                                                darkmode
                                                                    ? 'hover:text-black hover:bg-gray-400'
                                                                    : 'hover:bg-gray-100'
                                                            } `}
                                                            onClick={() => handleOptionChange('Bills')}
                                                        >
                                                            <img src={bullet} alt="" className="mr-1" />
                                                            Bills
                                                            <img
                                                                src={darkmode ? receiptTextWhite : receiptTextBlack}
                                                                alt=""
                                                                className="ml-1 w-4 h-4"
                                                            />
                                                        </div>

                                                        <div
                                                            key="transfers"
                                                            value="transfers"
                                                            className={`pl-8 py-1 cursor-pointer text-xs flex ${
                                                                darkmode
                                                                    ? 'hover:text-black hover:bg-gray-400'
                                                                    : 'hover:bg-gray-100'
                                                            } `}
                                                            onClick={() => handleOptionChange('Transfers')}
                                                        >
                                                            <img src={bullet} alt="" className="mr-1" />
                                                            Transfers
                                                            <img
                                                                src={darkmode ? convertWhite : convertBlack}
                                                                alt=""
                                                                className="ml-1 w-4 h-4"
                                                            />
                                                        </div>

                                                        <div
                                                            key="giftcards"
                                                            value="giftcards"
                                                            className={`pl-8 py-1 cursor-pointer text-xs flex items-center ${
                                                                darkmode
                                                                    ? 'hover:text-black hover:bg-gray-400'
                                                                    : 'hover:bg-gray-100'
                                                            } `}
                                                            onClick={() => handleOptionChange('Gift Card')}
                                                        >
                                                            <img src={bullet} alt="" className="mr-1" />
                                                            Gift Card
                                                            <img
                                                                src={darkmode ? cardsWhite : giftcards}
                                                                alt=""
                                                                className="ml-1 w-4 h-4"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {option === 'Date' && (
                                                <div
                                                    key={option}
                                                    value={option}
                                                    className={`px-4 py-1 cursor-pointer  text-xs mt-3.5 flex justify-between items-betwen ${
                                                        isDate ? 'text-cblue' : 'text-black'
                                                    } ${
                                                        darkmode
                                                            ? 'text-white hover:text-black hover:bg-gray-400'
                                                            : 'hover:bg-gray-100'
                                                    }`}
                                                    onClick={handleDateToggle}
                                                >
                                                    <div className="flex">
                                                        <img
                                                            src={darkmode ? dateWhite : isDate ? dateBlue : date}
                                                            alt=""
                                                            className="mr-2"
                                                        />
                                                        {option}
                                                    </div>
                                                    <FontAwesomeIcon icon={isDate ? faAngleDown : faAngleRight} />
                                                </div>
                                            )}

                                            <div>
                                                {option === 'Date' && isDate && (
                                                    <div className="mt-2 mx-4">
                                                        <div
                                                            key="calendar"
                                                            value="calendar"
                                                            className={`pl-8 py-1 cursor-pointer hover:bg-gray-100 text-xs flex border  rounded-2xl ${
                                                                darkmode
                                                                    ? 'hover:text-black border-white'
                                                                    : 'border-black'
                                                            } `}
                                                            onClick={handleCalendarClick}
                                                        >
                                                            Start - End
                                                            <img src={calendar} alt="" className="ml-1" />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <p className="text-greyy text-sm lg:hidden">
                            Filtered by:{' '}
                            {isDateSelected
                                ? isDateSelected && (
                                      <span className="font-medium">
                                          {format(startDate, 'MM/dd/yyyy')} - {format(endDate, 'MM/dd/yyyy')}
                                      </span>
                                  )
                                : selectedOption && <span className="font-medium">{selectedOption}</span>}
                            {!selectedOption && !isDateSelected && <span className="font-medium">{options[0]}</span>}
                            {/* {isDateSelected && (
                                <span className="font-medium">
                                    {format(startDate, 'MM/dd/yyyy')} - {format(endDate, 'MM/dd/yyyy')}
                                </span>
                            )} */}
                        </p>
                    </div>

                    <div
                        className="bg-cblue text-white rounded-ccround overflow-hidden pt-5 mt-5 lg:mt-0  cursor-pointer mb-5 lg:mb-0"
                        onClick={handleDownloadStatementPopup}
                    >
                        <div className="flex px-4 justify-between items-center">
                            <div>
                                <p className="text-sm font-medium">
                                    You can now download <br /> <span>account statement.</span>
                                </p>

                                <p className=" text-xs italic mt-2 mb-3">Track your transactions.</p>
                            </div>

                            <div className="flex justify-center">
                                <img src={cloud} alt="" />
                            </div>
                        </div>
                        <div className="bg-cdarkblue px-6 flex justify-between items-center py-3  ">
                            <p className="text-white font-bold text-xs ">Download account statement</p>

                            <FontAwesomeIcon icon={faArrowRightLong} className="text-white ml-4" />
                        </div>
                    </div>
                </div>

                <p className="hidden lg:block text-greyy text-sm">
                    Filtered by:{' '}
                    {isDateSelected
                        ? isDateSelected && (
                              <span className="font-medium">
                                  {format(startDate, 'MM/dd/yyyy')} - {format(endDate, 'MM/dd/yyyy')}
                              </span>
                          )
                        : selectedOption && <span className="font-medium">{selectedOption}</span>}
                    {/* {isDateSelected && (
                        <span className="font-medium">
                            {format(startDate, 'MM/dd/yyyy')} - {format(endDate, 'MM/dd/yyyy')}
                        </span>
                    )} */}
                    {!selectedOption && !isDateSelected && <span className="font-medium">{options[0]}</span>}
                </p>

                <div className="hidden lg:block container mx-auto mt-8">
                    <CustomTable filterOption={selectedOption} startDate={startDate} endDate={endDate} />
                </div>

                <DownloadStatement open={isDownload} handleDownloadStatementPopup={handleDownloadStatementPopup} />

                {isCalendar && (
                    // <TMonthlyCalendar onSelectDate={handleSelectStartDate} onClose={handleStartDateCalendarClose} />
                    <TMonthlyCalendar
                        onSelectStartDate={handleSelectStartDate}
                        onSelectEndDate={handleSelectEndDate} // Add this prop
                        onClose={handleStartDateCalendarClose}
                        startDate={startDate} // Pass the start date to the calendar
                        endDate={endDate} // Pass the end date to the calendar
                    />
                )}
            </div>

            <div className="lg:hidden flex flex-col items-center mt-12 mb-20">
                <div className="mb-4">
                    <img src={frame} alt="" />
                </div>
                <p className="font-medium text-2xl text-greyy">Holla! you must be new here.</p>
                <p className="text-greyy">Perform a transaction</p>
            </div>

            <section className="pl-5 text-white bg-black relative rounded-b-3xl md:hidden">
                <FooterComponent extra={true} />
            </section>

            <section
                className={`text-center pt-5 relative before:w-full before:absolute before:-top-5 before:left-0 before:-bottom-5 before:bg-white before:z-[-1] flex flex-col md:flex-row justify-between items-center md:hidden ${
                    darkmode ? 'bg-darkAll' : 'bg-white'
                } text-${darkmode ? 'white' : 'black'}`}
            >
                <p className="w-52 md:w-auto text-sm mb-5 md:mb-0">
                    All rights reserved. Copyrights © 2023 Flippay. co.{' '}
                </p>
                <p className="text-sm">
                    Owned by PayNyce Nigeria Ltd, a parent company registered under the Corporate Affairs Commission
                    (CAC) with RC 1731068
                </p>
            </section>
        </div>
    )
}

export default TransactionMid
