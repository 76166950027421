import React from 'react'
import FaqPage from './LandingPageComponents/Faq/Faq'
import { useSelector } from 'react-redux'

function FAQ() {
    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <section
            className={`pl-8 pr-8  -mt-28  xl:-mt-72 pt-16 pb-16 md:px-20 ${darkmode ? 'bg-darkSide' : 'bg-white'} `}
        >
            <h1 className="font-medium text-2xl md:text-5xl relative after:w-2 after:h-2 after:bg-cblue after:inline-block font-poppins ">
                Frequently Asked <br /> Questions(FAQ)
            </h1>
            <p className={`text-${darkmode ? 'white' : 'txtcolor'} mt-2 font-normal text-base mb-7 md:mb-20`}>
                See commonly asked questions on Flippay!
            </p>
            <FaqPage />
        </section>
    )
}

export default FAQ
