import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import PINInput from '../../../Account/AccountComponent/Security/PINInput'
import { useDispatch, useSelector } from 'react-redux'
import SidePopup from '../../../UniversalComponents/SidePopup'
import { buyElectric, getBillInfo } from '../../../../api'

const Electricity = ({ toggleElectricity, togglePayElectricity }) => {
    const [selectedBillName, setSelectedBillName] = useState('')
    // const [selectedProdType, setSelectedProdType] = useState('')
    const [transactionType, setTransactionType] = useState('')
    const [meterNum, setMeterNum] = useState('')
    const [amount, setAmount] = useState('')

    const [billName, setBillName] = useState(false)
    // const [prodType, setProdType] = useState(false)

    const [showPassword, setShowPassword] = useState(true)
    const [pin, setPin] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [electricPower, setElectricPower] = useState({})
    const [error, setError] = useState('')

    useEffect(() => {
        //  FOR CABLE
        getBillInfo('power=1')
            .then((res) => {
                const { data } = res
                const power = data.data

                const ngPower = power.filter((powr) => powr.country == 'NG')
                // console.log('ngC', ngPower)
                const processedData = {
                    PREPAID: [],
                    POSTPAID: []
                }

                // console.log(cablesdata, 'here go')
                for (let powr of ngPower) {
                    const lowerCaseName = powr.name.toLowerCase()

                    if (lowerCaseName.includes('prepaid')) {
                        processedData['PREPAID'].push(powr)
                    } else if (lowerCaseName.includes('postpaid')) {
                        processedData['POSTPAID'].push(powr)
                    }
                }
                // console.log(processedData, 'this is processed')
                setElectricPower(processedData)
            })
            .catch((err) => {})
    }, [])

    const handleClickReset = () => {
        // console.log('working')
        navigate('/account', { state: { isResetTrue: true } })
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleBillChange = (bill) => {
        setSelectedBillName(bill)
        toggleBill()
    }

    // const handleProductChange = (prod) => {
    //     setSelectedProdType(prod)
    //     toggleProdType()
    // }

    const handleRadioChange = (e) => {
        setTransactionType(e.target.value)
    }

    const toggleBill = () => {
        setBillName(!billName)
        // setProdType(false)
    }

    // const toggleProdType = () => {
    //     setProdType(!prodType)
    //     setBillName(false)
    // }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch({ type: 'SETLOADING' })
            // await validateUser(selectedBillName.item_code, selectedBillName.biller_code, subNumber)
            await buyElectric('power', {
                itemCode: selectedBillName.item_code,
                meterNum: meterNum,
                code: selectedBillName.biller_code,
                amount: Number(amount)
            })
            togglePayElectricity()
        } catch (err) {
            setError(err.response.data.message)
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }

    const handleDisabled = () => {
        if (!meterNum || !selectedBillName || !amount || pin.length != 4) {
            return true
        }

        return false
    }

    const handleAmountChange = (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }
        setAmount(value)
    }

    const handleMeterNumberChange = (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }
        setMeterNum(value)
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <SidePopup toggleGeneralPopup={toggleElectricity}>
            <div className="w-full flex flex-col items-center">
                <h2 className="text-3xl font-semibold mt-24">Electricity.</h2>

                <p className="text-sm text-center mt-2.5 mb-16 w-4/6">Easily pay your electricity bill on Flippay.</p>

                <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-gray-300 mb-4">
                    <div className="w-full flex flex-col items-start">
                        <label htmlFor="meter" className="text-xs font-semibold">
                            Enter meter number
                        </label>
                        <div className="w-full">
                            <input
                                className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                type="number"
                                id="meter"
                                name="meter"
                                required
                                placeholder="0000-0000-0000"
                                onChange={handleMeterNumberChange}
                                value={meterNum}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex justify-start w-2/3 mb-2.5">
                    <p className="text-xs font-semibold text-left">Meter type</p>
                </div>

                <div className="flex items-center justify-between w-2/3 mb-4">
                    <label className="flex items-center text-sm">
                        <input
                            type="radio"
                            name="transactionType"
                            value="postPaid"
                            checked={transactionType === 'postPaid'}
                            onChange={handleRadioChange}
                        />
                        <span className="ml-2">Postpaid</span>
                    </label>
                    <label className="flex items-center text-sm">
                        <input
                            type="radio"
                            name="transactionType"
                            value="prePaid"
                            checked={transactionType === 'prePaid'}
                            onChange={handleRadioChange}
                        />
                        <span className="ml-2">Prepaid</span>
                    </label>
                </div>

                <div className="relative w-4/5 md:w-5/6 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl mb-4">
                    <label htmlFor="bill" className="text-xs font-semibold" onClick={toggleBill}>
                        Select bill name
                    </label>
                    <div className="flex items-center justify-between w-full rounded-2xl py-1">
                        <input
                            type="text"
                            id="bill"
                            readOnly
                            required
                            value={selectedBillName.biller_name}
                            className="outline-none w-full cursor-pointer bg-transparent"
                            onClick={toggleBill}
                        />

                        <div className="cursor-pointer" onClick={toggleBill}>
                            <FontAwesomeIcon icon={billName ? faAngleDown : faAngleRight} />
                        </div>
                    </div>

                    {billName && (
                        <div
                            className={`absolute right-0 mt-1 w-2/3 border rounded-3xl rounded-tr-none shadow-lg z-20 ${
                                darkmode
                                    ? 'bg-ccdarkblue text-white border-cdarkblue'
                                    : 'bg-white text-black border-gray-300'
                            } max-h-64 scrollbar-hide overflow-y-scroll`}
                        >
                            <p className="px-4 text-xs font-medium text-warn py-1">Bill names</p>
                            {electricPower[transactionType.toUpperCase()]?.map((bill) => (
                                <div
                                    key={bill.id}
                                    value={bill}
                                    className={`px-4 py-2 cursor-pointer ${
                                        darkmode ? 'hover:bg-gray-400' : 'hover:bg-gray-100'
                                    }`}
                                    onClick={() => handleBillChange(bill)}
                                >
                                    {bill.biller_name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* <div className="relative w-4/5 md:w-5/6 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl mb-4">
                <label htmlFor="product" className="text-xs font-semibold" onClick={toggleProdType}>
                    Product type
                </label>
                <div className="flex items-center justify-between w-full rounded-2xl py-1">
                    <input
                        type="text"
                        id="product"
                        readOnly
                        required
                        value={selectedProdType}
                        className="outline-none w-full cursor-pointer bg-transparent"
                        onClick={toggleProdType}
                    />

                    <div className="cursor-pointer" onClick={toggleProdType}>
                        <FontAwesomeIcon icon={prodType ? faAngleDown : faAngleRight} />
                    </div>
                </div>

                {prodType && (
                    <div className={`absolute right-0 mt-1 w-2/3 border rounded-3xl rounded-tr-none shadow-lg z-20 ${darkmode ? 'bg-ccdarkblue text-white border-cdarkblue' : 'bg-white text-black border-gray-300'} max-h-64 scrollbar-hide overflow-y-scroll`}>
                        <p className='px-4 text-xs font-medium text-warn py-1'>Product types</p>
                        {prodTypes.map((product) => (
                            <div
                                key={product}
                                value={product}
                                className={`px-4 py-2 cursor-pointer ${darkmode ? 'hover:bg-gray-400' :'hover:bg-gray-100'}`}
                                onClick={() => handleProductChange(product)}
                            >
                                {product}
                            </div>
                        ))}
                    </div>
                )}
            </div> */}

                {/* <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-gray-300 mb-4">
                <div className="w-full flex flex-col items-start">
                    <label htmlFor="phone" className="text-xs font-semibold">
                        Phone number
                    </label>
                    <div className="w-full">
                        <input
                            className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                            type="tel"
                            id="phone"
                            name="phone"
                            required
                            placeholder="08012066000"
                        />
                    </div>
                </div>
            </div> */}

                <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-gray-300 mb-5">
                    <div className="w-full flex flex-col items-start">
                        <label htmlFor="amount" className="text-xs font-semibold">
                            Enter Amount
                        </label>
                        <div className="w-full">
                            <input
                                className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                type="number"
                                id="amount"
                                name="amount"
                                required
                                placeholder="0000-0000-0000"
                                onChange={handleAmountChange}
                                value={amount}
                            />
                        </div>
                    </div>
                </div>

                <PINInput
                    handleOnChange={setPin}
                    showPassword={showPassword}
                    toggleShowPassword={toggleShowPassword}
                    title={'Enter PIN'}
                />

                <p className="text-sm italic text-center text-red-500">{error}</p>

                <p className="text-sm font-bold mt-6 mb-4">
                    Forgot PIN?{' '}
                    <button onClick={handleClickReset} className="text-blue-500 font-bold">
                        Reset
                    </button>
                </p>
                <div className="flex w-full justify-center mb-24">
                    <button
                        className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-48 mr-3 disabled:bg-gray-600"
                        onClick={handleSubmit}
                        disabled={handleDisabled()}
                    >
                        Pay bill
                    </button>
                    <button
                        className={`border-2 border-solid border-red-500 text-${
                            darkmode ? 'white' : 'black'
                        } font-bold py-2 px-4 rounded-2xl w-48`}
                        onClick={toggleElectricity}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </SidePopup>
    )
}

export default Electricity
