import React, { useEffect } from 'react'
import Navigation from '../UniversalComponents/Navigation'
import LandBody from './LandingPageComponents/LandBody'
import { useState } from 'react'

const LandingPageContainer = () => {
    useEffect(() => {
        // localStorage.clear();
    }, [])
    const [selectedOption, setSelectedOption] = useState({})
    return (
        <div>
            <Navigation setSelectedOption={setSelectedOption} selectedOption={selectedOption} />
            <LandBody selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
        </div>
    )
}

export default LandingPageContainer
