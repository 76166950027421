import React from 'react'
import PinInput from 'react-pin-input'

const PINInput = ({ showPassword, toggleShowPassword, title, handleOnChange }) => {
    return (
        <div className="mb-5 mt-3">
            <div className="flex items-center">
                <PinInput
                    length={4} // Define the length of the PIN
                    focus
                    secret={showPassword}
                    type="numeric"
                    onChange={(value) => handleOnChange(value)}
                    inputStyle={{
                        borderColor: '#BFBEBE',
                        borderRadius: '15px',
                        margin: '5px',
                        width: '42px',
                        height: '42px'
                    }} // Style the inputs
                    inputFocusStyle={{ borderColor: 'blue' }} // Style the focused input
                />

                {/* <div className="cursor-pointer" onClick={toggleShowPassword}>
                    {showPassword ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-gray-700"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M2 4.55v.01M22 4.55v.01"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M7.65 20.36l.71-.7M16.36 20.36l-.7-.7"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-gray-700"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4.93 4.93a8 8 0 0111.14 0M1 1l22 22"
                            />
                        </svg>
                    )}
                </div> */}
            </div>
        </div>
    )
}

export default PINInput
