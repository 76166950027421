import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import export1 from '../../assets/export.svg'
import { useSelector } from 'react-redux'

function ActionTablet({ sideimg = export1, title, subtitle, action }) {
    const darkmode = useSelector((state) => state.userReducer.dark)
    return (
        <div
            style={{
                borderRadius: '30px'
            }}
            className={`${
                darkmode ? 'bg-ccdarkblue hover:bg-cdarkblue' : 'bg-cblue hover:bg-cdarkblue'
            } flex  items-center justify-between px-5 py-3 w-full cursor-pointer mb-3 lg:mb-0`}
            onClick={action}
        >
            <div className="flex items-center">
                <div className="mr-3">
                    <img src={sideimg} alt="" />
                </div>
                <div>
                    <p className="text-white font-bold">{title}</p>
                    <p className="text-xs text-white">{subtitle}</p>
                </div>
            </div>

            <div className="p-3 w-8 h-8 grid place-content-center rounded-full bg-white">
                <FontAwesomeIcon icon={faAngleRight} className="text-cdarkblue" />
            </div>
        </div>
    )
}

export default ActionTablet
