import React from 'react'
import WalletMid from './WalletComponents/WalletMid'
import UserContainer from '../UniversalComponents/UserContainer'

const Wallet = () => {
    return (
        <UserContainer active={'wallet'}>
            <WalletMid />
        </UserContainer>
    )
}

export default Wallet
