import React, { useState } from 'react'
import natlID2 from '../../../../assets/nationalID2.svg'
import gallery from '../../../../assets/gallery.svg'
import { useSelector } from 'react-redux'
import galleryGreen from '../../../../assets/gallery_green.svg'
import CustomModal from '../../../UniversalComponents/CustomModal'

const InternationalPassport = ({ open, toggleIntlPassport, toggleCancel }) => {
    const [selectedImages, setSelectedImages] = useState([])

    const removeImage = (index) => {
        setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index))
    }

    const handleImageChange = (event) => {
        const files = event.target.files

        // Convert FileList to an array
        const selectedImagesArray = Array.from(files)

        // Update the state with the selected images
        setSelectedImages((prevImages) => [...prevImages, ...selectedImagesArray])
    }

    const handleLabelClick = () => {
        document.getElementById('intlpassport').click()
    }

    const handleUpload = () => {
        // Implement your upload logic here, e.g., use a service to upload the image.

        // For a simple example, you can log the selected image details:
        toggleIntlPassport()
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <CustomModal open={open} handleClose={toggleIntlPassport}>
            <div
                className={`${
                    darkmode ? 'bg-darkSide text-white' : 'bg-white text-black'
                } p-8 shadow-3xl rounded-3xl w-456`}
            >
                <div className="max-w-xs m-auto">
                    <div className="flex justify-center">
                        <img src={natlID2} alt="" />
                    </div>
                    <p className="text-4xl font-semibold text-center">International Passport</p>
                    <p className="text-sm mt-3 mb-6 text-center w-4/5">Send to this account instead.</p>

                    <div className="flex justify-center">
                        <label
                            onClick={handleLabelClick}
                            className={`flex cursor-pointer items-center justify-between w-4/5 md:w-4/6 rounded-2xl border-dashed border-2 ${
                                selectedImages.length > 0 ? 'border-green-500' : 'border-cblue'
                            } px-4 py-6 mb-4`}
                        >
                            <div className="w-full flex flex-col items-center">
                                <img src={selectedImages.length > 0 ? galleryGreen : gallery} alt="" />
                                <p className={`text-sm ${selectedImages.length > 0 ? 'text-green-500' : 'text-cblue'}`}>
                                    Upload card image
                                </p>
                            </div>
                        </label>
                    </div>

                    {selectedImages.length > 0 && (
                        <div className="w-full max-h-64 scrollbar-hide overflow-y-scroll mb-2">
                            {selectedImages.map((image, index) => (
                                <div key={index} className="relative">
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt={`Selected ${index + 1}`}
                                        className="w-full h-auto rounded-2xl mb-6"
                                    />
                                    <button
                                        onClick={() => removeImage(index)}
                                        className="absolute top-0 right-0 bg-red-500 text-white p-2 rounded-full"
                                    >
                                        X
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="flex items-center justify-between">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="mb-4 hidden"
                            id="intlpassport"
                            multiple
                        />
                    </div>
                    <div className="flex w-full justify-center mt-6">
                        {selectedImages.length > 0 && (
                            <button
                                className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-48 mr-3"
                                onClick={handleUpload}
                            >
                                Upload image
                            </button>
                        )}

                        <button
                            className={`border-2 border-solid border-red-500 font-bold ${
                                darkmode ? 'text-white' : 'text-black'
                            } py-2 px-4 rounded-2xl w-48`}
                            onClick={toggleCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default InternationalPassport
