import React from 'react'
import profilpic from '../../assets/profilepic.svg'

function ProfileCircle({
    size = 50,
    bglinear = 'linear-gradient(127.53deg, #007BFF 15.38%, #213B98 52.19%, #213B98 52.75%)',
    profileimg = profilpic
}) {
    // if the user is there, then
    return (
        <div
            className="rounded-full"
            style={{
                color: '#8b8e92',
                padding: '2px',
                width: `${size}px`,
                height: `${size}px`,

                background: bglinear
            }}
        >
            <div className="bg-white w-full h-full overflow-hidden  grid place-items-center  rounded-full ">
                <img src={profileimg} className="w-full h-full object-cover " />
            </div>
        </div>
    )
}

export default ProfileCircle
