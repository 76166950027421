import React, { useEffect, useState } from 'react'
import FooterComponent from './FooterComponent'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import vector from '../../../assets/Vector.svg'
import playCircle from '../../../assets/play-cricle.svg'
import googlePlay from '../../../assets/google-play.svg'
import appleStore from '../../../assets/apple-store.svg'
import fbk from '../../../assets/Facebook.svg'
import aticon from '../../../assets/AtIcon.svg'
import insta from '../../../assets/Instagram.svg'
import twit from '../../../assets/Twitter.svg'
import FaqPage from './Faq/Faq'
import { useSelector } from 'react-redux'
import vector1 from '../../../assets/vector1.png'

const SupportBody = () => {
    const darkmode = useSelector((state) => state.userReducer.dark)
    const [isOn, setIsOn] = useState(false)

    useEffect(() => {
        if (darkmode) {
            setIsOn(true)
        } else if (!darkmode) {
            setIsOn(false)
        }
    }, [darkmode])

    return (
        <div className={`${darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'} overflow-x-hidden`}>
            <section className="flex justify-between items-center relative w-full h-full pt-28 pb-36 bg-cover bg-smileRight md:bg-center bg-customer">
                <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-r from-cblue to-purple-700 opacity-30"></div>

                <div className="pl-10 md:pl-20 md:flex justify-center items-center text-white z-10">
                    <div className="hidden lg:flex flex-col items-center">
                        <div className="border-l-2 border-darkGray border-solid h-40"></div>
                        <Link>
                            <FontAwesomeIcon icon={faInstagram} className="text-white text-2xl my-4 mx-2" />
                        </Link>
                        <Link>
                            <FontAwesomeIcon icon={faFacebook} className="text-white text-2xl my-4 mx-2" />
                        </Link>
                        <Link>
                            <FontAwesomeIcon icon={faTwitter} className="text-white text-2xl my-4 mx-2" />
                        </Link>
                        <div className="border-l-2 border-darkGray border-solid h-40"></div>
                    </div>

                    <div className="lg:ml-20">
                        <p className="hidden md:block text-xs">About Flippay</p>
                        <h1 className="text-4xl lg:text-6xl font-bold md:leading-12 text-white">
                            How can we help
                            <br /> you <span className="text-black">?</span>
                        </h1>

                        <p className="text-sm font-normal leading-5 mt-5">
                            Fill out the form below, and we’d get back to you within minutes. <br />{' '}
                            <span className="italic">You matter to us!</span>
                        </p>

                        <div className="flex justify-between items-center mt-14">
                            <div className="border-2 border-solid border-blue-500 p-2 rounded-3xl">
                                <Link
                                    to="https://youtu.be/kTVUpqIkZOc?si=hss7_W35tknRIP9l"
                                    className="flex hover:text-cblue"
                                >
                                    <span className="mr-2 text-sm font-semibold">How it works</span>{' '}
                                    <img src={playCircle} alt="Play" />
                                </Link>
                            </div>
                        </div>

                        <div className="flex justify-start mt-20">
                            <Link to={'https://play.google.com/store/apps/details?id=com.paynyce.flippay'}>
                                <img src={googlePlay} alt="Google Play" />
                            </Link>
                            <Link
                                className="ml-3"
                                to={'https://apps.apple.com/app/flippay-sell-gift-cards/id6475052879'}
                            >
                                <img src={appleStore} alt="Apple Store" />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pl-10 md:pl-20 bg-cdarkblue rounded-3xl relative -top-5 before:rounded-xl before:w-48 md:before:w-96 before:h-48 md:before:h-96 before:absolute before:-top-1 md:before:-top-5 before:left-24 md:before:left-48 before:bg-cdarkblue before:transform before:rotate-45 after:w-48 md:after:w-96 after:rounded-sm after:rounded-tl-3xl after:h-48 md:after:h-96 after:absolute after:-top-10 md:after:-top-24 after:left-0 after:bg-cdarkblue after:transform after:-skew-y-0 h-max pb-20">
                <ul className="md:pl-0 ml-5 text-white list-disc relative z-10 -top-5 md:-top-10">
                    <li>
                        <p>FAQ</p>
                    </li>
                </ul>

                <h1 className="font-medium md:font-normal text-2xl md:text-4xl mb-6 md:mb-12 text-white relative z-10 mt-6 md:mt-16">
                    Frequently Asked <br /> Questions(FAQ)
                </h1>
                <p className="w-4/5 md:w-full text-white relative z-10 font-normal text-base mb-20">
                    See commonly asked questions on Flippay!
                </p>

                <FaqPage />
            </section>

            <section
                className={`pl-10 pr-10 md:pl-20 ${
                    darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'
                } rounded-3xl -top-5 relative md:top-0 before:rounded-xl before:w-48 md:before:w-96 before:h-48 md:before:h-96 before:absolute before:-top-1 md:before:-top-5 before:left-24 md:before:left-48 ${
                    darkmode ? 'before:bg-darkAll before:text-white' : 'before:bg-white before:text-black'
                } before:transform before:rotate-45 after:w-48 md:after:w-96 after:rounded-sm after:rounded-tl-3xl after:h-48 md:after:h-96 after:absolute after:-top-10 md:after:-top-24 after:left-0 ${
                    darkmode ? 'after:bg-darkAll after:text-white' : 'after:bg-white after:text-black'
                } after:transform after:-skew-y-0 pb-20`}
            >
                <div
                    className={`absolute right-0 w-full h-full ${
                        darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'
                    } bg-opacity-70`}
                >
                    {/* <p>wagwan</p> */}
                </div>

                <ul
                    className={`md:pl-0 ml-5 ${
                        darkmode ? 'text-white' : 'text-black'
                    } list-disc relative z-10 -top-5 md:-top-10`}
                >
                    <li>
                        <p>Contact Us</p>
                    </li>
                </ul>

                <h1
                    className={`font-medium md:font-normal text-2xl md:text-4xl mb-6 md:mb-12 relative z-10 mt-6 md:mt-16 ${
                        darkmode ? 'text-white' : 'text-black'
                    }`}
                >
                    What do you need <br /> help with?
                </h1>
                <p className={` relative z-10 font-normal text-base mb-6 ${darkmode ? 'text-white' : 'text-black'}`}>
                    Send us an email or chat us up on our social media handles.
                </p>

                <div className="flex justify-start relative z-10 mb-10">
                    <Link>
                        <img src={insta} alt="" />
                    </Link>
                    <Link className="mx-5">
                        <img src={fbk} alt="" />
                    </Link>
                    <Link>
                        <img src={twit} alt="" />
                    </Link>
                </div>

                <div className={`relative z-10 ${darkmode ? 'text-white' : 'text-black'} md:w-8/12`}>
                    <div className="flex justify-between mb-5">
                        <div className="w-1/2 pr-5">
                            <p className="mb-3 pl-3">First name</p>
                            <input
                                className="border border-solid border-gray-500 w-full rounded-2xl py-5 pl-4 bg-inherit"
                                type="text"
                                placeholder="Enter first name"
                            />
                        </div>

                        <div className="w-1/2">
                            <p className="mb-3 pl-3">Last name</p>
                            <input
                                className="border border-solid border-gray-500 w-full rounded-2xl py-5 pl-4 bg-inherit"
                                type="text"
                                placeholder="Enter last name"
                            />
                        </div>
                    </div>

                    <div className="flex justify-between mb-5">
                        <div className="w-1/2 pr-5">
                            <p className="mb-3 pl-3">E-mail address</p>
                            <input
                                className="border border-solid border-gray-500 w-full rounded-2xl py-5 pl-4 bg-inherit"
                                type="email"
                                placeholder="example@gmail.com"
                            />
                        </div>

                        <div className="w-1/2">
                            <p className="mb-3 pl-3">Phone number</p>
                            <input
                                className="border border-solid border-gray-500 w-full rounded-2xl py-5 pl-4 bg-inherit"
                                type="tel"
                                placeholder="Enter number"
                            />
                        </div>
                    </div>

                    <div>
                        <p className="mb-3 pl-3">Enter message</p>
                        <textarea
                            className="border border-solid border-gray-500 w-full rounded-2xl py-5 pl-4 inherit bg-transparent outline-none"
                            name="message"
                            id="message"
                            cols="30"
                            rows="10"
                            placeholder={`Hello,\n\nI’m having difficulty trading a Steam gift card, need help.`}
                        ></textarea>
                    </div>

                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl flex items-center">
                        <p>Send email</p>
                        <img src={aticon} className="ml-1.5" alt="" />
                    </button>
                </div>

                <div className="absolute -right-16 top-5 md:-top-10 overflow-hidden">
                    <img
                        src={vector1}
                        className="transform rotate-45 scale-50 md:relative md:right-10 md:top-10 lg:scale-100 lg:-top-10"
                        alt=""
                    />
                </div>
            </section>

            <section className="pl-10 md:pl-20 md:pr-20 text-white bg-black relative before:w-full before:absolute before:-top-5 before:left-0 before:-bottom-5 before:bg-black before:z-[-1] rounded-3xl">
                <FooterComponent />
            </section>

            <section
                className={`pl-10 pr-10 text-center md:pl-20 md:pr-20 pt-5 relative before:w-full before:absolute before:-top-5 before:left-0 before:bg-white before:z-[-1] flex flex-col md:flex-row justify-between items-center ${
                    darkmode ? 'bg-darkAll' : 'bg-white'
                } text-${darkmode ? 'white' : 'black'} pb-4`}
            >
                <p className="w-52 md:w-auto text-sm mb-5 md:mb-0">
                    All rights reserved. Copyrights © 2023 Flippay. co.{' '}
                </p>
                <p className="text-sm">
                    Owned by PayNyce Nigeria Ltd, a parent company registered under the Corporate Affairs Commission
                    (CAC) with RC 1731068
                </p>
            </section>
        </div>
    )
}

export default SupportBody
