import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import googlePlay from '../../../assets/google-play.svg'
import appleStore from '../../../assets/apple-store.svg'
import fingerprint from '../../../assets/fingerprint.svg'
import securitySafe from '../../../assets/security-safe.svg'
import itunes from '../../../assets/itunes.svg'
import streams from '../../../assets/streams.svg'
import target from '../../../assets/target.svg'
import internetBill from '../../../assets/internet-bill-cropped.svg'
import cableTv from '../../../assets/cable-tv.svg'
import electricity from '../../../assets/electricity.svg'
import transfer from '../../../assets/tf-to-others-cropped.svg'
import send from '../../../assets/send-to-acc-cropped.svg'
import buyAirtime from '../../../assets/buy-airtime.svg'
import data from '../../../assets/data-subscription.svg'
import pCards from '../../../assets/cards_phone.svg'
import pConvert from '../../../assets/convert_phone.svg'
import pReceipt from '../../../assets/receipt-text_phone.svg'
import pCoin from '../../../assets/coin_phone.svg'
import twoPhonesMobile from '../../../assets/twoPhonesMobile.svg'
import twoPhonesWeb from '../../../assets/twoPhonesWeb.svg'
import landpagecurve from '../../../assets/landpagecurve.png'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import QuickLinksComponent from './QuickLinksComponent'
import FooterComponent from './FooterComponent'
import './LandBody.css'
import AboutCards from './AboutCards'
import FAQ from '../FAQ'
import SellGiftCard from '../../components/SellGiftCard'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import CalcGiftCards from './CalcGiftCards'

const LandBody = ({ selectedOption, setSelectedOption }) => {
    const dispatch = useDispatch()
    const [isOn, setIsOn] = useState(false)
    const navigate = useNavigate()

    const darkmode = useSelector((state) => state.userReducer.dark)
    const [isHovered, setIsHovered] = useState(false)
    const [isHovered1, setIsHovered1] = useState(false)
    const [isHovered2, setIsHovered2] = useState(false)
    const [isHovered3, setIsHovered3] = useState(false)
    const location = useLocation()
    const [isTrueCalc, setIsTrueCalc] = useState(false)
    // console.log('tsR', isTrueCalc)

    const handleClickMobile = (e) => {
        e.preventDefault()
        navigate('/about', { state: { isTrueMobile: true } })
    }

    const handleHover = (e) => {
        e.preventDefault()
        setIsHovered(!isHovered)
    }

    const handleHover1 = (e) => {
        e.preventDefault()
        setIsHovered1(!isHovered1)
    }

    const handleHover2 = (e) => {
        e.preventDefault()
        setIsHovered2(!isHovered2)
    }

    const handleHover3 = (e) => {
        e.preventDefault()
        setIsHovered3(!isHovered3)
    }

    const containerRef = useRef(null)
    const containerRef1 = useRef(null)

    useEffect(() => {
        const handleMouseMoveContainer2 = (e) => {
            const container = containerRef1.current
            handleMouseMove1(container, e)
        }

        const container2 = containerRef1.current

        container2.addEventListener('mousemove', handleMouseMoveContainer2)

        return () => {
            container2.removeEventListener('mousemove', handleMouseMoveContainer2)
        }
    }, [])

    const handleMouseMove1 = (container, e) => {
        const mouseX = e.clientX
        const windowWidth = window.innerWidth
        const containerWidth = container.offsetWidth
        const threshold = 50

        if (mouseX >= windowWidth - threshold) {
            container.scrollTo({
                left: container.scrollWidth - containerWidth,
                behavior: 'smooth' // Smooth scroll to left
            })
        } else if (mouseX <= threshold) {
            container.scrollTo({
                left: 0,
                behavior: 'smooth' // Smooth scroll to left
            })
        }
    }

    useEffect(() => {
        const handleMouseMoveContainer1 = (e) => {
            const container = containerRef.current
            handleMouseMove(container, e)
        }

        const container1 = containerRef.current

        container1.addEventListener('mousemove', handleMouseMoveContainer1)

        return () => {
            container1.removeEventListener('mousemove', handleMouseMoveContainer1)
        }
    }, [])

    const handleMouseMove = (container, e) => {
        const mouseX = e.clientX
        const windowWidth = window.innerWidth
        const containerWidth = container.offsetWidth
        const threshold = 50

        if (mouseX >= windowWidth - threshold) {
            container.scrollTo({
                left: container.scrollWidth - containerWidth,
                behavior: 'smooth' // Smooth scroll to left
            })
        } else if (mouseX <= windowWidth - 1000) {
            container.scrollTo({
                left: 0,
                behavior: 'smooth' // Smooth scroll to left
            })
        }
    }

    const scrollToSection = (sectionId) => {
        const section = document.querySelector(sectionId)

        if (section) {
            // Smooth scroll to the section
            section.scrollIntoView({ behavior: 'smooth' })
        }
    }

    useEffect(() => {
        const newValue = location?.state?.isTrueCalc
        setIsTrueCalc(newValue)

        if (isTrueCalc) {
            scrollToSection('#calc')
        }
    }, [location.state, isTrueCalc])

    useEffect(() => {
        if (darkmode) {
            setIsOn(true)
        } else if (!darkmode) {
            setIsOn(false)
        }
    }, [darkmode])

    return (
        <div className={`${darkmode ? 'bg-darkAll' : 'bg-white'} text-${darkmode ? 'white' : 'black'}`}>
            <SellGiftCard />

            <section className="z-10 relative">
                {' '}
                <img src={landpagecurve} className="w-full" alt="curve" />
                <section className="pl-8 md:pl-20 rounded-3xl bg-cblue -translate-y-8 xl:-translate-y-28">
                    <ul className="ml-5 text-white list-disc -translate-y-4 xl:-translate-y-20 ">
                        <li>
                            <p className="font-poppins text-sm lg:text-2xl">Our Features</p>
                        </li>
                    </ul>

                    <div className="relative z-10 text-white">
                        <p className="font-medium font-poppins md:font-normal text-2xl md:text-4xl mb-12">
                            Explore the list of <br /> features that Flippay has <br /> to offer.{' '}
                        </p>
                        <p className="pr-10 text-center md:text-left font-poppins text-base mb-6">Quick Links</p>
                        <div className="hidden md:flex justify-start mb-8">
                            <QuickLinksComponent linkTitle="Sell Cards" handleClickMobile={handleClickMobile} />
                            <QuickLinksComponent linkTitle="Pay Bills" handleClickMobile={handleClickMobile} />
                            <QuickLinksComponent linkTitle="Transfers" handleClickMobile={handleClickMobile} />
                            <QuickLinksComponent linkTitle="Airtime/Data" handleClickMobile={handleClickMobile} />
                        </div>

                        <div
                            className="pb-12 flex flex-nowrap scrollbar-hide overflow-x-scroll md:hidden"
                            ref={containerRef1}
                        >
                            <div className="mr-3 min-w-max" onClick={handleClickMobile}>
                                <div className="flex justify-center items-center">
                                    <img src={pCards} alt="" className="bg-ccdarkblue rounded-full p-3" />
                                </div>
                                <p className="font-medium text-white text-center">Gift cards</p>
                                <p className="text-center text-xs text-greyIsh w-36">
                                    Sell your cards instantly and safely.
                                </p>
                            </div>

                            <div className="mr-3 min-w-max" onClick={handleClickMobile}>
                                <div className="flex justify-center items-center  min-w-max">
                                    <img src={pConvert} alt="" className="bg-ccdarkblue rounded-full p-3" />
                                </div>
                                <p className="font-medium text-white text-center">Transfers</p>
                                <p className="text-center text-xs text-greyIsh w-36">Send money fast and securely.</p>
                            </div>

                            <div className="mr-3 min-w-max" onClick={handleClickMobile}>
                                <div className="flex justify-center items-center  min-w-max">
                                    <img src={pReceipt} alt="" className="bg-ccdarkblue rounded-full p-3" />
                                </div>
                                <p className="font-medium text-white text-center">Pay bills</p>
                                <p className="text-center text-xs text-greyIsh w-36">
                                    Settle all of your bills in one place.
                                </p>
                            </div>

                            <div className="mr-3 min-w-max" onClick={handleClickMobile}>
                                <div className="flex justify-center items-center  min-w-max">
                                    <img src={pCoin} alt="" className="bg-ccdarkblue rounded-full p-3" />
                                </div>
                                <p className="font-medium text-white text-center">Airtime/data</p>
                                <p className="text-center text-xs text-greyIsh w-36">Top-up on airtime or data subs.</p>
                            </div>
                        </div>
                        <div className="pb-8 hidden md:flex flex-nowrap scrollbar-hide">
                            <div className="mr-5 min-w-max">
                                <div className="bg-cdarkblue pt-8 pl-8 pr-8 rounded-tl-xl rounded-tr-xl">
                                    <p className="text-xl font-medium">
                                        We take care of your <br /> privacy and security.
                                    </p>
                                    <p className="italic text-sm">You matter to us!</p>
                                    <div className="flex justify-center pb-2">
                                        <img src={fingerprint} alt="fingerprint" />
                                    </div>
                                </div>
                                <div
                                    className={`flex justify-between items-center pl-8 pt-4 pr-8 pb-4 ${
                                        darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'
                                    } rounded-bl-xl rounded-br-xl`}
                                >
                                    <p>Flippay puts your safety first</p>
                                    <div>
                                        <img src={securitySafe} alt="security-safe" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-nowrap scrollbar-hide overflow-x-scroll" ref={containerRef}>
                                <div
                                    className="mr-5 min-w-max cursor-pointer"
                                    onMouseEnter={handleHover}
                                    onMouseLeave={handleHover}
                                    onClick={handleClickMobile}
                                >
                                    <div
                                        className={`${
                                            darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'
                                        } pt-8 `}
                                        style={{ borderRadius: '30px 30px 0px 0px' }}
                                    >
                                        <p className="text-xl pl-8 pr-8 font-medium mb-1">Sell Gift Cards</p>
                                        <p className="text-xs pl-8 pr-8 mb-5">Sell your gift cards instantly</p>

                                        <div
                                            className={`pb-2 pl-10 pr-10 bg-top bg-no-repeat ml-1 mr-1 rounded-t-xl bg-phoneChat`}
                                        >
                                            <div>
                                                <img className="ml-10 pt-4" src={itunes} alt="itunes" />
                                            </div>
                                            <div className="mt-3 mb-3">
                                                <img className="mr-10" src={target} alt="target" />
                                            </div>
                                            <div className={`${isHovered ? 'pb-12' : ''}`}>
                                                <img className="ml-10" src={streams} alt="streams" />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`flex justify-between items-center pl-8 pt-4 pr-8 pb-4 bg-cdarkerblue  ${
                                            isHovered ? 'text-cblue' : 'text-white'
                                        }`}
                                        style={{ borderRadius: '0px 0px 30px 30px' }}
                                    >
                                        <p>Sell any gift card</p>
                                        <div>
                                            <FontAwesomeIcon icon={faArrowRightLong} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="mr-5 min-w-max cursor-pointer"
                                    onMouseEnter={handleHover1}
                                    onMouseLeave={handleHover1}
                                    onClick={handleClickMobile}
                                >
                                    <div
                                        className={`${darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'} pt-8`}
                                        style={{ borderRadius: '30px 30px 0px 0px' }}
                                    >
                                        <p className="text-xl pl-8 pr-8 font-medium mb-1">Pay Bills</p>
                                        <p className="text-xs pl-8 pr-8 mb-5">
                                            Easily settle all of your bills in one place.
                                        </p>

                                        <div
                                            className={`pt-2 pb-2 bg-top bg-no-repeat ml-1 mr-1 rounded-t-xl ${
                                                isHovered1 ? 'bg-gradient-to-b from-cblue to-cdarkblue' : 'bg-cblue'
                                            }`}
                                        >
                                            <div>
                                                <img className="ml-20 mr-3" src={internetBill} alt="itunes" />
                                            </div>
                                            <div className="">
                                                <img className="ml-3 mr-20 mt-3 mb-2" src={cableTv} alt="target" />
                                            </div>
                                            <div className={`${isHovered1 ? 'pb-12' : ''}`}>
                                                <img className="ml-20" src={electricity} alt="streams" />
                                            </div>
                                        </div>
                                        <div className="bg-white h-1"></div>
                                    </div>

                                    <div
                                        className={`flex justify-between items-center pl-8 pt-4 pr-8 pb-4 bg-cdarkerblue  ${
                                            isHovered1 ? 'text-cblue' : 'text-white'
                                        }`}
                                        style={{ borderRadius: '0px 0px 30px 30px' }}
                                    >
                                        <p>Pay bills on flippay!</p>
                                        <div>
                                            <FontAwesomeIcon icon={faArrowRightLong} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="mr-5 min-w-max cursor-pointer"
                                    onMouseEnter={handleHover2}
                                    onMouseLeave={handleHover2}
                                    onClick={handleClickMobile}
                                >
                                    <div
                                        className={`${darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'} pt-8`}
                                        style={{ borderRadius: '30px 30px 0px 0px' }}
                                    >
                                        <p className="text-xl pl-8 pr-8 font-medium mb-1">Transfers</p>
                                        <p className="text-xs pl-8 pr-8 mb-5">Send money fast and securely.</p>

                                        <div
                                            className={`pb-2 pl-3 pr-3 bg-top bg-no-repeat ml-1 mr-1 rounded-t-xl bg-iPhone`}
                                        >
                                            <div>
                                                <img className="w-64 pt-11 ml-16" src={send} alt="itunes" />
                                            </div>
                                            <div className={`${isHovered2 ? 'pb-12' : ''}`}>
                                                <img className="w-64 pt-5 pb-3" src={transfer} alt="target" />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`flex justify-between items-center pl-8 pt-4 pr-8 pb-4 bg-cdarkerblue  ${
                                            isHovered2 ? 'text-cblue' : 'text-white'
                                        }`}
                                        style={{ borderRadius: '0px 0px 30px 30px' }}
                                    >
                                        <p>Transfer money</p>
                                        <div>
                                            <FontAwesomeIcon icon={faArrowRightLong} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="mr-5 min-w-max cursor-pointer"
                                    onMouseEnter={handleHover3}
                                    onMouseLeave={handleHover3}
                                >
                                    <div
                                        className={`${darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'} pt-8`}
                                        style={{ borderRadius: '30px 30px 0px 0px' }}
                                    >
                                        <p className="text-xl pl-8 pr-8 font-medium mb-1">Airtime/Data</p>
                                        <p className="text-xs pl-8 pr-8 mb-5">Top-up on airtime or data subs.</p>

                                        <div
                                            className={`pb-2 pl-3 pr-3 bg-top bg-no-repeat ml-1 mr-1 rounded-t-xl bg-topup`}
                                        >
                                            <div>
                                                <img className="w-64 pt-14 ml-16" src={buyAirtime} alt="itunes" />
                                            </div>
                                            <div className={`${isHovered3 ? 'pb-12' : ''}`}>
                                                <img className="w-64 pt-5" src={data} alt="target" />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`flex justify-between items-center pl-8 pt-4 pr-8 pb-4 bg-cdarkerblue  ${
                                            isHovered3 ? 'text-cblue' : 'text-white'
                                        }`}
                                        style={{ borderRadius: '0px 0px 30px 30px' }}
                                    >
                                        <p>Transfer money</p>
                                        <div>
                                            <FontAwesomeIcon icon={faArrowRightLong} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <CalcGiftCards selectedOption={selectedOption} />
            <AboutCards selectedOption={selectedOption} setSelectedOption={setSelectedOption} />

            <FAQ />

            <section className="pl-8 pr-8 -mt-16 md:pl-20 md:pr-20 text-white bg-gradient-to-br from-cdarkestblue to-cblue flex flex-col-reverse md:flex-row justify-between items-center rounded-3xl pt-10 relative -bottom-10 z-40">
                <div className="">
                    <ul className="hidden md:block ml-5 mb-10 list-disc">
                        <li>
                            <p className="font-medium text-2xl translate-y-1">Mobile app </p>
                        </li>
                    </ul>

                    <p className="text-center md:text-left text-2xl md:text-4xl font-medium mb-10">
                        Easier to trade <br />
                        on our mobile app.
                    </p>

                    <p className="mb-5 text-sm md:text-base text-center md:text-left">Download our app here.</p>
                    <div className="flex justify-start pb-10 md:pb-0">
                        <Link to={'https://play.google.com/store/apps/details?id=com.paynyce.flippay'}>
                            <img src={googlePlay} alt="Google Play" />
                        </Link>
                        <Link to={'https://apps.apple.com/app/flippay-sell-gift-cards/id6475052879'} className="ml-3">
                            <img src={appleStore} alt="Apple Store" />
                        </Link>
                    </div>
                </div>

                <div className="flex pb-2">
                    <div
                        className="hidden md:block z-10 relative top-2 right-20 overflow-hidden"
                        style={{ height: '448px' }}
                    >
                        <img src={twoPhonesWeb} alt="" />
                    </div>
                    {/* <div className="hidden md:block relative top-20">
                        <img src={youngerPhone} alt="" />
                    </div> */}

                    <div className="md:hidden">
                        <img src={twoPhonesMobile} alt="" />
                    </div>
                </div>
            </section>

            <section className="pl-8 md:pl-20 md:pr-20 text-white bg-black relative before:w-full before:absolute before:-top-5 before:left-0 before:-bottom-5 before:bg-black before:z-[-1] pt-10">
                <FooterComponent />
            </section>

            <section
                className={`pl-10 pr-10 text-center md:pl-20 md:pr-20 pt-5 relative before:w-full before:absolute before:-top-5 before:left-0 before:bg-white before:z-[-1] flex flex-col md:flex-row justify-between items-center ${
                    darkmode ? 'bg-darkAll' : 'bg-white'
                } text-${darkmode ? 'white' : 'black'} pb-4`}
            >
                <p className="w-52 md:w-auto font-normal text-sm mb-5 md:mb-0">
                    All rights reserved. Copyrights © 2024 Flippay. co.{' '}
                </p>
                <p className="text-sm font-normal">
                    Owned by PayNyce Nigeria Ltd, a parent company registered under the Corporate Affairs Commission
                    (CAC) with RC 1731068
                </p>
            </section>
        </div>
    )
}

export default LandBody
