import React from 'react'
import checkCircle from '../../../assets/check-circle.svg'
import { useSelector } from 'react-redux'


const GPopup = ({ toggleGPopup }) => {
  const darkmode = useSelector((state) => state.userReducer.dark)

  return (
    <div className={`fixed inset-0 flex items-center justify-center z-50 ${darkmode ? 'bg-darkSide' : 'bg-white'} bg-opacity-50`}>
        <div className={`${darkmode ? 'bg-darkSide' : 'bg-white'} p-8 shadow-3xl rounded-3xl flex items-center flex-col`}>
            <div className='flex justify-center'><img src={checkCircle} alt="" /></div>
            <p className='text-4xl font-semibold text-center'>Purchase</p>
            <p className='text-4xl font-semibold text-center'>Successful.</p>
            <div className='flex w-full justify-center mt-6'>
                <button className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-48" onClick={toggleGPopup}>Close</button>
            </div>
        </div>
    </div>
  )
}

export default GPopup