import React from 'react'
import { useSelector } from 'react-redux'

const SidePopup = ({ children, toggleGeneralPopup }) => {
    const darkmode = useSelector((state) => state.userReducer.dark)
    const handleInnerDivClick = (e) => {
      e.stopPropagation();
    };

  return (
    <div className='relative z-50 lg:z-50 overflow-scroll scrollbar-hide'>
      <div onClick={toggleGeneralPopup} className={`fixed top-0 lg:top-20 right-0 w-full lg:w-[calc(100%-288px)] h-screen bg-opacity-50 ${darkmode ? 'bg-darkSide' : 'bg-white'} flex justify-end items-center shadow-2xl`}>
          <div onClick={handleInnerDivClick} className={`shadow-3xl ${darkmode ? 'bg-darkSide' : 'bg-white'} p-8 pt-0 pb-32 w-full lg:w-2/5 h-full overflow-scroll scrollbar-hide`} style={{ pointerEvents: 'auto' }}>
              {children}
          </div>
      </div>

      <div className="absolute top-0 bottom-0 right-0 w-4 bg-gradient-to-l from-transparent to-black"></div>
  </div>
  )
}

export default SidePopup