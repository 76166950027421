import React from 'react'
import circleLoading from '../../assets/circleLoading.png'
import logoSend from '../../assets/logo_send.png'

function SmallLoading({ loading }) {
    if (loading) {
        return (
            <div className="relative w-9 h-9 flex justify-center items-center">
                <img className="absolute customRotate" src={circleLoading} />
                <img className="absolute  " src={logoSend} />
            </div>
        )
    }
    return <div className="w-9 h-9"></div>
}

export default SmallLoading
