import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import userBlue from '../../../assets/user-edit-blue.svg'
import phone from '../../../assets/phone.svg'
import bank from '../../../assets/bank.svg'
import bankBlue from '../../../assets/bank-blue.svg'
import safe from '../../../assets/safe.svg'
import safeBlue from '../../../assets/safe-blue.svg'
import accWallet from '../../../assets/acc-card.svg'
import accWalletBlue from '../../../assets/acc-card-blue.svg'
import verify from '../../../assets/verify.svg'
import Security from './Security/Security'
import Bank from './Bank/Bank'
import Wallet from './Wallet/Wallet'
import PersonalDetails from './PersonalDetails/PersonalDetails'
import Verify from './Verify/Verify'
import { useSelector } from 'react-redux'
import WalletCard3 from '../../UniversalComponents/WalletCard3'
import DownloadStatement from '../../Transaction/TransactionComponents/DownloadStatement'
import duser from '../../../assets/d_user-edit.svg'
import dsafe from '../../../assets/d_security-safe.svg'
import dbank from '../../../assets/d_bank.svg'
import check from '../../../assets/check.svg'
import dwallet from '../../../assets/d_card.svg'
import dverify from '../../../assets/d_verify.svg'
import verifyB from '../../../assets/verify_blue.svg'
import profileAsh from '../../../assets/profile_white.svg'
import MiniTitle from '../../UniversalComponents/MiniTitle'
import FooterComponent from '../../LandingPage/LandingPageComponents/FooterComponent'
import amountcard from '../../../assets/amountcard.png'
import CardProfile from '../../UniversalComponents/CardProfile'
import { useLocation } from 'react-router-dom'

const AccountMid = () => {
    const [isPersonalDetails, setIsPersonalDetails] = useState(false)
    const [isSafe, setIsSafe] = useState(false)
    const [isBank, setIsBank] = useState(false)
    const [isWallet, setIsWallet] = useState(false)
    const [isVerify, setIsVerify] = useState(false)
    const [isDownload, setIsDownload] = useState(false)
    const user = useSelector((state) => state.userReducer.user)
    const darkmode = useSelector((state) => state.userReducer.dark)
    const togglePersonalDetails = () => {
        setIsPersonalDetails(!isPersonalDetails)
        setIsSafe(false)
        setIsBank(false)
        setIsWallet(false)
        setIsVerify(false)
    }

    const toggleSafe = () => {
        setIsSafe(!isSafe)
        setIsPersonalDetails(false)
        setIsBank(false)
        setIsWallet(false)
        setIsVerify(false)
    }

    const toggleBank = () => {
        setIsBank(!isBank)
        setIsPersonalDetails(false)
        setIsWallet(false)
        setIsSafe(false)
        setIsVerify(false)
    }

    const toggleWallet = () => {
        setIsWallet(!isWallet)
        setIsBank(false)
        setIsSafe(false)
        setIsVerify(false)
        setIsPersonalDetails(false)
    }

    const toggleVerify = () => {
        setIsVerify(!isVerify)
        setIsPersonalDetails(false)
        setIsBank(false)
        setIsSafe(false)
        setIsWallet(false)
    }

    const handleDownloadStatementPopup = () => {
        setIsDownload(!isDownload)
    }

    const location = useLocation()
    const isResetTrue = location.state?.isResetTrue

    useEffect(() => {
        // Check if isTrue is true and set isOpen to true
        if (isResetTrue) {
            setIsSafe(true)
        }
    }, [isResetTrue])

    // useEffect(() => {
    //     return () => {
    //           // Cleanup logic here

    //         };
    // }, [user.verified])

    return (
        <div className="max-w-2xl py-3">
            <div
                className={`${darkmode ? 'bg-darkAll' : 'bg-white'} text-${
                    darkmode ? 'white' : 'black'
                } w-full mb-9 lg:mb-0`}
            >
                <div className="px-5 lg:px-0">
                    <MiniTitle sideImage={profileAsh} title="Account" />

                    <p className="text-4xl font-semibold">My account.</p>

                    <p className="text-sm mt-3 mb-8">Update account details.</p>

                    <div className={`block md:flex gap-4 items-end`}>
                        <CardProfile
                            bgimg={amountcard}
                            bgcolor="transaparent"
                            sidecontent={user.verified && <img src={check} className="-translate-y-2" alt="side" />}
                        />
                        <WalletCard3 handleDownloadStatementPopup={handleDownloadStatementPopup} />
                    </div>
                    <DownloadStatement open={isDownload} handleDownloadStatementPopup={handleDownloadStatementPopup} />

                    <div
                        className="flex justify-between px-10 mt-8 rounded-3xl w-full mr-4 cursor-pointer"
                        onClick={togglePersonalDetails}
                    >
                        <div className="flex items-center">
                            <div className="mr-3">
                                <img
                                    src={darkmode && !isPersonalDetails ? duser : isPersonalDetails ? userBlue : phone}
                                    alt=""
                                />
                            </div>
                            <div>
                                <p
                                    className={`${
                                        darkmode && !isPersonalDetails
                                            ? 'text-white'
                                            : isPersonalDetails
                                            ? 'text-cblue'
                                            : 'text-black'
                                    } font-bold`}
                                >
                                    Phone number
                                </p>
                                <p
                                    className={`text-sm ${
                                        darkmode && !isPersonalDetails
                                            ? 'text-properGrey'
                                            : isPersonalDetails
                                            ? 'text-cblue'
                                            : 'text-greyy'
                                    }`}
                                >
                                    Change phone number
                                </p>
                            </div>
                        </div>

                        <div
                            className={`p-3 w-8 h-8 grid place-content-center rounded-full ${
                                isPersonalDetails ? 'bg-cblue' : 'bg-ccdarkblue'
                            }`}
                        >
                            <FontAwesomeIcon
                                icon={isPersonalDetails ? faAngleDown : faAngleRight}
                                className="text-white"
                            />
                        </div>
                    </div>

                    {isPersonalDetails && <PersonalDetails />}

                    <div
                        className="flex justify-between px-10 mt-6 rounded-3xl w-full mr-4 cursor-pointer"
                        onClick={toggleSafe}
                    >
                        <div className="flex items-center">
                            <div className="mr-3">
                                <img src={darkmode && !isSafe ? dsafe : isSafe ? safeBlue : safe} alt="" />
                            </div>
                            <div>
                                <p
                                    className={`${
                                        darkmode && !isSafe ? 'text-white' : isSafe ? 'text-cblue' : 'text-black'
                                    } font-bold`}
                                >
                                    Security
                                </p>
                                <p
                                    className={`text-sm ${
                                        darkmode && !isSafe ? 'text-properGrey' : isSafe ? 'text-cblue' : 'text-greyy'
                                    }`}
                                >
                                    Touch ID, change PIN/password
                                </p>
                            </div>
                        </div>

                        <div
                            className={`p-3 w-8 h-8 grid place-content-center rounded-full ${
                                isSafe ? 'bg-cblue' : 'bg-ccdarkblue'
                            }`}
                        >
                            <FontAwesomeIcon icon={isSafe ? faAngleDown : faAngleRight} className="text-white" />
                        </div>
                    </div>

                    {isSafe && <Security isResetTrue={isResetTrue} />}

                    <div
                        className="flex justify-between px-10 mt-6 rounded-3xl w-full mr-4 cursor-pointer"
                        onClick={toggleBank}
                    >
                        <div className="flex items-center">
                            <div className="mr-3">
                                <img src={darkmode && !isBank ? dbank : isBank ? bankBlue : bank} alt="" />
                            </div>
                            <div>
                                <p
                                    className={`${
                                        darkmode && !isBank ? 'text-white' : isBank ? 'text-cblue' : 'text-black'
                                    } font-bold`}
                                >
                                    Bank Details
                                </p>
                                <p
                                    className={`text-sm ${
                                        darkmode && !isBank ? 'text-properGrey' : isBank ? 'text-cblue' : 'text-greyy'
                                    }`}
                                >
                                    Connect to your local account
                                </p>
                            </div>
                        </div>

                        <div
                            className={`p-3 w-8 h-8 grid place-content-center rounded-full ${
                                isBank ? 'bg-cblue' : 'bg-ccdarkblue'
                            }`}
                        >
                            <FontAwesomeIcon icon={isBank ? faAngleDown : faAngleRight} className="text-white" />
                        </div>
                    </div>

                    {isBank && <Bank />}

                    <div
                        className="flex justify-between px-10 mt-6 rounded-3xl w-full mr-4 cursor-pointer"
                        onClick={toggleWallet}
                    >
                        <div className="flex items-center">
                            <div className="mr-3">
                                <img
                                    src={darkmode && !isWallet ? dwallet : isWallet ? accWalletBlue : accWallet}
                                    alt=""
                                />
                            </div>
                            <div>
                                <p
                                    className={`${
                                        darkmode && !isWallet ? 'text-white' : isWallet ? 'text-cblue' : 'text-black'
                                    } font-bold`}
                                >
                                    Wallet
                                </p>
                                <p
                                    className={`text-sm ${
                                        darkmode && !isWallet
                                            ? 'text-properGrey'
                                            : isWallet
                                            ? 'text-cblue'
                                            : 'text-greyy'
                                    }`}
                                >
                                    Select wallet type
                                </p>
                            </div>
                        </div>

                        <div
                            className={`p-3 w-8 h-8 grid place-content-center rounded-full ${
                                isWallet ? 'bg-cblue' : 'bg-ccdarkblue'
                            }`}
                        >
                            <FontAwesomeIcon icon={isWallet ? faAngleDown : faAngleRight} className="text-white" />
                        </div>
                    </div>

                    {isWallet && <Wallet />}

                    <div
                        className={`flex justify-between px-10 mt-6 mb-6 rounded-3xl w-full mr-4 ${
                            user.verified ? 'opacity-50' : 'cursor-pointer'
                        }`}
                        onClick={user.verified ? null : toggleVerify}
                    >
                        <div className="flex items-center">
                            <div className="mr-3">
                                <img src={darkmode && !isVerify ? dverify : isVerify ? verifyB : verify} alt="" />
                            </div>
                            <div>
                                <p
                                    className={`${
                                        darkmode && !isVerify ? 'text-white' : isVerify ? 'text-cblue' : 'text-black'
                                    } font-bold`}
                                >
                                    Verify account
                                </p>
                                <p
                                    className={`text-sm ${
                                        darkmode && !isVerify
                                            ? 'text-properGrey'
                                            : isVerify
                                            ? 'text-cblue'
                                            : 'text-greyy'
                                    }`}
                                >
                                    Provide any means of ID
                                </p>
                            </div>
                        </div>

                        <div
                            className={`p-3 w-8 h-8 grid place-content-center rounded-full ${
                                isVerify ? 'bg-cblue' : 'bg-ccdarkblue'
                            }`}
                        >
                            <FontAwesomeIcon icon={isVerify ? faAngleDown : faAngleRight} className="text-white" />
                        </div>
                    </div>

                    {isVerify && <Verify />}
                </div>
                <section className="pl-5 text-white bg-black relative rounded-b-3xl md:hidden">
                    <FooterComponent extra={true} />
                </section>

                <section
                    className={`text-center pt-5 relative before:w-full before:absolute before:-top-5 before:left-0 before:-bottom-5 before:bg-white before:z-[-1] flex flex-col md:flex-row justify-between items-center md:hidden ${
                        darkmode ? 'bg-darkAll' : 'bg-white'
                    } text-${darkmode ? 'white' : 'black'}`}
                >
                    <p className="w-52 md:w-auto text-sm mb-5 md:mb-0">
                        All rights reserved. Copyrights © 2023 Flippay. co.{' '}
                    </p>
                    <p className="text-sm">
                        Owned by PayNyce Nigeria Ltd, a parent company registered under the Corporate Affairs Commission
                        (CAC) with RC 1731068
                    </p>
                </section>
            </div>
        </div>
    )
}

export default AccountMid
