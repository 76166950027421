import React from 'react'
import TopupMid from './TopupComponent/TopupMid'
import UserContainer from '../UniversalComponents/UserContainer'

const Topup = () => {
    return (
        <UserContainer>
            <TopupMid />
        </UserContainer>
    )
}

export default Topup
