import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import TransactOne from './TransactOne'
import { Skeleton } from '@mui/material'
import { getAllTransactions } from '../../api'

function RecentTransactions() {
    const [transactions, setTransations] = useState([])
    const [loading, setLoading] = useState(true)
    const [seeAll, setSeeAll] = useState(false)

    useEffect(() => {
        handleGetAllTransactions()
    }, [])

    const handleSeeAll = () => {
        setSeeAll(true)
    }

    const handleGetAllTransactions = async () => {
        setLoading(true)
        try {
            const { data } = await getAllTransactions()
            setTransations(data?.data)
        } catch (err) {}
        setLoading(false)
    }
    return (
        <div>
            <div className="flex justify-between mt-3 items-center">
                <p className="font-medium text-sm mr-16">Recent transactions</p>
                <div className="flex border border-solid border-blue-500 px-2 py-1 " style={{ borderRadius: '20px' }}>
                    <p className="text-xs font-semibold text-cblue cursor-pointer" onClick={handleSeeAll}>
                        See all <FontAwesomeIcon icon={faAngleRight} className="ml-1 text-xs" />
                    </p>
                </div>
            </div>

            <ul className="flex flex-col gap-5 py-8">
                {loading && (
                    <>
                        <Skeleton height={34} style={{ transform: 'scale(1,1)' }} />
                        <Skeleton height={34} style={{ transform: 'scale(1,1)' }} />
                        <Skeleton height={34} style={{ transform: 'scale(1,1)' }} />
                        <Skeleton height={34} style={{ transform: 'scale(1,1)' }} />
                        <Skeleton height={34} style={{ transform: 'scale(1,1)' }} />
                    </>
                )}

                {seeAll
                    ? transactions.map((oneBeneficiary, index) => (
                          <TransactOne key={index} beneficiary={oneBeneficiary} />
                      ))
                    : transactions.map((oneBeneficiary, index) => (
                          <TransactOne key={index} beneficiary={oneBeneficiary} />
                      ))}
            </ul>
        </div>
    )
}

export default RecentTransactions
