const authReducer = (state = { signUpData: null, signInData: '' }, action) => {
    switch (action.type) {
        case 'SIGNUP':
            return { ...state, signUpData: action?.data }

        case 'SIGNIN':
            const response = action?.data
            localStorage.setItem('token', response.token)
            localStorage.setItem('expiresIn', response.expiresIn)

            return { ...state, signInData: action?.data }

        case 'EMAIL_VERIFY':
            const responseEmail = action?.data
            return

        case 'LOGOUT':
            localStorage.clear()
            return { ...state, signInData: action?.data }
        default:
            return state
    }
}

export default authReducer
