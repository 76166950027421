import React from 'react'

const Supportsvg = ({ fill, fill2 }) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill={fill2} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.8333 21.5027H15.1666L9.97497 24.956C9.20497 25.4693 8.16665 24.921 8.16665 23.9877V21.5027C4.66665 21.5027 2.33331 19.1693 2.33331 15.6693V8.66927C2.33331 5.16927 4.66665 2.83594 8.16665 2.83594H19.8333C23.3333 2.83594 25.6666 5.16927 25.6666 8.66927V15.6693C25.6666 19.1693 23.3333 21.5027 19.8333 21.5027Z"
                stroke={fill}
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M14 13.2539V13.009C14 12.2156 14.49 11.7956 14.98 11.4573C15.4584 11.1306 15.9366 10.7106 15.9366 9.94061C15.9366 8.86728 15.0733 8.00391 14 8.00391C12.9267 8.00391 12.0634 8.86728 12.0634 9.94061"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.9948 16.0423H14.0053"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default Supportsvg
