import React from 'react'

const PasswordInput = ({ title, showPassword, toggleShowPassword, value, handleChange }) => {
    return (
        <div className="flex items-center justify-between">
            <div>
                <label htmlFor="currentPassword" className="text-sm font-bold">
                    {title}
                </label>
                <div className="mt-2">
                    <input
                        className="focus:border-none focus:outline-none w-full bg-transparent"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        required
                        placeholder="* * * * * * * * *"
                        value={value}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="cursor-pointer" onClick={toggleShowPassword}>
                {showPassword ? (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_678_53085)">
                            <path
                                d="M8.24999 3.53301C8.8236 3.39874 9.41088 3.33162 9.99999 3.33301C15.8333 3.33301 19.1667 9.99967 19.1667 9.99967C18.6608 10.946 18.0575 11.837 17.3667 12.658M11.7667 11.7663C11.5378 12.012 11.2618 12.209 10.9551 12.3456C10.6485 12.4822 10.3174 12.5557 9.98174 12.5616C9.64606 12.5676 9.31263 12.5058 9.00134 12.3801C8.69004 12.2543 8.40726 12.0672 8.16987 11.8298C7.93247 11.5924 7.74532 11.3096 7.61959 10.9983C7.49385 10.687 7.4321 10.3536 7.43802 10.0179C7.44394 9.68226 7.51742 9.35121 7.65406 9.04455C7.7907 8.73788 7.98771 8.46188 8.23333 8.23301M0.833328 0.833008L19.1667 19.1663M14.95 14.9497C13.5255 16.0355 11.7909 16.6371 9.99999 16.6663C4.16666 16.6663 0.833328 9.99967 0.833328 9.99967C1.8699 8.06792 3.30761 6.38018 5.04999 5.04967L14.95 14.9497Z"
                                stroke="#AAAAAA"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_678_53085">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                ) : (
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_678_53085)">
                            <path
                                d="M8.24999 3.53301C8.8236 3.39874 9.41088 3.33162 9.99999 3.33301C15.8333 3.33301 19.1667 9.99967 19.1667 9.99967C18.6608 10.946 18.0575 11.837 17.3667 12.658M11.7667 11.7663C11.5378 12.012 11.2618 12.209 10.9551 12.3456C10.6485 12.4822 10.3174 12.5557 9.98174 12.5616C9.64606 12.5676 9.31263 12.5058 9.00134 12.3801C8.69004 12.2543 8.40726 12.0672 8.16987 11.8298C7.93247 11.5924 7.74532 11.3096 7.61959 10.9983C7.49385 10.687 7.4321 10.3536 7.43802 10.0179C7.44394 9.68226 7.51742 9.35121 7.65406 9.04455C7.7907 8.73788 7.98771 8.46188 8.23333 8.23301M0.833328 0.833008L19.1667 19.1663M14.95 14.9497C13.5255 16.0355 11.7909 16.6371 9.99999 16.6663C4.16666 16.6663 0.833328 9.99967 0.833328 9.99967C1.8699 8.06792 3.30761 6.38018 5.04999 5.04967L14.95 14.9497Z"
                                stroke="#AAAAAA"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_678_53085">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                )}
            </div>
        </div>
    )
}

export default PasswordInput
