import React from 'react'
import flippayFooter from '../../../assets/flippay-footer.svg'
import tm from '../../../assets/tm.svg'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'


const FooterComponent = () => {
  return (
    <div className='flex flex-col md:flex-row justify-between md:items-center pt-5 pb-2'>
        <div>
            <div className='flex mb-10 md:mb-0'>
                <img src={flippayFooter} alt="" />
                <img src={tm} alt="" className='relative -top-2' />
            </div>

            <p className='text-sm mb-10 md:mb-0'>A Fin-Tech platform that allows <br/> users sell their gift card(s), make bill payments, <br/> buy airtime/data and transfer money to users via <br/> their e-mail address.</p>
        </div> 

        <div className='flex justify-between items-center'>
          <div className='md:pl-10 pr-10'>
            <h1 className='text-white'>Company</h1>
            <Link><p className='text-cblack mt-5 mb:mt-2'>About</p></Link>
            <Link><p className='text-cblack mt-5 mb:mt-2'>Contact us</p></Link>
            <Link><p className='text-cblack mt-5 mb:mt-2'>FAQ</p></Link>
          </div>

          <div className='pl-10 pr-10'>
            <h1 className='text-white'>Products</h1>
            <Link><p className='text-cblack mt-5 mb:mt-2'>Business</p></Link>
            <Link><p className='text-cblack mt-5 mb:mt-2'>Personal</p></Link>
            <Link><p className='text-cblack mt-5 mb:mt-2'>Rate Calculator</p></Link>
          </div>

          <div className='hidden md:flex flex-col items-center'>
              <div className='border-l-2 border-white border-solid h-20'></div>
              <Link><FontAwesomeIcon icon={faInstagram} className='text-2xl my-4 mx-2'/></Link>
              <Link><FontAwesomeIcon icon={faFacebook} className='text-2xl my-4 mx-2'/></Link>
              <Link><FontAwesomeIcon icon={faTwitter} className='text-2xl my-4 mx-2'/></Link>
              <div className='border-l-2 border-white border-solid h-20'></div>
          </div>
        </div>

        <div className='flex flex-row items-center justify-start mt-10 md:hidden'>
            <hr className='w-10 border-white border-solid border-t-2 text-white' />
            <Link><FontAwesomeIcon icon={faInstagram} className='text-2xl my-4 mx-5'/></Link>
            <Link><FontAwesomeIcon icon={faFacebook} className='text-2xl my-4 mx-5'/></Link>
            <Link><FontAwesomeIcon icon={faTwitter} className='text-2xl my-4 mx-5'/></Link>
            <hr className='w-10 border-white border-solid border-t-2 text-white' />
        </div>
    </div>
  )
}

export default FooterComponent;