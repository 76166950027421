import React, { useState } from 'react'
import FaqItem from './FaqItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'


const FaqSection = ({ sectionTitle, faqs }) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleSection = () => {
        setIsOpen(!isOpen)
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

    return (

        <div className="mb-8">
            <div className="flex mb-9 justify-between items-center cursor-pointer" onClick={toggleSection}>
                <h2 className={`text-sm md:text-2xl text-${darkmode ? 'white' : 'txtcolor'} font-poppins font-semibold`}>{sectionTitle}</h2>
                <div className={`${darkmode ? 'bg-white text-black' : 'bg-black text-white'} p-2 rounded-full w-6 md:w-12 h-6 md:h-12 flex justify-center items-center`}>
                    {isOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleRight} />}
                </div>
            </div>
            {isOpen &&
                faqs.map((faq, index) => (
                    <FaqItem key={index} question={faq.question} answer={faq.answer} answers={faq.answers} />
                ))}
        </div>
    )
}

export default FaqSection
