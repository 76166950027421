import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import fcmb from '../../../assets/FCMB.svg'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import PINInput from '../../Account/AccountComponent/Security/PINInput'
import { useSelector } from 'react-redux'
import SidePopup from '../../UniversalComponents/SidePopup'
import Dot from '../../UniversalComponents/Dot'
import ProfileCircle from '../../UniversalComponents/ProfileCircle'
import { bankwithdraw, comparePin } from '../../../api'

const SendToAccount = ({ togglePopup, toggleAlternateSendMoney }) => {
    const [showPassword, setShowPassword] = useState(true)
    const [pin, setPin] = useState('')
    const [amount, setAmount] = useState('')
    const user = useSelector((state) => state.userReducer.user)
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const request = {
        amount: Number(amount)
    }

    const handleChange = async (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }
        setAmount(value)
    }

    const handleClickReset = () => {
        // console.log('working')
        navigate('/account', { state: { isResetTrue: true } })
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const darkmode = useSelector((state) => state.userReducer.dark)

    const handleSendMoney = async () => {
        //compare pin
        try {
            const data = await comparePin({ pin })

            const response = await bankwithdraw(request)
        } catch (err) {
            console.log(err?.response?.data?.message)
            setError(err?.response?.data?.message)
        }
    }
    return (
        <SidePopup>
            <div className="w-full flex flex-col items-center">
                <h2 className="text-cfont32 lg:text-center font-poppins font-semibold mt-24">
                    Send to my account
                    <Dot />{' '}
                </h2>

                <p className="text-sm text-left md:text-center mt-2.5 mb-20">
                    Easily withdraw money from your Flippay wallet to your local account.
                </p>

                <div className="flex items-center border-gray-500 p-2 rounded-3xl">
                    <p className="text-xs font-semibold text-greyy">To</p>
                    <div className="flex items-center mx-5">
                        <ProfileCircle size={32} />

                        <div className="ml-2 flex-1">
                            <p
                                className={`bg-dark text-xs font-poppins font-semibold ${
                                    darkmode ? 'text-white' : 'text-boldgray'
                                }`}
                            >
                                {user?.accName}
                            </p>
                            <p>
                                <span className="text-warn text-xs">Account Number:&nbsp;</span>
                                <span className="text-xs italic text-warn">{user?.accNumber}</span>
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center">
                        {/* <img src={fcmb} alt="" /> */}

                        <p className="bg-dark text-xs ml-1 font-semibold">{user?.bank}</p>
                    </div>
                </div>

                <p className="text-sm  mt-6 mb-8">
                    Use a different account?{' '}
                    <button onClick={toggleAlternateSendMoney} className="text-blue-500 font-bold">
                        Change
                    </button>
                </p>

                <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4">
                    <div className="w-full">
                        <label htmlFor="amount" className="text-sm font-semibold">
                            Amount
                        </label>
                        <div>
                            <input
                                className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]+"
                                id="amount"
                                name="amount"
                                required
                                placeholder="N000,000,000"
                                value={amount}
                                onChange={handleChange}
                            />
                        </div>
                        {/* <div className='text-red-500 text-sm mb-2'>{errors.phone}</div> */}
                    </div>
                    {/* <div className='border-2 border-solid border-red-500'>Warn</div> */}
                </div>

                <PINInput handleOnChange={setPin} showPassword={showPassword} toggleShowPassword={toggleShowPassword} />
                <p className="text-sm mt-6 mb-4">
                    Forgot PIN?{' '}
                    <button onClick={handleClickReset} className="text-blue-500 font-bold">
                        Reset
                    </button>
                </p>

                {error && <p className="text-sm my-5 w-2/3 text-red-500 text-center">{error}</p>}

                <div className="flex w-full justify-center mb-24">
                    <button
                        className="bg-cblue hover:bg-blue-700 text-white font-bold px-3 py-5 rounded-cround w-48 mr-3"
                        onClick={handleSendMoney}
                    >
                        Send Money
                    </button>
                    <button
                        className={`border border-solid border-red-500 text-${
                            darkmode ? 'white' : 'black'
                        } font-bold py-5 px-3 rounded-cround  w-48`}
                        onClick={togglePopup}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </SidePopup>
    )
}

export default SendToAccount
