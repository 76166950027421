import React, { useState } from 'react'
import doorLockDBlue from '../../../../assets/door-lock-dblue.svg'
import PINInput from './PINInput'
import { useDispatch, useSelector } from 'react-redux'

import keyWhite from '../../../../assets/keyWhite.svg'
import CustomModal from '../../../UniversalComponents/CustomModal'
import Dot from '../../../UniversalComponents/Dot'
import { changepin } from '../../../../api'

const CPin = ({ toggleCPin, open }) => {
    const dispatch = useDispatch()
    const [showPassword1, setShowPassword1] = useState(true)
    const [showPassword2, setShowPassword2] = useState(true)
    const [showPassword3, setShowPassword3] = useState(true)
    const [pin, setPin] = useState('')
    const [newPin, setnewPin] = useState('')
    const [confirmNewPin, setconfirmNewPin] = useState('')

    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1)
    }

    const toggleShowPassword2 = () => {
        setShowPassword2(!showPassword2)
    }

    const toggleShowPassword3 = () => {
        setShowPassword3(!showPassword3)
    }
    const handleDisabled = () => {
        if (pin.length != 4 || newPin.length != 4 || confirmNewPin.length != 4 || newPin !== confirmNewPin) {
            return true
        }
        return false
    }
    const handlesubmit = async (e) => {
        e.preventDefault()
        try {
            dispatch({ type: 'SETLOADING' })
            await changepin({ pin, newPin, confirmNewPin })
            toggleCPin()
        } catch (err) {
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

    if (!open) {
        return null
    }
    return (
        <CustomModal open={open} handleClose={toggleCPin}>
            <div
                className={`${
                    darkmode ? 'bg-darkSide text-white' : 'bg-white text-black'
                } p-8 shadow-3xl rounded-3xl w-456`}
            >
                <div className="max-w-xs m-auto">
                    <div className="flex justify-center m-5">
                        <img src={darkmode ? keyWhite : doorLockDBlue} alt="" />
                    </div>
                    <p className="text-cfont32 font-poppins font-semibold text-center">
                        Change PIN
                        <Dot />{' '}
                    </p>
                    <p className="text-sm mt-3 mb-6 text-center ">
                        Enter a secure four-digit PIN for accessing the app. This is different from your password
                    </p>

                    <form action="" className="flex flex-col items-center">
                        <label className="text-sm  text-left w-full font-bold">Old PIN</label>
                        <PINInput
                            handleOnChange={setPin}
                            showPassword={showPassword1}
                            toggleShowPassword={toggleShowPassword1}
                        />
                        <label className="text-sm  text-left  w-full font-bold">Set new PIN</label>
                        <PINInput
                            showPassword={showPassword2}
                            toggleShowPassword={toggleShowPassword2}
                            handleOnChange={setnewPin}
                        />
                        <label className="text-sm  text-left w-full font-bold">Re-enter new PIN</label>
                        <PINInput
                            showPassword={showPassword3}
                            toggleShowPassword={toggleShowPassword3}
                            handleOnChange={setconfirmNewPin}
                        />

                        <div className="flex w-full justify-center mt-10">
                            <button
                                className="bg-cblue text-sm hover:bg-blue-700 text-white font-bold py-5 px-4 rounded-cround w-48 mr-3 disabled:bg-gray-400   "
                                onClick={handlesubmit}
                                type="submit"
                                disabled={handleDisabled()}
                            >
                                Save PIN
                            </button>
                            <button
                                className={`border text-sm border-solid border-red-500 font-bold ${
                                    darkmode ? 'text-white' : 'text-black'
                                } py-5 px-4 rounded-cround w-48`}
                                onClick={toggleCPin}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </CustomModal>
    )
}

export default CPin
