import React from 'react'
import majorCard from '../../assets/major_card.svg'
function GiftCardPreview({ cardValue, selectedCountry, selectedCardCategory }) {
    return (
        <div className="flex flex-col rounded-ccround flex-1 " style={{ backgroundColor: '#CCE5FF80' }}>
            <div className="flex justify-center items-center w-full h-full">
                <img src={majorCard} alt="" />
            </div>
            <div className="mt-auto bg-ccdarkblue px-6 flex justify-between items-center text-xs rounded-b-3xl py-4">
                <p className="text-white font-poppins text-sm">Gift card</p>
                <p className="text-white text-xl font-bold">N{cardValue * selectedCountry[selectedCardCategory]}</p>
            </div>
        </div>
    )
}

export default GiftCardPreview
