import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const QuickLinksComponent = ({ linkTitle, handleClickMobile }) => {
    return (
        <div onClick={handleClickMobile}>
            <Link className="flex items-center justify-center mr-6 text-sm">
                <p className="mr-4 text-sm">{linkTitle}</p>
                <FontAwesomeIcon icon={faAngleRight} />
            </Link>
        </div>
    )
}

export default QuickLinksComponent
