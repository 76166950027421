import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import lWhite from '../../../assets/flippay_logo_white.svg'
import vector from '../../../assets/Vector.svg'
import { resetPassword } from '../../../api'

const NewPassword = () => {
    const [passwords, setPasswords] = useState({
        password: '',
        confirmPassword: ''
    })
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')

    // const { token } = useParams()

    const handleChange = (e) => {
        const { name, value } = e.target

        setPasswords({ ...passwords, [name]: value })
    }

    const handleNewPassword = async (e) => {
        e.preventDefault()
        try {
            // Call the forgotPassword API using Axios

            const response = await resetPassword(token, {
                password: passwords.password,
                confirmPassword: passwords.confirmPassword
            })

            // Handle success, e.g., show a success message

            navigate('/signin')
        } catch (error) {
            // Handle error, e.g., show an error message

            setError(error.response.data.errors[0].msg)
        }
    }

    return (
        <div className="pt-12 md:overflow-y-hidden h-screen bg-emailV bg-50% bg-center-r bg-no-repeat relative">
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-br from-blue-900 via-blue-800 to-purple-900 opacity-70"></div>

            <div className="absolute right-5 top-1 xl:hidden">
                <img src={lWhite} alt="" />
            </div>

            <div className="hidden xl:block absolute xl:bottom-5 xl:right-10">
                <img src={lWhite} alt="" />
            </div>

            <div className="w-full xl:w-1/2 h-full rounded-3xl -top-3 before:rounded-xl before:absolute before:bg-white after:rounded-sm after:absolute after:left-0 after:bg-white after:transform after:-skew-y-0 text-black relative before:w-48 md:before:w-96 before:h-48 md:before:h-96 before:-top-1 md:before:-top-5 before:left-24 md:before:left-48 before:transform before:rotate-45 after:w-48 md:after:w-96 after:h-48 md:after:h-96 after:-top-10 md:after:-top-24">
                <div className="flex justify-end mr-5 relative z-20 top-10">
                    <Link>
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                    </Link>
                </div>

                <div className="bg-white h-screen rounded-3xl pt-6 pb-28 relative z-10 flex flex-col items-center justify-center">
                    <p className="w-4/5 md:w-1/2 text-3xl font-bold mb-2">Enter new password</p>

                    <p className="w-4/5 md:w-1/2">Make sure it is something you can remember.</p>

                    <p className="w-4/5 md:w-1/2">Passwords must be more than 8 characters long.</p>

                    <form action="" className="mt-6 w-4/5 md:w-1/2">
                        <div className="flex items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4">
                            <div className="w-full">
                                <label htmlFor="password" className="text-sm font-semibold">
                                    New Password
                                </label>

                                <div>
                                    <input
                                        className="focus:border-none focus:outline-none w-full"
                                        type="password"
                                        id="password"
                                        name="password"
                                        required
                                        value={passwords.password}
                                        onChange={handleChange}
                                        placeholder="* * * * * * * *"
                                    />
                                </div>
                            </div>
                            {/* <div className='border-2 border-solid border-red-500'>Warn</div> */}
                        </div>

                        <div className="flex items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-8">
                            <div className="w-full">
                                <label htmlFor="confirmPassword" className="text-sm font-semibold">
                                    Re-enter New Password
                                </label>

                                <div>
                                    <input
                                        className="focus:border-none focus:outline-none w-full"
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={passwords.confirmPassword}
                                        onChange={handleChange}
                                        required
                                        placeholder="* * * * * * * *"
                                    />
                                </div>
                            </div>
                            {/* <div className='border-2 border-solid border-red-500'>Warn</div> */}
                        </div>

                        <p className="text-sm italic text-red-500 text-center mb-4">{error}</p>

                        <div>
                            <button
                                className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-full"
                                onClick={handleNewPassword}
                            >
                                Reset
                            </button>
                        </div>
                    </form>
                </div>

                <div className="relative -top-48 md:hidden z-20 -right-10">
                    <img src={vector} className="transform rotate-45 scale-50" alt="" />
                </div>
            </div>
        </div>
    )
}

export default NewPassword
