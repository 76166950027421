import React from 'react'

function Profile({ fill, fill2 }) {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill={fill2} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.1867 12.6823C14.07 12.6707 13.93 12.6707 13.8017 12.6823C11.025 12.589 8.82001 10.314 8.82001 7.51398C8.82001 4.65565 11.13 2.33398 14 2.33398C16.8583 2.33398 19.18 4.65565 19.18 7.51398C19.1683 10.314 16.9633 12.589 14.1867 12.6823Z"
                stroke={fill}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.35334 16.986C5.53001 18.876 5.53001 21.956 8.35334 23.8343C11.5617 25.981 16.8233 25.981 20.0317 23.8343C22.855 21.9443 22.855 18.8643 20.0317 16.986C16.835 14.851 11.5733 14.851 8.35334 16.986Z"
                stroke={fill}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default Profile
