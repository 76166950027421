import * as api from '../../api'

export const getAllTransactionsAction = () => async (dispatch) => {
    try {
        const { data } = await api.getAllTransactions()

        dispatch({ type: 'GET_ALL_TRANSACTIONS', data: data?.data })
    } catch (error) {
        // console.log(error);
    }
}

export const downLoadTransactionAction = (id) => async (dispatch) => {
    try {
        await api.downloadOneTransaction(id)
    } catch (error) {
        // console.log(error);
    }
}
