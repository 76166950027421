import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import sms from '../../../assets/sms-tracking.svg'
import lWhite from '../../../assets/flippay_logo_white.svg'
import vector from '../../../assets/Vector.svg'

const RecoveryLink = () => {
    const location = useLocation()
    const email = location.state && location.state.email
    return (
        <div className="pt-12 h-screen md:overflow-y-hidden bg-emailV bg-50% bg-center-r bg-no-repeat relative">
            <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-br from-blue-900 via-blue-800 to-purple-900 opacity-70"></div>

            <div className="absolute right-5 top-1 xl:hidden">
                <img src={lWhite} alt="" />
            </div>

            <div className="hidden xl:block absolute xl:bottom-5 xl:right-10">
                <img src={lWhite} alt="" />
            </div>

            <div className="w-full xl:w-1/2 h-full rounded-3xl -top-3 before:rounded-xl before:absolute before:bg-white after:rounded-sm after:absolute after:left-0 after:bg-white after:transform after:-skew-y-0 text-black relative before:w-48 md:before:w-96 before:h-48 md:before:h-96 before:-top-1 md:before:-top-5 before:left-24 md:before:left-48 before:transform before:rotate-45 after:w-48 md:after:w-96 after:h-48 md:after:h-96 after:-top-10 md:after:-top-24">
                <div className="bg-white h-screen rounded-3xl pt-6 pb-28 relative z-10 flex flex-col items-center justify-center">
                    <div className="px-10 md:px:0">
                        <div className="flex justify-center">
                            <img src={sms} alt="sms-tracking" />
                        </div>
                        <p className="font-semibold text-left text-4xl mt-6 mb-3">Recovery link sent.</p>
                        <p className="text-justify">
                            A password recovery was sent to, <span className="text-blue-500 font-bold">{email}</span>
                        </p>
                        <p className="text-left md:text-center mb-4">
                            Check your email and click on the link to reset your password.
                        </p>

                        <p>
                            Didn’t receive the email? <Link className="text-blue-500 font-bold">Resend</Link>
                        </p>
                    </div>
                </div>

                <div className="relative -top-48 md:hidden z-20 -right-10">
                    <img src={vector} className="transform rotate-45 scale-50" alt="" />
                </div>
            </div>
        </div>
    )
}

export default RecoveryLink
