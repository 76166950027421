import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTransactionsAction } from '../../redux/actions/transactionAction'
// import cloudD from '../../assets/download-cloud.svg'
import frame from '../../assets/Frame.svg'
import success from '../../assets/success.svg'
import copy from '../../assets/copy.svg'
import error from '../../assets/error.svg'
import ProfileCircle from './ProfileCircle'

function CustomTable({ filterOption, startDate, endDate }) {
    const darkmode = useSelector((state) => state.userReducer.dark)
    const transactions = useSelector((state) => state?.transactionReducer?.transactions)
    const dispatch = useDispatch()

    // const handleDownload = (id) => {
    //     dispatch(downLoadTransactionAction(id))
    // }

    useEffect(() => {
        dispatch(getAllTransactionsAction())
    }, [dispatch])

    const filteredTransactions = transactions.filter((transaction) => {
        const matchesOption =
            filterOption === 'All' ||
            transaction.type === filterOption ||
            (filterOption === 'Debit' && transaction.tranType === 'sending') ||
            (filterOption === 'Credit' && transaction.tranType === 'receiving')

        const dateInRange =
            startDate && endDate
                ? new Date(transaction.updatedAt) >= startDate && new Date(transaction.updatedAt) <= endDate
                : true

        return matchesOption && dateInRange
    })

    return (
        <div>
            <table className={`min-w-full ${darkmode ? 'bg-darkAll' : 'bg-white'}`}>
                <thead>
                    <tr className={`text-sm ${darkmode ? 'text-white' : 'text-txtcolor'}`}>
                        <th className="py-2  text-center"></th>
                        <th className="py-2 px-4  text-left ">Type</th>
                        <th className="py-2 px-4  text-left">Date & Time</th>
                        <th className="py-2 px-4  text-left">Amount</th>
                        <th className="py-2 px-4  text-left">Status</th>
                        <th className="py-2  text-right">Ref Number</th>
                    </tr>
                </thead>
                <tbody className={`text-xs ${darkmode ? 'text-white' : 'text-txtcolor'}`}>
                    {filteredTransactions?.map((transaction, index) => (
                        <tr key={index}>
                            <td className="py-2 text-center">
                                <div className=" flex items-center gap-3">
                                    <ProfileCircle size={32} />
                                    <span> {transaction?.to_from?.split('/')[0]}</span>
                                </div>
                            </td>
                            <td className="py-2 px-4 text-left">{transaction.type}</td>
                            <td className="py-2 px-4 text-left">
                                {transaction?.updatedAt?.split('T')[0]}
                                <br />{' '}
                                {new Date(transaction.updatedAt).toLocaleTimeString('en-US', {
                                    hour12: false,
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                            </td>
                            <td
                                className={`py-2 px-4  text-left ${
                                    transaction.tranType === 'receiving' ? 'text-green-500' : 'text-red-500'
                                } `}
                            >
                                N{transaction.amount}
                            </td>
                            <td className="py-2 px-4 text-left ">
                                <div className="flex items-center gap-1">
                                    <img
                                        src={
                                            transaction?.tranStat === 'successful' ||
                                            transaction?.tranStat === 'pending'
                                                ? success
                                                : error
                                        }
                                        alt=""
                                    />
                                    <span> {transaction.tranStat}</span>
                                </div>
                            </td>
                            <td className="py-2 text-right">
                                <div className="flex items-center gap-1 justify-end">
                                    <span>
                                        {transaction.referenceId.slice(0, 12)}
                                        {transaction.referenceId.length > 12 ? '...' : ''}
                                    </span>
                                    <img className="cursor-pointer" src={copy} alt="" />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {transactions?.length === 0 && (
                <div className="flex flex-col items-center mt-12">
                    <div className="mb-4">
                        <img src={frame} alt="" />
                    </div>
                    <p className="font-medium text-2xl text-greyy">Holla! you must be new here.</p>
                    <p className="text-greyy">Perform a transaction</p>
                </div>
            )}
        </div>
    )
}

export default CustomTable
