import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from '../../../UniversalComponents/PhoneInput'
import PhoneVerificationModal from '../../../../Modals/PhoneVerificationModal'

const PersonalDetails = () => {
    const user = useSelector((state) => state.userReducer.user)
    const [phone, setphone] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        if (user?.phone) {
            setphone(user?.phone?.slice(4))
        }
    }, [user])
    const handleChange = (e) => {
        const { name, value } = e.target
        setphone(value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const handleClick = async () => {
        try {
            // dispatch({ type: 'SETLOADING' })
            // await sendPhoneVerification({
            //     newPhoneNumber: '+234' + phone
            // })
            // openmodal
        } catch (err) {
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }
    const handleDisabled = () => {
        if (phone.length !== 10) {
            return true
        }
        return false
    }
    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <div className={`px-10 lg:px-20 ${darkmode ? 'bg-darkAll text-white' : 'bg-white text-black'}`}>
            <form action="" className="mt-6" onSubmit={handleSubmit}>
                <div className="block xl:flex gap-2">
                    <div className="flex-1 mb-3 xl:mb-0">
                        <PhoneInput
                            title={'Phone number'}
                            name={'phone'}
                            value={phone}
                            handlechange={handleChange}
                            type="tel"
                            placeholder="814 624 1700"
                        />
                    </div>

                    <button
                        onClick={handleClick}
                        disabled={handleDisabled()}
                        className="bg-cblue rounded-cround hover:bg-blue-700 flex-1 text-white font-bold disabled:bg-gray-400 w-1/2 py-3"
                    >
                        Save
                    </button>
                </div>
            </form>
            <PhoneVerificationModal />
        </div>
    )
}

export default PersonalDetails
