import React, { useState } from 'react'
import FaqSection from './FaqSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const FaqPage = () => {
    const [showAllSections, setShowAllSections] = useState(false)

    const toggleShowAllSections = () => {
        setShowAllSections(!showAllSections)
    }
    const faqSections = [
        {
            sectionTitle: 'Airtime/data and bill payment',
            faqs: [
                {
                    question: 'How do I purchase airtime from Flippay?',
                    answers: [
                        'On your dashboard, click on “airtime/data”.',
                        'Select “self” or “others”.',
                        'Input the phone number of the recipient or select from your existing contacts for "others".',
                        'Proceed to enter the amount of airtime and input your pin or use your biometric to confirm the transaction.'
                    ]
                },

                {
                    question: 'How do I purchase a data bundle?',
                    answers: [
                        'On your dashboard, click on “airtime/data”',
                        'Select “self” or “others”',
                        'Input the phone number of the recipient or select from your existing contacts for "others"',
                        'Select the carrier and the prefer data bundle package',
                        'Confirm before proceeding to purchase',
                        'Enter your transaction pin or use your biometrics to complete the transaction.'
                    ]
                },

                {
                    question: 'Can I loan airtime on Flippay?',
                    answers: ['No, we don’t offer loan services on Flippay.']
                },

                {
                    question: 'How do I pay bills on Flippay?',
                    answers: [
                        'Log into your Flippay account',
                        'On your dashboard click on “pay bills”',
                        'Select the bill you want to pay',
                        'Follow the on-screen instruction and fill in the necessary details to complete your transaction.'
                    ]
                },

                {
                    question: 'What type of bill payment does Flippay offer?',
                    answer: 'We offer the following bill payments',
                    answers: ['Internet bill', 'Cable tv (GOTV, StarTimes, etc.)', 'Electricity']
                }
            ]
        },

        {
            sectionTitle: 'Gift cards.',
            faqs: [
                {
                    question: 'Does Flippay sell gift cards?',
                    answers: ['No, we don’t sell gift cards. We only buy.']
                },

                {
                    question: 'Does Flippay offer good rates?',
                    answers: ['No, we don’t sell gift cards. We only buy.']
                },

                {
                    question: 'Can I loan airtime on Flippay?',
                    answers: ['We give the most competitive exchange rate to ensure that you get the best.']
                },

                {
                    question: 'I got an error while transacting.',
                    answers: [
                        'Firstly, make sure your internet connection is active and stable, then refresh the page. If the error persists, kindly reach out to us.'
                    ]
                },

                {
                    question: 'What type of gift cards do you buy?',
                    answers: [
                        'We buy all gift cards listed under the gift card section, if you are unsure about us buying your card, reach out to us with the name of the card you want to sell.'
                    ]
                },

                {
                    question: 'How long does it take to load a gift card?',
                    answers: [
                        'Loading of gift cards take an average time of 3minutes except in cases where the gift card is a store card which can take 1-3hrs. Check the status of your gift card sale by clicking on the transaction in the transaction history.'
                    ]
                },

                {
                    question: 'How long does it take to load a gift card?',
                    answers: [
                        'Loading of gift cards take an average time of 3minutes except in cases where the gift card is a store card which can take 1-3hrs. Check the status of your gift card sale by clicking on the transaction in the transaction history.',
                        'NOTE: Please kindly wait patiently while trading your gift card with us. Do not share the card to a 3rd party to ensure the balance is intact.'
                    ]
                },

                {
                    question: 'How soon will I receive my money after trading my gift cards on Flippay?',
                    answers: [
                        'We aim to complete all trades as soon as possible, and making sure you get your money immediately. Payments are therefore added to your Flippay wallet and not directly to your local bank account.'
                    ]
                }
            ]
        },

        {
            sectionTitle: 'Transfer and withdrawal.',
            faqs: [
                {
                    question: 'Can I get a refund if I incorrectly send money to another Flippay account?',
                    answers: [
                        'No. All transactions are final. Always double-check the beneficiary’s name, email and transaction amount before proceeding to confirm transfer.'
                    ]
                },

                {
                    question:
                        'I sent money to another Flippay account, but the recipient hasn’t received the money yet.',
                    answers: [
                        'Check your transaction history and verify if the email of the recipient was mistyped as it is very unusual for a Flippay to Flippay payment to be delayed as this transactions are completed instantly. Nevertheless, if this occurs, do not hesitate to reach out to our support team at http://flippay.co/contact or hit the support/contact button on the app or website.'
                    ]
                },

                {
                    question: 'How do I send money to another Flippay account?',
                    answers: [
                        'On your home/dashboard tab, click on “Transfers”',
                        'Fill in the necessary details (please ensure the recipient’s details are correct before confirming the transaction).',
                        'Enter a note on the intent of payment as a reference (Optional)',
                        'Verify the details of your transaction once again before sending.',
                        'Transaction will be successful!'
                    ]
                },

                {
                    question: 'Can I transfer money from my Flippay account to my friend’s local bank?',
                    answers: [
                        'No, you can’t. You can only carry out transactions to your families and friends, from your Flippay account to their Flippay account.'
                    ]
                },

                {
                    question: 'Why can’t I withdraw money to my local bank?',
                    answers: [
                        'Kindly be notified that we only allow withdrawals to a users local bank. Make sure the account name on your local bank account matches the one on your Flippay account.'
                    ]
                },

                {
                    question: 'I haven’t received withdrawn money to my local bank yet.',
                    answers: [
                        'Kindly hold on as the transaction may take few minutes or hours before reflecting in your bank. This can also depend on your bank, if after 24hours you don’t receive your money in your local bank, reach out to our support team.'
                    ]
                }
            ]
        },
        // Add more sections as needed
        {
            sectionTitle: 'Account',
            faqs: [
                {
                    question: 'How do I change my account/transaction pin?',
                    answers: [
                        'Login to your Flippay account on your app',
                        'Click on your profile icon in the top right-hand corner.',
                        'Click on security',
                        'Click on change pin',
                        'Enter your old pin, and set your new pin',
                        'Hold on for it to update',
                        'Account/transaction pin successfully changed.'
                    ]
                },

                {
                    question: 'How can I reset my pin?',
                    answers: ['Reset your account password to reset your account pin.']
                },

                {
                    question: 'Why can’t I perform unlimited transactions on my Flippay account?',
                    answers: [
                        'This is because your Flippay account has not been verified. You have to verify your Flippay account to enable you enjoy unlimited transactions on your Flippay account.'
                    ]
                },

                {
                    question: 'How do I verify my Flippay account?',
                    answers: [
                        'Login to your Flippay account on the app',
                        'Click on the profile icon in the top right-hand corner',
                        'Click on personal details',
                        'Click on “Verify account”',
                        'Follow the on-screen prompt to get your account fully verified.'
                    ]
                },

                {
                    question: 'How long does it take to verify my account?',
                    answers: [
                        'Verification is instant except in few cases where a manual verification has to be done on your account. If your submission is approved, you will receive a notification of your verification being accepted, and if rejected, you would also receive a notification and why it failed. At this point, you are to re-submit your documents.'
                    ]
                },

                {
                    question: 'I have forgotten my Flippay account password. What should I do?',
                    answers: [
                        'On the login page of the app, click on “Forgot password”.',
                        'Input your registered email',
                        'A reset link will be sent to your registered email.'
                    ]
                },

                {
                    question: 'Why do I have to set a new pin while resetting my account password?',
                    answers: [
                        'This is because your account password is attached to your pin. So, anytime you reset your account password, you will have to set a new pin.',
                        'After a successful login with your new password, you will be prompted to set a new pin',
                        'You will also be asked to re-enable your biometric. Do so and your biometrics will be re-activated.'
                    ]
                },

                {
                    question: 'I did not receive my one-time password (OTP). What should I do?',
                    answers: [
                        'Please confirm the email address and phone number entered is correct. Also, check your spam or promotion folders for the code. If not seen still, kindly contact support for further assistance or check if you are on DND with your network provider.'
                    ]
                },

                {
                    question: 'How do I change my email address and phone number on the app?',
                    answers: [
                        'Login to your Flippay account on the app.',
                        'Click on the profile icon in the top right-hand corner',
                        'Click on personal details',
                        'Click on the phone number box space and change your phone number.',
                        'Your email address can not be changed.'
                    ]
                },

                {
                    question: 'Why was my ID verification rejected?',
                    answers: ['This occurs when the document submitted is not clear or is expired.']
                },

                {
                    question: 'How do I enable biometrics for transactions?',
                    answers: [
                        'Login to your Flippay account on the app',
                        'Click on the profile icon in the top right-hand corner',
                        'Click on “Security”',
                        'Turn on “Set up touch ID”',
                        'Turn on “Security request”'
                    ]
                },

                {
                    question: 'How do I download my account statement?(on the mobile app)',
                    answers: [
                        'Login to your Flippay account on the app',
                        'Click on the profile icon in the top right-hand corner',
                        'Click on statement download to download your account statements.'
                    ]
                },

                {
                    question: 'I inputted my email address to log in and it was rejected. why, please?',
                    answers: [
                        'kindly confirm you entered your registered email, and also ensure it is correctly spelt.'
                    ]
                },

                {
                    question: 'Why is the one-time password (OTP) I entered, incorrect?',
                    answers: [
                        'You are given a specific time to input your otp after it is sent to your email. If the time given elapses, the OTP will automatically become invalid. However, if this happens, kindly click on the “Resend code” for a new code.'
                    ]
                },

                {
                    question: 'How do I change my app theme to dark mode?',
                    answers: [
                        'Login to your Flippay account on the app',
                        'Click on the profile icon in the top right-hand corner',
                        'Scroll down and enable dark mode.'
                    ]
                },

                {
                    question: 'Note',
                    answers: [
                        'If any of our FAQs on “account” doesn’t solve your problem, please, do not fail or hesitate to reach out to us for further assistance on our social media handle, email (hey@flippay.co), or our support team by clicking the support icon on our website or our mobile app.'
                    ]
                }
            ]
        },

        {
            sectionTitle: 'Common issues.',
            faqs: [
                {
                    question: 'Does Flippay offer loan?',
                    answers: ['No, we don’t offer loan services']
                },

                {
                    question: 'What is the withdrawal limit on Flippay?',
                    answers: ['Withdrawal limit is 500,000 on a single transaction.']
                },

                {
                    question: 'Can I invest my money on Flippay?',
                    answers: ['No, our platform doesn’t offer investment services.']
                },

                {
                    question: 'Why can’t I use Flippay verified services?',
                    answers: [
                        'Please ensure your Flippay account is verified, if it is not, you won’t be able to perform Flippay transactions like email transfers and gift card sales. This limit applies after your first successful gift card sale.'
                    ]
                },

                {
                    question: 'Is there a reward for trading regularly and constantly?',
                    answers: ['You will receive an email or SMS if you are selected for a reward.']
                }
            ]
        }
    ]

    const visibleSections = showAllSections ? faqSections : faqSections.slice(0, 2)

    return (
        <div className="container ">
            {visibleSections.map((section, index) => (
                <FaqSection key={index} sectionTitle={section.sectionTitle} faqs={section.faqs} />
            ))}

            {!showAllSections && (
                <Link
                    to="/support"
                    // onClick={toggleShowAllSections}
                    className="bg-transparent border inline-block  mb-10  border-solid border-blue-600 text-cblue  py-4 px-5 rounded-cround"
                >
                    See more FAQ <FontAwesomeIcon icon={faArrowRightLong} className="ml-2" />
                </Link>
            )}
        </div>
    )
}

export default FaqPage
