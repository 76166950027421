const giftcardReducer = (state = { cards: [] }, action) => {
    switch (action.type) {
        case 'GET_RAW_GIFTCARDS':
            return { ...state, cards: action?.data }
        default:
            return state
    }
}

export default giftcardReducer
