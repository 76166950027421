import axios from 'axios'

const URL = process.env.REACT_APP_SERVER_URL

const API = axios.create({
    baseURL: URL
})

API.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})

export default API
/**
 * AUTENTICATION
 */
export const signUp = (signUpData) =>
    API.post('/auth/signup', signUpData, { withCredentials: false, credentials: 'include' })
export const signIn = (signInData) =>
    API.post('/auth/login', signInData, { withCredentials: false, credentials: 'include' })
export const emailVerify = (email) =>
    API.post('/auth/resend-verification', email, { withCredentials: false, credentials: 'include' })
export const emailVerifyWithCode = (data) =>
    API.post('/auth/email-verification', data, { withCredentials: false, credentials: 'include' })
export const createPin = (data) => API.post('/user/pin', data, { withCredentials: false, credentials: 'include' })
export const changepin = (data) => API.put('/auth/pin/change', data, { withCredentials: false, credentials: 'include' })
export const changepassword = (data) =>
    API.put('/auth/password/change', data, { withCredentials: false, credentials: 'include' })
export const pinReset = (data) => API.put('/auth/pin-reset', data, { withCredentials: false, credentials: 'include' })
export const getUserDetails = () => API.get('/user', { withCredentials: false, credentials: 'include' })
export const sendPhoneVerification = (data) =>
    API.post('/user/update/sendVerificationCode', data, { withCredentials: false, credentials: 'include' })
export const verifyPhoneNumber = (data) =>
    API.post('/user/update/verifyPhoneNum', data, { withCredentials: false, credentials: 'include' })
export const forgotPassword = (email) =>
    API.post('/auth/password-reset/request', email, { withCredentials: false, credentials: 'include' })
export const resetPassword = (token, data) =>
    API.put(`/auth/password-reset/?passwordResetToken=${token}`, data, {
        withCredentials: false,
        credentials: 'include'
    })

export const uploadImage = (data) =>
    API.patch('/user/update/profile-pic', data, {
        withCredentials: false,
        credentials: 'include'
    })

/**
 *  BANK INFOMATIONS
 */
export const getallbanks = () => API.get('/admin/getBanks', { withCredentials: false, credentials: 'include' })
export const verifybankinfo = (data) =>
    API.post('/user/check', data, { withCredentials: false, credentials: 'include' })
export const updateBankInfo = (data) =>
    API.patch('/user/update/bankdetails', data, { withCredentials: false, credentials: 'include' })

/**
 * BENEFICIARIES ENDPOINT
 */
export const getBeneficiary = () => API.get('/user/beneficiaries', { withCredentials: false, credentials: 'include' })
export const createBeneficiary = (data) =>
    API.post('/user/beneficiary', data, { withCredentials: false, credentials: 'include' })
export const deleteBeneficiary = (id) =>
    API.delete(`/user/beneficiary/${id}`, { withCredentials: false, credentials: 'include' })
export const getOneBeneficairy = (id) =>
    API.get(`/user/beneficiary/${id}`, { withCredentials: false, credentials: 'include' })

/**
 * TRANSACTIONS
 */
export const getAllTransactions = () =>
    API.get('/user/transactions', { withCredentials: false, credentials: 'include' })
export const downloadOneTransaction = (id) => API.get(`/user/transaction/${id}/download`)
export const transactionStatement = (query) =>
    API.get(`/user/transactions/statement/download?${query}`, { withCredentials: false, credentials: 'include' })

/**
 * WITHDRAW
 */

export const bankwithdraw = (data) =>
    API.post('/user/withdraw', data, { withCredentials: false, credentials: 'include' })
export const hotspotbankwithdraw = (data) =>
    API.post('/user/withdraw/once', data, { withCredentials: false, credentials: 'include' })
export const initiateAccount = (data) =>
    API.post('/user/transfer/init', data, { withCredentials: false, credentials: 'include' })

export const internalTransfer = (data) => {
    API.post('/user/internal-transfer', data, { withCredentials: false, credentials: 'include' })
}
// export const initializeIdempotencyKey = () => {
//     API.get('/user/initializeIdempotencyKey', { withCredentials: false, credentials: 'include' })
// }

/**
 * BUY AIRTIME, DATA, CABLE
 */
export const getBillInfo = (query) =>
    API.get(`/v3/bill-categories?${query}`, { withCredentials: false, credentials: 'include' })
export const buyAirtime = (billType, data) =>
    API.post(`/user/bill/airtime/${billType}`, data, { withCredentials: false, credentials: 'include' })

export const buyData = (billType, data) =>
    API.post(`/user/bill/data/${billType}`, data, { withCredentials: false, credentials: 'include' })
export const buyCable = (billType, data) =>
    API.post(`/user/bill/cable/${billType}`, data, { withCredentials: false, credentials: 'include' })
export const buyInternet = (billType, data) =>
    API.post(`/user/bill/internet/${billType}`, data, { withCredentials: false, credentials: 'include' })
export const buyElectric = (billType, data) =>
    API.post(`user/bill/electricity/${billType}`, data, { withCredentials: false, credentials: 'include' })
export const validateUser = (itemCode, code, customer) =>
    API.get(`/v3/user/${itemCode}/validate?code=${code}&customer=${customer}`, {
        withCredentials: false,
        credentials: 'include'
    })

/**
 * GIFT CARDS ENDPOINTS
 */

export const getAllRawCards = () => API.get('/user/cards', { withCredentials: false, credentials: 'include' })
export const getAllRawCardsNoAuth = () => API.get('/user/homepage/cards', { withCredentials: false, credentials: 'include' })
export const uploadGiftCard = (body) =>
    API.post('/user/upload', body, { withCredentials: false, credentials: 'include' })

/**
 * COMPARE PIN
 */
export const comparePin = (data) =>
    API.post('/user/pin/compare', data, { withCredentials: false, credentials: 'include' })

/**
 * VERIFICATION
 */

export const verifynin = (data) =>
    API.post('/user/document/verify/NIN/NIN', data, { withCredentials: false, credentials: 'include' })

export const verifybvn = (data) =>
    API.post('/user/document/verify/BVN', data, { withCredentials: false, credentials: 'include' })

export const verifypasspport = (data) =>
    API.post('user/document/verify/passport/:doc', data, { withCredentials: false, credentials: 'include' })
