import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ProfileImage from './ProfileImage'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { getUserBeneficiary } from '../../redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Beneficiary() {
    const beneficiaries = useSelector((state) => state.userReducer.beneficiaries)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()

    useEffect(() => {
        dispatch(getUserBeneficiary(setLoading))
    }, [])

    return (
        <div className="mt-6">
            <p className="font-semibold ">Send money</p>
            <p className="text-xs mt-2">Beneficiaries</p>

            <ul className="flex items-center py-8">
                <li className="ml-10 p-2 w-12 h-12 grid place-items-center border-2 border-solid border-black bg-black  mr-2 rounded-full lg:mr-6">
                    <FontAwesomeIcon icon={faArrowRightLong} className=" text-white text-xs" />
                </li>

                {loading ? (
                    <>
                        <li className="w-12 h-12 lg:mr-6 mr-2 -m-1">
                            <Skeleton className="w-12 h-12" count={1} circle />
                        </li>
                        <li className="w-12 h-12 lg:mr-6 mr-2 -m-1">
                            <Skeleton className="w-12 h-12" count={1} circle />
                        </li>
                        <li className="w-12 h-12 lg:mr-6 mr-2 -m-1">
                            <Skeleton className="w-12 h-12" count={1} circle />
                        </li>
                        <li className="w-12 h-12 -m-1">
                            <Skeleton className="w-12 h-12" count={1} circle />
                        </li>
                    </>
                ) : (
                    beneficiaries?.map((oneBeneficiary, index) => <ProfileImage key={index} />)
                )}
            </ul>
        </div>
    )
}

export default Beneficiary
