import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import LandingPageContainer from './pages/LandingPage/LandingPageContainer'
import AboutPageContainer from './pages/AboutPage/AboutPageContainer'
import SupportPageContainer from './pages/SupportPage/SupportPageContainer'
import Signup from './pages/Authentication/SignupPage/Signup'
import Signin from './pages/Authentication/SigninPage/Signin'
import EmailVerification from './pages/Authentication/EmailVerification/EmailVerification'
import EnterOtp from './pages/Authentication/EnterOtp/EnterOtp'
import CreatePin from './pages/Authentication/CreatePin/CreatePin'
import ForgotPassword from './pages/Authentication/ForgotPassword/ForgotPassword'
import RecoveryLink from './pages/Authentication/RecoveryLink/RecoveryLink'
import NewPassword from './pages/Authentication/NewPassword/NewPassword'
import RecreatePin from './pages/Authentication/RecreatePin/RecreatePin'
import Dashboard from './pages/Dashboard/Dashboard'
import Wallet from './pages/Wallet/Wallet'
import Transaction from './pages/Transaction/Transaction'
import Topup from './pages/Topup/Topup'
import Account from './pages/Account/Account'
import { useSelector } from 'react-redux'
import Loading from './pages/UniversalComponents/Loading'
import GiftCards from './pages/GiftCards/GiftCards'
import Terms from './pages/Terms/Terms'

const router = createBrowserRouter([
    {
        path: '/',
        element: <LandingPageContainer />
    },

    {
        path: 'about',
        element: <AboutPageContainer />
    },

    {
        path: 'support',
        element: <SupportPageContainer />
    },

    {
        path: 'signup',
        element: <Signup />
    },

    {
        path: 'signin',
        element: <Signin />
    },

    {
        path: 'verify/:email',
        element: <EmailVerification />
    },

    {
        path: 'otp/:email',
        element: <EnterOtp />
    },

    {
        path: 'create',
        element: <CreatePin />
    },

    {
        path: 'forgot',
        element: <ForgotPassword />
    },

    {
        path: 'recovery',
        element: <RecoveryLink />
    },

    {
        path: 'resetpassword',
        element: <NewPassword />
    },

    {
        path: 'recreate',
        element: <RecreatePin />
    },

    {
        path: 'dashboard',
        element: <Dashboard />
    },

    {
        path: 'wallet',
        element: <Wallet />
    },

    {
        path: 'transaction',
        element: <Transaction />
    },

    {
        path: 'topup',
        element: <Topup />
    },

    {
        path: 'account',
        element: <Account />
    },

    {
        path: 'giftcard',
        element: <GiftCards />
    },
    {
        path: 'terms',
        element: <Terms />
    }
])

function App() {
    const loading = useSelector((state) => state.userReducer.loading)
    return (
        <div>
            <Loading loading={loading} />

            <RouterProvider router={router} />
        </div>
    )
}

export default App
