import React from 'react'

function PieChart({ fill = '#fff' }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.95 12.9998C21.449 18.0528 17.185 21.9998 12 21.9998C6.477 21.9998 2 17.5228 2 11.9998C2 6.81483 5.947 2.55083 11 2.04983V4.06183C8.98271 4.31851 7.13885 5.3337 5.84319 6.90105C4.54752 8.4684 3.89728 10.4703 4.02462 12.4999C4.15196 14.5294 5.04733 16.4343 6.52874 17.8274C8.01016 19.2206 9.96645 19.9973 12 19.9998C13.9486 19.9998 15.8302 19.2886 17.2917 17.9998C18.7533 16.711 19.6942 14.9331 19.938 12.9998H21.95ZM21.95 10.9998H19.938C19.7154 9.23743 18.9129 7.59916 17.6568 6.34306C16.4007 5.08696 14.7624 4.28441 13 4.06183V2.04883C15.2951 2.28006 17.4398 3.29743 19.0709 4.92865C20.7019 6.55986 21.719 8.70466 21.95 10.9998Z"
                fill={fill}
            />
        </svg>
    )
}

export default PieChart
