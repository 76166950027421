import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function UserDp() {
    return (
        <div
            className=" rounded-full  "
            style={{
                color: '#8b8e92',
                padding: '2px',
                width: '50px',
                height: '50px',
                marginRight: '5px',
                background: 'linear-gradient(127.53deg, #007BFF 15.38%, #213B98 52.19%, #213B98 52.75%)'
            }}
        >
            <div className="bg-white w-full h-full  grid place-items-center  rounded-full ">
                <FontAwesomeIcon icon={faUser} className=" text-3xl" />
            </div>
        </div>
    )
}

export default UserDp
