import React, { useEffect } from 'react'
import TransactionMid from './TransactionComponents/TransactionMid'
import { useDispatch } from 'react-redux'
import { getAllTransactionsAction } from '../../redux/actions/transactionAction'
import UserContainer from '../UniversalComponents/UserContainer'

const Transaction = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllTransactionsAction())
    }, [dispatch])
    return (
        <UserContainer active={'transaction'}>
            <TransactionMid />
        </UserContainer>
    )
}

export default Transaction
