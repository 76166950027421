import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
    format,
    startOfMonth,
    endOfMonth,
    addMonths,
    subMonths,
    eachDayOfInterval,
    isSameMonth,
    getDay
} from 'date-fns'

const MonthlyCalendar = ({ onSelectDate, onClose }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date())

    const handleMonthChange = (increment) => {
        setCurrentMonth((prevMonth) => (increment ? addMonths(prevMonth, 1) : subMonths(prevMonth, 1)))
    }

    const handleDateClick = (date) => {
        onSelectDate(date)
    }
    const darkmode = useSelector((state) => state.userReducer.dark)

    const renderCalendarDays = () => {
        const startDate = startOfMonth(currentMonth)
        const endDate = endOfMonth(currentMonth)
        const daysInMonth = eachDayOfInterval({ start: startDate, end: endDate })

        const dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

        const daysMatrix = Array(6)
            .fill(null)
            .map(() => Array(7).fill(null)) // 6 rows x 7 columns

        daysInMonth.forEach((day) => {
            const dayOfWeek = getDay(day)
            let weekOfMonth = Math.floor((day.getDate() - 1 + getDay(startOfMonth(day))) / 7) // Determine the week of the month

            if (weekOfMonth === -1) weekOfMonth = 5 // Adjust for the last week of the previous month

            daysMatrix[weekOfMonth][dayOfWeek] = day
        })

        return (
            <table className={`${darkmode ? 'bg-darkSide' : 'bg-white'} text-${darkmode ? 'white' : 'black'} w-full`}>
                <thead>
                    <tr>
                        {dayLabels.map((label) => (
                            <th key={label} className="text-center text-sm font-semibold p-2">
                                {label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {daysMatrix.map((week, rowIndex) => (
                        <tr key={rowIndex}>
                            {week.map((day, colIndex) => (
                                <td
                                    key={`${rowIndex}-${colIndex}`}
                                    className={`p-2 cursor-pointer text-center ${
                                        day && darkmode
                                            ? isSameMonth(day, currentMonth) && darkmode
                                                ? 'text-white'
                                                : 'text-white'
                                            : day
                                            ? isSameMonth(day, currentMonth)
                                                ? 'text-black'
                                                : 'text-gray-500'
                                            : 'text-gray-500'
                                    }`}
                                    onClick={() => day && handleDateClick(day)}
                                >
                                    {day ? format(day, 'd') : ''}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    return (
        <div
            className={`fixed inset-0 flex items-center justify-center z-50 ${
                darkmode ? 'bg-darkSide' : 'bg-white'
            } bg-opacity-50`}
        >
            <div
                className={`${
                    darkmode ? 'bg-darkSide' : 'bg-white'
                } p-8 shadow-3xl rounded-3xl flex items-center flex-col w-5/6 md:w-full`}
            >
                <div className="flex justify-between w-full mb-4">
                    <button onClick={() => handleMonthChange(false)}> {'<'} </button>
                    <div className="font-semibold">{format(currentMonth, 'MMMM yyyy')}</div>
                    <button onClick={() => handleMonthChange(true)}> {'>'} </button>
                </div>
                {renderCalendarDays()}
                <button
                    onClick={onClose}
                    className="mt-4 bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl"
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default MonthlyCalendar
