import React from 'react'
import fingerprint from '../../assets/fingerprint.png'
import secure from '../../assets/security-safe-init.svg'
import { useSelector } from 'react-redux'

function CardDetail({
    title,
    subtitle,
    cardimg = fingerprint,
    footertitle,
    footerimg = secure,
    bgcolor = '#CCE5FF',
    footerbgcolor = '#213B98',
    subtitlecolor = '#121212',
    titlecolor = '#121212'
}) {
    const darkmode = useSelector((state) => state.userReducer.dark)
    return (
        <div
            className={`flex flex-1 flex-col pt-5 rounded-ccround mb-7 lg:mb-0 ${
                darkmode ? 'text-black' : 'text-black'
            }`}
            style={{ backgroundColor: bgcolor }}
        >
            <p className="px-4 text-sm font-medium w-3/4" style={{ color: titlecolor }}>
                {title}
            </p>
            <p className="px-4 text-xs italic mt-1.5" style={{ color: subtitlecolor }}>
                {subtitle}
            </p>
            <img className="self-center" src={cardimg} alt="" />
            <div
                className="mt-auto bg-cdarkblue px-6 flex justify-between text-xs rounded-b-3xl py-2"
                style={{ backgroundColor: footerbgcolor }}
            >
                <p className="text-white text-cfont10">{footertitle}</p>
                <img src={footerimg} alt="" />
            </div>
        </div>
    )
}

export default CardDetail
