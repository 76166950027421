import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { signInAction } from '../../../redux/actions/authActions'
import AuthContainer from '../../UniversalComponents/AuthContainer'
import Dot from '../../UniversalComponents/Dot'
import warning from '../../../assets/warning.svg'

const InputWithError = ({ handleChange, formData, errors }) => {
    return (
        <div className="flex items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4">
            <div className="w-full">
                <label htmlFor="email" className="text-boldgray text-sm font-semibold">
                    Email address
                </label>
                <div>
                    <input
                        className="focus:border-none focus:outline-none w-full"
                        type="email"
                        id="email"
                        name="email"
                        required
                        placeholder="example@gmail.com"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
            </div>

            {errors['email'] && (
                <div className="relative">
                    <img src={warning} alt="" />
                    <div
                        className="absolute bottom-7 -right-4 w-28 px-5 py-2 text-center rounded-cround"
                        style={{ background: '#FFE0B2', fontSize: '8px', boxShadow: '0px 0px 3px 2px rgba(0,0,0,.1)' }}
                    >
                        {errors['email']}
                    </div>
                </div>
            )}
        </div>
        // {errors.email}
    )
}
const Signin = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const [errors, setErrors] = useState({})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        localStorage.clear()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })

        let newErrors = { ...errors }

        switch (name) {
            case 'email':
                newErrors.email = !value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)
                    ? 'Please enter a valid email address'
                    : ''
                break
            case 'password':
                // newErrors.password = !value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/)
                //     ? 'Password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one number and one special character'
                //     : ''
                break

            default:
                break
        }

        setErrors(newErrors)
    }

    const validateForm = () => {
        let valid = true
        let newErrors = {}

        // Validate email
        if (!formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
            valid = false
            newErrors.email = 'Please enter a valid email address'
        }

        // Validate password
        // if (!formData.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/)) {
        //     valid = false
        //     newErrors.password =
        //         'Password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one number and one special character'
        // }

        setErrors(newErrors)
        return valid
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateForm()) {
            // Form submission logic here
            dispatch(signInAction(formData, navigate, setErrors, errors))
        } else {
        }
    }

    // const handleCheckboxChange = (e) => {
    //   setAgreedToTerms(e.target.checked);
    // }

    return (
        <AuthContainer>
            <div className=" -translate-y-7 ">
                <p className=" text-4xl  font-normal mb-3  ">
                    Welcome
                    <Dot />
                </p>

                <p className="text-xs w-4/5 md:w-full">Glad to have you back, log in to your account to continue.</p>

                <form action="" className="mt-10" onSubmit={handleSubmit}>
                    <InputWithError errors={errors} formData={formData} handleChange={handleChange} />
                    <div className="flex items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4">
                        <div className="w-full flex items-center">
                            <div className="flex-1">
                                <label htmlFor="password" className="text-sm text-boldgray font-semibold">
                                    Password
                                </label>
                                <input
                                    className="focus:border-none focus:outline-none w-full"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    required
                                    placeholder="********"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>

                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="ml-2 focus:outline-none"
                            >
                                {showPassword ? (
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_678_53085)">
                                            <path
                                                d="M8.24999 3.53301C8.8236 3.39874 9.41088 3.33162 9.99999 3.33301C15.8333 3.33301 19.1667 9.99967 19.1667 9.99967C18.6608 10.946 18.0575 11.837 17.3667 12.658M11.7667 11.7663C11.5378 12.012 11.2618 12.209 10.9551 12.3456C10.6485 12.4822 10.3174 12.5557 9.98174 12.5616C9.64606 12.5676 9.31263 12.5058 9.00134 12.3801C8.69004 12.2543 8.40726 12.0672 8.16987 11.8298C7.93247 11.5924 7.74532 11.3096 7.61959 10.9983C7.49385 10.687 7.4321 10.3536 7.43802 10.0179C7.44394 9.68226 7.51742 9.35121 7.65406 9.04455C7.7907 8.73788 7.98771 8.46188 8.23333 8.23301M0.833328 0.833008L19.1667 19.1663M14.95 14.9497C13.5255 16.0355 11.7909 16.6371 9.99999 16.6663C4.16666 16.6663 0.833328 9.99967 0.833328 9.99967C1.8699 8.06792 3.30761 6.38018 5.04999 5.04967L14.95 14.9497Z"
                                                stroke="#AAAAAA"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_678_53085">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                ) : (
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_678_53085)">
                                            <path
                                                d="M8.24999 3.53301C8.8236 3.39874 9.41088 3.33162 9.99999 3.33301C15.8333 3.33301 19.1667 9.99967 19.1667 9.99967C18.6608 10.946 18.0575 11.837 17.3667 12.658M11.7667 11.7663C11.5378 12.012 11.2618 12.209 10.9551 12.3456C10.6485 12.4822 10.3174 12.5557 9.98174 12.5616C9.64606 12.5676 9.31263 12.5058 9.00134 12.3801C8.69004 12.2543 8.40726 12.0672 8.16987 11.8298C7.93247 11.5924 7.74532 11.3096 7.61959 10.9983C7.49385 10.687 7.4321 10.3536 7.43802 10.0179C7.44394 9.68226 7.51742 9.35121 7.65406 9.04455C7.7907 8.73788 7.98771 8.46188 8.23333 8.23301M0.833328 0.833008L19.1667 19.1663M14.95 14.9497C13.5255 16.0355 11.7909 16.6371 9.99999 16.6663C4.16666 16.6663 0.833328 9.99967 0.833328 9.99967C1.8699 8.06792 3.30761 6.38018 5.04999 5.04967L14.95 14.9497Z"
                                                stroke="#AAAAAA"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_678_53085">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>

                    <div className="">
                        <p className="text-left md:text-center mt-10 mb-5">
                            Forgot password?{' '}
                            <Link to="/forgot" className="text-blue-500 font-bold">
                                Reset
                            </Link>
                        </p>
                    </div>

                    <div>
                        <button className="bg-cblue hover:bg-blue-700 text-white font-bold py-5 px-4 rounded-2xl w-full">
                            Login
                        </button>
                    </div>

                    <div className="w-full mt-4">
                        <p className="text-left md:text-center">
                            Don't have an account?{' '}
                            <Link to="/signup" className="text-blue-500 font-bold">
                                Signup
                            </Link>{' '}
                        </p>
                    </div>
                </form>
            </div>
        </AuthContainer>
    )
}

export default Signin
