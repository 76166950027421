import { faBell } from '@fortawesome/free-regular-svg-icons'
import { faAngleRight, faAngleUp, faMagnifyingGlass, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import flippayLogo from '../../../assets/flippay-logo.svg'
import viewDashboard from '../../../assets/view-dashboard.svg'
import wallet from '../../../assets/wallet-2.svg'
import pieChart from '../../../assets/jam_pie-chart-alt.svg'
import profile from '../../../assets/profile.svg'
import supportImg from '../../../assets/message-question.svg'
import moon from '../../../assets/moon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logOutAction } from '../../../redux/actions/authActions'
import logOut from '../../../assets/logout.svg'
import { getUserAction } from '../../../redux/actions/userActions'
import curvebg from '../../../assets/dashboardcurve.png'
import darkcurvebg from '../../../assets/dashboardcurvedark.png'
import flippayLogoWhite from '../../../assets/flippay_logo_white.svg'
import ViewDashboard from '../../../assets/Viewdashboardsvg.jsx'
import WalletSvg from '../../../assets/Wallet.jsx'
import PieChart from '../../../assets/PieChart.jsx'
import Profile from '../../../assets/Profile.jsx'
import flipWhite from '../../../assets/flip_white.svg'

// import checkTokenExpiration from '../../../authServices/authServices'

const NavBar = ({ active }) => {
    const [scrolled, setScrolled] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const isTokenValid = checkTokenExpiration()
    // console.log('valid?', isTokenValid)

    // useEffect(() => {
    //     if (!isTokenValid) {
    //         dispatch(logOutAction(navigate))
    //     }
    // }, [dispatch, isTokenValid, navigate])

    // if (!isTokenValid) {
    //     dispatch(logOutAction(navigate))
    // }

    useEffect(() => {
        dispatch(getUserAction())
    }, [dispatch])

    const handleLogout = () => {
        dispatch(logOutAction(navigate))
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const name = localStorage.getItem('name')
    const darkmode = useSelector((state) => state.userReducer.dark)

    // const navBarClasses = `hidden z-50 lg:flex items-center ${darkmode ? 'bg-darkAll' : 'bg-white/30'} justify-end pt-4 w-5/6 pb-4 bg-white backdrop-blur w-full sticky `

    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState('')
    const [option, setOption] = useState(false)
    const [changed, setChanged] = useState(false)

    const activestyle =
        'relative after:w-cw after:scale-cs after:h-10 after:w-1 after:bg-blue-400 after:absolute after:right-0 after:rounded-l-lg'

    const options = [
        'American Express',
        "Macy's",
        'Nike',
        'Target visa',
        'Footlocker',
        'Visa gifted',
        'Mastercard giftcard',
        'Walmart visa',
        'Sephora',
        'Vanilla',
        'Amazon',
        'iTunes',
        'Steam',
        'Google play',
        'Nordstrom',
        'Apple universal',
        'Ebay',
        'Razer gold',
        'American express serve'
    ]

    useEffect(() => {
        if (darkmode) {
            setIsOn(true)
        } else if (!darkmode) {
            setIsOn(false)
        }
    }, [darkmode])

    const toggleOption = () => {
        setOption(!option)
    }

    const handleOptionChange = (opt) => {
        setSelectedOption(opt)
        toggleOption()
    }

    const [isOn, setIsOn] = useState(false)

    const handleClick = () => {
        setIsOn(!isOn)
        setChanged(true)
        if (!darkmode) {
            dispatch({ type: 'SETDARKMODE' })
        } else if (darkmode) {
            dispatch({ type: 'CLEARDARKMODE' })
        }
    }

    const toggleNavbar = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div className="relative scrollbar-hide">
            <div
                className={`hidden z-50 lg:flex items-center ${
                    darkmode ? 'bg-darkAll' : 'bg-white/30'
                } justify-end pt-4 pb-4 backdrop-blur w-full sticky `}
            >
                <div className="relative w-4/5 md:w-96 bg-gray-200 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl mb-3">
                    <div className="flex items-center justify-between w-full rounded-2xl py-1 text-xs">
                        <div className="cursor-pointer mr-2" onClick={toggleOption}>
                            <FontAwesomeIcon icon={option ? '' : faMagnifyingGlass} />
                        </div>

                        <input
                            type="text"
                            id="option"
                            readOnly
                            required
                            value={selectedOption}
                            className="outline-none w-full cursor-pointer bg-inherit py-1.5"
                            onClick={toggleOption}
                            placeholder="Type a gift card name"
                        />

                        <div className="cursor-pointer" onClick={toggleOption}>
                            <FontAwesomeIcon icon={option ? faAngleUp : faAngleRight} />
                        </div>
                    </div>

                    {option && (
                        <div
                            className={`absolute right-0 mt-1 w-2/3 ${
                                darkmode
                                    ? 'bg-ccdarkblue text-white border-cdarkblue'
                                    : 'bg-white text-black border-gray-300'
                            } border  rounded-3xl rounded-tr-none shadow-lg z-50`}
                        >
                            <p className="px-4 text-xs font-medium text-warn py-1">Categories</p>
                            {options.map((option) => (
                                <div
                                    key={option}
                                    value={option}
                                    className={`px-4 py-1 cursor-pointer text-xs ${
                                        darkmode ? 'hover:bg-gray-400' : 'hover:bg-gray-100'
                                    }`}
                                    onClick={() => handleOptionChange(option)}
                                >
                                    {option}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className="py-10 px-6 flex justify-between items-center lg:hidden">
                <div>
                    <Link to="/">
                        <img src={darkmode ? flipWhite : flippayLogo} alt="Flippay" />{' '}
                    </Link>
                </div>

                <div className="flex items-center z-50">
                    <div className="ml-20">
                        <FontAwesomeIcon
                            icon={faBell}
                            className={`text-3xl ${darkmode ? 'text-white' : 'text-greyType'}`}
                        />
                    </div>

                    <button onClick={toggleNavbar} className="text-black mx-2">
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke={darkmode && !isOpen ? 'white' : darkmode && isOpen ? 'blue' : 'currentColor'}
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            {isOpen ? (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            ) : (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16m-7 6h7"
                                />
                            )}
                        </svg>
                    </button>

                    <div className="flex items-center">
                        <FontAwesomeIcon
                            icon={faUser}
                            className="p-2 border-2 border-solid rounded-full text-lg"
                            style={{ color: '#8b8e92', borderColor: '#007BFF' }}
                        />
                    </div>
                </div>
            </div>

            {isOpen && (
                <section
                    className={`h-screen z-40 absolute top-0 flex flex-col w-full overflow-y-scroll scrollbar-hide ${
                        darkmode ? 'bg-darkAll' : 'bg-white'
                    }`}
                >
                    {/* <img src={flippayLogoWhite} className="absolute top-8 left-10" alt="" /> */}

                    <img src={darkmode ? darkcurvebg : curvebg} alt="curve" />
                    <div
                        className={`${darkmode ? 'bg-darkSide' : 'bg-ccdarkblue'}  pt-5 flex-1 `}
                        style={{ transform: 'translate(0px, -1px' }}
                    >
                        <div className="pl-10 relative ">
                            <p className="text-cblack mb-5 text-xs">Main</p>

                            <Link to="/dashboard">
                                <div
                                    className={`mt-3 mb-3  flex items-center pt-2 pb-2 ${
                                        active === 'dashboard' ? activestyle : ''
                                    }`}
                                >
                                    <ViewDashboard fill={active === 'dashboard' ? '#55A7FF' : '#fff'} />
                                    <p
                                        className={`ml-3 ${
                                            active === 'dashboard' ? 'text-specialblue' : ' text-white'
                                        }`}
                                    >
                                        Dashboard
                                    </p>
                                </div>
                            </Link>

                            <Link to="/wallet">
                                <div
                                    className={`mt-3 mb-3 flex items-center pt-2 pb-2 ${
                                        active === 'wallet' ? activestyle : ''
                                    }`}
                                >
                                    <WalletSvg fill={active === 'wallet' ? '#55A7FF' : '#fff'} />

                                    <p className={`ml-3 ${active === 'wallet' ? 'text-specialblue' : ' text-white'}`}>
                                        Wallet
                                    </p>
                                </div>
                            </Link>

                            <Link to="/transaction">
                                <div
                                    className={`mt-3 mb-3 flex items-center pt-2 pb-2 ${
                                        active === 'transaction' ? activestyle : ''
                                    }`}
                                >
                                    <PieChart fill={active === 'transaction' ? '#55A7FF' : '#fff'} />
                                    <p
                                        className={`ml-3 ${
                                            active === 'transaction' ? 'text-specialblue' : ' text-white'
                                        }`}
                                    >
                                        Transactions
                                    </p>
                                </div>
                            </Link>

                            <Link to="/account">
                                <div
                                    className={`mt-3 mb-3 flex items-center pt-2 pb-2 ${
                                        active === 'account' ? activestyle : ''
                                    }`}
                                >
                                    <Profile fill={active === 'account' ? '#55A7FF' : '#fff'} />

                                    <p className={`ml-3 ${active === 'account' ? 'text-specialblue' : ' text-white'}`}>
                                        Account
                                    </p>
                                </div>
                            </Link>

                            <Link to="/support">
                                <div className="mt-3 mb-3 flex items-center pt-2 pb-2">
                                    <img src={supportImg} alt="" />
                                    <p className="ml-3 text-white">Support center</p>
                                </div>
                            </Link>
                        </div>

                        <div className="pl-10 mt-28">
                            <p className="text-cblack text-xs mb-8">Management</p>

                            <Link>
                                <div className="mb-6 flex items-center justify-between pt-2 pb-2">
                                    <div className="flex justify-center items-center">
                                        <img src={moon} alt="" />
                                        <p className="ml-1 text-white">Dark mode</p>
                                    </div>

                                    <button
                                        onClick={handleClick}
                                        className={`mr-2 relative w-9 h-5 rounded-full transition-colors duration-300 ${
                                            isOn ? 'bg-cblue' : 'bg-gray-400'
                                        }`}
                                    >
                                        <div
                                            className={`absolute left-0 top-1 w-4 h-4 rounded-full transition-transform duration-300 transform ${
                                                isOn ? 'translate-x-full' : 'translate-x-0'
                                            } bg-white`}
                                        />
                                    </button>
                                </div>
                            </Link>

                            <Link onClick={handleLogout}>
                                <div className="mb-14 flex items-center pt-2 pb-2">
                                    <img src={logOut} alt="" />
                                    <p className="ml-3 text-white">Log Out</p>
                                </div>
                            </Link>

                            <div className="flex">
                                <Link className="text-specialblue">TC &nbsp;</Link>
                                <p className="text-white">& &nbsp; </p>
                                <Link className="text-specialblue">Privacy Policy</Link>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    )
}

export default NavBar
