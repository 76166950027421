import React, { useState } from 'react'

function CustomInput({ title, name, value, handlechange, type = 'text', placeholder }) {
    return (
        <div className="flex items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 w-full mb-4">
            <div className="w-full">
                <label htmlFor="email" className="text-boldgray text-xs font-semibold">
                    {title}
                </label>
                <div>
                    <input
                        className="focus:border-none focus:outline-none w-full bg-transparent"
                        type={type}
                        id={name}
                        name={name}
                        required
                        placeholder={placeholder}
                        value={value}
                        onChange={handlechange}
                    />
                </div>
            </div>
        </div>
    )
}

export const CustomDropdown = ({ title, name, value, handlechange, type = 'text', placeholder, options }) => {
    const [toggle, setToggle] = useState(false)
    return (
        <div className="flex relative items-center justify-between rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 w-full mb-4">
            <div className="w-full">
                <label htmlFor="email" className="text-boldgray text-xs font-semibold">
                    {title}
                </label>
                <div>
                    <input
                        className="cursor-pointer focus:border-none focus:outline-none w-full bg-transparent"
                        type={type}
                        id={name}
                        name={name}
                        required
                        placeholder={placeholder}
                        value={value}
                        readOnly
                        onClick={() => setToggle(!toggle)}
                    />
                </div>
            </div>
            {toggle && (
                <div
                    className={`absolute right-0 mb-20 w-2/3 ${'bg-white text-black border-gray-300'} border  rounded-3xl rounded-tr-none shadow-lg z-50 max-h-96 overflow-scroll`}
                >
                    <p className="px-4 text-xs font-medium text-warn py-1">Categories</p>
                    {options.map((option) => (
                        <div
                            key={option?.code}
                            value={option?.code}
                            className="px-4 py-1 cursor-pointer hover:bg-gray-100 text-xs"
                            onClick={() => {
                                handlechange(option)
                                setToggle(false)
                            }}
                        >
                            {option?.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
export default CustomInput
