import React from 'react'

function Dot() {
    return (
        <span
            className="inline-block"
            style={{
                backgroundColor: '#007BFF',
                width: '6px',
                height: '6px',
                transform: 'translate(0,-6xpx)'
            }}
        ></span>
    )
}

export default Dot
