import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import close from '../../assets/close.svg'
import curvedlogin from '../../assets/curvedlogin.svg'
import { useDispatch } from 'react-redux'
import { signInAction } from '../../redux/actions/authActions'

const AuthContainer = ({ children }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const [errors, setErrors] = useState({})

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        localStorage.clear()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })

        let newErrors = { ...errors }

        switch (name) {
            case 'email':
                newErrors.email = !value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)
                    ? 'Please enter a valid email address'
                    : ''
                break
            case 'password':
                // newErrors.password = !value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/)
                //     ? 'Password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one number and one special character'
                //     : ''
                break

            default:
                break
        }

        setErrors(newErrors)
    }

    const validateForm = () => {
        let valid = true
        let newErrors = {}

        // Validate email
        if (!formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
            valid = false
            newErrors.email = 'Please enter a valid email address'
        }

        // Validate password
        // if (!formData.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/)) {
        //     valid = false
        //     newErrors.password =
        //         'Password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one number and one special character'
        // }

        setErrors(newErrors)
        return valid
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateForm()) {
            // Form submission logic here
            dispatch(signInAction(formData, navigate))
        } else {
        }
    }

    // const handleCheckboxChange = (e) => {
    //   setAgreedToTerms(e.target.checked);
    // }

    return (
        <div className=" h-screen overflow-scroll bg-signup bg-cover bg-no-repeat ">
            <div className="w-full xl:w-1/2 h-screen flex flex-col rounded-3xl relative text-black ">
                <img src={curvedlogin} className="w-full" />

                <div
                    className="grid flex-1 place-content-center bg-white rounded-br-3xl  relative z-10 "
                    style={{ marginTop: '-1px' }}
                >
                    <div className="flex justify-end mr-5 absolute z-20 -top-2 right-3">
                        <Link to={'/'}>
                            <img src={close} />
                        </Link>
                    </div>

                    {children}
                </div>
            </div>
            {/* 
            <div className="absolute right-5 top-3 xl:hidden">
                <img src={lWhite} alt="" />
            </div>

            <div className="hidden xl:block absolute xl:bottom-5 xl:right-10">
                <img src={lWhite} alt="" />
            </div> */}
        </div>
    )
}

export default AuthContainer
