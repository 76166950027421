import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const FaqItem = ({ question, answer, answers }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4">
      <div className="flex justify-start items-center cursor-pointer" onClick={toggleOpen}>
        <div className='mr-5'>{isOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</div>
        <h2 className="text-sm md:text-xl font-semibold">{question}</h2>
        
      </div>
      {isOpen && <div className='text-sm md:text-base pl-10'>{answer}</div>}
      {isOpen && <ol className="list-decimal text-sm md:text-base pl-12 md:pl-20">
        {answers.map((answer, index) => (
          <li key={index}>{answer}</li>
        ))}
      </ol>}
    </div>
  );
};

export default FaqItem;
