import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PINInput from '../../../Account/AccountComponent/Security/PINInput'
import { useDispatch, useSelector } from 'react-redux'
import SidePopup from '../../../UniversalComponents/SidePopup'
import { buyInternet, getBillInfo } from '../../../../api'

const categories = ['Smile', 'Swift', 'MTN', 'Ipnx', 'Spectranet']

const Internet = ({ toggleInternet, togglePayInternet }) => {
    const [selectedOption, setSelectedOption] = useState('')
    const [selectedProdType, setSelectedProdType] = useState('')
    // const [selectedOpType, setSelectedOpType] = useState('')
    const [subNumber, setSubNumber] = useState('')
    const [amount, setAmount] = useState('')

    // const prodTypes = ['postpaid airtimes', 'dealers payments', 'in-betwen']
    // const opTypes = ['pay prepayments', 'pay bills', 'pay deposits']

    const [category, setCategory] = useState(false)
    const [prodType, setProdType] = useState(false)
    // const [opType, setOpType] = useState(false)

    const [showPassword, setShowPassword] = useState(true)
    const [pin, setPin] = useState('')
    const [internetBills, setInternetBills] = useState({})
    const [error, setError] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleClickReset = () => {
        // console.log('working')
        navigate('/account', { state: { isResetTrue: true } })
    }

    useEffect(() => {
        //  FOR CABLE
        getBillInfo('internet=1')
            .then((res) => {
                const { data } = res
                const internetdata = data.data

                const ngInternet = internetdata.filter((intr) => intr.country == 'NG')
                // console.log('ngC', ngInternet)
                const processedData = {
                    SMILE: [],
                    SWIFT: [],
                    MTN: [],
                    IPNX: [],
                    SPECTRANET: []
                }

                // console.log(cablesdata, 'here go')
                for (let intr of ngInternet) {
                    const lowerCaseName = intr.name.toLowerCase()

                    if (lowerCaseName.includes('smile')) {
                        processedData['SMILE'].push(intr)
                    } else if (lowerCaseName.includes('swift')) {
                        processedData['SWIFT'].push(intr)
                    } else if (lowerCaseName.includes('mtn')) {
                        processedData['MTN'].push(intr)
                    } else if (lowerCaseName.includes('ipnx')) {
                        processedData['IPNX'].push(intr)
                    } else if (lowerCaseName.includes('spectranet')) {
                        processedData['SPECTRANET'].push(intr)
                    }
                }
                // console.log(processedData, 'this is processed')
                setInternetBills(processedData)
            })
            .catch((err) => {})
    }, [])

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleCategoryChange = (cat) => {
        setSelectedOption(cat)
        toggleCategory()
    }

    const handleProductChange = (prod) => {
        setSelectedProdType(prod)
        setAmount(prod.amount)
        toggleProdType()
    }

    // const handleOperationsChange = (ops) => {
    //     setSelectedOpType(ops)
    //     toggleOpType()
    // }

    const toggleCategory = () => {
        setCategory(!category)
        setProdType(false)
        // setOpType(false)
    }

    const toggleProdType = () => {
        setProdType(!prodType)
        setCategory(false)
        // setOpType(false)
    }

    // const toggleOpType = () => {
    //     setOpType(!opType)
    //     setCategory(false)
    //     setProdType(false)
    // }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            dispatch({ type: 'SETLOADING' })
            // await validateUser(selectedProdType.item_code, selectedProdType.biller_code, subNumber)
            await buyInternet('internet', {
                itemCode: selectedProdType.item_code,
                number: subNumber,
                code: selectedProdType.biller_code
            })
            togglePayInternet()
        } catch (err) {
            setError(err.response.data.message)
        } finally {
            dispatch({ type: 'CLEARLOADING' })
        }
    }

    const handleDisabled = () => {
        if (!subNumber || !selectedProdType || !amount || pin.length != 4) {
            return true
        }

        return false
    }

    const handleSubNumberChange = (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }
        setSubNumber(value)
    }

    const darkmode = useSelector((state) => state.userReducer.dark)

    return (
        <SidePopup toggleGeneralPopup={toggleInternet}>
            <div className="w-full flex flex-col items-center">
                <h2 className="text-4xl font-semibold mt-24">Internet bill.</h2>

                <p className="text-sm text-center mt-2.5 mb-16 w-4/6">Easily pay your internet bill on Flippay.</p>

                <div className="relative w-4/5 md:w-5/6 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl mb-4">
                    <label htmlFor="category" className="text-sm font-semibold" onClick={toggleCategory}>
                        Select a category
                    </label>
                    <div className="flex items-center justify-between w-full rounded-2xl py-1">
                        <input
                            type="text"
                            id="category"
                            readOnly
                            required
                            value={selectedOption}
                            className="outline-none w-full cursor-pointer bg-transparent"
                            onClick={toggleCategory}
                        />

                        <div className="cursor-pointer" onClick={toggleCategory}>
                            <FontAwesomeIcon icon={category ? faAngleDown : faAngleRight} />
                        </div>
                    </div>

                    {category && (
                        <div
                            className={`absolute right-0 mt-1 w-2/3 border rounded-3xl rounded-tr-none shadow-lg z-20 ${
                                darkmode
                                    ? 'bg-ccdarkblue text-white border-cdarkblue'
                                    : 'bg-white text-black border-gray-300'
                            } max-h-64 scrollbar-hide overflow-y-scroll`}
                        >
                            <p className="px-4 text-xs font-medium text-warn py-1">Categories</p>
                            {categories.map((category) => (
                                <div
                                    key={category}
                                    value={category}
                                    className={`px-4 py-2 cursor-pointer ${
                                        darkmode ? 'hover:bg-gray-400' : 'hover:bg-gray-100'
                                    }`}
                                    onClick={() => handleCategoryChange(category)}
                                >
                                    {category}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="relative w-4/5 md:w-5/6 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl mb-4">
                    <label htmlFor="prodType" className="text-sm font-semibold" onClick={toggleProdType}>
                        Product Type
                    </label>
                    <div className="flex items-center justify-between w-full rounded-2xl py-1">
                        <input
                            type="text"
                            id="prodType"
                            readOnly
                            required
                            value={selectedProdType.biller_name}
                            className="outline-none w-full cursor-pointer bg-transparent"
                            onClick={toggleProdType}
                        />

                        <div className="cursor-pointer" onClick={toggleProdType}>
                            <FontAwesomeIcon icon={prodType ? faAngleDown : faAngleRight} />
                        </div>
                    </div>

                    {prodType && (
                        <div
                            className={`absolute right-0 mt-1 w-2/3 border rounded-3xl rounded-tr-none shadow-lg z-20 ${
                                darkmode
                                    ? 'bg-ccdarkblue text-white border-cdarkblue'
                                    : 'bg-white text-black border-gray-300'
                            } max-h-64 scrollbar-hide overflow-y-scroll`}
                        >
                            <p className="px-4 text-xs font-medium text-warn py-1">Product Types</p>
                            {internetBills[selectedOption.toUpperCase()]?.map((product) => (
                                <div
                                    key={product.id}
                                    value={product}
                                    className={`px-4 py-2 cursor-pointer ${
                                        darkmode ? 'hover:bg-gray-400' : 'hover:bg-gray-100'
                                    }`}
                                    onClick={() => handleProductChange(product)}
                                >
                                    {product.biller_name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-gray-300 mb-4">
                    <div className="w-full flex flex-col items-start">
                        <label htmlFor="subscriberNumber" className="text-sm font-semibold">
                            Subscriber number
                        </label>
                        <div className="w-full">
                            <input
                                className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                type="tel"
                                id="subscriberNumber"
                                name="subscriberNumber"
                                required
                                placeholder="08012066000"
                                value={subNumber}
                                onChange={handleSubNumberChange}
                            />
                        </div>
                    </div>
                </div>

                {/* <div className="relative w-4/5 md:w-5/6 border-gray-300 px-4 border hover:border-gray-300 rounded-2xl mb-4">
                <label htmlFor="opType" className="text-sm font-semibold" onClick={toggleOpType}>
                    Operation Type
                </label>
                <div className="flex items-center justify-between w-full rounded-2xl py-1">
                    <input
                        type="text"
                        id="opType"
                        readOnly
                        // required
                        value={selectedOpType}
                        className="outline-none w-full cursor-pointer bg-transparent"
                        onClick={toggleOpType}
                    />

                    <div className="cursor-pointer" onClick={toggleOpType}>
                        <FontAwesomeIcon icon={opType ? faAngleDown : faAngleRight} />
                    </div>
                </div>

                {opType && (
                    <div className={`absolute right-0 mt-1 w-2/3 border rounded-3xl rounded-tr-none shadow-lg z-20 ${darkmode ? 'bg-ccdarkblue text-white border-cdarkblue' : 'bg-white text-black border-gray-300'} max-h-64 scrollbar-hide overflow-y-scroll`}>
                        <p className='px-4 text-xs font-medium text-warn py-1'>Operation Types</p>
                        {opTypes.map((operation) => (
                            <div
                                key={operation}
                                value={operation}
                                className={`px-4 py-2 cursor-pointer ${darkmode ? 'hover:bg-gray-400' :'hover:bg-gray-100'}`}
                                onClick={() => handleOperationsChange(operation)}
                            >
                                {operation}
                            </div>
                        ))}
                    </div>
                )}
            </div> */}

                <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-gray-300 mb-5">
                    <div className="w-full flex flex-col items-start">
                        <label htmlFor="phoneNum" className="text-sm font-semibold">
                            Amount
                        </label>
                        <div className="w-full">
                            <input
                                className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                type="number"
                                id="amount"
                                name="amount"
                                required
                                placeholder="N000,000"
                                readOnly
                                value={amount}
                            />
                        </div>
                    </div>
                </div>

                <PINInput
                    handleOnChange={setPin}
                    showPassword={showPassword}
                    toggleShowPassword={toggleShowPassword}
                    title={'Enter PIN'}
                />

                <p className="text-sm italic text-center text-red-500">{error}</p>
                <p className="text-sm font-bold mt-6 mb-4">
                    Forgot PIN?{' '}
                    <button onClick={handleClickReset} className="text-blue-500 font-bold">
                        Reset
                    </button>
                </p>
                <div className="flex w-full justify-center mb-24">
                    <button
                        className="bg-cblue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl w-48 mr-3 disabled:bg-gray-600"
                        onClick={handleSubmit}
                        disabled={handleDisabled()}
                    >
                        Pay bill
                    </button>
                    <button
                        className={`border-2 border-solid border-red-500 text-${
                            darkmode ? 'white' : 'black'
                        } font-bold py-2 px-4 rounded-2xl w-48`}
                        onClick={toggleInternet}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </SidePopup>
    )
}

export default Internet
