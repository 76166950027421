import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PINInput from '../../Account/AccountComponent/Security/PINInput'
import shieldTick from '../../../assets/shield-tick.svg'
import checkSmall from '../../../assets/check-circle-small.svg'
import { useSelector } from 'react-redux'
import SidePopup from '../../UniversalComponents/SidePopup'
import ProfileCircle from '../../UniversalComponents/ProfileCircle'
import SmallLoading from '../../UniversalComponents/SmallLoading'
import { comparePin, initiateAccount, internalTransfer } from '../../../api'

const TransferToOthers = ({ toggleSendMoneyPopup2, toggleOthersPopup }) => {
    const [showPassword, setShowPassword] = useState(true)
    const [loading, setLoading] = useState(false)
    const [pin, setPin] = useState('')
    const [amount, setAmount] = useState('')
    const [email, setEmail] = useState('')
    const [accountInformation, setAccountInformation] = useState({})
    const [error, setError] = useState()
    const [errorTwo, setErrorTwo] = useState('')
    const user = useSelector((state) => state.userReducer.user)
    const navigate = useNavigate()

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }
    const darkmode = useSelector((state) => state.userReducer.dark)

    const handleChange = async (e) => {
        const { value } = e.target
        if (isNaN(value)) {
            return
        }
        setAmount(value)
    }

    const request = {
        receiver: email,
        amount: Number(amount)
    }

    const handleClickReset = () => {
        // console.log('working')
        navigate('/account', { state: { isResetTrue: true } })
    }

    const verifyFlipAccount = async (val) => {
        try {
            setError('')
            setLoading(true)
            const { data } = await initiateAccount({ email: val })
            setAccountInformation(data?.data)
        } catch (err) {
            setError(err.response.data.message)
        } finally {
            setLoading(false)
        }
    }

    const handleEmailChange = (e) => {
        const { value } = e.target
        setEmail(value)

        if (value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
            // try debounce

            verifyFlipAccount(value)
        } else {
        }
    }

    const handleSendMoney = async () => {
        //compare pin
        try {
            // Compare pin
            const data = await comparePin({ pin })

            const response = await internalTransfer(request)

            toggleSendMoneyPopup2()
        } catch (error) {
            console.error('Error sending money', error)

            // Handle specific error messages
            if (error?.response?.data?.message) {
                setErrorTwo(error.response.data.message)
            } else {
                // Handle other errors
                setErrorTwo('An error occurred while sending money.')
            }
        }
    }

    return (
        <SidePopup>
            <div className="w-full flex flex-col items-center">
                <h2 className="text-cfont32 lg:text-center font-poppins font-semibold mt-24">Transfer to others.</h2>

                <p className="text-sm text-left md:text-center mt-2.5 mb-16 ">
                    Quickly send money to other users on Flippay.
                </p>

                <div className="flex items-center p-2 rounded-3xl">
                    <p className="text-xs font-semibold text-greyy">From</p>
                    <div className="flex items-center mx-5">
                        <ProfileCircle size={32} />

                        <div className="ml-2">
                            <p className={`bg-dark ${darkmode ? 'text-white' : 'text-boldgray'} text-xs font-semibold`}>
                                {user?.accName}
                            </p>
                            <p>
                                <span className="text-warn text-xs">Account Number:&nbsp;</span>
                                <span className="text-xs italic text-warn">{user?.accNumber}</span>
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center">
                        <img src={shieldTick} alt="" />
                    </div>
                </div>

                <p className="text-sm my-5 w-2/3 text-yellow-500 text-center">
                    You can only transfer to Flippay users via their registered email address.
                </p>

                <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-4">
                    <div className="w-full">
                        <label htmlFor="email" className="text-sm font-semibold">
                            Email address
                        </label>
                        <div>
                            <input
                                className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                type="eamil"
                                id="email"
                                name="email"
                                required
                                placeholder="example@gmail.com"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                        {/* <div className='text-red-500 text-sm mb-2'>{errors.phone}</div> */}
                    </div>
                    {/* <div className='border-2 border-solid border-red-500'>Warn</div> */}
                </div>

                {loading && (
                    <div className="flex my-3 items-center gap-3">
                        <SmallLoading loading={loading} />
                        <p className="text-xs font-normal">Verifying receiver, please wait a minute!</p>
                    </div>
                )}
                {error && <p className="text-sm my-5 w-2/3 text-red-500 text-center">{error}</p>}
                {!loading && accountInformation?.fname && (
                    <div className="flex items-center mb-5 p-2 rounded-3xl">
                        <p className="text-xs font-semibold text-greyy">To</p>
                        <div className="flex items-center mx-5">
                            <ProfileCircle size={32} />

                            <div className="ml-2">
                                <p className="bg-dark text-xs font-semibold">{accountInformation?.fname}</p>
                                <p className="text-xs italic text-warn">{accountInformation?.email}</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <img src={checkSmall} alt="" />
                        </div>
                    </div>
                )}

                <div className="flex items-center justify-between w-4/5 md:w-5/6 rounded-2xl border border-gray-300 px-4 py-1 hover:border-blue-600 mb-7">
                    <div className="w-full">
                        <label htmlFor="amount" className="text-sm font-semibold">
                            Amount
                        </label>
                        <div>
                            <input
                                className="focus:border-none focus:outline-none w-full appearance-none bg-transparent"
                                type="number"
                                id="amount"
                                name="amount"
                                required
                                placeholder="N000,000,000"
                                value={amount}
                                onChange={handleChange}
                            />
                        </div>
                        {/* <div className='text-red-500 text-sm mb-2'>{errors.phone}</div> */}
                    </div>
                    {/* <div className='border-2 border-solid border-red-500'>Warn</div> */}
                </div>

                <PINInput handleOnChange={setPin} showPassword={showPassword} toggleShowPassword={toggleShowPassword} />
                <p className="text-sm  mt-6 mb-4">
                    Forgot PIN?{' '}
                    <button onClick={handleClickReset} className="text-blue-500 font-bold">
                        Reset
                    </button>
                </p>

                {errorTwo && <p className="text-sm my-5 w-2/3 text-red-500 text-center">{errorTwo}</p>}
                <div className="flex w-full justify-center mb-24">
                    <button
                        className="bg-cblue hover:bg-blue-700 text-white font-bold px-3 py-5  rounded-cround w-48 mr-3"
                        onClick={handleSendMoney}
                    >
                        Send Money
                    </button>
                    <button
                        className={`border border-solid border-red-500 text-${
                            darkmode ? 'white' : 'black'
                        } font-bold px-3 py-5 rounded-cround w-48`}
                        onClick={toggleOthersPopup}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </SidePopup>
    )
}

export default TransferToOthers
