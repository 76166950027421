import React, { useState } from 'react';
import FaqItem from './FaqItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleUp } from '@fortawesome/free-solid-svg-icons';

const FaqSection = ({ sectionTitle, faqs }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleSection}>
        <h2 className="text-sm md:text-2xl font-semibold mb-4">{sectionTitle}</h2>
        <div className='bg-white w-8 h-8 grid place-content-center text-black p-3 rounded-full'>{isOpen ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleRight} />}</div>
      </div>
      {isOpen && faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} answers={faq.answers} />
      ))}
    </div>
  );
};

export default FaqSection;
